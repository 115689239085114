import React from "react"
import PropTypes from "prop-types"
import styled, { withTheme } from "styled-components"
import Box from "components/Box"
import CircleImage from "components/CircleImage"
import gradientGenerator from "utils/gradientGenerator"

const DEFAULT_AVATAR_IMAGE =
  "https://s3-us-west-2.amazonaws.com/soundstripe-production/assets/images/fallback/default_image_thumb.png"

const CIRCLE_SIZES = {
  sm: "28px",
  md: "32px",
  lg: "40px",
}

const TEXT_SIZES = {
  sm: "10px",
  md: "12px",
  lg: "14px",
}

const StyledCircleImage = styled(CircleImage)`
  box-shadow: ${({ theme }) => theme.shadows.default};
`

const Avatar = ({ avatarUrl, id, initials, size, theme, archived }) => {
  const diameter = CIRCLE_SIZES[size]
  const avatarIndex = parseInt(id, 10)
  const background = archived
    ? theme.colors.border.default
    : gradientGenerator(isNaN(avatarIndex) ? 1 : avatarIndex)

  return (
    <Box
      height={diameter}
      width={diameter}
      aria-hidden="true"
      style={{ opacity: archived ? 0.9 : 1 }}
    >
      {avatarUrl === DEFAULT_AVATAR_IMAGE && initials ? (
        <Box
          fontSize={TEXT_SIZES[size]}
          fontWeight="semiBold"
          letterSpacing={4}
          pl="2px"
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          userSelect="none"
          color="white"
          borderRadius="circle"
          background={background}
          boxShadow="default"
          pb={size === "lg" ? "2px" : "1px"}
        >
          {initials.toUpperCase()}
        </Box>
      ) : (
        <StyledCircleImage src={avatarUrl} />
      )}
    </Box>
  )
}

Avatar.propTypes = {
  avatarUrl: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  initials: PropTypes.string,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  archived: PropTypes.bool,
}

Avatar.defaultProps = {
  avatarUrl: DEFAULT_AVATAR_IMAGE,
  id: 1,
  size: "md",
  archived: false,
}

export default withTheme(Avatar)
