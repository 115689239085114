export const VOCALS = "Vocals"
export const INSTRUMENTAL = "Instrumental"
export const ALL = "All"
export const ARTISTS = "Artists"
export const PLAYLISTS = "Playlists"
export const KEYS = "Keys"

export const DURATION_MIN = 0
export const DURATION_MAX = 480

export const BPM_MIN = 0
export const BPM_MAX = 300

export const SORTING_INDEX_MAP = {
  popular: process.env.ALGOLIA_SONG_INDEX_NAME,
  recent: `${process.env.ALGOLIA_SONG_INDEX_NAME}_most_recent`,
}

export const PARAMS = {
  ARTIST_IDS: "filter[artist_ids][]",
  BPM_MAX: "filter[bpm][max]",
  BPM_MIN: "filter[bpm][min]",
  CHARACTERISTIC: "filter[tags.characteristic][]",
  DURATION_INCLUDE_ALTERNATES: "filter[duration][include_alternates]",
  DURATION_MAX: "filter[duration][max]",
  DURATION_MIN: "filter[duration][min]",
  ENERGY: "filter[energy][]",
  GENRE: "filter[tags.genre][]",
  INSTRUMENT: "filter[tags.instrument][]",
  KEY: "filter[key][]",
  MODE: "filter[mode]",
  MOOD: "filter[tags.mood][]",
  PLAYLIST_IDS: "filter[playlist_ids][]",
  SEARCH: "filter[q]",
  SIMILAR_SONG_IDS: "filter[similar_ids][]",
  SIMILAR_SONG_PARENT_ID: "filter[parent_id]",
  SIMILAR_SONG_NAME: "filter[similar_name]",
  SONG_TIER: "filter[song_tier][]", // Used for Marketplace
  SORT_BY: "sort",
  STEMS: "filter[stems]",
  FREE: "filter[free]",
  PREVIOUSLY_LICENSED: "filter[previously_downloaded]",
  PLAYED_TODAY: "filter[played_today]",
  VOCALS: "filter[vocals]",
  NEGATIVE: {
    MOOD: "filter[neg.tags.mood][]",
    CHARACTERISTIC: "filter[neg.tags.characteristic][]",
    GENRE: "filter[neg.tags.genre][]",
    INSTRUMENT: "filter[neg.tags.instrument][]",
    ENERGY: "filter[neg.energy][]",
    ARTIST_IDS: "filter[neg.artist_ids][]",
    PREVIOUSLY_LICENSED: "filter[neg.previously_downloaded]",
    PLAYED_TODAY: "filter[neg.played_today]",
  },
}

export const FACET_TYPES = {
  MOOD: "tags.mood",
  CHARACTERISTIC: "tags.characteristic",
  GENRE: "tags.genre",
  INSTRUMENT: "tags.instrument",
  ENERGY: "energy",
  KEYS: "key.name",
  MODE: "key.mode",
  ARTISTS: "artists.name",
  PLAYLISTS: "playlists.name",
  VOCAL: "has_vocal_version",
  SONG_TIER: "song_tier", // Used for Marketplace
  STEMS: "has_stems",
  FREE: "free",
}

export const TAG_FILTER_RANKS = {
  CHARACTERISTIC: "tag_ranks.characteristic",
  GENRE: "tag_ranks.genre",
  INSTRUMENT: "tag_ranks.instrument",
  MOOD: "tag_ranks.mood",
}

export const SECTIONS = {
  ARTIST: "Artist",
  BPM: "BPM",
  ADVANCED: "Advanced",
  DURATION: "Duration",
  GENRE: "Genre",
  INSTRUMENT: "Instrument",
  KEY: "Key",
  MOOD: "Mood",
  ENERGY: "Energy",
  CHARACTERISTIC: "Characteristic",
  PLAYLIST: "Playlist",
  SORT_BY: "Sort By",
  VOCAL_DEGREE: "Vocals / Inst",
}

export const FILTERS = {
  ARTISTS: "artists",
  PLAYLISTS: "playlists",
  MOOD: "moodTags",
  GENRE: "genreTags",
  ENERGY: "energyTags",
  CHARACTERISTIC: "characteristicTags",
  INSTRUMENT: "instrumentTags",
  SONG_TIER: "song_tier", // Used for Marketplace
}

export const keysList = [
  { id: 0, name: "Ab", value: "Ab/G#" },
  { id: 1, name: "A", value: "A" },
  { id: 2, name: "A#", value: "Bb/A#" },
  { id: 3, name: "Bb", value: "Bb/A#" },
  { id: 4, name: "B", value: "B" },
  { id: 5, name: "", value: "" },
  { id: 6, name: "", value: "" },
  { id: 7, name: "C", value: "C" },
  { id: 8, name: "C#", value: "Db/C#" },
  { id: 9, name: "Db", value: "Db/C#" },
  { id: 10, name: "D", value: "D" },
  { id: 11, name: "D#", value: "Eb/D#" },
  { id: 12, name: "Eb", value: "Eb/D#" },
  { id: 13, name: "E", value: "E" },
  { id: 14, name: "", value: "" },
  { id: 15, name: "", value: "" },
  { id: 16, name: "F", value: "F" },
  { id: 17, name: "F#", value: "Gb/F#" },
  { id: 18, name: "Gb", value: "Gb/F#" },
  { id: 19, name: "G", value: "G" },
  { id: 20, name: "G#", value: "Ab/G#" },
]

export const modes = [
  { id: 0, name: "Major" },
  { id: 1, name: "Minor" },
]

export const FILTER_CONFIG = {
  MOOD: {
    algoliaFilter: FILTERS.MOOD,
    facetType: FACET_TYPES.MOOD,
    params: {
      active: PARAMS.MOOD,
      negative: PARAMS.NEGATIVE.MOOD,
    },
  },
  CHARACTERISTIC: {
    algoliaFilter: FILTERS.CHARACTERISTIC,
    facetType: FACET_TYPES.CHARACTERISTIC,
    params: {
      active: PARAMS.CHARACTERISTIC,
      negative: PARAMS.NEGATIVE.CHARACTERISTIC,
    },
  },
  GENRE: {
    algoliaFilter: FILTERS.GENRE,
    facetType: FACET_TYPES.GENRE,
    params: {
      active: PARAMS.GENRE,
      negative: PARAMS.NEGATIVE.GENRE,
    },
  },
  ENERGY: {
    algoliaFilter: FILTERS.ENERGY,
    facetType: FACET_TYPES.ENERGY,
    params: {
      active: PARAMS.ENERGY,
      negative: PARAMS.NEGATIVE.ENERGY,
    },
  },
  INSTRUMENT: {
    algoliaFilter: FILTERS.INSTRUMENT,
    facetType: FACET_TYPES.INSTRUMENT,
    tagRank: TAG_FILTER_RANKS.INSTRUMENT,
    params: {
      active: PARAMS.INSTRUMENT,
      negative: PARAMS.NEGATIVE.INSTRUMENT,
    },
  },
  KEY: {
    facetType: FACET_TYPES.KEYS,
    joinType: "or",
    params: {
      active: PARAMS.KEY,
    },
  },
  MODE: {
    facetType: FACET_TYPES.MODES,
    params: {
      active: PARAMS.MODE,
    },
  },
  ARTIST: {
    algoliaFilter: FILTERS.ARTISTS,
    facetType: FACET_TYPES.ARTISTS,
    params: {
      active: PARAMS.ARTIST_IDS,
      negative: PARAMS.NEGATIVE.ARTIST_IDS,
    },
  },
  PLAYLIST: {
    algoliaFilter: FILTERS.PLAYLISTS,
    facetType: FACET_TYPES.PLAYLISTS,
    joinType: "or",
    params: {
      active: PARAMS.PLAYLIST_IDS,
      negative: PARAMS.NEGATIVE.PLAYLIST_IDS,
    },
  },
  VOCAL: {
    facetType: FACET_TYPES.VOCAL,
    params: {
      active: PARAMS.VOCALS,
    },
  },
  DURATION_INCLUDE_ALTERNATES: {
    facetType: FACET_TYPES.DURATION,
    params: {
      active: PARAMS.DURATION_INCLUDE_ALTERNATES,
    },
  },
  PREVIOUSLY_LICENSED: {
    facetType: "previously_downloaded",
    params: {
      active: PARAMS.PREVIOUSLY_LICENSED,
      negative: PARAMS.NEGATIVE.PREVIOUSLY_LICENSED,
    },
  },
  PLAYED_TODAY: {
    facetType: "played_today",
    params: {
      active: PARAMS.PLAYED_TODAY,
      negative: PARAMS.NEGATIVE.PLAYED_TODAY,
    },
  },
  STEMS: {
    facetType: FACET_TYPES.STEMS,
    params: {
      active: PARAMS.STEMS,
    },
  },
  FREE: {
    facetType: FACET_TYPES.FREE,
    params: {
      active: PARAMS.FREE,
    },
  },
  DURATION: {
    peaks: {
      min: DURATION_MIN,
      max: DURATION_MAX,
    },
    params: {
      min: PARAMS.DURATION_MIN,
      max: PARAMS.DURATION_MAX,
    },
  },
  BPM: {
    peaks: {
      min: BPM_MIN,
      max: BPM_MAX,
    },
    params: {
      min: PARAMS.BPM_MIN,
      max: PARAMS.BPM_MAX,
    },
  },
  SONG_TIER: {
    algoliaFilter: FILTERS.SONG_TIER,
    facetType: FACET_TYPES.SONG_TIER,
    joinType: "or",
    params: {
      active: PARAMS.SONG_TIER,
    },
  },
}
