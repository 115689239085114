import { createAction } from "./utils"

export const types = {
  FAVORITE: "soundstripe/favorites/FAVORITE",
  UNFAVORITE: "soundstripe/favorites/UNFAVORITE",
}

export const actions = {
  favorite: createAction(types.FAVORITE, "record"),
  unfavorite: createAction(
    types.UNFAVORITE,
    "record",
    "infListStoreKey",
    "algoliaListStoreKey"
  ),
}
