import { LOCATION_CHANGE } from "react-router-redux"
import { createSelector } from "reselect"

export default function(state = [], action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return [...state, action.payload]
    default:
      return state
  }
}

const urlHistoryState = (state) => state.getIn(["ducks", "urlHistory"])

export const selectUrlHistory = () =>
  createSelector(urlHistoryState, (urlHistory) => urlHistory)
