import { call, put, takeLatest, select } from "redux-saga/effects"
import { push } from "react-router-redux"
import { stopSubmit } from "redux-form/immutable"
import { post, patch } from "utils/request"
import { actions as apiActions } from "ducks/api"
import { actions as modalActions } from "ducks/modal"
import { storeToken } from "utils/authentication"
import { actions as cookieActions } from "middlewares/cookies"
import { types, actions } from "ducks/marketplace"
import { UTM_PARAMS } from "utils/tracking"
import { actions as trackingActions } from "ducks/tracking"

import {
  authorizeWithThreeDS,
  createOrUpdatePaymentIntent,
} from "sagas/currentUser"

import {
  actions as currentUserActions,
  selectCurrentUserRecord,
  selectCurrentUserEstimate,
  selectCurrentUserPaymentSource,
  selectCurrentUserPaymentIntent,
} from "ducks/currentUser"

////////////////////////////////////////////////////////////////////////
// Card & PayPal Checkout
////////////////////////////////////////////////////////////////////////

export function* checkoutFormSubmit({
  chargebeeCard,
  checkoutAttributes,
  form,
}) {
  try {
    const user = yield select(selectCurrentUserRecord())
    const paymentSource = yield select(selectCurrentUserPaymentSource())

    const billingToken = !!checkoutAttributes.payment_info
      .billing_agreement_token

    const isPaypalPayment =
      (paymentSource && paymentSource.paypal) || billingToken

    const vatInfo = {
      vat_number: checkoutAttributes.vat_number,
      vat_number_prefix: checkoutAttributes.vat_number_prefix,
    }

    const paymentInfo = checkoutAttributes.payment_info
    const billingAddress = checkoutAttributes.billing_address

    delete checkoutAttributes.vat_number
    delete checkoutAttributes.vat_number_prefix
    delete checkoutAttributes.billing_address

    // Create and authorize the payment intent
    if (!isPaypalPayment && !paymentSource) {
      const paymentIntentResponse = yield* createPaymentIntent({
        chargebeeCard,
        billingAddress,
        form,
      })

      checkoutAttributes.payment_info.payment_intent = paymentIntentResponse
    }

    delete checkoutAttributes.payment_info

    if (!user?.hasPaymentSource()) {
      yield* updateUserBillingInfo({ billingAddress, vatInfo })
    }

    yield* createAndHandleSale({
      checkoutAttributes,
      paymentInfo,
      vatInfo,
    })
  } catch (error) {
    yield* handleCheckoutError({ error, form })
  }
}

export function* watchCheckoutFormSubmit() {
  yield takeLatest(types.CHECKOUT_FORM_SUBMIT, checkoutFormSubmit)
}

////////////////////////////////////////////////////////////////////////
// Apple & Google Pay Checkout
////////////////////////////////////////////////////////////////////////

export function* paymentRequestButtonSubmit({
  authorizedPaymentIntent,
  checkoutAttributes,
  form,
}) {
  try {
    checkoutAttributes.payment_info.stripe_payment_intent = authorizedPaymentIntent
    const paymentType = checkoutAttributes.payment_type
    const paymentInfo = checkoutAttributes.payment_info
    const billingAddress = checkoutAttributes.billing_address

    delete checkoutAttributes.payment_type
    delete checkoutAttributes.payment_info
    delete checkoutAttributes.billing_address

    yield* updateUserBillingInfo({ billingAddress })
    yield* createAndHandleSale({ checkoutAttributes, paymentInfo, paymentType })
    yield put(actions.paymentRequestButtonSuccess())
  } catch (error) {
    yield* handleCheckoutError({ error, form })
  }
}

export function* watchPaymentRequestButtonSubmit() {
  yield takeLatest(
    types.PAYMENT_REQUEST_BUTTON_SUBMIT,
    paymentRequestButtonSubmit
  )
}

////////////////////////////////////////////////////////////////////////
// Internal Sagas
////////////////////////////////////////////////////////////////////////

export function* createPaymentIntent({ chargebeeCard, billingAddress, form }) {
  const estimate = yield select(selectCurrentUserEstimate())

  const paymentIntentAttributes = {
    amount: estimate.invoice_estimate.total,
    currency_code: estimate.invoice_estimate.currency_code,
  }

  yield call(createOrUpdatePaymentIntent, {
    attributes: paymentIntentAttributes,
  })

  const paymentIntent = yield select(selectCurrentUserPaymentIntent())

  const paymentIntentResponse = yield call(authorizeWithThreeDS, {
    chargebeeCard,
    checkoutAttributes: billingAddress,
    paymentIntent,
  })

  if (paymentIntentResponse && paymentIntentResponse.status !== "authorized") {
    yield put(currentUserActions.removePaymentIntent())
    yield put(
      stopSubmit(form, {
        _error: { message: paymentIntentResponse.displayMessage },
      })
    )
    yield put(currentUserActions.requestFailed())
    return false
  }

  return paymentIntentResponse
}

export function* updateUserBillingInfo({ billingAddress, vatInfo }) {
  const user = yield select(selectCurrentUserRecord())
  const billingRequestUrl = `${process.env.API_URL}/users/${user.id}/update_billing_info`

  const vatData = !!vatInfo ? { ...vatInfo } : {}

  const billingData = {
    data: {
      type: "users",
      id: user.id.toString(),
      attributes: {
        billing_address: billingAddress,
        ...vatData,
      },
    },
  }

  yield call(patch, billingRequestUrl, {
    data: JSON.stringify(billingData),
  })
}

export function* createAndHandleSale({
  checkoutAttributes,
  paymentInfo,
  paymentType,
  vatInfo,
}) {
  const requestURL = `${process.env.API_URL}/marketplace/license/submit`
  const utmCookies = yield put(cookieActions.getCookies(UTM_PARAMS))

  const vatData = !!vatInfo ? { vat_info: vatInfo } : {}
  const paymentTypeData = !!paymentType ? { payment_type: paymentType } : {}

  const data = {
    data: {
      type: "marketplace_sales",
      attributes: {
        price: checkoutAttributes.price,
        phone_number: checkoutAttributes.phone_number,
        payment_info: paymentInfo,
        sale_items_attributes: { ...checkoutAttributes },
        ...vatData,
      },
      utm_cookies: utmCookies,
      ...paymentTypeData,
    },
  }

  const options = { data: JSON.stringify(data) }
  const response = yield call(post, requestURL, options)
  if (response?.meta?.token) storeToken(response.meta.token)

  yield put(trackingActions.trackMarketplacePurchase(response.meta))
  yield put(apiActions.readSuccess(response))
  yield put(currentUserActions.removePaymentIntent())
  yield put(currentUserActions.load())
  yield put(push("/market/browse"))

  yield put(
    modalActions.open("MarketplaceDownloadSongModal", {
      saleItemId: response.included.filter(
        (d) => d.type === "marketplace_sale_items"
      )?.[0]?.id,
      header: "License Purchase Complete!",
      withDownload: true,
    })
  )
}

export function* handleCheckoutError({ error, form }) {
  yield put(currentUserActions.removePaymentIntent())
  yield put(currentUserActions.removePaymentSource())
  yield put(stopSubmit(form, { _error: error }))
  yield put(currentUserActions.requestFailed())
}

export default [watchCheckoutFormSubmit, watchPaymentRequestButtonSubmit]
