import styled from "styled-components"
import { color, space, typography } from "styled-system"

const H4 = styled.h4`
  ${color}
  ${space}
  ${typography}
  text-transform: capitalize;
`

H4.defaultProps = {
  color: "text.primary",
  fontWeight: "500",
  fontSize: "lg",
  lineHeight: "1.75rem",
}

export default H4
