import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { actions as modalActions } from "ducks/modal"
import { addDays, isBefore, isAfter } from "date-fns"
import { MODAL_TYPE } from "utils/plan"
import isEmpty from "lodash/isEmpty"
import {
  selectCurrentUserRecord,
  selectCurrentUserIsLoading,
} from "ducks/currentUser"

const storeJS = require("utils/store.min.js")

const RecurringUpgradeModalLauncher = () => {
  const dispatch = useDispatch()
  const currentUser = useSelector(selectCurrentUserRecord())
  const currentUserLoading = useSelector(selectCurrentUserIsLoading())
  const currentDate = new Date()
  const promptUpgradeAt = storeJS.get("promptUpgradeAt")

  const openUpgradeModal = () => {
    dispatch(
      modalActions.open("BannerUpgradeModal", {
        modalType: MODAL_TYPE.FREE_ACCOUNT_RECURRING,
        upsellTrigger: "App",
      })
    )
  }

  useEffect(() => {
    if (currentUser.id && !currentUser.hasSubscription()) {
      if (isEmpty(promptUpgradeAt)) {
        const userCreatedAt = new Date(currentUser.created_at)
        const upgradeAt = isBefore(userCreatedAt, currentDate)
          ? addDays(currentDate, 9)
          : addDays(userCreatedAt, 9)

        storeJS.set("promptUpgradeAt", upgradeAt)
      } else {
        const nextUpsellDate = new Date(promptUpgradeAt)

        if (isAfter(currentDate, nextUpsellDate)) {
          const upgradeAt = addDays(nextUpsellDate, 29)
          storeJS.set("promptUpgradeAt", upgradeAt)
          setTimeout(openUpgradeModal, 2000)
        }
      }
    }
  }, [currentUserLoading])

  return null
}

export default RecurringUpgradeModalLauncher
