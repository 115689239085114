export const SORTING_INDEX_MAP = {
  popular: process.env.ALGOLIA_SFX_INDEX_NAME,
  recent: `${process.env.ALGOLIA_SFX_INDEX_NAME}_most_recent`,
}

export const FILTERS = {
  CATEGORY_LVL0: "category_levels.lvl0",
  CATEGORY_LVL1: "category_levels.lvl1",
  SEARCH: "q",
  OBJECT_ID: "objectID",
  PREVIOUSLY_LICENSED: "previously_downloaded",
  PLAYLISTS: "playlists",
}

export const FACET_TYPES = {
  PLAYLISTS: "playlists.name",
}

export const PARAMS = {
  CATEGORY_LVL0: `filter[${FILTERS.CATEGORY_LVL0}][]`,
  CATEGORY_LVL1: `filter[${FILTERS.CATEGORY_LVL1}][]`,
  SEARCH: `filter[${FILTERS.SEARCH}]`,
  SORT_BY: "sort",
  PLAYLIST_IDS: "filter[playlist_ids][]",
  PREVIOUSLY_LICENSED: "filter[previously_downloaded]",
  PLAYED_TODAY: "filter[played_today]",
  NEGATIVE: {
    CATEGORY_LVL0: `filter[neg.${FILTERS.CATEGORY_LVL0}][]`,
    CATEGORY_LVL1: `filter[neg.${FILTERS.CATEGORY_LVL1}][]`,
    PREVIOUSLY_LICENSED: "filter[neg.previously_downloaded]",
    PLAYED_TODAY: "filter[neg.played_today]",
  },
}

export const SECTIONS = {
  CATEGORIES: "Categories",
  PLAYLISTS: "Playlists",
  ADVANCED: "Advanced",
}

export const FILTER_CONFIG = {
  PREVIOUSLY_LICENSED: {
    algoliaFilter: FILTERS.PREVIOUSLY_LICENSED,
    facetType: FILTERS.PREVIOUSLY_LICENSED,
    params: {
      active: PARAMS.PREVIOUSLY_LICENSED,
      negative: PARAMS.NEGATIVE.PREVIOUSLY_LICENSED,
    },
  },
  PLAYED_TODAY: {
    facetType: "played_today",
    params: {
      active: PARAMS.PLAYED_TODAY,
      negative: PARAMS.NEGATIVE.PLAYED_TODAY,
    },
  },
  PLAYLISTS: {
    algoliaFilter: FILTERS.PLAYLISTS,
    facetType: FACET_TYPES.PLAYLISTS,
    joinType: "or",
    params: {
      active: PARAMS.PLAYLIST_IDS,
    },
  },
}
