import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { selectAppBannerComponent } from "ducks/ui"
import { selectAppBannerVisible } from "../../ducks/ui"

const AppBanner = ({ appBannerComponent, appBannerVisible }) => {
  if (appBannerVisible) {
    return <>{appBannerComponent}</>
  } else {
    return null
  }
}

AppBanner.propTypes = {
  appBannerComponent: PropTypes.element,
  appBannerVisible: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  appBannerComponent: selectAppBannerComponent()(state),
  appBannerVisible: selectAppBannerVisible()(state),
})

export default connect(mapStateToProps)(AppBanner)
