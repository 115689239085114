import isArray from "lodash/isArray"

export const insertOrUpdateCurrentUserRecords = (state, records) => {
  const insertRecord = (record) => {
    if (record && record.type === "users") {
      return state.setIn(["userRecord"], record)
    } else if (record) {
      return state.setIn(
        ["relationshipRecords", record.type, record.id],
        record
      )
    }
    return state
  }

  if (isArray(records)) {
    records.forEach((record) => {
      state = insertRecord(record)
    })
    return state
  }

  return insertRecord(records)
}

export const setLiveChatUserInfo = (user) => {
  if (user && $crisp) {
    $crisp.push(["set", "user:email", [user.email]])
    $crisp.push(["set", "user:nickname", [user.name()]])
    $crisp.push([
      "set",
      "session:data",
      [
        [
          ["plan_name", user.subscription ? user.subscription.plan_name : ""],
          ["plan_status", user.subscription ? user.subscription.status : ""],
        ],
      ],
    ])
  }
}

const TYPE_TO_USER_ATTRIBUTE = {
  artists: "followed_artist_ids",
  playlists: "followed_playlist_ids",
  songs: "favorited_song_ids",
  sound_effects: "favorited_sound_effect_ids",
  videos: "favorited_video_ids",
  marketplace_songs: "favorited_marketplace_song_ids",
}

export const addFavoritedIdToList = (state, favoriteType, id) => {
  const attribute = TYPE_TO_USER_ATTRIBUTE[favoriteType]
  const ids = state.getIn(["userRecord", attribute]).insert(0, Number(id))
  const newState = state.setIn(["userRecord", attribute], ids)
  return newState
}

export const removeFavoritedIdFromList = (state, favoriteType, id) => {
  const attribute = TYPE_TO_USER_ATTRIBUTE[favoriteType]
  const indexOfId = state
    .getIn(["userRecord", attribute])
    .findIndex((favoriteId) => favoriteId === Number(id))
  const ids = state.getIn(["userRecord", attribute]).splice(indexOfId, 1)
  const newState = state.setIn(["userRecord", attribute], ids)
  return newState
}

export const addActionToList = (state, mediaType, id) => {
  const licenseTypeLocation = ["userRecord", "license_history", mediaType]
  const newLicenseArray = state
    .getIn(licenseTypeLocation)
    .concat(parseInt(id, 10))
  return state.setIn(licenseTypeLocation, newLicenseArray)
}
