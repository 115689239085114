import Cookies from "js-cookie"

const STATSIG_STABLE_ID_COOKIE = "soundstripe.statsig.shared_stable_id"

export function sharedStableId(localStableID = null) {
  const cookieStableID = Cookies.get(STATSIG_STABLE_ID_COOKIE)
  // the shared stableID is the stableID that is stored in the first party cookie
  // (entry from another soundstripe client)
  if (!localStableID && cookieStableID) {
    return cookieStableID
    // the shared stableId is the stableID stored in local storage (first entry)
  } else if (localStableID && !cookieStableID) {
    return localStableID
    // if both are set, use the one in the first party cookie
  } else if (cookieStableID !== localStableID) {
    return cookieStableID
  }
}
