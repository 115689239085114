import { createSelector } from "reselect"
import { selectRelationshipRecordsByType, selectRecordById } from "ducks/api"
import { createAction } from "./utils"

export const types = {
  TRY_SONG: "soundstripe/song/TRY_SONG",
}

export const actions = {
  trySong: createAction(types.TRY_SONG, "song"),
}

export const selectSong = (songId) =>
  createSelector(selectRecordById("songs", songId), (song) => song)

export const selectSongAudioFiles = (songId) =>
  createSelector(
    selectRelationshipRecordsByType("songs", songId, "audio_files"),
    (audioFiles) => {
      if (audioFiles) {
        return audioFiles
          .sortBy((audioFile) => parseFloat(audioFile.get("duration")))
          .sortBy((audioFile) => audioFile.get("description"))
          .sortBy((x) => !x.primary)
      }
    }
  )

export const selectPrimaryAudioFile = (songId) =>
  createSelector(selectSongAudioFiles(songId), (audioFiles) => {
    if (audioFiles) {
      return audioFiles.find((x) => x.primary)
    }

    return null
  })

export const selectAlternateAudioFiles = (songId) =>
  createSelector(selectSongAudioFiles(songId), (audioFiles) => {
    if (!audioFiles) {
      return null
    }

    return audioFiles.filter((x) => !x.primary)
  })
