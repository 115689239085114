import Immutable from "immutable"
import { createRelationships } from "records/Relationship"

const ProjectItemRecord = Immutable.Record(
  {
    id: null,
    type: "project_items",
    note: "",
    position: "",
    project_id: "",
    projectable_id: "",
    projectable_type: "",

    relationships: {
      projectable: {},
    },
    included: [],
  },
  "ProjectItem"
)

const relationshipTypes = {
  projectable: ["songs", "sound_effects", "videos"],
}

class ProjectItem extends ProjectItemRecord {
  constructor(args = {}) {
    super(
      Object.assign({}, args, {
        relationships: createRelationships(
          args.relationships,
          relationshipTypes
        ),
        included: args.included,
      })
    )
  }
}

export default ProjectItem
