import Immutable from "immutable"

const PlaylistUiRecord = Immutable.Record(
  {
    id: null,
    type: "curated_playlists",

    // Attributes
    name: "",
    thumbnail_url: "",
  },
  "PlaylistUi"
)

class PlaylistUi extends PlaylistUiRecord {
  constructor(args) {
    super(Object.assign({}, args))
  }
}

export default PlaylistUi
