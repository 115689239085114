import jwtDecode from "jwt-decode"

const storeJS = require("./store.min.js")

const AUTH_TOKEN = "authToken"

// Subaccounts don't have higher privileges than their main type (aka Member is higher
// privileged than a MemberSubaccount), but we have to put them ahead in the list
// so that they're included in that type's privileges
export const [
  GUEST,
  VISITOR,
  FREE,
  MEMBER,
  MEMBER_SFX,
  MEMBER_SUBACCOUNT,
  MEMBER_PREMIUM,
  MEMBER_PREMIUM_SUBACCOUNT,
  MEMBER_BUSINESS,
  MEMBER_BUSINESS_SUBACCOUNT,
  PRO_USER,
  PRO_USER_SUBACCOUNT,
] = [
  "Guest",
  "Visitor",
  "Free",
  "Member",
  "MemberSfx",
  "MemberSubaccount",
  "MemberPremium",
  "MemberPremiumSubaccount",
  "MemberBusiness",
  "MemberBusinessSubaccount",
  "ProUser",
  "ProUserSubaccount",
]

export const PLAN_IDS = {
  // Legacy Plans
  MEMBER_PREMIUM_YEARLY: "premium-yearly-usd",
  MEMBER_MONTHLY: "standard-monthly-usd",
  MEMBER_YEARLY: "standard-yearly-usd",
  MEMBER_BUSINESS_MONTHLY: "business-monthly-usd",
  MEMBER_BUSINESS_YEARLY: "business-yearly-usd",
  MEMBER_SUBACCOUNTS: "subaccounts-monthly-usd",
  // INR (Indian Rupee)
  MUSIC_YEARLY_INR: "music-yearly-inr",
  MUSIC_MONTHLY_INR: "music-monthly-inr",
  MUSIC_SFX_YEARLY_INR: "music-sfx-yearly-inr",
  MUSIC_SFX_MONTHLY_INR: "music-sfx-monthly-inr",
  // GBP (Pound Sterling)
  MUSIC_YEARLY_GBP: "music-yearly-gbp",
  MUSIC_MONTHLY_GBP: "music-monthly-gbp",
  MUSIC_SFX_YEARLY_GBP: "music-sfx-yearly-gbp",
  MUSIC_SFX_MONTHLY_GBP: "music-sfx-monthly-gbp",
  // EUR (Euro)
  MUSIC_YEARLY_EUR: "music-yearly-eur",
  MUSIC_MONTHLY_EUR: "music-monthly-eur",
  MUSIC_SFX_YEARLY_EUR: "music-sfx-yearly-eur",
  MUSIC_SFX_MONTHLY_EUR: "music-sfx-monthly-eur",
  // Music Only
  MUSIC_YEARLY: "music-yearly-usd",
  MUSIC_MONTHLY: "music-monthly-usd",
  MUSIC_TEAM_YEARLY: "music-team-yearly-usd",
  MUSIC_TEAM_MONTHLY: "music-team-monthly-usd",
  // Video Only
  VIDEO_YEARLY: "video-yearly-usd",
  VIDEO_MONTHLY: "video-monthly-usd",
  VIDEO_TEAM_YEARLY: "video-team-yearly-usd",
  VIDEO_TEAM_MONTHLY: "video-team-monthly-usd",
  // SFX Only
  SFX_YEARLY: "sfx-yearly-usd",
  SFX_MONTHLY: "sfx-monthly-usd",
  SFX_TEAM_YEARLY: "sfx-team-yearly-usd",
  SFX_TEAM_MONTHLY: "sfx-team-monthly-usd",
  // Music & SFX
  MUSIC_SFX_YEARLY: "music-sfx-yearly-usd",
  MUSIC_SFX_MONTHLY: "music-sfx-monthly-usd",
  MUSIC_SFX_TEAM_YEARLY: "music-sfx-team-yearly-usd",
  MUSIC_SFX_TEAM_MONTHLY: "music-sfx-team-monthly-usd",
  // Music & Video
  MUSIC_VIDEO_YEARLY: "music-video-yearly-usd",
  MUSIC_VIDEO_MONTHLY: "music-video-monthly-usd",
  MUSIC_VIDEO_TEAM_YEARLY: "music-video-team-yearly-usd",
  MUSIC_VIDEO_TEAM_MONTHLY: "music-video-team-monthly-usd",
  // Video & SFX
  VIDEO_SFX_YEARLY: "video-sfx-yearly-usd",
  VIDEO_SFX_MONTHLY: "video-sfx-monthly-usd",
  VIDEO_SFX_TEAM_YEARLY: "video-sfx-team-yearly-usd",
  VIDEO_SFX_TEAM_MONTHLY: "video-sfx-team-monthly-usd",
  // Music, Video & SFX
  MUSIC_VIDEO_SFX_YEARLY: "music-video-sfx-yearly-usd",
  MUSIC_VIDEO_SFX_MONTHLY: "music-video-sfx-monthly-usd",
  MUSIC_VIDEO_SFX_TEAM_YEARLY: "music-video-sfx-team-yearly-usd",
  MUSIC_VIDEO_SFX_TEAM_MONTHLY: "music-video-sfx-team-monthly-usd",
  // Enterprise
  ENTERPRISE_MUSIC_SFX_YEARLY: "enterprise-music-sfx-yearly",
  ENTERPRISE_MUSIC_VIDEO_SFX_YEARLY: "enterprise-music-video-sfx-yearly",
  ENTERPRISE_CUSTOM: "enterprise-custom-pricing",
  ENTERPRISE_API_MUSIC_YEARLY: "enterprise-api-music-yearly",
  ENTERPRISE_API_MUSIC_SFX_YEARLY: "enterprise-api-music-sfx-yearly",
  STRAYNOTE_YEARLY: "straynote-billing-yearly",
  // Twitch Pro
  TWITCH_PRO_YEARLY: "twitch-pro-yearly-usd",
  TWITCH_PRO_MONTHLY: "twitch-pro-monthly-usd",
  // Creator Plans
  CREATOR_YEARLY: "creator-yearly-usd",
  CREATOR_QUARTERLY: "creator-quarterly-usd",
  CREATOR_MONTHLY: "creator-monthly-usd",
  PRO_YEARLY: "pro-yearly-usd",
  PRO_QUARTERLY: "pro-quarterly-usd",
  PRO_MONTHLY: "pro-monthly-usd",
  PRO_PLUS_YEARLY: "pro-plus-yearly-usd",
  PRO_PLUS_QUARTERLY: "pro-plus-quarterly-usd",
  PRO_PLUS_MONTHLY: "pro-plus-monthly-usd",
  //Enterprise Business Plans
  ENTERPRISE_BUSINESS_YEARLY: "enterprise-business-yearly-usd",
  ENTERPRISE_BUSINESS_QUARTERLY: "enterprise-business-quarterly-usd",
  ENTERPRISE_BUSINESS_MONTHLY: "enterprise-business-monthly-usd",
}

export const LEGACY_PLAN_IDS = [
  PLAN_IDS.MEMBER_PREMIUM_YEARLY,
  PLAN_IDS.MEMBER_MONTHLY,
  PLAN_IDS.MEMBER_YEARLY,
  PLAN_IDS.MEMBER_BUSINESS_MONTHLY,
  PLAN_IDS.MEMBER_BUSINESS_YEARLY,
  PLAN_IDS.MEMBER_SUBACCOUNTS,
]

export const TIER_ONE_PLAN_IDS = [
  PLAN_IDS.MEMBER_MONTHLY,
  PLAN_IDS.MEMBER_YEARLY,
  PLAN_IDS.MUSIC_MONTHLY,
  PLAN_IDS.MUSIC_YEARLY,
  PLAN_IDS.SFX_MONTHLY,
  PLAN_IDS.SFX_YEARLY,
  PLAN_IDS.TWITCH_PRO_MONTHLY,
  PLAN_IDS.TWITCH_PRO_YEARLY,
  PLAN_IDS.CREATOR_MONTHLY,
  PLAN_IDS.CREATOR_QUARTERLY,
  PLAN_IDS.CREATOR_YEARLY,
]

export const TWITCH_PLAN_IDS = [
  PLAN_IDS.TWITCH_PRO_YEARLY,
  PLAN_IDS.TWITCH_PRO_MONTHLY,
]

export const PACKAGED_PLAN_IDS = [
  PLAN_IDS.CREATOR_MONTHLY,
  PLAN_IDS.CREATOR_QUARTERLY,
  PLAN_IDS.CREATOR_YEARLY,
  PLAN_IDS.PRO_MONTHLY,
  PLAN_IDS.PRO_QUARTERLY,
  PLAN_IDS.PRO_YEARLY,
  PLAN_IDS.PRO_PLUS_MONTHLY,
  PLAN_IDS.PRO_PLUS_QUARTERLY,
  PLAN_IDS.PRO_PLUS_YEARLY,
  PLAN_IDS.ENTERPRISE_BUSINESS_YEARLY,
  PLAN_IDS.ENTERPRISE_BUSINESS_QUARTERLY,
]

export const LEGACY_CONVERSION_TABLE = {
  [PLAN_IDS.MEMBER_PREMIUM_YEARLY]: PLAN_IDS.MUSIC_SFX_YEARLY,
  [PLAN_IDS.MEMBER_MONTHLY]: PLAN_IDS.MUSIC_MONTHLY,
  [PLAN_IDS.MEMBER_YEARLY]: PLAN_IDS.MUSIC_MONTHLY,
  [PLAN_IDS.MEMBER_BUSINESS_MONTHLY]: PLAN_IDS.MUSIC_SFX_TEAM_MONTHLY,
  [PLAN_IDS.MEMBER_BUSINESS_YEARLY]: PLAN_IDS.MUSIC_SFX_TEAM_YEARLY,
}

export const ALL_ACCESS_PLAN_IDS = [
  PLAN_IDS.MUSIC_SFX_YEARLY_INR,
  PLAN_IDS.MUSIC_SFX_MONTHLY_INR,
  PLAN_IDS.MUSIC_SFX_YEARLY_GBP,
  PLAN_IDS.MUSIC_SFX_MONTHLY_GBP,
  PLAN_IDS.MUSIC_SFX_YEARLY_EUR,
  PLAN_IDS.MUSIC_SFX_MONTHLY_EUR,
  PLAN_IDS.MUSIC_VIDEO_SFX_YEARLY,
  PLAN_IDS.MUSIC_VIDEO_SFX_TEAM_YEARLY,
  PLAN_IDS.MUSIC_VIDEO_SFX_MONTHLY,
  PLAN_IDS.MUSIC_VIDEO_SFX_TEAM_MONTHLY,
  PLAN_IDS.ENTERPRISE_MUSIC_VIDEO_SFX_YEARLY,
  PLAN_IDS.PRO_PLUS_MONTHLY,
  PLAN_IDS.PRO_PLUS_YEARLY,
  PLAN_IDS.ENTERPRISE_BUSINESS_MONTHLY,
  PLAN_IDS.ENTERPRISE_BUSINESS_YEARLY,
]

export const ALL_ACCESS_PLAN_CONVERSION_TABLE = {
  [PLAN_IDS.MUSIC_SFX_MONTHLY_INR]: PLAN_IDS.MUSIC_SFX_YEARLY_INR,
  [PLAN_IDS.MUSIC_MONTHLY_INR]: PLAN_IDS.MUSIC_SFX_YEARLY_INR,
  [PLAN_IDS.MUSIC_YEARLY_INR]: PLAN_IDS.MUSIC_SFX_YEARLY_INR,
  [PLAN_IDS.MUSIC_SFX_MONTHLY_GBP]: PLAN_IDS.MUSIC_SFX_YEARLY_GBP,
  [PLAN_IDS.MUSIC_MONTHLY_GBP]: PLAN_IDS.MUSIC_SFX_YEARLY_GBP,
  [PLAN_IDS.MUSIC_YEARLY_GBP]: PLAN_IDS.MUSIC_SFX_YEARLY_GBP,
  [PLAN_IDS.MUSIC_SFX_MONTHLY_EUR]: PLAN_IDS.MUSIC_SFX_YEARLY_EUR,
  [PLAN_IDS.MUSIC_MONTHLY_EUR]: PLAN_IDS.MUSIC_SFX_YEARLY_EUR,
  [PLAN_IDS.MUSIC_YEARLY_EUR]: PLAN_IDS.MUSIC_SFX_YEARLY_EUR,
  [PLAN_IDS.TWITCH_PRO_MONTHLY]: PLAN_IDS.MUSIC_YEARLY,
  [PLAN_IDS.TWITCH_PRO_YEARLY]: PLAN_IDS.MUSIC_YEARLY,
}

export const MONTHLY_PLAN_IDS = [
  PLAN_IDS.MEMBER_MONTHLY,
  PLAN_IDS.MUSIC_MONTHLY,
  PLAN_IDS.MUSIC_MONTHLY_INR,
  PLAN_IDS.MUSIC_MONTHLY_GBP,
  PLAN_IDS.MUSIC_MONTHLY_EUR,
  PLAN_IDS.MUSIC_TEAM_MONTHLY,
  PLAN_IDS.VIDEO_MONTHLY,
  PLAN_IDS.VIDEO_TEAM_MONTHLY,
  PLAN_IDS.SFX_MONTHLY,
  PLAN_IDS.SFX_TEAM_MONTHLY,
  PLAN_IDS.MUSIC_SFX_MONTHLY,
  PLAN_IDS.MUSIC_SFX_MONTHLY_INR,
  PLAN_IDS.MUSIC_SFX_MONTHLY_GBP,
  PLAN_IDS.MUSIC_SFX_MONTHLY_EUR,
  PLAN_IDS.MUSIC_SFX_TEAM_MONTHLY,
  PLAN_IDS.MUSIC_VIDEO_MONTHLY,
  PLAN_IDS.MUSIC_VIDEO_TEAM_MONTHLY,
  PLAN_IDS.VIDEO_SFX_MONTHLY,
  PLAN_IDS.VIDEO_SFX_TEAM_MONTHLY,
  PLAN_IDS.MUSIC_VIDEO_SFX_MONTHLY,
  PLAN_IDS.MUSIC_VIDEO_SFX_TEAM_MONTHLY,
  PLAN_IDS.TWITCH_PRO_MONTHLY,
  PLAN_IDS.CREATOR_MONTHLY,
  PLAN_IDS.PRO_MONTHLY,
  PLAN_IDS.PRO_PLUS_MONTHLY,
  PLAN_IDS.ENTERPRISE_BUSINESS_MONTHLY,
]

export const ENTERPRISE_PLAN_IDS = [
  PLAN_IDS.ENTERPRISE_MUSIC_SFX_YEARLY,
  PLAN_IDS.ENTERPRISE_MUSIC_VIDEO_SFX_YEARLY,
  PLAN_IDS.STRAYNOTE_YEARLY,
]

export const ENTERPRISE_API_PLAN_IDS = [
  PLAN_IDS.ENTERPRISE_API_MUSIC_YEARLY,
  PLAN_IDS.ENTERPRISE_API_MUSIC_SFX_YEARLY,
]

export const UPGRADE_INELIGIBLE_PLAN_IDS = [
  PLAN_IDS.MEMBER_SUBACCOUNTS,
  PLAN_IDS.MUSIC_VIDEO_SFX_YEARLY,
  PLAN_IDS.MUSIC_VIDEO_SFX_TEAM_YEARLY,
  PLAN_IDS.PRO_PLUS_YEARLY,
  PLAN_IDS.ENTERPRISE_BUSINESS_YEARLY,
  PLAN_IDS.ENTERPRISE_BUSINESS_QUARTERLY,
  PLAN_IDS.ENTERPRISE_BUSINESS_MONTHLY,
  ...ENTERPRISE_PLAN_IDS,
]

export const DUAL_PLAN_IDS = [
  PLAN_IDS.MUSIC_VIDEO_YEARLY,
  PLAN_IDS.MUSIC_SFX_YEARLY,
  PLAN_IDS.MUSIC_SFX_YEARLY_INR,
  PLAN_IDS.MUSIC_SFX_YEARLY_GBP,
  PLAN_IDS.MUSIC_SFX_YEARLY_EUR,
  PLAN_IDS.VIDEO_SFX_YEARLY,
  PLAN_IDS.MUSIC_VIDEO_TEAM_YEARLY,
  PLAN_IDS.MUSIC_SFX_TEAM_YEARLY,
  PLAN_IDS.VIDEO_SFX_TEAM_YEARLY,
  PLAN_IDS.MUSIC_VIDEO_MONTHLY,
  PLAN_IDS.MUSIC_SFX_MONTHLY,
  PLAN_IDS.MUSIC_SFX_MONTHLY_INR,
  PLAN_IDS.MUSIC_SFX_MONTHLY_GBP,
  PLAN_IDS.MUSIC_SFX_MONTHLY_EUR,
  PLAN_IDS.VIDEO_SFX_MONTHLY,
  PLAN_IDS.MUSIC_VIDEO_TEAM_MONTHLY,
  PLAN_IDS.MUSIC_SFX_TEAM_MONTHLY,
  PLAN_IDS.VIDEO_SFX_TEAM_MONTHLY,
  PLAN_IDS.MEMBER_PREMIUM_YEARLY,
  PLAN_IDS.MEMBER_BUSINESS_MONTHLY,
  PLAN_IDS.MEMBER_BUSINESS_YEARLY,
  PLAN_IDS.CREATOR_YEARLY,
  PLAN_IDS.CREATOR_MONTHLY,
  PLAN_IDS.PRO_YEARLY,
  PLAN_IDS.PRO_MONTHLY,
  PLAN_IDS.PRO_PLUS_YEARLY,
  PLAN_IDS.PRO_PLUS_MONTHLY,
]

// NOTE: This array does not include legacy plans.
export const INDIVIDUAL_PLAN_IDS = [
  PLAN_IDS.MUSIC_MONTHLY,
  PLAN_IDS.VIDEO_MONTHLY,
  PLAN_IDS.SFX_MONTHLY,
  PLAN_IDS.MUSIC_SFX_MONTHLY,
  PLAN_IDS.MUSIC_VIDEO_MONTHLY,
  PLAN_IDS.VIDEO_SFX_MONTHLY,
  PLAN_IDS.MUSIC_VIDEO_SFX_MONTHLY,
  PLAN_IDS.MUSIC_YEARLY,
  PLAN_IDS.VIDEO_YEARLY,
  PLAN_IDS.SFX_YEARLY,
  PLAN_IDS.MUSIC_SFX_YEARLY,
  PLAN_IDS.MUSIC_VIDEO_YEARLY,
  PLAN_IDS.VIDEO_SFX_YEARLY,
  PLAN_IDS.MUSIC_VIDEO_SFX_YEARLY,
  PLAN_IDS.TWITCH_PRO_MONTHLY,
  PLAN_IDS.TWITCH_PRO_YEARLY,
  PLAN_IDS.CREATOR_YEARLY,
  PLAN_IDS.CREATOR_QUARTERLY,
  PLAN_IDS.CREATOR_MONTHLY,
  PLAN_IDS.PRO_YEARLY,
  PLAN_IDS.PRO_QUARTERLY,
  PLAN_IDS.PRO_MONTHLY,
]

export const TEAM_PLAN_IDS = [
  PLAN_IDS.MUSIC_TEAM_MONTHLY,
  PLAN_IDS.VIDEO_TEAM_MONTHLY,
  PLAN_IDS.SFX_TEAM_MONTHLY,
  PLAN_IDS.MUSIC_SFX_TEAM_MONTHLY,
  PLAN_IDS.MUSIC_VIDEO_TEAM_MONTHLY,
  PLAN_IDS.VIDEO_SFX_TEAM_MONTHLY,
  PLAN_IDS.MUSIC_VIDEO_SFX_TEAM_MONTHLY,
  PLAN_IDS.MUSIC_TEAM_YEARLY,
  PLAN_IDS.VIDEO_TEAM_YEARLY,
  PLAN_IDS.SFX_TEAM_YEARLY,
  PLAN_IDS.MUSIC_SFX_TEAM_YEARLY,
  PLAN_IDS.MUSIC_VIDEO_TEAM_YEARLY,
  PLAN_IDS.VIDEO_SFX_TEAM_YEARLY,
  PLAN_IDS.MUSIC_VIDEO_SFX_TEAM_YEARLY,
]

export const MUSIC_ONLY_PLAN_IDS = [
  PLAN_IDS.MEMBER_MONTHLY,
  PLAN_IDS.MEMBER_YEARLY,
  PLAN_IDS.MUSIC_YEARLY,
  PLAN_IDS.MUSIC_YEARLY_INR,
  PLAN_IDS.MUSIC_YEARLY_GBP,
  PLAN_IDS.MUSIC_YEARLY_EUR,
  PLAN_IDS.MUSIC_MONTHLY,
  PLAN_IDS.MUSIC_MONTHLY_INR,
  PLAN_IDS.MUSIC_MONTHLY_GBP,
  PLAN_IDS.MUSIC_MONTHLY_EUR,
  PLAN_IDS.MUSIC_TEAM_YEARLY,
  PLAN_IDS.MUSIC_TEAM_MONTHLY,
]

export const SFX_ONLY_PLAN_IDS = [
  PLAN_IDS.SFX_MONTHLY,
  PLAN_IDS.SFX_YEARLY,
  PLAN_IDS.SFX_TEAM_MONTHLY,
  PLAN_IDS.SFX_TEAM_YEARLY,
]

export const BUSINESS_PLAN_IDS = [
  PLAN_IDS.MEMBER_BUSINESS_MONTHLY,
  PLAN_IDS.MEMBER_BUSINESS_YEARLY,
]

export const CREATOR_PLAN_IDS = [
  PLAN_IDS.CREATOR_MONTHLY,
  PLAN_IDS.CREATOR_QUARTERLY,
  PLAN_IDS.CREATOR_YEARLY,
]

export const PRO_PLAN_IDS = [
  PLAN_IDS.PRO_MONTHLY,
  PLAN_IDS.PRO_QUARTERLY,
  PLAN_IDS.PRO_YEARLY,
]

export const ENTERPRISE_BUSINESS_PLAN_IDS = [
  PLAN_IDS.ENTERPRISE_BUSINESS_YEARLY,
  PLAN_IDS.ENTERPRISE_BUSINESS_QUARTERLY,
  PLAN_IDS.ENTERPRISE_BUSINESS_MONTHLY,
]

export const PRO_PLUS_PLAN_IDS = [
  PLAN_IDS.PRO_PLUS_MONTHLY,
  PLAN_IDS.PRO_PLUS_QUARTERLY,
  PLAN_IDS.PRO_PLUS_YEARLY,
]

export const PRO_PRO_PLUS_IDS = [
  PLAN_IDS.PRO_MONTHLY,
  PLAN_IDS.PRO_QUARTERLY,
  PLAN_IDS.PRO_YEARLY,
  PLAN_IDS.PRO_PLUS_MONTHLY,
  PLAN_IDS.PRO_PLUS_QUARTERLY,
  PLAN_IDS.PRO_PLUS_YEARLY,
]

export const COMPANY_NAME_REQUIRED_SINGLE_USE_LICENSES = ["digital", "expanded"]

export const authTokenExpired = () => {
  const expiry = new Date(getTokenPayload().exp * 1000)
  return expiry <= new Date()
}

export const loggedIn = () => !!getToken()

export const loggedOut = () => !loggedIn()

export const storeToken = (token) => storeJS.set(AUTH_TOKEN, token)

export const getToken = () => storeJS.get(AUTH_TOKEN)

export const removeToken = () => storeJS.remove(AUTH_TOKEN)

export const getTokenPayload = () => jwtDecode(getToken())

export const currentUserIdFromToken = () => {
  if (loggedIn()) {
    return getTokenPayload().sub
  }
  return null
}
