import {
  DURATION_MIN,
  DURATION_MAX,
} from "components/VideoSearchSidebar/constants"

const trackMixpanelVideoSearch = (searchState) => {
  const {
    artists,
    frameRates,
    genders,
    numberOfPeople,
    resolutions,
    shotDetails,
    themes,
    duration,
    previouslyLicensed,
    playedToday,
  } = searchState
  const activeArtists = artists.activeFilters || []
  const activeFrameRates = frameRates.activeFilters || []
  const activeGenders = genders.activeFilters || []
  // const activeLoops = loops.activeFilters || []
  const activeNumPeople = numberOfPeople.activeFilters || []
  const activeResolutions = resolutions.activeFilters || []
  const activeShotDetails = shotDetails.activeFilters || []
  const activeThemes = themes.activeFilters || []
  const durationMin = duration.activeFilters.min || DURATION_MIN
  const durationMax = duration.activeFilters.max || DURATION_MAX
  const searchQuery = searchState.searchQuery || ""

  const showPreviouslyLicensedFilter = !previouslyLicensed.isActive
    ? ""
    : previouslyLicensed.value
    ? "True"
    : "False"

  const showPlayedTodayFilter = !playedToday.isActive
    ? ""
    : playedToday.value
    ? "True"
    : "False"

  const negativeArtists = artists.negativeFilters || []
  const negativeFrameRates = frameRates.negativeFilters || []
  const negativeNumPeople = numberOfPeople.negativeFilters || []
  const negativeResolutions = resolutions.negativeFilters || []
  const negativeShotDetails = shotDetails.negativeFilters || []
  const negativeThemes = themes.negativeFilters || []

  const properties = {
    Query: searchQuery,
    "Duration Min": durationMin,
    "Duration Max": durationMax,
    Artists: activeArtists,
    "Frame Rates": activeFrameRates,
    Genders: activeGenders,
    // Loops: activeLoops,
    "Number of People": activeNumPeople,
    Resolutions: activeResolutions,
    "Shot Details": activeShotDetails,
    Themes: activeThemes,
    "Show Previously Downloaded": showPreviouslyLicensedFilter,
    "Show Played Today": showPlayedTodayFilter,
    "Excluded Artists": negativeArtists,
    "Excluded Themes": negativeThemes,
    "Excluded Frame Rates": negativeFrameRates,
    "Excluded Resolutions": negativeResolutions,
    "Excluded Shot Details": negativeShotDetails,
    "Excluded Number of People": negativeNumPeople,
  }

  return properties
}

export default trackMixpanelVideoSearch
