import { LOCATION_CHANGE } from "react-router-redux"
import { fromJS } from "immutable"
import { createSelector } from "reselect"
import uuidGenerator from "utils/uuidGenerator"

import { TAG_TYPES } from "components/Song/constants"

import { createAction } from "./utils"
import { loggedOut } from "utils/authentication"

const storeJS = require("utils/store.min.js")

const hideCrispOnSidebarToggleForVisitors = (open) => {
  if (loggedOut()) {
    !open ? window.$crisp.do("chat:hide") : window.$crisp.do("chat:show")
  }
}

export const types = {
  SET_ERROR: "soundstripe/ui/SET_ERROR",
  CLEAR_ERROR: "soundstripe/ui/CLEAR_ERROR",
  SET_NOTICE: "soundstripe/ui/SET_NOTICE",
  ADD_NOTIFICATION: "soundstripe/ui/ADD_NOTIFICATION",
  REPLACE_NOTIFICATION: "soundstripe/ui/REPLACE_NOTIFICATION",
  CLEAR_NOTICE: "soundstripe/ui/CLEAR_NOTICE",
  CLEAR_NOTIFICATION: "soundstripe/ui/CLEAR_NOTIFICATION",
  CLEAR_NOTIFICATIONS: "soundstripe/ui/CLEAR_NOTIFICATIONS",
  SET_APP_BANNER: "soundstripe/ui/SET_APP_BANNER",
  CLEAR_APP_BANNER: "soundstripe/ui/CLEAR_APP_BANNER",
  UPDATE_PREFERRED_TAG_TYPE: "soundstripe/ui/UPDATE_PREFERRED_TAG_TYPE",
  UPDATE_MARKETPLACE_PREFERRED_TAG_TYPE:
    "soundstripe/ui/UPDATE_MARKETPLACE_PREFERRED_TAG_TYPE",
  PROFILE_SUBMENU_TOGGLE: "soundstripe/ui/PROFILE_SUBMENU_TOGGLE",
  SEARCH_SIDEBAR_TOGGLE: "soundstripe/ui/SEARCH_SIDEBAR_TOGGLE",
  SFX_SEARCH_SIDEBAR_TOGGLE: "soundstripe/ui/SFX_SEARCH_SIDEBAR_TOGGLE",
  SIDEBAR_SUBMENU_TOGGLE: "soundstripe/ui/SIDEBAR_SUBMENU_TOGGLE",
  MY_MEDIA_SUBMENU_TOGGLE: "soundstripe/ui/MY_MEDIA_SUBMENU_TOGGLE",
  LIVE_CHAT_TOGGLE: "soundstripe/ui/LIVE_CHAT_TOGGLE",
  THEME_MODE_TOGGLE: "soundstripe/ui/THEME_MODE_TOGGLE",
  MOBILE_SIDEBAR_TOGGLE: "soundstripe/ui/MOBILE_SIDEBAR_TOGGLE",
  MOBILE_SIDEBAR_CLOSED: "soundstripe/ui/MOBILE_SIDEBAR_CLOSED",
}

export const actions = {
  setError: createAction(types.SET_ERROR, "error"),
  clearError: createAction(types.CLEAR_ERROR),
  setNotice: createAction(types.SET_NOTICE, "notice", "level"),
  addNotification: createAction(types.ADD_NOTIFICATION, "notification"),
  replaceNotification: createAction(
    types.REPLACE_NOTIFICATION,
    "notificationId",
    "updated"
  ),
  clearNotice: createAction(types.CLEAR_NOTICE),
  clearNotification: createAction(types.CLEAR_NOTIFICATION, "notificationId"),
  clearNotifications: createAction(types.CLEAR_NOTIFICATIONS),
  setAppBanner: createAction(types.SET_APP_BANNER, "component"),
  clearAppBanner: createAction(types.CLEAR_APP_BANNER),
  closeMobileSidebar: createAction(types.MOBILE_SIDEBAR_CLOSED),
  updatePreferredTagType: createAction(
    types.UPDATE_PREFERRED_TAG_TYPE,
    "tagType"
  ),
  updateMarketplacePreferredTagType: createAction(
    types.UPDATE_MARKETPLACE_PREFERRED_TAG_TYPE,
    "tagType"
  ),
  toggleProfileSubmenu: createAction(types.PROFILE_SUBMENU_TOGGLE),
  toggleSearchSideBar: createAction(types.SEARCH_SIDEBAR_TOGGLE),
  toggleSfxSearchSideBar: createAction(types.SFX_SEARCH_SIDEBAR_TOGGLE),
  toggleSidebarSubmenu: createAction(types.SIDEBAR_SUBMENU_TOGGLE),
  toggleMyMediaSubmenu: createAction(types.MY_MEDIA_SUBMENU_TOGGLE),
  toggleLiveChat: createAction(types.LIVE_CHAT_TOGGLE),
  toggleThemeMode: createAction(types.THEME_MODE_TOGGLE),
  toggleMobileSidebar: createAction(types.MOBILE_SIDEBAR_TOGGLE),
}

const osTheme =
  typeof window?.matchMedia === "function" &&
  window.matchMedia("(prefers-color-scheme: dark)").matches
    ? "dark"
    : "light"

const initThemeMode = storeJS.get("themeMode") || osTheme

const initialState = fromJS({
  error: null,
  notice: null,
  notifications: [],
  preferredTagType: storeJS.get("preferredTagType") || TAG_TYPES.MOOD,
  marketplacePreferredTagType:
    storeJS.get("marketplacePreferredTagType") || TAG_TYPES.GENRE,
  sfxSearchSidebarOpen: false,
  searchSidebarOpen: false,
  sidebarSubmenuOpen: false,
  myMediaSubmenuOpen: false,
  profileSubmenuOpen: false,
  liveChatOpen: false,
  appBannerVisible: false,
  appBannerComponent: null,
  searchTipClosed: storeJS.get("searchTipClosed") || false,
  themeMode: initThemeMode,
})

const setDarkModeClasses = (themeMode) => {
  if (!document) return
  const htmlEl = document.getElementsByTagName("html")[0]
  const bodyEl = document.getElementsByTagName("body")[0]
  if (themeMode === "dark" && htmlEl.className.match(/darkMode/) == null) {
    htmlEl.className += " darkMode"
    bodyEl.className += " darkMode"
  } else if (themeMode === "light") {
    htmlEl.className = htmlEl.className.replace(/darkMode/, "")
    bodyEl.className = bodyEl.className.replace(/darkMode/, "")
  }
}

setDarkModeClasses(initThemeMode)

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ERROR:
      // This pushes the stack trace to the console fo debugging :)
      if (process.env.NODE_ENV !== "production") {
        console.error("UI ERROR:", action.error)
      }
      return state.set("notifications", [
        ...state.get("notifications"),
        {
          id: uuidGenerator.generate(),
          level: "alert",
          message: action.error.message ? action.error.message : action.error,
          duration: 7000,
          dismissable: true,
        },
      ])
    case types.SET_NOTICE:
      const level = action.level || "success"
      const duration = 7000
      const id = uuidGenerator.generate()
      const noticeArgs =
        typeof action.notice === "object"
          ? { level, duration, ...action.notice, id }
          : { level, duration, id, message: action.notice }
      return state.set("notifications", [
        ...state.get("notifications"),
        noticeArgs,
      ])
    case types.ADD_NOTIFICATION:
      return state.set("notifications", [
        ...state.get("notifications"),
        action.notification,
      ])
    case types.REPLACE_NOTIFICATION:
      return state.set(
        "notifications",
        state
          .get("notifications")
          .map((n) => (n.id === action.notificationId ? action.updated : n))
      )
    case types.CLEAR_ERROR:
      return state.set("error", null)
    case types.CLEAR_NOTICE:
      return state.set("notice", null)
    case types.CLEAR_NOTIFICATION:
      return state.set(
        "notifications",
        [...state.get("notifications")].filter(
          ({ id }) => id !== action.notificationId
        )
      )
    case types.CLEAR_NOTIFICATIONS:
      return state.set("notifications", [])
    case types.SET_APP_BANNER:
      return state
        .set("appBannerVisible", true)
        .set("appBannerComponent", action.component)
    case types.CLEAR_APP_BANNER:
      return state
        .set("appBannerVisible", false)
        .set("appBannerComponent", null)
    case types.UPDATE_PREFERRED_TAG_TYPE:
      storeJS.set("preferredTagType", action.tagType)
      return state.set("preferredTagType", action.tagType)
    case types.UPDATE_MARKETPLACE_PREFERRED_TAG_TYPE:
      storeJS.set("marketplacePreferredTagType", action.tagType)
      return state.set("marketplacePreferredTagType", action.tagType)
    case types.SFX_SEARCH_SIDEBAR_TOGGLE:
      hideCrispOnSidebarToggleForVisitors(state.get("sfxSearchSidebarOpen"))
      return state.set(
        "sfxSearchSidebarOpen",
        !state.get("sfxSearchSidebarOpen")
      )
    case types.SEARCH_SIDEBAR_TOGGLE:
      hideCrispOnSidebarToggleForVisitors(state.get("searchSidebarOpen"))
      return state.set("searchSidebarOpen", !state.get("searchSidebarOpen"))
    case types.SIDEBAR_SUBMENU_TOGGLE:
      return state.set("sidebarSubmenuOpen", !state.get("sidebarSubmenuOpen"))
    case types.MY_MEDIA_SUBMENU_TOGGLE:
      return state.set("myMediaSubmenuOpen", !state.get("myMediaSubmenuOpen"))
    case types.PROFILE_SUBMENU_TOGGLE:
      return state.set("profileSubmenuOpen", !state.get("profileSubmenuOpen"))
    case types.LIVE_CHAT_TOGGLE:
      return state.set("liveChatOpen", !state.get("liveChatOpen"))
    case types.THEME_MODE_TOGGLE: {
      const themeMode = state.get("themeMode") === "dark" ? "light" : "dark"
      setDarkModeClasses(themeMode)
      storeJS.set("themeMode", themeMode)

      return state.set("themeMode", themeMode)
    }
    case types.MOBILE_SIDEBAR_TOGGLE: {
      document.body.classList.toggle("overlayed")
      return state.set("mobileSidebarOpen", !state.get("mobileSidebarOpen"))
    }
    case types.MOBILE_SIDEBAR_CLOSED: {
      document.body.classList.remove("overlayed")
      return state.set("mobileSidebarOpen", false)
    }
    // Route change
    case LOCATION_CHANGE: {
      let newState = state.set("error", null).set("notice", null)
      if (!action.payload.pathname.match(/^\/(music|video|market)$/)) {
        newState = newState.set("searchSidebarOpen", false)
      }
      return newState
    }

    default:
      return state
  }
}

const selectUiState = (state) => state.getIn(["ducks", "ui"])

export const selectUiLoading = () =>
  createSelector(selectUiState, (uiState) => uiState.get("loading"))

export const selectUiError = () =>
  createSelector(selectUiState, (uiState) => uiState.get("error"))

export const selectUiNotice = () =>
  createSelector(selectUiState, (uiState) => uiState.get("notice"))

export const selectNotifications = () =>
  createSelector(selectUiState, (uiState) => uiState.get("notifications"))

export const selectPreferredTagType = () =>
  createSelector(selectUiState, (uiState) => uiState.get("preferredTagType"))

export const selectMarketplacePreferredTagType = () =>
  createSelector(selectUiState, (uiState) =>
    uiState.get("marketplacePreferredTagType")
  )

export const selectAppBannerVisible = () =>
  createSelector(selectUiState, (uiState) => uiState.get("appBannerVisible"))

export const selectAppBannerComponent = () =>
  createSelector(selectUiState, (uiState) => uiState.get("appBannerComponent"))

export const selectSfxSearchSidebarOpen = () =>
  createSelector(selectUiState, (uiState) =>
    uiState.get("sfxSearchSidebarOpen")
  )

export const selectSearchSidebarOpen = () =>
  createSelector(selectUiState, (uiState) => uiState.get("searchSidebarOpen"))

export const selectSidebarSubmenuOpen = () =>
  createSelector(selectUiState, (uiState) => uiState.get("sidebarSubmenuOpen"))

export const selectProfileSubmenuOpen = () =>
  createSelector(selectUiState, (uiState) => uiState.get("profileSubmenuOpen"))

export const selectMyMediaSubmenuOpen = () =>
  createSelector(selectUiState, (uiState) => uiState.get("myMediaSubmenuOpen"))

export const selectLiveChatOpen = () =>
  createSelector(selectUiState, (uiState) => uiState.get("liveChatOpen"))

export const selectThemeMode = () =>
  createSelector(selectUiState, (uiState) => uiState.get("themeMode"))

export const selectMobileSidebarOpen = () =>
  createSelector(selectUiState, (uiState) => uiState.get("mobileSidebarOpen"))
