import PropTypes from "prop-types"
import React from "react"
import { push } from "react-router-redux"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import { Link, withRouter } from "react-router"
import classNames from "classnames"
import Box from "components/Box"
import Button from "components/Button/New"
import linkStyles from "../linkStyles"
import { locationMatches } from "../utils"
import DropdownTab, { DropDownSubLink } from "../DropdownTab"
import useBreakpoints from "hooks/useBreakpoints"
import useMarketplaceMixpanelTracking from "hooks/useMarketplaceMixpanelTracking"
import useFlag from "hooks/useFlag"

const StyledLink = styled(Link)`
  ${linkStyles};
`

const StoreLink = styled(Button)`
  ${linkStyles}
`

const PlaylistMenu = styled.ul`
  align-content: center;
  animation: fadeIn 0.05s linear 0.05s forwards;
  background: ${(props) => props.theme.colors.background.layered};
  border-radius: ${(props) => props.theme.radii.default};
  box-shadow: ${(props) => props.theme.shadows.dropdown};
  display: flex;
  right: 17px;
  flex-direction: column;
  min-width: 100%;
  padding: ${(props) => props.theme.space[2]} 0;
  position: absolute;
  top: calc(100% + ${(props) => props.theme.space[2]});
  user-select: none;
  z-index: ${(props) => props.theme.zIndices.dropdown};

  & li {
    display: flex;
    text-align: left;

    a {
      width: 100%;
    }
  }
`

const MainNavigation = styled.ul`
  padding-left: 0;
`

const NavLinks = (props) => {
  const { location } = props
  const { isMd } = useBreakpoints()
  const { trackMarketplaceMixpanel } = useMarketplaceMixpanelTracking()
  const dispatch = useDispatch()
  const { enabled: newNav } = useFlag("new_avatar_menu")

  const goToStore = () => {
    dispatch(push("/market"))
    trackMarketplaceMixpanel("Clicked Element", {
      Context: "Marketplace Link - Header",
    })
  }

  return (
    <MainNavigation aria-label="Main navigation" role="menu">
      <Box alignItems="center" display="flex" height="100%">
        <li role="menuitem">
          <StyledLink
            to="/royalty-free-music"
            data-cy="nav-music-link"
            aria-label="Music"
            className={classNames({
              active: locationMatches(location, /^\/royalty-free-music/),
            })}
          >
            Music
          </StyledLink>
        </li>
        <li role="menuitem">
          <StyledLink
            to="/video"
            data-cy="nav-video-link"
            aria-label="Video"
            className={classNames({
              active: locationMatches(location, /^\/video/),
            })}
          >
            Video
          </StyledLink>
        </li>
        <li role="menuitem">
          <StyledLink
            to="/sound-effects"
            data-cy="nav-sfx-link"
            aria-label="Sound Effects"
            className={classNames({
              active: locationMatches(location, /^\/sound-effects/),
            })}
          >
            {isMd ? "SFX" : "Sound Effects"}
          </StyledLink>
        </li>
        <li role="menuitem">
          <DropdownTab
            title="Playlists"
            data-cy="nav-playlists-link"
            aria-haspopup="true"
            aria-expanded="false"
            aria-label="Playlists"
            className={classNames({
              active: locationMatches(location, /^\/playlists/),
            })}
          >
            <PlaylistMenu role="menu">
              <li role="menuitem">
                <DropDownSubLink
                  to="/playlists/music"
                  data-cy="nav-playlists-music-link"
                  aria-label="Music playlists"
                >
                  Music
                </DropDownSubLink>
              </li>
              <li role="menuitem">
                <DropDownSubLink
                  to="/playlists/video"
                  data-cy="nav-playlists-video-link"
                  aria-label="Video playlists"
                >
                  Video
                </DropDownSubLink>
              </li>
              <li role="menuitem">
                <DropDownSubLink
                  to="/playlists/sound-effects"
                  data-cy="nav-playlists-sfx-link"
                  aria-label="Sound Effects playlists"
                >
                  Sound Effects
                </DropDownSubLink>
              </li>
            </PlaylistMenu>
          </DropdownTab>
        </li>
        {newNav && (
          <li role="menuitem">
            <StoreLink onClick={goToStore} aria-label="Market">
              Market
              <Box
                display="inline-block"
                color="pink400"
                fontSize="xs"
                borderRadius="default"
                p={1}
                backgroundColor="brand.mist"
                ml={2}
                lineHeight="locked"
              >
                Beta
              </Box>
            </StoreLink>
          </li>
        )}
      </Box>
    </MainNavigation>
  )
}

NavLinks.propTypes = {
  location: PropTypes.object,
  router: PropTypes.object,
}

export default withRouter(NavLinks)
