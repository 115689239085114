import { buildImgixUrl } from "utils/imgix"

export const contentfulDataForUser = (user, data) => {
  const planTypes = data?.planType
  if (!planTypes) return
  if (planTypes.includes("Everyone")) return data
  if (planTypes.includes("Visitors") && !user.loggedIn()) return data
  if (!user.loggedIn()) return
  if (planTypes.includes("Free") && !user.hasSubscription()) return data
  if (!user.hasSubscription()) return
  if (planTypes.includes("Subscribers")) return data
  if (planTypes.includes("Twitch") && user.twitch()) return data
  if (planTypes.includes("Creator") && user.creator()) return data
  if (planTypes.includes("Pro Plus") && user.proPlus()) return data
  if (planTypes.includes("Enterprise") && user.isAnyEnterprise()) return data

  // Pro in contentful includes any non migrated legacy
  // plan that should in theory should just be international.

  if (
    planTypes.includes("Pro (includes international)") &&
    !user.twitch() &&
    !user.creator() &&
    !user.proPlus() &&
    !user.isAnyEnterprise()
  ) {
    return data
  }
}

export const contentfulImageUrl = (assets, contentAsset, options = {}) => {
  const foundAsset = assets?.find((a) => a?.sys?.id === contentAsset?.sys?.id)
  return buildImgixUrl(foundAsset?.fields?.file?.url, options)
}
