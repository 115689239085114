import flattenDeep from "lodash/flattenDeep"
import algoliaList from "sagas/algoliaList"
import apiSagas from "sagas/api"
import audioPlayerSagas from "sagas/audioPlayer"
import currentUserSagas from "sagas/currentUser"
import favoritableSagas from "sagas/favoritable"
import frameioSagas from "sagas/frameio"
import infiniteListSagas from "sagas/infiniteList"
import listSagas from "sagas/list"
import marketplaceSagas from "sagas/marketplace"
import marketplaceAudioPlayerSagas from "sagas/marketplaceAudioPlayer"
import playlistSagas from "sagas/playlist"
import playlistableSagas from "sagas/playlistable"
import projectSagas from "sagas/project"
import projectableSagas from "sagas/projectable"
import recentlyPlayedSagas from "sagas/recentlyPlayed"
import referralSagas from "sagas/referral"
import searchFilterSagas from "sagas/searchFilter"
import songSagas from "sagas/song"
import soundEffectSagas from "sagas/soundEffect"
import soundEffectsPlayerSagas from "sagas/soundEffectsPlayer"
import trackingSagas from "sagas/tracking"
import videoSagas from "sagas/video"
import uiSagas from "sagas/ui"
import cartSagas from "sagas/cart"
import subscriptionSagas from "sagas/subscription"
import flagSagas from "sagas/flag"
import checkoutSagas from "sagas/checkout"

const sagas = flattenDeep([
  algoliaList,
  apiSagas,
  audioPlayerSagas,
  currentUserSagas,
  favoritableSagas,
  frameioSagas,
  infiniteListSagas,
  listSagas,
  marketplaceSagas,
  marketplaceAudioPlayerSagas,
  playlistSagas,
  playlistableSagas,
  projectSagas,
  projectableSagas,
  recentlyPlayedSagas,
  referralSagas,
  searchFilterSagas,
  songSagas,
  soundEffectSagas,
  soundEffectsPlayerSagas,
  trackingSagas,
  videoSagas,
  uiSagas,
  cartSagas,
  subscriptionSagas,
  flagSagas,
  checkoutSagas,
])

export default sagas
