import { call, put, select, takeLatest } from "redux-saga/effects"
import { actions as checkoutActions, types } from "ducks/checkout"
import { actions as currentUserActions } from "ducks/currentUser"
import { selectCurrentUserEstimate } from "ducks/currentUser"
import { selectCurrentUserPaymentIntent } from "ducks/currentUser"
import {
  authorizeWithThreeDS,
  createOrUpdatePaymentIntent,
} from "sagas/currentUser"

// This saga simply handles authorizing payment intents with the given card
// and checkout attributes.
//
// TODO: Payment source stuff (use transactional license purchase flows as an
// example).
//
// TODO: Move payment intent actions/sagas out of currentUser, probably to here
export function* authorizePaymentIntent(action) {
  try {
    const { chargebeeCard, checkoutAttributes } = action

    // TODO: PayPal payments should not even go to this saga. It has a separate
    // saga where you redirect to a PayPal page and then get redirected back to our
    // app and receive the `billing_agreement_token` (stored in checkout page state)
    // so that could probably just be refactored into a `payWithPayPal` saga similar
    // to this one.
    const isPaypalPayment = !!checkoutAttributes.billing_agreement_token
    if (isPaypalPayment) {
      yield put(
        checkoutActions.authorizePaymentIntentSuccess(checkoutAttributes)
      )
      return
    }

    // TODO: estimate should be passed in `action`, so we can reuse this for all purchase
    // flows, e.g. cart estimates are stored in the cart duck.
    const estimate = yield select(selectCurrentUserEstimate())

    yield call(createOrUpdatePaymentIntent, {
      attributes: {
        amount: estimate.invoice_estimate.total,
        currency_code: estimate.invoice_estimate.currency_code,
      },
    })

    const paymentIntent = yield select(selectCurrentUserPaymentIntent())

    // TODO: Move authorizeWithThreeDS to this saga, probably
    const paymentIntentResponse = yield call(authorizeWithThreeDS, {
      chargebeeCard,
      checkoutAttributes,
      paymentIntent,
    })

    if (
      paymentIntentResponse &&
      paymentIntentResponse.status !== "authorized"
    ) {
      yield put(currentUserActions.removePaymentIntent())
      yield put(currentUserActions.requestFailed())
      yield put(
        checkoutActions.authorizePaymentIntentFailed(
          paymentIntentResponse.displayMessage
        )
      )
      return
    }

    yield put(
      checkoutActions.authorizePaymentIntentSuccess({
        payment_intent: paymentIntentResponse,
        ...checkoutAttributes,
      })
    )
  } catch (err) {
    yield put(checkoutActions.authorizePaymentIntentFailed(err))
  }
}

export function* watchAuthorizePaymentIntent() {
  yield takeLatest(types.AUTHORIZE_PAYMENT_INTENT, authorizePaymentIntent)
}

export default [watchAuthorizePaymentIntent]
