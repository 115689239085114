import _format from "date-fns/format"
import _isAfter from "date-fns/is_after"
import _isBefore from "date-fns/is_before"
import add_months from "date-fns/add_months"

export const formatDate = (date, formatWith = "MMM D, YYYY") =>
  _format(date, formatWith)

export const dateIsBetween = (date, start, end) =>
  _isAfter(date, start) && _isBefore(date, end)

export const getUnixTime = (date) => {
  const dateObj = new Date(date)
  return (dateObj.getTime() / 1000) | 0
}

export const addMonths = (date, months) => {
  const currentDate = date
  return add_months(currentDate, months)
}
