import styled from "styled-components"
import PropTypes from "prop-types"
import {
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  typography,
  system,
} from "styled-system"
import { removeScrollbar } from "containers/App/style-utils.js"

const Box = styled.div`
  ${background}
  ${border}
  ${color}
  ${flexbox}
  ${grid}
  ${layout}
  ${position}
  ${shadow}
  ${space}
  ${typography}
  ${system({
    prop: "wordBreak",
    cssProperty: "wordBreak",
  })}
  ${(props) => props.cursor && `cursor: ${props.cursor};`}
  ${(props) => (props.hideScrollbar ? removeScrollbar : "")}
`

Box.propTypes = {
  hideScrollbar: PropTypes.bool,
}

Box.defaultProps = {
  hideScrollbar: false,
}

export default Box
