import { useDebugFlag } from "context/DebugFlagContext"
import { isProduction } from "utils/environment"

export default () => {
  if (isProduction()) return { enabled: false }

  const { debugFlags } = useDebugFlag()
  const debugFlagEnabled = debugFlags["NEW_SEARCH_UI"]

  return {
    enabled: debugFlagEnabled,
  }
}
