import { fromJS, List as ImmutableList } from "immutable"
import { createSelector } from "reselect"
import { createSearchFilterRecordsFromResponse } from "records/util"
import { createAction } from "./utils"

export const types = {
  LOAD: "soundstripe/searchFilter/LOAD",
  LOAD_FACETS: "soundstripe/searchFilter/LOAD_FACETS",
  LOAD_SUCCESS: "soundstripe/searchFilter/LOAD_SUCCESS",
  LOAD_FACETS_SUCCESS: "soundstripe/searchFilter/LOAD_FACETS_SUCCESS",
  LOAD_FAILED: "soundstripe/searchFilter/LOAD_FAILED",
  STORE_TAG_IDS: "soundstripe/searchFilter/STORE_TAG_IDS",
}

export const actions = {
  storeTagIds: createAction(types.STORE_TAG_IDS, "visibleTagIds"),
  loadFilters: createAction(types.LOAD, "filterType", "mediaType"),
  loadFacets: createAction(types.LOAD_FACETS, "mediaType"),
  loadSuccess: createAction(types.LOAD_SUCCESS, "response", "filterType"),
  loadFacetsSuccess: createAction(types.LOAD_FACETS_SUCCESS, "response"),
  loadFailed: createAction(types.LOAD_FAILED),
}

const emptyList = new ImmutableList()

const initialState = fromJS({
  artists: {
    loading: false,
    loaded: false,
    error: null,
    artists: emptyList,
    type: "artist",
  },
  playlists: {
    loading: false,
    loaded: false,
    error: null,
    playlists: emptyList,
    type: "playlist",
  },
  genreTags: {
    loading: false,
    loaded: false,
    error: null,
    tags: emptyList,
    type: "genre",
  },
  instrumentTags: {
    loading: false,
    loaded: false,
    error: null,
    tags: emptyList,
    type: "instruments",
  },
  moodTags: {
    loading: false,
    loaded: false,
    error: null,
    tags: emptyList,
    type: "mood",
  },
  energyTags: {
    loading: false,
    loaded: false,
    error: null,
    tags: emptyList,
    type: "energy",
  },
  characteristicTags: {
    loading: false,
    loaded: false,
    error: null,
    tags: emptyList,
    type: "characteristic",
  },
})

const recordKey = (filterType) =>
  filterType && filterType.match(/tags/i) ? "tags" : filterType

export default (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD:
      return state.setIn([action.filterType, "loading"], true)
    case types.STORE_TAG_IDS:
      return state.set("visibleTagIds", new ImmutableList(action.visibleTagIds))
    case types.LOAD_SUCCESS: {
      const immRecords = createSearchFilterRecordsFromResponse(action.response)
      return state
        .setIn(
          [action.filterType, recordKey(action.filterType)],
          new ImmutableList(immRecords)
        )
        .setIn([action.filterType, "loading"], false)
        .setIn([action.filterType, "loaded"], true)
    }
    case types.LOAD_FACETS_SUCCESS: {
      return state.setIn(["algolia"], action.response)
    }
    case types.LOAD_FAILED:
      return state.setIn([action.filterType, "loading"], false)
    default:
      return state
  }
}

const filtersState = (state) => state.getIn(["ducks", "searchFilter"])

export const selectLoading = (filterType) =>
  createSelector(filtersState, (filters) =>
    filters.getIn([filterType, "loading"])
  )

export const selectLoaded = (filterType) =>
  createSelector(filtersState, (filters) =>
    filters.getIn([filterType, "loaded"])
  )

export const selectFilters = (filterType) =>
  createSelector(filtersState, (filters) =>
    filters.getIn([filterType, recordKey(filterType)])
  )

export const selectAlgoliaFilters = () =>
  createSelector(filtersState, (filters) => filters.getIn(["algolia"]))

export const selectMultipleFilters = (filterTypes) =>
  createSelector(filtersState, (filters) => {
    let selectedFilters = new ImmutableList()
    filterTypes.forEach((filterType) => {
      const selectedFilter = filters.getIn([filterType, recordKey(filterType)])
      selectedFilters = selectedFilters.merge(selectedFilter)
    })

    return selectedFilters
  })
