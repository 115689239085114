import { createAction } from "ducks/utils"
import get from "lodash/get"
import { types as userActionTypes } from "ducks/currentUser"
import Cookie from "js-cookie"
import { DISABLE_TRACKING_COOKIE } from "utils/tracking"

export const GET_COOKIE = "GET_COOKIE"
export const GET_COOKIES = "GET_COOKIES"
export const SET_COOKIE = "SET_COOKIE"
export const REMOVE_COOKIE = "REMOVE_COOKIE"

export const MARKETING_FUNNEL_COOKIE = "mk_token"
export const STATSIG_STABLE_ID_COOKIE = "soundstripe.statsig.shared_stable_id"
export const ADOBE_REGISTRATION_COOKIE = "adobe_registration"
export const VISITOR_SEARCH_LOCATIONS_TRIGGER_COOKIE =
  "sst_visitor_search_locations_trigger"

export const actions = {
  getCookie: createAction(GET_COOKIE, "name"),
  getCookies: createAction(GET_COOKIES, "names"),
  setCookie: createAction(
    SET_COOKIE,
    "name",
    "value",
    "isDomainCookie",
    "expires"
  ),
  removeCookie: createAction(REMOVE_COOKIE, "name", "isDomainCookie"),
}

const SS_COOKIE_DOMAIN = ".soundstripe.com"
const DEFAULT_EXPIRES = 14

const middleware = () => (store) => (next) => (action) => {
  // This is to ensure that everytime the user record is set to the correct cookie value.
  if (action.type === userActionTypes.REQUEST_SUCCESS) {
    const testUser = get(action, "response.data.attributes.test_user", false)
    Cookie.set(DISABLE_TRACKING_COOKIE, testUser, { expires: DEFAULT_EXPIRES })
    next(action)
  }
  if (action.type === GET_COOKIES) {
    const cookies = action.names.reduce((obj, name) => {
      const cookie = Cookie.get(name)
      if (cookie) {
        const newObj = {
          ...obj,
          [name]: cookie,
        }
        return newObj
      }
      return obj
    }, {})
    return cookies
  }
  if (action.type === GET_COOKIE) {
    const cookie = Cookie.get(action.name)
    if (cookie) {
      return cookie
    }
    return null
  }
  if (action.type === SET_COOKIE) {
    if (action.isDomainCookie && process.env.BUILD_ENV !== "development") {
      Cookie.set(action.name, action.value, {
        domain: SS_COOKIE_DOMAIN,
        expires: action.expires || DEFAULT_EXPIRES,
      })
    } else {
      Cookie.set(action.name, action.value, {
        expires: action.expires || DEFAULT_EXPIRES,
      })
    }
  }
  if (action.type === REMOVE_COOKIE) {
    if (action.isDomainCookie && process.env.BUILD_ENV !== "development") {
      Cookie.remove(action.name, { domain: SS_COOKIE_DOMAIN })
    } else {
      Cookie.remove(action.name)
    }
  }
  return next(action)
}

export default middleware
