import map from "lodash/map"
import { call, put, takeEvery, select } from "redux-saga/effects"
import {
  get,
  prependApiUrl,
  updatePageAndFilterQueryString,
} from "utils/request"
import { types, actions as listActions, selectListByStoreKey } from "ducks/list"
import { actions as apiActions } from "ducks/api"
import { actions as uiActions } from "ducks/ui"

export function* loadList(action) {
  try {
    const listRecord = yield select(selectListByStoreKey(action.storeKey))
    const endpoint = updatePageAndFilterQueryString(listRecord.requestPath, {
      offset: 0,
      limit: listRecord.limit,
      query: null,
    })
    const requestURL = prependApiUrl(endpoint)
    const response = yield call(get, requestURL)
    const ids = map(response.data, "id")
    yield put(apiActions.readSuccess(response))
    yield put(listActions.loadSuccess(action.storeKey, ids))
  } catch (err) {
    yield put(uiActions.setError(err))
    yield put(listActions.loadFailed(action.storeKey))
  }
}

export function* watchLoadList() {
  yield takeEvery(types.CREATE, loadList)
}

export default [watchLoadList]
