import Immutable from "immutable"

const ArtistUiRecord = Immutable.Record(
  {
    id: null,
    type: "artists",

    // Attributes
    name: "",
    thumbnail_url: "",
  },
  "ArtistUi"
)

class ArtistUi extends ArtistUiRecord {
  constructor(args) {
    super(Object.assign({}, args))
  }
}

export default ArtistUi
