const storeJS = require("utils/store.min.js")
export const PLAY_HISTORY = "play_history"

export const addToPlayHistory = (mediaType, data) => {
  const today = new Date(Date.now()).setHours(0, 0, 0, 0)
  const playHistory = storeJS.get(PLAY_HISTORY)
  const currentSongs = playHistory?.songs || []
  const currentSfx = playHistory?.sfx || []
  const currentVideos = playHistory?.videos || []
  const currentMarketplaceSongs = playHistory?.marketplace_songs || []

  const currentMarketplaceAudioFiles =
    playHistory?.marketplace_audio_files || []

  let newSongs,
    newSoundEffects,
    newVideos,
    newMarketplaceSongs,
    newMarketplaceAudioFiles

  if (mediaType === "songs") {
    newSoundEffects = currentSfx
    newVideos = currentVideos
    newMarketplaceSongs = currentMarketplaceSongs
    newMarketplaceAudioFiles = currentMarketplaceAudioFiles

    const foundSong = currentSongs.find(
      (song) => song.audioFileId === data.audioFileId
    )

    if (foundSong) {
      const foundIndex = currentSongs.indexOf(foundSong)
      currentSongs.splice(foundIndex, 1, data)
      newSongs = currentSongs
    } else {
      newSongs = [...currentSongs, data]
    }
  } else if (mediaType === "sound_effects") {
    newSongs = currentSongs
    newVideos = currentVideos
    newMarketplaceSongs = currentMarketplaceSongs
    newMarketplaceAudioFiles = currentMarketplaceAudioFiles

    const foundSfx = currentSfx.find((id) => id === data.objectId)
    newSoundEffects = foundSfx ? currentSfx : [...currentSfx, data.objectId]
  } else if (mediaType === "videos") {
    newSongs = currentSongs
    newSoundEffects = currentSfx
    newMarketplaceSongs = currentMarketplaceSongs
    newMarketplaceAudioFiles = currentMarketplaceAudioFiles

    const foundVideo = currentVideos.find((v) => v.objectId === data.objectId)

    if (foundVideo) {
      const foundIndex = currentVideos.indexOf(foundVideo)
      currentVideos.splice(foundIndex, 1, data)
      newVideos = currentVideos
    } else {
      newVideos = [...currentVideos, data]
    }
  } else if (mediaType === "marketplace_songs") {
    newSongs = currentSongs
    newSoundEffects = currentSfx
    newVideos = currentVideos
    newMarketplaceAudioFiles = currentMarketplaceAudioFiles

    const foundMarketplaceSong = currentMarketplaceSongs.find(
      (song) => song.objectId === data.objectId
    )

    if (foundMarketplaceSong) {
      const foundIndex = currentMarketplaceSongs.indexOf(foundMarketplaceSong)
      currentMarketplaceSongs.splice(foundIndex, 1, data)
      newMarketplaceSongs = currentMarketplaceSongs
    } else {
      newMarketplaceSongs = [...currentMarketplaceSongs, data]
    }
  } else if (mediaType === "marketplace_audio_files") {
    newSongs = currentSongs
    newSoundEffects = currentSfx
    newVideos = currentVideos
    newMarketplaceSongs = currentMarketplaceSongs

    const foundMarketplaceAudioFile = currentMarketplaceAudioFiles.find(
      (song) => song.objectId === data.objectId
    )

    if (foundMarketplaceAudioFile) {
      const foundIndex = currentMarketplaceAudioFiles.indexOf(
        foundMarketplaceAudioFile
      )

      currentMarketplaceAudioFiles.splice(foundIndex, 1, data)
      newMarketplaceAudioFiles = currentMarketplaceAudioFiles
    } else {
      newMarketplaceAudioFiles = [...currentMarketplaceAudioFiles, data]
    }
  }

  storeJS.set(PLAY_HISTORY, {
    date: today,
    songs: newSongs,
    sfx: newSoundEffects,
    videos: newVideos,
    marketplace_songs: newMarketplaceSongs,
    marketplace_audio_files: newMarketplaceAudioFiles,
  })
}

export const getPlayHistoryIds = (mediaType) => {
  const history = storeJS.get(PLAY_HISTORY)

  switch (mediaType) {
    case "songs":
      return [...new Set((history?.songs || []).map((song) => song.objectId))]
    case "sound_effects":
      return history?.sfx
    case "videos":
      return [
        ...new Set((history?.videos || []).map((video) => video.objectId)),
      ]
    case "marketplace_songs":
      return [
        ...new Set(
          (history?.marketplace_songs || []).map((song) => song.objectId)
        ),
      ]
    case "marketplace_audio_files":
      return [
        ...new Set(
          (history?.marketplace_audio_files || []).map((song) => song.objectId)
        ),
      ]
    default:
      return []
  }
}

export const checkPlayHistoryExpiration = () => {
  const today = new Date(Date.now()).setHours(0, 0, 0, 0)
  const playHistory = storeJS.get(PLAY_HISTORY)
  if (!playHistory) return
  if (playHistory.date === today) return
  storeJS.remove(PLAY_HISTORY)
}
