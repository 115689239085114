import { fromJS } from "immutable"
import { createSelector } from "reselect"
import { createAction } from "./utils"

export const types = {
  STORE_OPEN_STATE: "soundstripe/sidebar/STORE_OPEN_STATE",
  ARTISTS_LOADED: "soundstripe/sidebar/ARTISTS_LOADED",
  PLAYLISTS_LOADED: "soundstripe/sidebar/PLAYLISTS_LOADED",
  TAGS_LOADED: "soundstripe/sidebar/TAGS_LOADED",
}

export const actions = {
  storeOpenState: createAction(types.STORE_OPEN_STATE, "openState"),
  artistsLoaded: createAction(types.ARTISTS_LOADED),
  playlistsLoaded: createAction(types.PLAYLISTS_LOADED),
  tagsLoaded: createAction(types.TAGS_LOADED, "tagType"),
}

const initialState = fromJS({
  openState: null,
  artistsLoaded: false,
  playlistsLoaded: false,
  tagsLoaded: {
    moodTags: false,
    genreTags: false,
    instrumentsTags: false,
    characteristicTags: false,
  },
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.STORE_OPEN_STATE: {
      return state.set("openState", action.openState)
    }
    case types.ARTISTS_LOADED: {
      return state.set("artistsLoaded", true)
    }
    case types.PLAYLISTS_LOADED: {
      return state.set("playlistsLoaded", true)
    }
    case types.TAGS_LOADED: {
      return state.setIn(["tagsLoaded", action.tagType], true)
    }
    default: {
      return state
    }
  }
}

const sidebarState = (state) => state.getIn(["ducks", "sidebar"])

export const selectOpenState = () =>
  createSelector(sidebarState, (sidebar) => sidebar.get("openState"))

export const selectArtistsLoaded = () =>
  createSelector(sidebarState, (sidebar) => sidebar.get("artistsLoaded"))

export const selectPlaylistsLoaded = () =>
  createSelector(sidebarState, (sidebar) => sidebar.get("playlistsLoaded"))

export const selectTagsLoaded = (tagType) =>
  createSelector(sidebarState, (sidebar) =>
    sidebar.getIn(["tagsLoaded", tagType])
  )
