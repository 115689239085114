import { connect } from "react-redux"
import { selectThemeMode } from "ducks/ui"
import { compose } from "redux"

const mapStateToProps = (state) => ({
  themeMode: selectThemeMode()(state),
})

const withThemeMode = compose(connect(mapStateToProps))

export default withThemeMode
