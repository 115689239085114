import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import get from "lodash/get"
import key from "keymaster"
import ReactHowler from "react-howler"
import parseInt from "lodash/parseInt"
import { selectAlgoliaListByStoreKey } from "ducks/algoliaList"
import {
  actions,
  AudioPlayerRecord,
  selectAudioPlayer,
  selectAudioPlayerSong,
  selectAudioPlayerPlaylist,
  selectAudioPlayerAudioFile,
  selectAudioPlayerCursor,
} from "ducks/audioPlayer"
import {
  selectPlayerVisible as selectMarketplaceAudioPlayerVisible,
  actions as marketplaceAudioPlayerActions,
} from "ducks/marketplaceAudioPlayer"
import { selectCurrentUserRecord } from "ducks/currentUser"
import {
  actions as soundEffectsPlayerActions,
  selectSoundEffectsPlayerPlaying,
} from "ducks/soundEffectsPlayer"
import { actions as modalActions } from "ducks/modal"
import { actions as favoritableActions } from "ducks/favoritable"
import {
  Song as SongRecord,
  AudioFile as AudioFileRecord,
  User as UserRecord,
} from "records"
import { MOBILE, withScreenSize } from "hocs/withScreenSize"
import withMixpanelTracking from "hocs/withMixpanelTracking"
import { currentSortIndexForUser } from "utils/algolia"

import { loggedIn } from "utils/authentication"
import { get as getRequest } from "utils/request"
import { trackAlgolia } from "utils/tracking"

import InfoSection from "./InfoSection"
import RadioControls from "./RadioControls"
import Volume from "./Volume"
import WaveformSection from "./WaveformSection"
import ActionSection from "./ActionSection"
import MobileView from "./MobileView"

const DesktopWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: ${(props) => props.theme.layout.audioPlayer.desktop.height};
  left: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.background.secondary};
  border-top: 1px solid ${(props) => props.theme.colors.border.default};
  z-index: ${(props) => props.theme.zIndices.fixed};
  display: flex;
  justify-content: space-between;
`

const PageContentSection = styled.div``

class AudioPlayer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loadingAudioPeaks: true,
      mute: false,
      peaks: null,
      src: null,
    }
  }

  componentDidMount = () => {
    key("space", (e) => {
      e.preventDefault()
      if (!e.repeat) this.handleTogglePause()
    })

    key("left, up", (e) => {
      e.preventDefault()
      this.handlePrev()
    })

    key("right, down", (e) => {
      e.preventDefault()
      this.handleNext()
    })

    key("shift+., .", (e) => {
      e.preventDefault()
      this.handleSeekForward(15)
    })

    key("shift+,, ,", (e) => {
      e.preventDefault()
      this.handleSeekBackward(15)
    })

    key("f", (e) => {
      e.preventDefault()
      this.handleToggleFavorite()
    })

    key("p", (e) => {
      e.preventDefault()
      this.handleAddToPlaylist()
    })

    if (this.props.audioFile) {
      this.setState((_, props) => ({
        src: props.audioFile.mp3Url(),
      }))
    }
  }

  componentDidUpdate = (prevProps) => {
    const {
      algoliaState,
      audioPlayer,
      currentUser,
      song,
      trackMixpanel,
    } = this.props

    const {
      seekPos,
      audioFileId,
      parentSongId,
      childSongRank,
      playing,
    } = this.props.audioPlayer
    const prevAudioPlayer = prevProps.audioPlayer
    const prevSong = prevProps.song
    const prevPlaylist = prevProps.playlist
    const prevAudioFile = prevProps.audioFile
    const prevSeekPos = prevAudioPlayer.seekPos
    const prevPos = prevAudioPlayer.pos
    const prevAudioFileId = prevAudioPlayer.audioFileId

    if (this.player) {
      if (seekPos !== prevSeekPos) {
        this.player.seek(seekPos)
        this.props.playProgress(seekPos)
      }

      if (audioFileId !== prevAudioFileId) {
        this.setState({
          loadingAudioPeaks: true,
          peaks: null,
        })
        this.getPeaks(this.props.audioFile)
      }

      // If it was paused, track that they listened this far
      // If the audio file was changed while playing, track that they
      // listened this far. If it wasn't playing, it was tracked when
      // it was paused.
      const songAndAudio = prevSong && prevAudioFile
      const songChanged = prevAudioFileId !== audioFileId

      if (
        songAndAudio &&
        prevAudioPlayer.playing &&
        (!playing || songChanged)
      ) {
        trackMixpanel("Played Song", {
          "Play Duration": Math.round(prevPos),
          "Play Completed": songChanged,
          "Song ID": prevSong.id,
          "Song Title": prevSong.title,
          "Song Version ID": prevAudioFile.id,
          "Song Version Description": prevAudioFile.description,
          "Song Parent ID": parentSongId,
          "Recommendation Rank": childSongRank,
          "Artist ID": prevSong.primaryArtistId(),
          "Artist Name": prevSong.primaryArtistName(),
          "Playlist ID": get(prevPlaylist, "id", null),
          "Playlist Name": get(prevPlaylist, "name", null),
        })
      }
      const userToken = currentUser.get("id")
      const queryID = algoliaState.get("queryID")

      if (audioFileId && songChanged && userToken) {
        trackAlgolia(
          queryID ? "clickedObjectIDsAfterSearch" : "clickedObjectIDs",
          {
            userToken,
            eventName: "Song Played",
            index: currentSortIndexForUser(
              currentUser,
              algoliaState.get("sort")
            ),
            ...(queryID && { queryID }),
            objectIDs: [song.get("id")],
            ...(queryID && { positions: [audioPlayer.get("radioIndex") + 1] }),
          }
        )
      }
    }
  }

  componentWillUnmount = () =>
    key.unbind("space, left, right, up, down, f, p, shift+., shift+,, ., ,")

  handleAddToPlaylist = () => {
    if (!this.props.song) return

    this.props.addToPlaylist(this.props.song)
  }

  handleToggleFavorite = () => {
    const { song, currentUser, unfavorite, favorite } = this.props
    if (!song) return

    if (currentUser.favoritedSong(song.id)) {
      unfavorite(song)
    } else {
      favorite(song)
    }
  }

  handleNext = () => {
    if (!this.props.audioFile) return
    const { audioPlayerCursor, next } = this.props
    const hasNext = audioPlayerCursor && audioPlayerCursor.get("next")
    if (hasNext) next()
  }

  handleOnEnd = () => {
    const { next, ended, audioPlayerCursor } = this.props
    const hasNext = audioPlayerCursor && audioPlayerCursor.get("next")

    const endedData = hasNext
      ? { ended: true }
      : { ended: true, playing: false }

    ended(endedData)
    if (hasNext) next()
  }

  handlePlay = () => {
    if (this.props.soundEffectIsPlaying) {
      this.props.pauseSoundEffectsPlayer()
    }
    if (this.props.marketplaceAudioPlayerVisible) {
      this.props.hideSongMarketplaceAudioPlayer()
    }

    setTimeout(this.step, 200)
  }

  handlePrev = () => {
    if (!this.props.audioFile) return

    this.props.prev()
  }

  handleSeek = (e) => {
    this.props.trackMixpanel("Clicked Element", {
      context: "Waveform",
    })
    if (!loggedIn()) {
      return this.props.openSignupModal("waveform")
    }
    this.props.seek(e)
  }

  handleToggleMute = () => {
    if (!this.state.mute && !this.props.audioPlayer.volume) {
      this.props.changeVolume(1)
    } else {
      this.setState((state) => ({ mute: !state.mute }))
    }
  }

  handleTogglePause = () => {
    if (!this.props.audioFile) return

    this.props.togglePause()
  }

  handleVolumeChange = (value) => this.props.changeVolume(value / 100)

  handleSeekBackward = (sec) => {
    if (!this.props.audioFile) return
    const newPos = this.props.audioPlayer.pos - sec

    if (newPos <= 0) {
      this.handlePrev()
    } else {
      this.props.seek(newPos)
    }
  }

  handleSeekForward = (sec) => {
    const { audioPlayer, audioFile, seek } = this.props

    if (audioFile) {
      const newPos = audioPlayer.pos + sec

      if (newPos >= audioFile.duration) {
        this.handleNext()
      } else {
        seek(newPos)
      }
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const player = this.props.audioPlayer
    const nextPlayer = nextProps.audioPlayer
    const nextAudioFile = nextProps.audioFile

    if (player.audioFileId !== nextPlayer.audioFileId) {
      this.setState({ src: nextAudioFile.mp3Url() })
    }
  }

  getPeaks = (audioFile) => {
    if (audioFile.peakDataUrl()) {
      getRequest(audioFile.peakDataUrl())
        .then((response) => {
          if (this.wrapper) {
            // Response can either be an array if the peak data was generated by transloadit
            // or an object with an array property if it was generated by carrierwave-audio-waveform
            const peaks =
              (Array.isArray(response) ? response : response.data) || null
            this.setState({ peaks, loadingAudioPeaks: false })
          }
        })
        .catch(() => {
          if (this.wrapper) {
            this.setState({ loadingAudioPeaks: false })
          }
        })
    }
  }

  step = () => {
    const howler = this.player && this.player.howler
    if (howler && howler.playing()) {
      this.props.playProgress(this.player.seek())
      setTimeout(this.step, 200)
    }
  }

  render() {
    const {
      audioPlayer,
      audioFile,
      song,
      screenSize,
      className,
      currentUser,
    } = this.props

    if (!audioPlayer.playerVisible) {
      return null
    }

    return (
      <PageContentSection
        className={className}
        ref={(wrapper) => {
          this.wrapper = wrapper
        }}
      >
        <ReactHowler
          ref={(instance) => (this.player = instance)}
          src={this.state.src}
          playing={audioPlayer.playing}
          html5
          onEnd={this.handleOnEnd}
          onPlay={this.handlePlay}
          format={[audioFile.fileExtension]}
          volume={audioPlayer.volume}
          mute={this.state.mute}
        />
        {screenSize === MOBILE ? (
          <MobileView
            duration={parseInt(audioFile.duration)}
            handleTogglePause={this.handleTogglePause}
            index={audioPlayer.radioIndex}
            isPlaying={audioPlayer.playing}
            pos={audioPlayer.pos}
            storeKey={audioPlayer.listStoreKey}
            song={song}
          />
        ) : (
          <DesktopWrapper>
            <InfoSection song={song} />
            <RadioControls
              handlePrev={this.handlePrev}
              handleNext={this.handleNext}
              handleTogglePause={this.handleTogglePause}
              isPlaying={audioPlayer.playing}
              showRadioControls={!!audioPlayer.listStoreKey}
            />
            <WaveformSection
              duration={parseInt(audioFile.duration)}
              formattedDuration={audioFile.durationFormatted()}
              handleSeek={this.handleSeek}
              peaks={this.state.peaks}
              pos={audioPlayer.pos}
            />
            <Volume
              handleToggleMute={this.handleToggleMute}
              handleVolumeChange={this.handleVolumeChange}
              mute={this.state.mute}
              volume={audioPlayer.volume}
            />
            <ActionSection song={song} currentUser={currentUser} />
          </DesktopWrapper>
        )}
      </PageContentSection>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  favorite: (song) => dispatch(favoritableActions.favorite(song)),
  addToPlaylist: (song) =>
    dispatch(modalActions.open("AddMediaToPlaylistModal", { record: song })),
  changeVolume: (volume) => dispatch(actions.changeVolume(volume)),
  ended: (data) => dispatch(actions.ended(data)),
  next: (noAutoPlay) => dispatch(actions.next(noAutoPlay)),
  openSignupModal: (actionType) =>
    dispatch(modalActions.open("SignUpModal", { action: actionType })),
  playProgress: (pos) => dispatch(actions.progress(pos)),
  prev: () => dispatch(actions.prev()),
  unfavorite: (song) => dispatch(favoritableActions.unfavorite(song)),
  seek: (seekPos) => dispatch(actions.seek(seekPos)),
  pause: () => dispatch(actions.pause()),
  resume: () => dispatch(actions.resume()),
  togglePause: () => dispatch(actions.togglePause()),
  pauseSoundEffectsPlayer: () =>
    dispatch(soundEffectsPlayerActions.togglePause()),
  hideSongMarketplaceAudioPlayer: () =>
    dispatch(marketplaceAudioPlayerActions.stop()),
})

const mapStateToProps = (state) => ({
  algoliaState: selectAlgoliaListByStoreKey("songs")(state),
  audioFile: selectAudioPlayerAudioFile()(state),
  audioPlayer: selectAudioPlayer()(state),
  currentUser: selectCurrentUserRecord()(state),
  playlist: selectAudioPlayerPlaylist()(state),
  song: selectAudioPlayerSong()(state),
  soundEffectIsPlaying: selectSoundEffectsPlayerPlaying()(state),
  marketplaceAudioPlayerVisible: selectMarketplaceAudioPlayerVisible()(state),
  audioPlayerCursor: selectAudioPlayerCursor()(state),
})

AudioPlayer.propTypes = {
  addToPlaylist: PropTypes.func.isRequired,
  algoliaState: PropTypes.object,
  audioFile: PropTypes.instanceOf(AudioFileRecord),
  audioPlayer: PropTypes.instanceOf(AudioPlayerRecord),
  changeVolume: PropTypes.func.isRequired,
  className: PropTypes.string,
  currentUser: PropTypes.instanceOf(UserRecord),
  ended: PropTypes.func.isRequired,
  favorite: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  pauseSoundEffectsPlayer: PropTypes.func.isRequired,
  playProgress: PropTypes.func.isRequired,
  prev: PropTypes.func.isRequired,
  screenSize: PropTypes.string,
  seek: PropTypes.func.isRequired,
  song: PropTypes.instanceOf(SongRecord),
  soundEffectIsPlaying: PropTypes.bool,
  togglePause: PropTypes.func.isRequired,
  unfavorite: PropTypes.func.isRequired,
}

export default withScreenSize(
  withMixpanelTracking(
    connect(mapStateToProps, mapDispatchToProps)(AudioPlayer)
  )
)
