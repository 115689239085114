import React from "react"
import styled, { css, useTheme } from "styled-components"
import Box from "components/Box"
import Button from "components/Button/New"
import { Text } from "components/Typography"
import { ICON_SS_CLOSE } from "components/Icon/constants"
import useCurrentUser from "hooks/useCurrentUser"
import useLocalStorage from "hooks/useLocalStorage"
import Img from "components/Img"
import useBreakpoints from "hooks/useBreakpoints"
import { useDispatch } from "react-redux"
import { actions as modalActions } from "ducks/modal"
import useMixpanelTracking from "hooks/useMixpanelTracking"
import { contentfulDataForUser } from "utils/contentful"
import { push } from "react-router-redux"
import useContentful from "hooks/useContentful"

const focusStyles = css`
  &:focus-visible {
    box-shadow: 0 0 0 3px transparent,
      0 0 0 2px ${(props) => props.theme.colors.white};
    outline: none;
    text-decoration: none;
  }

  &:focus:not(:focus-visible) {
    outline: initial;
  }
`

const DismissButton = styled(Button)`
  ${focusStyles}
  align-self: center;
  border: 1px solid transparent !important;
  padding: 0.5rem !important;
  background-color: transparent;

  svg {
    color: ${(props) => props.theme.colors.white};
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.24) !important;

    svg {
      color: ${(props) => props.theme.colors.white};
    }
  }
`

const CtaButton = styled(Button)`
  ${focusStyles}
  background-color: rgba(0,0,0,0.16) !important;
  color: ${(props) => props.theme.colors.white} !important;
  white-space: nowrap;

  &:hover {
    background-color: rgba(0, 0, 0, 0.24) !important;
  }
`

const CTA_ACTIONS = {
  LINK_TO_PRICING: "Link to Pricing",
  OPEN_UPGRADE_MODAL: "Open Upgrade Modal",
}

const StickyBanner = () => {
  const { currentUser } = useCurrentUser()
  const theme = useTheme()
  const { trackMixpanel } = useMixpanelTracking()
  const { gtSm, gtLg } = useBreakpoints()
  const dispatch = useDispatch()
  const { entries } = useContentful("stickyBanner")

  const banner = entries
    ?.map((b) => contentfulDataForUser(currentUser, b?.fields))
    ?.filter(Boolean)?.[0]

  const { value: barState, set: setBarState } = useLocalStorage(
    `sticky_banner_${banner?.title?.toLowerCase()?.replaceAll(" ", "_")}`
  )

  if (!banner || barState === "dismissed") return null

  const {
    buttonAction,
    buttonText,
    environment,
    mobileText,
    planType,
    text,
  } = banner

  const showButton = buttonText && buttonAction

  const onDismiss = () => setBarState("dismissed")

  const onClick = () => {
    trackMixpanel("Clicked Element", {
      "Sticky Banner Environment": environment,
      "Sticky Banner Plan Type": planType,
      element: buttonText,
      context: "Sticky Banner",
    })

    switch (buttonAction) {
      case CTA_ACTIONS.LINK_TO_PRICING:
        return dispatch(push("/pricing"))
      case CTA_ACTIONS.OPEN_UPGRADE_MODAL:
        if (!currentUser.isUpgradeEligible()) return

        return dispatch(
          modalActions.open("UpgradeModal", {
            context: "Sticky Banner",
          })
        )
      default:
        return
    }
  }

  return (
    <Box
      position="relative"
      top="0"
      display="flex"
      background={theme.colors.offer.banner.background}
      height={{ _: "4rem", sm: "3rem" }}
      flexDirection="row"
      p={3}
      width={1}
      zIndex="alert"
    >
      {gtLg && (
        <>
          <Img
            position="absolute"
            top="0"
            left="0"
            zIndex="-1"
            src={`/assets/banner-stripes/left-stripes.svg`}
            alt="stripes"
          />

          <Img
            position="absolute"
            top="0"
            right="0"
            zIndex="-1"
            src={`/assets/banner-stripes/right-stripes.svg`}
            alt="stripes"
          />
        </>
      )}

      <Box
        display="flex"
        flex="1 1 auto"
        flexDirection={{ _: "columm", md: "row" }}
        textAlign={{ _: "left", md: "center" }}
        alignItems="center"
        justifyContent="center"
      >
        <Text
          verticalAlign="middle"
          color="white"
          fontSize={{ _: "sm", xs: "md" }}
          fontWeight="medium"
          lineHeight={{ _: "1rem", xs: "1.5rem" }}
        >
          {gtSm ? text : mobileText}
        </Text>

        {showButton && (
          <CtaButton appearance="subtle" size="sm" onClick={onClick} ml={3}>
            {buttonText}
          </CtaButton>
        )}
      </Box>

      <DismissButton
        appearance="subtle"
        icon={ICON_SS_CLOSE}
        onClick={onDismiss}
      />
    </Box>
  )
}

export default StickyBanner
