import { createSelector } from "reselect"
import { selectRecordById } from "ducks/api"
import { createAction } from "./utils"

export const types = {
  DOWNLOAD_URL: "soundstripe/video/DOWNLOAD_URL",
}

export const actions = {
  getDownloadUrl: createAction(
    types.DOWNLOAD_URL,
    "downloadableId",
    "version",
    "saleId",
    "useDropbox"
  ),
}

export const selectVideo = (videoId) =>
  createSelector(selectRecordById("video", videoId), (video) => video)
