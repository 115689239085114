// row height
export const HEIGHT = 56

// TAG_TYPES
export const TAG_TYPES = {
  MOOD: "mood_tags",
  GENRE: "genre_tags",
  CHARACTERISTIC: "characteristic_tags",
  INSTRUMENTS: "instrument_tags",
}

// IMAGES
export const VOCAL_IMAGE = `${process.env.BASE_ASSET_URL}/images/vocal.png?&auto=format`
export const INSTRUMENTAL_IMAGE = `${process.env.BASE_ASSET_URL}/images/instrumental.png?&auto=format`
