export const TOTAL_SONGS = "9,000+"
export const TOTAL_SFX = "76,000+"
export const TOTAL_VIDEOS = "108,000+"
export const STEMS_INCLUDED = "Stems Included with all Songs"
export const EXTENSIONS =
  "Full access to extensions for Adobe Premiere Pro, Twitch & Frame.io"
export const EXTENSIONS_LOWERCASE =
  "full access to extensions for Adobe Premiere Pro, Twitch & Frame.io"

export const COUPONS = {
  REFERRAL_COUPON: "REFERRAL_20",
}

export const PAYMENT_SOURCES = {
  APPLE_PAY: "apple_pay",
  GOOGLE_PAY: "google_pay",
}

export const SINGLE_SONG_LIFETIME_LICENSE = "Single Song Lifetime License"

export const ENTITY_TYPES = [
  "YouTuber / Social Media Creator",
  "Filmmaker",
  "Podcaster",
  "Marketing & Advertising Creative",
  "Live Streamer",
  "Wedding Videographer",
  "Church Media Creator",
  "Other",
]

export const ENTITY_SIZES = [
  "Self-employed / Personal",
  "2-10",
  "11-50",
  "51-100",
  "100+",
]

export const ENTITY_INDUSTRIES = [
  "Agency / Professional Services",
  "Broadcast Media",
  "Church / Religious Institution",
  "Education",
  "Entertainment",
  "Film / Commercial Production",
  "Financial",
  "Government",
  "Healthcare",
  "Hospitality",
  "Internet Media",
  "Non-Profit",
  "Retail",
  "Real Estate",
  "Software / Technology",
  "Sports",
  "Other",
]

export const LINKS = {
  FACEBOOK: "https://www.facebook.com/soundstripemusic",
  INSTAGRAM: "https://www.instagram.com/soundstripemusic",
  X: "https://x.com/soundstripeapp",
  YOUTUBE: "https://www.youtube.com/channel/UCKVpD_yFC4WHUhlX5of3YLA",
  ADOBE:
    "https://exchange.adobe.com/creativecloud.details.103010.soundstripe-for-premiere-pro.html",
  JOBS: "https://soundstripe.bamboohr.com/jobs/",
  BLOG: "https://www.soundstripe.com/blog",
  LEGAL: "https://www.soundstripe.com/legal",
  FAQ: "https://www.soundstripe.com/help",
  AFFILIATE: "https://www.soundstripe.com/partners",
  PRODUCT_UPDATES: "https://www.soundstripe.com/product-updates",
}

export const LIFETIME_LICENSE_TOOLTIP =
  "A lifetime license legally allows the use of one particular song with multiple projects, without needing to relicense."

export const RECENTLY_ADDED_PLAYLIST = {
  app_route: "playlists/music/54",
  songs_path: "playlists/54/songs",
}

export const QUALIFIED_USER_VALUES = {
  personal: 1.2,
  clients: 2.46,
  business: 1.88,
}
