import Immutable from "immutable"
import { createRelationships } from "records/Relationship"

const PlaylistCategoryRecord = Immutable.Record(
  {
    id: null,
    type: "playlist_categories",

    // Attributes
    name: "",
    featured: false,
    published: false,
    playlist_ids: [],
    media_type: "",
    playlist_count: "",

    // Relationships
    relationships: {
      playlist_category_playlists: {},
    },
  },
  "PlaylistCategory"
)

const relationshipTypes = {
  playlist_category_playlists: ["playlist_category_playlists"],
}

class PlaylistCategory extends PlaylistCategoryRecord {
  constructor(args) {
    super(
      Object.assign({}, args, {
        relationships: createRelationships(
          args.relationships,
          relationshipTypes
        ),
      })
    )
  }
}

export default PlaylistCategory
