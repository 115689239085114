import { css } from "styled-components"

const linkStyles = css`
  align-items: center;
  color: ${(props) => props.theme.colors.text.primary};
  cursor: pointer;
  display: inline-flex;
  height: auto;
  text-decoration: underline;
  transition: box-shadow ${(props) => props.theme.transitions.quick};

  &.secondary {
    color: ${(props) => props.theme.colors.text.primary};
  }

  &.subtle {
    color: ${(props) => props.theme.colors.text.secondary};
  }

  &:hover {
    text-decoration: none;
  }

  &.disabled {
    cursor: default;
    text-decoration: none;
  }

  &.withIcon,
  &.noUnderline {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &:active {
      text-decoration: underline;
    }
  }

  figure {
    margin: auto;

    figcaption {
      color: ${(props) => props.theme.colors.brand.pinkJoy};
      font-weight: 500;
    }
  }

  svg {
    &:first-child {
      margin-right: 2px;
    }

    &:last-child {
      margin-left: 2px;
    }
  }

  &.blue {
    color: ${(props) => props.theme.colors.blue300};
  }

  &.purple {
    color: ${(props) => props.theme.colors.purple300};
  }

  &.pinkjoy {
    color: ${(props) => props.theme.colors.brand.pinkJoy};
  }

  &.offer {
    color: ${(props) => props.theme.colors.brand.pinkJoy};
    font-weight: ${(props) => props.theme.fontWeights.medium};
  }

  &.marketplace-contact {
    color: ${(props) => props.theme.colors.marketplace.orange};
  }

  &.marketplace-secondary {
    color: ${(props) => props.theme.colors.gray500};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    font-size: 13px;
  }
`

export default linkStyles
