import { createSelector } from "reselect"
import { selectRecordById } from "ducks/api"
import { createAction } from "./utils"

export const types = {
  DOWNLOAD_SOUND_EFFECT: "soundstripe/song/DOWNLOAD_SOUND_EFFECT",
}

export const actions = {
  downloadSoundEffect: createAction(
    types.DOWNLOAD_SOUND_EFFECT,
    "sound_effect"
  ),
}

export const selectSoundEffect = (soundEffectId) =>
  createSelector(
    selectRecordById("sound_effects", soundEffectId),
    (sound_effect) => sound_effect
  )
