import { actions as modalActions } from "ducks/modal"
import { useDispatch } from "react-redux"
import { useEffect } from "react"

const storeJS = require("utils/store.min.js")

const MarketplaceWelcomeModalLauncher = () => {
  const dispatch = useDispatch()

  const hasOldCookie = storeJS.get("has_seen_marketplace_welcome_modal")
  if (hasOldCookie !== undefined) {
    storeJS.remove("has_seen_marketplace_welcome_modal")
  }
  const viewCount = storeJS.get("marketplace_welcome_modal_views") || 0

  useEffect(() => {
    if (viewCount < 2) {
      const timer = setTimeout(() => {
        if (window.location.pathname.includes("/market")) {
          dispatch(modalActions.open("MarketplaceWelcomeModal"))
        }
      }, 20000)

      return () => clearTimeout(timer)
    }
  }, [])

  return null
}

export default MarketplaceWelcomeModalLauncher
