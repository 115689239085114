import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { actions as currentUserActions } from "ducks/currentUser"
import {
  actions as frameioActions,
  selectFrameioAuthorizing,
} from "ducks/frameio"

class FrameioListener extends React.Component {
  componentDidUpdate() {
    if (this.props.frameioAuthorizing) this.startFrameInterval()
  }

  startFrameInterval = () => {
    const timer = setInterval(() => {
      if (window.frame_window?.closed) {
        clearInterval(timer)
        this.props.loadCurrentUser()
        this.props.stopAuthorization()
        this.props.getFrameioAccounts()
      }
    }, 1000)
  }

  render() {
    return this.props.children
  }
}

FrameioListener.propTypes = {
  children: PropTypes.node,
  frameioAuthorizing: PropTypes.bool,
  loadCurrentUser: PropTypes.func,
  stopAuthorization: PropTypes.func,
}

const mapStateToProps = (state) => ({
  frameioAuthorizing: selectFrameioAuthorizing()(state),
})

const mapDispatchToProps = (dispatch) => ({
  loadCurrentUser: () => dispatch(currentUserActions.load()),
  stopAuthorization: () => dispatch(frameioActions.stopAuthorization()),
  getFrameioAccounts: () => dispatch(frameioActions.getAccounts()),
})

export default connect(mapStateToProps, mapDispatchToProps)(FrameioListener)
