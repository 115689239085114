import PropTypes from "prop-types"
import React, { memo } from "react"
import ReactHowler from "react-howler"
import { connect } from "react-redux"
import { compose } from "redux"
import {
  selectSoundEffectsPlayer,
  SoundEffectsPlayerState,
  actions,
} from "ducks/soundEffectsPlayer"
import {
  selectSongAudioPlayerIsPlaying,
  actions as audioPlayerActions,
} from "ducks/audioPlayer"
import {
  selectSongMarketplaceAudioPlayerIsPlaying,
  actions as marketplaceAudioPlayerActions,
} from "ducks/marketplaceAudioPlayer"

class SoundEffectsAudioPlayer extends React.Component {
  componentDidUpdate = (prevProps) => {
    const { seekPos } = this.props.player
    const prevPlayer = prevProps.player
    const prevSeekPos = prevPlayer.seekPos
    if (this.player) {
      if (seekPos !== prevSeekPos) {
        this.player.seek(seekPos)
      }
    }
  }

  handleOnEnd = () => {
    this.clearProgressTracker()
    this.props.onEnd()
  }

  handlePlay = () => {
    if (this.props.songIsPlaying) {
      this.props.pauseSongAudioPlayer()
    }
    if (this.props.marketplaceSongIsPlaying) {
      this.props.pauseSongMarketplaceAudioPlayer()
    }

    this.setProgressTracker()
  }

  handlePause = () => {
    this.clearProgressTracker()
  }

  setProgressTracker = () => {
    if (!this.progressTracker) {
      this.progressTracker = setInterval(() => {
        this.trackProgress()
      }, 200)
    }
  }

  trackProgress = () => {
    const howler = this.player.howler
    if (howler && howler.playing()) {
      this.props.progress(this.player.seek())
    }
  }

  clearProgressTracker = () => {
    if (this.progressTracker) {
      clearInterval(this.progressTracker)
      this.progressTracker = null
    }
  }

  render() {
    const { player } = this.props
    if (!player.src) return null
    return (
      <ReactHowler
        ref={(instance) => (this.player = instance)}
        src={player.src}
        playing={player.playing}
        html5
        onEnd={this.handleOnEnd}
        onPlay={this.handlePlay}
        onPause={this.handlePause}
        volume={player.volume}
        mute={false}
      />
    )
  }
}

SoundEffectsAudioPlayer.propTypes = {
  progress: PropTypes.func.isRequired,
  onEnd: PropTypes.func.isRequired,
  pauseSongAudioPlayer: PropTypes.func,
  player: PropTypes.instanceOf(SoundEffectsPlayerState).isRequired,
  songIsPlaying: PropTypes.bool,
}

const mapStateToProps = (state) => {
  return {
    player: selectSoundEffectsPlayer()(state),
    songIsPlaying: selectSongAudioPlayerIsPlaying()(state),
    marketplaceSongIsPlaying: selectSongMarketplaceAudioPlayerIsPlaying()(
      state
    ),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onEnd: () => dispatch(actions.ended()),
    progress: (pos) => dispatch(actions.progress(pos)),
    pauseSongAudioPlayer: () => dispatch(audioPlayerActions.togglePause()),
    pauseSongMarketplaceAudioPlayer: () =>
      dispatch(marketplaceAudioPlayerActions.togglePause()),
  }
}

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), memo)

export default enhance(SoundEffectsAudioPlayer)
