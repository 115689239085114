import { fromJS, List } from "immutable"
import { createSelector } from "reselect"
import { createRecordsFromResponse } from "records/util"
import { createAction } from "./utils"

export const types = {
  CREATE: "soundstripe/playlist/CREATE",
  LOAD: "soundstripe/playlist/LOAD",
  LOAD_SUCCESS: "soundstripe/playlist/LOAD_SUCCESS",
  LOAD_FAILED: "soundstripe/playlist/LOAD_FAILED",
  UPDATE_SONG_POSITION: "soundstripe/playlist/UPDATE_SONG_POSITION",
  UPDATE_PUBLISHED: "soundstripe/playlist/UPDATE_PUBLISHED",
}

export const actions = {
  create: createAction(
    types.CREATE,
    "form",
    "playlist",
    "playlistItem",
    "mobileSongObject",
    "onSuccess"
  ),
  loadPlaylists: createAction(types.LOAD),
  loadSuccess: createAction(types.LOAD_SUCCESS, "response"),
  loadFailed: createAction(types.LOAD_FAILED),
  updateSongPosition: createAction(
    types.UPDATE_SONG_POSITION,
    "playlist",
    "songId",
    "index"
  ),
  updatePublished: createAction(
    types.UPDATE_PUBLISHED,
    "playlistType",
    "playlistId",
    "published"
  ),
}

const emptyList = new List()

const initialState = fromJS({
  loading: false,
  error: null,
  playlists: emptyList,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD: {
      return state.set("loading", true)
    }
    case types.LOAD_SUCCESS: {
      const playlistResponse = {
        data: action.response,
      }
      const immRecords = createRecordsFromResponse(playlistResponse)
      return state.set("playlists", immRecords).set("loading", false)
    }
    case types.LOAD_FAILED: {
      return state.set("loading", false)
    }
    default: {
      return state
    }
  }
}

const playlistsState = (state) => state.getIn(["ducks", "playlist"])

export const selectLoading = () =>
  createSelector(playlistsState, (playlists) => playlists.get("loading"))

export const selectPlaylists = () =>
  createSelector(playlistsState, (playlists) => playlists.get("playlists"))
