import Immutable from "immutable"
import Avatar from "./Avatar"

export const TEAM_MEMBER_ROLES = ["owner", "team_member"]

const AccountInvitationRecord = Immutable.Record(
  {
    id: null,
    type: "account_invitation",
    // Attributes
    name: "Invited Member",
    email: "",
    role: "",
  },
  "AccountInvitation"
)

class AccountInvitation extends AccountInvitationRecord {
  constructor(args) {
    super(
      Object.assign({}, args, {
        avatar: new Avatar(args.avatar),
      })
    )
  }

  invite() {
    return true
  }

  defaultAvatar() {
    return "https://s3-us-west-2.amazonaws.com/soundstripe-production/assets/images/fallback/default_image_thumb.png"
  }

  roleDisplay() {
    return this.role === "owner" ? "Admin" : "Team Member"
  }
}

export default AccountInvitation
