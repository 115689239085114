import { getUnixTime } from "utils/dateUtils"
import { Userpilot } from "userpilot"
import { isProduction } from "utils/environment"

export const initAskNicely = (user) => {
  const unixTimeString = getUnixTime(user.created_at)
  const askNicelyName = user.name()
  const askNicelyEmail = user.email
  const askNicelyEmailHash = user.asknicely_email_hash
  const askNicelyCreatedAt = parseInt(unixTimeString, 10)

  window.asknicelySettings = {
    domain_id: "soundstripe.asknice.ly",
    domain_key: "soundstripe",
    name: askNicelyName,
    email: askNicelyEmail,
    email_hash: askNicelyEmailHash,
    created: askNicelyCreatedAt,
    mode: "docked",
  }

  const askNicelyId = "asknicely-async-script"
  if (!document.getElementById(askNicelyId)) {
    const script = document.createElement("script")
    script.id = askNicelyId
    script.async = true
    script.type = "text/javascript"
    script.src = "https://live.asknice.ly/widgetv2.js"
    document.body.appendChild(script)
  }
}

export const initProfitwellRetain = (user) => {
  const { profitwell } = window
  if (!profitwell) return
  if (user) {
    profitwell("start", { user_email: user.email })
  }
}

export const initUserPilot = (user) => {
  if (window && window.bugbug) return
  const userPilotApiKey = process.env.USERPILOT_API_KEY
  const isStaging = process.env.BUILD_ENV === "staging"
  if (!((isStaging || isProduction()) && userPilotApiKey && user)) return

  Userpilot.initialize(userPilotApiKey)

  Userpilot.identify(user.id, {
    name: user.name(),
    email: user.email,
    created_at: user.created_at,
    plan_id: user.planId(),
    account_status: user.subscription?.status,
  })
}
