import PropTypes from "prop-types"
import styled from "styled-components"
import { layout, space } from "styled-system"
import Box from "components/Box"

const Container = styled(Box)`
  ${layout}
  ${space}
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  &.collection {
    max-width: ${({ theme }) => theme.sizes.container.xl};
    padding: ${({ theme }) => theme.space[6]} ${({ theme }) => theme.space[3]};

    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding: ${({ theme }) => theme.space[6]} ${({ theme }) => theme.space[7]};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      padding: ${({ theme }) => theme.space[7]} ${({ theme }) => theme.space[8]}
        ${({ theme }) => theme.space[8]};
    }
  }

  &.header {
    height: 100%;
    max-width: 100%;
    padding: 0 ${({ theme }) => theme.space[4]};

    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding: 0 ${({ theme }) => theme.space[5]};
    }
  }
`

Container.propTypes = {
  type: PropTypes.string,
}

Container.defaultProps = {
  pt: { _: 5, xs: 6, lg: 8 },
  px: { _: 5, md: 6, lg: 8 },
  pb: { _: 5, xs: 6, lg: 8 },
  maxWidth: "container.xl2",
}

export default Container
