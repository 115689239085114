import PropTypes from "prop-types"
import styled from "styled-components"
import {
  system,
  color,
  display,
  space,
  typography,
  variant,
} from "styled-system"

const textVariants = variant({
  prop: "size",
  variants: {
    xl: {
      fontSize: "xl",
      lineHeight: "text.xl",
    },
    lg: {
      fontSize: "lg",
      lineHeight: "text.lg",
    },
    md: {
      fontSize: "md",
      lineHeight: "text.md",
    },
    sm: {
      fontSize: "sm",
      lineHeight: "text.sm",
    },
    xs: {
      fontSize: "xs",
      lineHeight: "text.xs",
    },
  },
})

const Text = styled.p`
  ${textVariants}
  display: ${(props) => (props.inline ? "inline-block" : "block")};
  text-decoration: ${(props) => props.textDecoration};
  margin: 0;
  ${color}
  ${space}
  ${typography}
  ${display}
  ${system({
    prop: "wordBreak",
    cssProperty: "wordBreak",
  })}
`

Text.propTypes = {
  size: PropTypes.oneOfType([
    PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
    PropTypes.object,
  ]),
}

Text.defaultProps = {
  color: "text.primary",
  size: "md",
}

export default Text
