import styled from "styled-components"
import { variant } from "styled-system"
import { focusStyles } from "containers/App/style-utils"
import Box from "components/Box"

export const CheckboxContainer = styled(Box)`
  align-items: center;
  color: ${(props) => props.theme.colors.text.primary};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  display: inline-flex;
  position: relative;
  transition: all ${(props) => props.theme.transitions.quick};

  &.noWrap {
    overflow: hidden;
  }
`

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
export const HiddenCheckbox = styled.input`
  border: 0px none;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0px;
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
`

const checkboxVariants = variant({
  prop: "size",
  variants: {
    sm: {
      height: "16px",
      width: "16px",
    },
    md: {
      height: "20px",
      width: "20px",
    },
    lg: {
      height: "24px",
      width: "24px",
    },
  },
})

export const StyledCheckbox = styled.span`
  ${checkboxVariants}
  align-items: center;
  border-color: ${(props) => props.theme.colors.button.default.border.default};
  border-radius: ${(props) => props.theme.radii.default};
  border-style: solid;
  border-width: 1px;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  padding: 1px;
  transition: all ${(props) => props.theme.transitions.quick};

  > svg {
    width: 100%;
  }

  &[data-active="true"] {
    background: ${(props) => props.theme.colors.background.inverted};
    border-color: ${(props) => props.theme.colors.button.default.border.hover};
    color: ${(props) => props.theme.colors.text.inverted};
  }

  &[data-disabled="true"] {
    border-color: ${(props) => props.theme.input.borderDisabled};
    color: ${(props) => props.theme.colors.text.disabled};
  }

  &[data-error="true"] {
    border-color: ${(props) => props.theme.colors.border.alert};
  }

  /* Focus state */
  ${HiddenCheckbox}:focus + & {
    ${focusStyles}
  }
`

const checkboxLabelVariants = variant({
  prop: "labelSize",
  variants: {
    sm: {
      fontSize: "sm",
      lineHeight: "text.sm",
    },
    md: {
      fontSize: "md",
      lineHeight: "text.md",
    },
    lg: {
      fontSize: "md",
      lineHeight: "text.md",
    },
  },
})

export const CheckboxLabel = styled(Box)`
  ${checkboxLabelVariants}
  align-items: center;
  color: ${(props) => props.theme.colors.text.primary};
  display: inline-flex;
  margin-left: ${(props) => props.theme.space[2]};

  &.noWrap {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &[data-disabled="true"] {
    color: ${(props) => props.theme.colors.text.disabled};
  }
`
