import styled from "styled-components"
import { color, space, typography, layout } from "styled-system"

const H2 = styled.h2`
  ${color}
  ${space}
  ${typography}
  ${layout}
`

H2.defaultProps = {
  color: "text.primary",
  fontSize: { _: "lg", sm: "xl" },
  fontWeight: "500",
  lineHeight: { _: "1.75rem", sm: "normal" },
}

export default H2
