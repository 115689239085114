import { createAction } from "./utils"

export const types = {
  ADD_TO_PLAYLIST: "soundstripe/playlistable/ADD_TO_PLAYLIST",
  REMOVE_FROM_PLAYLIST: "soundstripe/playlistable/REMOVE_FROM_PLAYLIST",
  UPDATE_PLAYLISTABLE_POSITION:
    "soundstripe/playlistable/UPDATE_PLAYLISTABLE_POSITION",
}

export const actions = {
  addToPlaylist: createAction(
    types.ADD_TO_PLAYLIST,
    "playlist",
    "playlistable",
    "mobileSongObject",
    "onSuccess"
  ),
  updatePlaylistablePosition: createAction(
    types.UPDATE_PLAYLISTABLE_POSITION,
    "playlist",
    "playlistableId",
    "index"
  ),
  removeFromPlaylist: createAction(
    types.REMOVE_FROM_PLAYLIST,
    "playlist",
    "playlistable"
  ),
}
