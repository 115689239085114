import { fromJS } from "immutable"
import { createSelector } from "reselect"
import { createAction } from "./utils"
import { getConvertExperiment } from "utils/convertTesting"

export const types = {
  SET_CURRENT_DATA: "soundstripe/convert/SET_CURRENT_DATA",
  SET_HISTORICAL_DATA: "soundstripe/convert/SET_HISTORICAL_DATA",
}

export const actions = {
  setCurrentData: createAction(types.SET_CURRENT_DATA, "data"),
  setHistoricalData: createAction(types.SET_HISTORICAL_DATA, "data"),
}

const initialState = fromJS({
  currentData: {},
  historicalData: {},
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CURRENT_DATA:
      const currentData = state.get("currentData")
      return state.set("currentData", currentData.merge(fromJS(action.data)))
    case types.SET_HISTORICAL_DATA:
      const historicalData = state.get("historicalData")
      return state.set(
        "historicalData",
        historicalData.merge(fromJS(action.data))
      )
    default:
      return state
  }
}

const selectConvertState = (state) => state.getIn(["ducks", "convert"])

export const selectCurrentData = () =>
  createSelector(selectConvertState, (convertState) =>
    convertState.get("currentData")
  )

export const selectHistoricalData = () =>
  createSelector(selectConvertState, (convertState) =>
    convertState.get("historicalData")
  )

export const selectCurrentVariant = (experience) =>
  createSelector(selectConvertState, (convertState) => {
    const currentData = convertState.get("currentData").toJS()
    const historicalData = convertState.get("historicalData").toJS()
    const { variantIds, experimentId } = getConvertExperiment(experience)

    if (currentData[experimentId]) {
      return variantIds.indexOf(currentData[experimentId]?.variation?.id)
    } else if (historicalData[experimentId]) {
      return variantIds.indexOf(historicalData[experimentId]?.variationId)
    } else {
      return 0
    }
  })
