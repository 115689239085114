import styled from "styled-components"

const ExplicitFlag = styled.div`
  width: 16px;
  flex-basis: 16px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    display: none;
  }
`

export default ExplicitFlag
