import React, { useContext, useState } from "react"
import useLocalStorage from "hooks/useLocalStorage"
import { DEBUG_FLAGS, debugFlag } from "utils/debugFlag"

const DebugFlagContext = React.createContext({})

export const useDebugFlag = () => useContext(DebugFlagContext)

export const DebugFlagContextProvider = ({ children }) => {
  const { value: debugFlags = {}, set: setDebugFlags } = useLocalStorage(
    "debugFlags"
  )
  const [state, setState] = useState(
    Object.keys(DEBUG_FLAGS).reduce((prev, curr) => {
      prev[curr] = debugFlag(curr)
      return prev
    }, {})
  )

  const setDebugFlag = (debugFlag, value) => {
    setState((prev) => ({ ...prev, [debugFlag]: value }))
    setDebugFlags({ ...state, [debugFlag]: value })
  }

  const defaultContext = {
    DEBUG_FLAGS,
    debugFlags,
    setDebugFlag,
  }

  return (
    <DebugFlagContext.Provider value={defaultContext}>
      {children}
    </DebugFlagContext.Provider>
  )
}
