import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const StyledCircleImage = styled.div`
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "100%"};
  border-radius: ${(props) => props.theme.radii.circle};
  background: url("${(props) => props.src}") no-repeat top center/cover;
  display: inline-block;
`

const CircleImage = (props) => <StyledCircleImage {...props} />

CircleImage.propTypes = {
  src: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
}

export default CircleImage
