import PropTypes from "prop-types"
import React from "react"
import styled, { withTheme } from "styled-components"
import { withRouter } from "react-router"

import { User as UserRecord } from "records"
import Avatar from "components/Avatar"
import Box from "components/Box"
import Dropdown from "./Dropdown"

const DEFAULT_AVATAR_IMAGE =
  "https://s3-us-west-2.amazonaws.com/soundstripe-production/assets/images/fallback/default_image_thumb.png"

const DropDownWrapper = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${(props) => props.theme.space[2]};
  text-align: left;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 0 ${(props) => props.theme.space[3]};
  }
`

class UserInfoSection extends React.Component {
  constructor() {
    super()
    this.state = {
      showMenu: false,
    }

    this.showMenu = this.showMenu.bind(this)
    this.onOutsideClick = this.onOutsideClick.bind(this)
  }

  onOutsideClick(event) {
    if (!this.dropdownMenu.contains(event.target)) {
      this.closeMenu()
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const locationChange = this.props.location !== nextProps.location

    if (locationChange && this.state.showMenu) {
      this.closeMenu()
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onOutsideClick)
  }

  showMenu(event) {
    event.preventDefault()

    this.setState({ showMenu: true }, () => {
      document.addEventListener("click", this.onOutsideClick)
    })
  }

  closeMenu = () => {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener("click", this.onOutsideClick)
    })
  }

  render() {
    const { currentUser } = this.props
    const image = currentUser.avatar.thumbnailUrl() || DEFAULT_AVATAR_IMAGE

    return (
      <Box
        alignItems="center"
        display="flex"
        height="100%"
        justifyContent="center"
        data-cy="sign_in-success"
        position="relative"
      >
        <DropDownWrapper
          aria-label="Account menu"
          data-cy="user-nav-dropdown-btn"
          onClick={this.showMenu}
        >
          <Avatar
            avatarUrl={image}
            id={currentUser.id}
            initials={currentUser.initials()}
            size="lg"
          />
        </DropDownWrapper>
        {this.state.showMenu && (
          <div
            ref={(element) => {
              this.dropdownMenu = element
            }}
          >
            <Dropdown />
          </div>
        )}
      </Box>
    )
  }
}

UserInfoSection.propTypes = {
  location: PropTypes.object.isRequired,
  currentUser: PropTypes.instanceOf(UserRecord).isRequired,
}

UserInfoSection.contextTypes = {
  router: PropTypes.object,
}

export default withRouter(withTheme(UserInfoSection))
