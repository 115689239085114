import useFlag from "hooks/useFlag"
import { useDebugFlag } from "context/DebugFlagContext"

const useHolidaySale = () => {
  const { debugFlags } = useDebugFlag()
  const { enabled: bfcmEnabled } = useFlag("black_friday")

  return {
    active: bfcmEnabled || debugFlags["HOLIDAY_SALE"],
  }
}

export default useHolidaySale
