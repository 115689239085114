import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import { selectCurrentUserLaunchAmobeeSuccess } from "ducks/currentUser"
import throttle from "lodash/throttle"
import { isProduction } from "utils/environment"

const Img = styled.img`
  height: 0px;
  width: 0px;
  display: none;
`

/**
 * The AmobeeBeacon component needs to always be rendered to the page for Amobee tracking.
 */
export const AmobeeBeacon = () => {
  if (!isProduction()) {
    return null
  }
  return (
    <Img
      alt="amobee"
      src="https://r.turn.com/r/beacon?b2=giPSoJFgrVGAyas12L2cMGquMJCdqtv3rwrkJM5cLWet89WOlSUIHiwT_UTlphhOeG6M2WT2Z9lDUzYG2lfBzw&cid=&bprice="
    />
  )
}

/**
 * The AmobeeSuccess component only needs to be rendered to the page after a user completes the sign up.
 * This happens on the main app component and renders and closes it self accordingly.
 */

export class AmobeeSuccessBeacon extends React.Component {
  static propTypes = {
    launchAmobeeSuccess: PropTypes.bool,
  }

  constructor(props) {
    super(props)
    this.state = {
      success: false,
    }
    this.successTimeoutThrottle = throttle(this.successTimeout, 1000)
  }

  componentDidUpdate() {
    if (this.props.launchAmobeeSuccess && !this.state.success) {
      this.successTimeoutThrottle()
    }
  }

  success = () => {
    this.setState({
      success: true,
    })
  }

  successTimeout = () => {
    setTimeout(this.success, 500)
  }

  render() {
    const { launchAmobeeSuccess } = this.props
    if (!isProduction) {
      return null
    }
    if (this.state.close) {
      return null
    }

    if (launchAmobeeSuccess) {
      return (
        <Img
          alt="amobee-success"
          src="https://r.turn.com/r/beacon?b2=1SYJtrUQDJr_odI8_dFzqy1sypGx_nH3maMoA9vC0Nyt89WOlSUIHiwT_UTlphhOYeBu7Z8HeUnmUpqMckGH0A&cid=&bprice="
        />
      )
    }
    return null
  }
}

const mapStateToProps = (state) => ({
  launchAmobeeSuccess: selectCurrentUserLaunchAmobeeSuccess()(state),
})

export const AmobeeSuccess = connect(mapStateToProps, null)(AmobeeSuccessBeacon)
