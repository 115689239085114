import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import styled, { withTheme } from "styled-components"
import {
  MarketplaceSong as MarketplaceSongRecord,
  User as UserRecord,
} from "records"
import withCurrentUser from "hocs/withCurrentUser"
import Icon from "components/Icon"
import { useSelector } from "react-redux"
import {
  ICON_SS_PLAY,
  ICON_SS_PAUSE,
  ICON_SS_HEART_FULL,
  ICON_SS_HEART_EMPTY,
  ICON_SS_ELLIPSIS,
} from "components/Icon/constants"
import { actions as modalActions } from "ducks/modal"
import { selectSearchSidebarOpen, selectSfxSearchSidebarOpen } from "ducks/ui"

const MobileViewWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.background.secondary};
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 4rem;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: ${(props) => props.theme.zIndices.fixed};
`

const InfoSection = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
  width: 100%;
`

const ClickWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1 0 0;
  min-width: 0;
  padding: ${(props) => props.theme.space[2]} ${(props) => props.theme.space[3]};
`

const SongImgWrapper = styled.div`
  border-radius: ${(props) => props.theme.radii.default};
  height: 40px;
  margin-right: ${(props) => props.theme.space[3]};
  overflow: hidden;
  width: 40px;
`

const SongImg = styled.img`
  height: 100%;
  object-fit: cover;
  width: 100%;
`

const TitleSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: ${(props) => props.theme.space[3]};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const TitleItem = styled.div`
  color: ${(props) => props.theme.colors.text.primary};
  font-size: ${(props) => props.theme.fontSizes.sm};
  line-height: 1.25rem;
  font-weight: ${(props) => props.theme.fontWeights.medium};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;

  &.artist {
    color: ${(props) => props.theme.colors.text.secondary};
    font-weight: ${(props) => props.theme.fontWeights.regular};
  }
`

const ControlsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 0;
  height: 100%;
  justify-content: flex-end;
  margin-right: ${(props) => props.theme.space[2]};
`

const IconWrapper = styled.div`
  cursor: pointer;
  padding: ${(props) => props.theme.space[3]};
`

const MobileView = (props) => {
  const {
    currentUser,
    handleToggleFavorite,
    handleTogglePause,
    playing,
    openMarketplaceMobileSongMenuModal,
    song,
    theme,
  } = props

  const searchSidebarIsOpen = useSelector(selectSearchSidebarOpen())
  const sfxSidebarIsOpen = useSelector(selectSfxSearchSidebarOpen())

  if (searchSidebarIsOpen || sfxSidebarIsOpen) {
    return null
  }

  return (
    <MobileViewWrapper>
      <InfoSection>
        <ClickWrapper onClick={openMarketplaceMobileSongMenuModal}>
          <SongImgWrapper>
            <SongImg src={song.pic.url} />
          </SongImgWrapper>
          <TitleSection>
            <TitleItem>{song.title}</TitleItem>
            <TitleItem className="artist">{song.primaryArtistName()}</TitleItem>
          </TitleSection>
          <Icon class="pointer" icon={ICON_SS_ELLIPSIS} size={24} />
        </ClickWrapper>
        <ControlsWrapper>
          <IconWrapper onClick={handleToggleFavorite}>
            <Icon
              icon={
                currentUser.favoritedMarketplaceSong(song.id)
                  ? ICON_SS_HEART_FULL
                  : ICON_SS_HEART_EMPTY
              }
              color={
                currentUser.favoritedMarketplaceSong(song.id)
                  ? theme.colors.text.accent
                  : undefined
              }
              size={24}
            />
          </IconWrapper>
          <IconWrapper onClick={handleTogglePause}>
            <Icon icon={playing ? ICON_SS_PAUSE : ICON_SS_PLAY} size={24} />
          </IconWrapper>
        </ControlsWrapper>
      </InfoSection>
    </MobileViewWrapper>
  )
}

const mapDispatchToProps = (dispatch, props) => ({
  openMarketplaceMobileSongMenuModal: () =>
    dispatch(
      modalActions.open("MarketplaceMobileSongMenuModal", {
        song: props.song,
        index: props.index,
        storeKey: props.storeKey,
      })
    ),
})

export default compose(
  connect(null, mapDispatchToProps),
  withCurrentUser,
  withTheme
)(MobileView)

MobileView.propTypes = {
  currentUser: PropTypes.instanceOf(UserRecord),
  handleTogglePause: PropTypes.func.isRequired,
  handleToggleFavorite: PropTypes.func.isRequired,
  playing: PropTypes.bool.isRequired,
  openMarketplaceMobileSongMenuModal: PropTypes.func.isRequired,
  song: PropTypes.instanceOf(MarketplaceSongRecord).isRequired,
  theme: PropTypes.object,
}
