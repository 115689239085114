import { Record } from "immutable"
import { createSelector } from "reselect"
import { createAction } from "./utils"

const storeJS = require("utils/store.min.js")

export const types = {
  CHANGE_VOLUME: "soundstripe/soundEffectsPlayer/CHANGE_VOLUME",
  ENDED: "soundstripe/soundEffectsPlayer/ENDED",
  PLAY: "soundstripe/soundEffectsPlayer/PLAY",
  NEXT: "soundstripe/soundEffectsPlayer/NEXT",
  PREV: "soundstripe/soundEffectsPlayer/PREV",
  PROGRESS: "soundstripe/soundEffectsPlayer/PROGRESS",
  SEEK: "soundstripe/soundEffectsPlayer/SEEK",
  TOGGLE_PAUSE: "soundstripe/soundEffectsPlayer/TOGGLE_PAUSE",
}

export const actions = {
  changeVolume: createAction(types.CHANGE_VOLUME, "volume"),
  ended: createAction(types.ENDED),
  play: createAction(types.PLAY, "soundEffect", "projectItemId"),
  next: createAction(types.NEXT),
  prev: createAction(types.PREV),
  progress: createAction(types.PROGRESS, "pos"),
  seek: createAction(types.SEEK, "seekPos"),
  togglePause: createAction(types.TOGGLE_PAUSE),
}

export const SoundEffectsPlayerState = Record(
  {
    objectId: null,
    src: null,
    playing: false,
    pos: 0,
    seekPos: null,
    volume: storeJS.get("audioPlayerVolume") || 1,
    projectItemId: null,
  },
  "SoundEffectsPlayerState"
)

const initialState = new SoundEffectsPlayerState()

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_VOLUME:
      return state.set("volume", action.volume)
    case types.ENDED:
      return state.merge({ playing: false, src: null, pos: 0 })
    case types.PLAY:
      return state.merge({
        objectId: action.soundEffect.objectID,
        src: action.soundEffect.file.playback_url,
        playing: true,
        pos: 0,
        projectItemId: action.projectItemId,
      })
    case types.PROGRESS:
      return state.set("pos", action.pos)
    case types.SEEK:
      return state.set("seekPos", action.seekPos)
    case types.TOGGLE_PAUSE:
      return state.set("playing", !state.get("playing"))

    default:
      return state
  }
}

const soundEffectsPlayerState = (state) =>
  state.getIn(["ducks", "soundEffectsPlayer"])

const selectPlayerSrc = (state) =>
  state.getIn(["ducks", "soundEffectsPlayer", "src"])

const selectPlayerPlaying = (state) =>
  state.getIn(["ducks", "soundEffectsPlayer", "playing"])

const selectPlayerPos = (state) =>
  state.getIn(["ducks", "soundEffectsPlayer", "pos"])

const selectPlayerProjectItemId = (state) =>
  state.getIn(["ducks", "soundEffectsPlayer", "projectItemId"])

export const selectSoundEffectsPlayer = () =>
  createSelector(soundEffectsPlayerState, (playerState) => playerState)

export const selectSoundEffectsPlayerSrc = () =>
  createSelector(selectPlayerSrc, (src) => src)

export const selectSoundEffectsPlayerPlaying = () =>
  createSelector(selectPlayerPlaying, (playing) => playing)

export const selectSoundEffectsPlayerPos = () =>
  createSelector(selectPlayerPos, (pos) => pos)

export const selectSoundEffectsPlayerProjectItemId = () =>
  createSelector(selectPlayerProjectItemId, (projectItemId) => projectItemId)
