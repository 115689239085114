import { loggedIn } from "utils/authentication"
import { actions as modalActions } from "ducks/modal"
import { useSelector, useDispatch } from "react-redux"
import { selectUrlHistory } from "ducks/urlHistory"

const SignUpModalLauncher = () => {
  const dispatch = useDispatch()
  const urlHistory = useSelector(selectUrlHistory())
  const signUpParam = new URLSearchParams(window.location.search).get("signup")

  if (loggedIn() || urlHistory.length > 0) return null

  if (signUpParam === "true") {
    dispatch(
      modalActions.open("SignUpModal", {
        action: "signUpLink",
        fromSongsPage: false,
      })
    )
  }
  return null
}

export default SignUpModalLauncher
