import { Record } from "immutable"

const PermissionsRecord = Record(
  {
    adobe_extension: false,
    access_billing: false,
    access_referral_program: false,
    bulk_download: false,
    content_id_clearance: false,
    frameio_extension: false,
    license_songs: false,
    license_sound_effects: false,
    license_videos: false,
    manage_team: false,
    request_custom_playlists: false,
    song_stems: false,
  },
  "Permissions"
)

export default PermissionsRecord
