import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { Link as ReactRouterLink } from "react-router"
import Box from "components/Box"
import { loggedIn } from "utils/authentication"
import SignUpBtn from "./SignUpBtn"
import linkStyles from "./linkStyles"

const SignIn = styled(ReactRouterLink)`
  ${linkStyles};
`

const AuthSection = ({ signUpMode, singleSongCheckoutMode }) => {
  return (
    <Box
      alignItems="center"
      display="flex"
      height="100%"
      textTransform="uppercase"
    >
      {!loggedIn() && !singleSongCheckoutMode && (
        <SignIn to="/sign_in" data-cy="nav-sign-in-link">
          Sign In
        </SignIn>
      )}
      {(!loggedIn() || (!signUpMode && !singleSongCheckoutMode)) && (
        <SignUpBtn>Sign Up</SignUpBtn>
      )}
    </Box>
  )
}

AuthSection.propTypes = {
  signUpMode: PropTypes.bool,
  singleSongCheckoutMode: PropTypes.bool,
}

export default AuthSection
