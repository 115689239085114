import PropTypes from "prop-types"
import React from "react"
import Toggle from "react-toggle"
import styled from "styled-components"
import { connect } from "react-redux"
import Icon from "components/Icon"
import { ICON_THEME_DARK, ICON_THEME_LIGHT } from "components/Icon/constants"

import { actions as uiActions, selectThemeMode } from "ducks/ui"

const Wrapper = styled.div`
  pointer-events: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const StyledToggle = styled(Toggle)`
  .react-toggle-track {
    height: 25px;
    background-color: ${(props) => props.theme.colors.gray600};
  }

  .react-toggle-track-check {
    width: 13px;
    height: 13px;
  }

  .react-toggle-track-x {
    width: 12px;
    height: 12px;
  }

  .react-toggle-thumb {
    height: 19px;
    width: 19px;
    top: 3px;
    border: none;
  }

  .dark-icon {
    transition: opacity ${(props) => props.theme.transitions.quick};
    color: ${(props) => props.theme.colors.gray400};
  }

  &:hover:not(.react-toggle--disabled) {
    .react-toggle-track {
      background-color: ${(props) => props.theme.colors.gray800};
    }

    .dark-icon {
      transition: opacity ${(props) => props.theme.transitions.quick};
      color: #e3e7ed;
    }
  }

  &.react-toggle--checked {
    .react-toggle-track {
      background: ${(props) => props.theme.colors.gray500};
    }

    .light-icon {
      transition: opacity ${(props) => props.theme.transitions.quick};
      color: ${(props) => props.theme.colors.gray000};
    }

    &:hover:not(.react-toggle--disabled) {
      .react-toggle-track {
        background: ${(props) => props.theme.colors.gray400};
      }

      .react-toggle-thumb {
        right: 3px;
      }

      .light-icon {
        transition: opacity ${(props) => props.theme.transitions.quick};
        color: ${(props) => props.theme.colors.yellow100};
      }
    }
  }

  &:not(.react-toggle--checked) {
    .react-toggle-thumb {
      left: 3px;
    }
  }

  &.react-toggle--focus {
    .react-toggle-thumb {
      box-shadow: none;
      -webkit-box-shadow: none;
    }
  }

  &:active:not(.react-toggle--disabled) {
    .react-toggle-thumb {
      box-shadow: none;
      -webkit-box-shadow: none;
    }
  }
`

const ThemeModeToggle = (props) => (
  <Wrapper>
    <StyledToggle
      checked={props.themeMode === "dark"}
      icons={{
        checked: (
          <Icon size="13" icon={ICON_THEME_LIGHT} className="light-icon" />
        ),
        unchecked: (
          <Icon size="12" icon={ICON_THEME_DARK} className="dark-icon" />
        ),
      }}
      onChange={props.toggleThemeMode}
    />
  </Wrapper>
)

const mapDispatchToProps = (dispatch) => ({
  toggleThemeMode: () => {
    dispatch(uiActions.toggleThemeMode())
  },
})

const mapStateToProps = (state) => ({
  themeMode: selectThemeMode()(state),
})

ThemeModeToggle.propTypes = {
  themeMode: PropTypes.string.isRequired,
  toggleThemeMode: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(ThemeModeToggle)
