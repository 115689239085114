import { Record, fromJS, OrderedMap, List } from "immutable"
import { createSelector } from "reselect"
import { selectRecordsByType } from "ducks/api"
import get from "lodash/get"
import { createAction } from "./utils"
import shuffle from "lodash/shuffle"

export const types = {
  CREATE: "soundstripe/infiniteList/CREATE",
  CREATE_FAILED: "soundstripe/infiniteList/CREATE_FAILED",
  LOAD_MORE: "soundstripe/infiniteList/LOAD_MORE",
  CANCEL_LOAD_MORE: "soundstripe/infiniteList/CANCEL_LOAD_MORE",
  RESET: "soundstripe/infiniteList/RESET",
  STORE: "soundstripe/infiniteList/STORE",
  UPDATE: "soundstripe/infiniteList/UPDATE",
}

export const actions = {
  create: createAction(types.CREATE, "storeKey", "requestPath", "limit"),
  createFailed: createAction(types.CREATE_FAILED, "storeKey"),
  loadMore: createAction(types.LOAD_MORE, "storeKey", "nextLink"),
  cancelLoadMore: createAction(types.CANCEL_LOAD_MORE),
  reset: createAction(types.RESET, "storeKey"),
  store: createAction(types.STORE, "storeKey", "data", "links"),
  update: createAction(types.UPDATE, "storeKey", "attribute", "payload"),
}

export const InfiniteListRecord = Record(
  {
    storeKey: null,
    placeholderCount: 0,
    data: new List(),
    nextLink: null,
    totalCount: 0,
    loading: true,
    shuffledData: new List(),
  },
  "InfiniteList"
)

const initialState = fromJS({})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE:
      return state.set(
        action.storeKey,
        new InfiniteListRecord({
          storeKey: action.storeKey,
          placeholderCount: action.limit,
        })
      )
    case types.CREATE_FAILED:
      return state.setIn([action.storeKey, "loading"], false)
    case types.LOAD_MORE:
      return state.setIn([action.storeKey, "loading"], true)
    case types.RESET:
      return state.set(action.storeKey, new InfiniteListRecord())
    case types.STORE:
      let currentList = state.get(action.storeKey)
      if (!currentList) {
        currentList = new InfiniteListRecord({
          storeKey: action.storeKey,
        })
        state.set(action.storeKey, currentList)
      }
      const newList = currentList.merge({
        totalCount: get(action, "links.meta.total_count", action.data.length),
        data: currentList.data.concat(action.data),
        nextLink: get(action, "links.next", null),
        loading: false,
        shuffledData: currentList.shuffledData.concat(
          shuffle([...action.data])
        ),
      })
      return state.set(action.storeKey, newList)
    case types.UPDATE:
      return state.setIn([action.storeKey, action.attribute], action.payload)
    default:
      return state
  }
}

export const selectInfiniteListState = (state) =>
  state.getIn(["ducks", "infiniteList"])

export const selectInfiniteListByStoreKey = (storeKey) =>
  createSelector(selectInfiniteListState, (infiniteListState) => {
    const list = infiniteListState.get(storeKey)
    return list && list.data ? list : new InfiniteListRecord()
  })

export const selectRecordsFromInfiniteList = (storeKey, typeOfRecord) =>
  createSelector(
    selectInfiniteListState,
    selectRecordsByType(typeOfRecord),
    (infiniteListState, recordTypeState) => {
      const infList = infiniteListState.get(storeKey)
      const records = recordTypeState
      const data = infList && infList.data
      if (!data || !records) return {}

      const orderedMap = new OrderedMap({}).withMutations((map) => {
        data.forEach((k) => {
          map.set(k, records.get(k))
        })
      })
      return orderedMap
    }
  )
