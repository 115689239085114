import { useSelector } from "react-redux"
import { selectFlag, selectLoading } from "ducks/flag"

export default (flagName) => {
  const loading = useSelector(selectLoading())
  const flag = useSelector(selectFlag(flagName))

  return {
    loading,
    enabled: !!flag?.enabled,
    enabledGroups: flag?.enabled_groups,
  }
}
