import { debugFlag } from "utils/debugFlag"
import { identifyMixpanelUser } from "utils/tracking"

export const identifyMixpanel = (currentUser) => {
  if (debugFlag("DEBUG_TRACKING")) {
    // eslint-disable-next-line no-console
    console.log(`%cMIXPANEL IDENTIFY:`, css, [currentUser.id])
  }

  if (typeof mixpanel?.identify === "function")
    identifyMixpanelUser(currentUser.id)
}

const css = "background: #222; color: #3fc9f9; padding: 12px;"

export const identifyLinkedIn = (currentUser) => {
  const data = {
    email: currentUser.email,
  }

  if (debugFlag("DEBUG_TRACKING")) {
    // eslint-disable-next-line no-console
    console.log(`%cLINKEDIN IDENTIFY:`, css, ["setUserData", data])
  }

  if (window && window.lintrk) window.lintrk("setUserData", data)
}

export const trackLinkedIn = (event, data) => {
  if (debugFlag("DEBUG_TRACKING")) {
    // eslint-disable-next-line no-console
    console.log(`%cLINKEDIN EVENT:`, css, [event, data])
  }

  if (window && window.lintrk) window.lintrk(event, data)
}

export const identifyBing = (currentUser) => {
  const data = {
    pid: {
      em: currentUser.email,
    },
  }

  if (debugFlag("DEBUG_TRACKING")) {
    // eslint-disable-next-line no-console
    console.log(`%cBING IDENTIFY:`, css, ["set", data])
  }

  window.uetq = window.uetq || []
  window.uetq.push("set", data)
}

export const trackBingEvent = (action, params = {}) => {
  const data = {
    ecomm_prodid: params.ecomm_prodid,
    ecomm_pagetype: params.ecomm_pagetype,
    event_category: params.event_category,
    event_label: params.event_label,
    event_value: params.event_value,
    revenue_value: params.revenue_value,
    currency: params.currency || "USD",
  }

  if (debugFlag("DEBUG_TRACKING")) {
    // eslint-disable-next-line no-console
    console.log(`%cBING EVENT:`, css, ["event", action, data])
  }

  window.uetq = window.uetq || []
  window.uetq.push("event", action, data)
}

export const identifyTikTok = (currentUser) => {
  const data = { email: currentUser.email.toLowerCase() }

  if (debugFlag("DEBUG_TRACKING")) {
    // eslint-disable-next-line no-console
    console.log(`%cTIKTOK IDENTIFY:`, css, [data])
  }

  if (window.ttq) window.ttq.identify(data)
}

export const trackTikTokEvent = (event, data) => {
  if (debugFlag("DEBUG_TRACKING")) {
    // eslint-disable-next-line no-console
    console.log(`%cTIKTOK EVENT:`, css, [event, data])
  }

  if (window.ttq) window.ttq.track(event, data)
}

export const identifyGoogleAnalytics = (currentUser) => {
  const data = { email: currentUser.email.toLowerCase() }

  if (debugFlag("DEBUG_TRACKING")) {
    // eslint-disable-next-line no-console
    console.log(`%cGOOGLE ANALYTICS IDENTIFY:`, css, ["set", "user_data", data])
  }

  if (gtag) gtag("set", "user_data", data)
}

export const trackGoogleAnalytics = (event, data) => {
  if (debugFlag("DEBUG_TRACKING")) {
    // eslint-disable-next-line no-console
    console.log(`%cGOOGLE ANALYTICS EVENT:`, css, ["event", event, data])
  }

  if (gtag) gtag("event", event, data)
}
