const grays = {
  gray000: "#F5F7FA",
  gray100: "#E3E6ED",
  gray200: "#D0D6E1",
  gray300: "#A2A9B8",
  gray400: "#8D95A5",
  gray500: "#676F7E",
  gray600: "#3E4451",
  gray700: "#272A34",
  gray800: "#181B20",
  gray900: "#111317",
  white: "#FFF",
  black: "#000",

  lightAlpha100: "hsla(220, 32%, 60%, 0.08)",
  lightAlpha200: "hsla(220, 30%, 60%, 0.13)",
  lightAlpha300: "hsla(220, 30%, 60%, 0.20)",

  darkAlpha100: "hsla(220, 100%, 100%, 0.05)",
  darkAlpha200: "hsla(220, 100%, 100%, 0.11)",
  darkAlpha300: "hsla(220, 100%, 100%, 0.17)",
}

const brand = {
  brand: {
    pink: "#ED2A7A",
    pinkJoy: "#FF277D",
    mist: "#E9EFF0",
  },
}

export const lightPalette = {
  ...grays,
  ...brand,

  red000: "#FFEBEB",
  red100: "#FDB3B6",
  red200: "#EF6267",
  red300: "#D12E33",
  red400: "#992226",
  red500: "#6D1316",
  red600: "#461617",

  orange000: "#FFF2EB",
  orange100: "#FFCCAD",
  orange200: "#FF9C5D",
  orange300: "#F57D33",
  orange400: "#CE5511",
  orange500: "#85270A",
  orange600: "#521400",

  yellow000: "#FFF8E2",
  yellow100: "#FFE99A",
  yellow200: "#FFE16E",
  yellow300: "#FFCB14",
  yellow400: "#EBAD00",
  yellow500: "#944C0C",
  yellow600: "#412506",

  green000: "#EEFCFB",
  green100: "#B3F2E6",
  green200: "#65E7CE",
  green300: "#08C4B2",
  green400: "#0B968F",
  green500: "#025A56",
  green600: "#073632",

  blue000: "#EBF5FF",
  blue100: "#A0C7F8",
  blue200: "#5EA0F3",
  blue300: "#2767E7",
  blue400: "#144BB8",
  blue500: "#123478",
  blue600: "#001642",

  purple000: "#F2F2F9",
  purple100: "#C7C5F6",
  purple200: "#8D7CF4",
  purple300: "#6253BC",
  purple400: "#453795",
  purple500: "#352B6E",
  purple600: "#1D1D38",

  pink000: "#FFEBF3",
  pink100: "#FF95C1",
  pink200: "#EF5897",
  pink300: "#E41469",
  pink400: "#C22459",
  pink500: "#950942",
  pink600: "#56122E",
}

export const darkPalette = {
  ...grays,
  ...brand,

  red000: "#FFDBDC",
  red100: "#F9B7B9",
  red200: "#E66B6F",
  red300: "#BF4044",
  red400: "#9B272B",
  red500: "#6D1316",
  red600: "#4E0E10",

  orange000: "#FFE5D6",
  orange100: "#F2CCB5",
  orange200: "#E7A074",
  orange300: "#DD7C40",
  orange400: "#B05821",
  orange500: "#76360F",
  orange600: "#441F08",

  yellow000: "#FFF6DB",
  yellow100: "#F5E3A3",
  yellow200: "#EED168",
  yellow300: "#E8C033",
  yellow400: "#B99618",
  yellow500: "#826A17",
  yellow600: "#534209",

  green000: "#E5FAF8",
  green100: "#B6EFE4",
  green200: "#6EDDC8",
  green300: "#1DAFA1",
  green400: "#1B837E",
  green500: "#0D5E5A",
  green600: "#0C3C37",

  blue000: "#D6E4FF",
  blue100: "#AAC4F8",
  blue200: "#6F95E0",
  blue300: "#396DD5",
  blue400: "#2952A3",
  blue500: "#223C72",
  blue600: "#13213F",

  purple000: "#E4E1FE",
  purple100: "#B9B4E4",
  purple200: "#8982C9",
  purple300: "#635AAF",
  purple400: "#49428A",
  purple500: "#332E61",
  purple600: "#232046",

  pink000: "#FCEEF4",
  pink100: "#EEA0C0",
  pink200: "#E06C9C",
  pink300: "#C63973",
  pink400: "#A8245B",
  pink500: "#871D48",
  pink600: "#54122D",
}
