import React from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import classNames from "classnames"
import SidebarMenu from "components/SidebarMenu"
import {
  actions as uiActions,
  selectMobileSidebarOpen,
  selectAppBannerVisible,
} from "ducks/ui"

const MobileSideBarMenu = ({ appBannerVisible }) => {
  const dispatch = useDispatch()
  const mobileSidebarShown = useSelector(selectMobileSidebarOpen())
  const toggleSidebarMenu = () => dispatch(uiActions.toggleMobileSidebar())

  return mobileSidebarShown ? (
    <SidebarMenu
      className={classNames({
        shownMobile: mobileSidebarShown,
        hidden: !mobileSidebarShown,
        appBannerVisible,
      })}
      onMobileSidebarClose={toggleSidebarMenu}
      shownMobile={mobileSidebarShown}
    />
  ) : null
}

const mapStateToProps = (state) => ({
  appBannerVisible: selectAppBannerVisible()(state),
})

export default connect(mapStateToProps)(MobileSideBarMenu)
