import {
  BPM_MAX,
  BPM_MIN,
  DURATION_MAX,
  DURATION_MIN,
} from "components/SongSearchSidebar/constants"

const trackMixpanelMusicSearch = (searchState) => {
  const {
    mood,
    characteristic,
    instrument,
    genre,
    mode,
    energy,
    keys,
    artist,
    playlists,
    duration,
    bpm,
    vocal,
    previouslyLicensed,
    playedToday,
    stems,
  } = searchState
  const activeArtistIds = artist.activeFilters || []

  const activeEnergy =
    (energy.activeFilters &&
      energy.activeFilters.map((e) => e.toLowerCase().replace(/\s/g, "_"))) ||
    []
  const activeCharacteristics = characteristic.activeFilters || []
  const activeGenres = genre.activeFilters || []
  const activeInstruments = instrument.activeFilters || []
  const activeMoods = mood.activeFilters || []

  const negativeEnergy =
    (energy.negativeFilters &&
      energy.negativeFilters.map((e) => e.toLowerCase().replace(/\s/g, "_"))) ||
    []
  const negativeCharacteristics = characteristic.negativeFilters || []
  const negativeGenres = genre.negativeFilters || []
  const negativeInstruments = instrument.negativeFilters || []
  const negativeMoods = mood.negativeFilters || []
  const negativeArtists = artist.negativeFilters || []

  const activeMode = mode.activeFilters || ""
  const activePlaylistIds = playlists.activeFilters || []
  const bpmMin = bpm.activeFilters.min || BPM_MIN
  const bpmMax = bpm.activeFilters.ma || BPM_MAX
  const durationMin = duration.activeFilters.min || DURATION_MIN
  const durationMax = duration.activeFilters.max || DURATION_MAX
  const searchQuery = searchState.searchQuery || ""
  const sort = searchState.sort || ""
  const vocalFilter = vocal.activeFilter || ""
  const parentSongId = searchState.similarSongParentId || null
  const showPreviouslyLicensedFilter = !previouslyLicensed.isActive
    ? ""
    : previouslyLicensed.value
    ? "True"
    : "False"
  const showPlayedTodayFilter = !playedToday.isActive
    ? ""
    : playedToday.value
    ? "True"
    : "False"
  const withStemsFilter = stems.isActive === "1" || false

  const keysTracking = keys.activeFilters.reduce((prev, val) => {
    if (activeMode) {
      prev.push(`${val} ${activeMode}`)
    } else {
      prev.push(`${val} Major`)
      prev.push(`${val} Minor`)
    }
    return prev
  }, [])

  const activeTags = activeEnergy.concat(
    activeCharacteristics,
    activeGenres,
    activeInstruments,
    activeMoods
  )

  const negativeTags = negativeEnergy.concat(
    negativeCharacteristics,
    negativeGenres,
    negativeInstruments,
    negativeMoods
  )

  const properties = {
    Query: searchQuery,
    "BPM Min": bpmMin,
    "BPM Max": bpmMax,
    "Duration Min": durationMin,
    "Duration Max": durationMax,
    Tags: activeTags,
    "Excluded Tags": negativeTags,
    "Excluded Artists": negativeArtists,
    Keys: keysTracking,
    Artists: activeArtistIds,
    Playlists: activePlaylistIds,
    "Songs Similar To": parentSongId,
    "Show Previously Downloaded": showPreviouslyLicensedFilter,
    "Show Played Today": showPlayedTodayFilter,
    "With Stems": withStemsFilter,
    "Has Vocals": vocalFilter === "Vocals",
    Sort:
      sort === "approved_at"
        ? "order_most_recently_approved"
        : "order_most_popular",
  }

  return properties
}

export default trackMixpanelMusicSearch
