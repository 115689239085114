import { useEffect, useState } from "react"

const useMediaQueryV2 = (query) => {
  const getMatches = (query) => {
    if (typeof window !== "undefined") {
      return window.matchMedia(query).matches
    }
    return false
  }

  const [matches, setMatches] = useState(getMatches(query))
  const handleChange = () => {
    setMatches(getMatches(query))
  }

  useEffect(() => {
    const matchMedia = window.matchMedia(query)
    handleChange()
    if (matchMedia.addEventListener) {
      matchMedia.addEventListener("change", handleChange)
      return () => {
        matchMedia.removeEventListener("change", handleChange)
      }
    } else {
      matchMedia.addListener(handleChange)
      return () => {
        matchMedia.removeListener(handleChange)
      }
    }
  }, [query])

  return matches
}

export default useMediaQueryV2
