export const createAction = (type, ...argNames) => {
  if (!type) {
    console.error(
      "You forgot to create an action with a type dummy!",
      type,
      ...argNames
    )
  }
  return (...args) => {
    const action = { type }
    argNames.forEach((_arg, index) => {
      action[argNames[index]] = args[index]
    })
    return action
  }
}

export default createAction
