import React from "react"
import PropTypes from "prop-types"
import Button from "components/Button/New"
import { ICON_SS_ELLIPSIS } from "components/Icon/constants"

const DottedButton = (props) => (
  <Button
    data-cy="dotted-btn"
    aria-label="More Options"
    icon={ICON_SS_ELLIPSIS}
    onClick={props.onClick}
    style={{
      padding: props.padding && props.padding,
      margin: props.margin && props.margin,
    }}
    appearance="subtle"
  />
)

DottedButton.propTypes = {
  padding: PropTypes.string,
  theme: PropTypes.shape({
    colors: PropTypes.object,
  }),
}

export default DottedButton
