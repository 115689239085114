import { getConvertExperiment } from "utils/convertTesting"

export const locationMatches = (location, regEx) => {
  return location.pathname.match(regEx) != null
}

const experimentProps = () => {
  const experiment = process.env.CONVERT_PRICING_NAV_BAR_TEST
  const convExp = getConvertExperiment(experiment)
  const { variantIds, currentVariantId } = convExp
  const variants = {
    B: variantIds[1],
  }
  const bVariantActive = currentVariantId === variants.B

  if ($crisp) {
    $crisp.push(["safe", true])
    $crisp.push([
      "set",
      "session:data",
      ["pricing_nav_bar_test_variant", bVariantActive ? "B" : "Control"],
    ])
  }

  return {
    bVariantActive,
  }
}

export default experimentProps
