import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { push } from "react-router-redux"
import {
  actions as currentUserActions,
  selectCurrentUserRecord,
} from "ducks/currentUser"
import withMixpanelTracking from "hocs/withMixpanelTracking"
import { User as UserRecord } from "records"
import { LINKS } from "constants.js"
import { refreshPage } from "utils/window"
import { loggedIn } from "utils/authentication"
import Badge from "components/Badge"
import Box from "components/Box"
import { actions as cookieActions } from "middlewares/cookies"

const withAccountLinks = (WrappedComponent) => {
  const AccountLinkBuilder = ({ currentUser, ...props }) => {
    const handleSignOut = (e) => {
      e.preventDefault()
      props.trackMixpanel("Signed Out")
      mixpanel.reset()
      props.removeSignupMediaCookie()
      props.removeHubspotCookie()
      props.signOut()
      props.push("/sign_in")
      refreshPage()
    }

    const handleAdobeClick = (e) => {
      e.preventDefault()
      window.open(LINKS.ADOBE)
    }

    const handleMobileAppClick = () => {
      props.trackMixpanel("Clicked Mobile App Link", {
        Context: "Account Menu",
        "Link Text": "Mobile App",
      })
    }

    return (
      <WrappedComponent
        {...props}
        adobePremiereProObject={{
          content: "Premiere Extension",
          props: { onClick: handleAdobeClick, href: LINKS.ADOBE },
        }}
        billingLinkObject={
          currentUser.canAccessBilling() && {
            content: "Billing",
            props: { to: "/account/billing" },
          }
        }
        contentIdLinkObject={
          !currentUser.enterpriseApi() && {
            content: "Content ID",
            props: { to: "/account/content_id" },
          }
        }
        subscriptionLinkObject={
          currentUser.canManageSubscription() &&
          currentUser.hasSubscription() && {
            content: "Subscription",
            props: { to: "/account/subscription" },
          }
        }
        mobileAppLinkObject={
          loggedIn() && {
            content: "Mobile App",
            props: { to: "/mobile-app", onClick: handleMobileAppClick },
          }
        }
        extensionsLinkObject={
          loggedIn() && {
            content: "Extensions",
            props: { to: "/extensions" },
          }
        }
        memberDealsLinkObject={
          currentUser.hasSubscription() && {
            content: "Member Deals",
            props: { to: "/member_deals" },
          }
        }
        profileLinkObject={
          loggedIn() && {
            content: "Profile",
            props: { to: "/account/profile" },
          }
        }
        referralLinkObject={
          currentUser.canAccessReferralProgram() && {
            content: (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                Invite Friends
                <Box ml={2}>
                  <Badge type="success" text="Get $15" />
                </Box>
              </Box>
            ),
            props: { to: "/referral" },
          }
        }
        signOutLinkObject={
          loggedIn() && {
            content: "Sign Out",
            props: { onClick: handleSignOut },
          }
        }
        teamMembersLinkObject={
          (currentUser.onTeam() || currentUser.showTeamUpsell()) && {
            content: "Team",
            props: { to: "/account/account_users" },
          }
        }
        apiAccessLinkObject={
          currentUser.hasApiAccess() && {
            content: "API",
            props: { to: "/account/api_access" },
          }
        }
      />
    )
  }

  const mapDispatchToProps = (dispatch) => ({
    push: (path) => dispatch(push(path)),
    signOut: () => dispatch(currentUserActions.signOut()),
    removeSignupMediaCookie: () =>
      dispatch(cookieActions.removeCookie("signup_media_item", true)),
    removeHubspotCookie: () =>
      dispatch(cookieActions.removeCookie("hubspotutk", true)),
  })

  const mapStateToProps = (state) => ({
    currentUser: selectCurrentUserRecord()(state),
  })

  AccountLinkBuilder.propTypes = {
    signOut: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    currentUser: PropTypes.instanceOf(UserRecord).isRequired,
  }

  return withMixpanelTracking(
    connect(mapStateToProps, mapDispatchToProps)(AccountLinkBuilder)
  )
}

export default withAccountLinks
