import React, { Component } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import styled, { withTheme } from "styled-components"
import isEqual from "lodash/isEqual"
import Box from "components/Box"
import WaveCanvas from "./WaveCanvas"

const pixelRatio =
  window.devicePixelRatio || screen.deviceXDPI / screen.logicalXDPI

const ProgressWaveWrapper = styled.div`
  bottom: 0;
  box-sizing: border-box;
  display: block;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: left ${({ theme }) => theme.transitions.quick};
  width: 100%;
  z-index: 2;
`

const OffsetWrapper = styled.div`
  pointer-events: none;
  position: relative;
  transition: left ${({ theme }) => theme.transitions.quick};
  width: 100%;
`

class CanvasWaveform extends Component {
  state = {
    waveWidth: 0,
    waveHeight: this.props.height * pixelRatio,
  }

  resize = () => {
    const containerWidth = ReactDOM.findDOMNode(this.wrapper).clientWidth
    this.setState({ waveWidth: containerWidth * pixelRatio })
  }

  componentDidMount = () => {
    this.resize()
    window.addEventListener("resize", this.resize)
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    const { peaks, pos, duration, theme } = this.props
    const stateChanged = !isEqual(this.state, nextState)
    const peaksChanged = !isEqual(peaks, nextProps.peaks)
    const posChanged = pos !== nextProps.pos
    const durationChanged = duration !== nextProps.duration

    const colorChanged =
      theme.waveform.default !== nextProps.theme.waveform.default ||
      nextProps.progressColor !== this.props.progressColor

    return (
      stateChanged ||
      peaksChanged ||
      posChanged ||
      durationChanged ||
      colorChanged
    )
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.resize)
  }

  handleClick = (e) => {
    this.resize()
    const containerWidth = ReactDOM.findDOMNode(this.wrapper).clientWidth
    const percentageOffsetX = e.nativeEvent.offsetX / containerWidth
    this.props.onClick(Math.round(percentageOffsetX * this.props.duration))
  }

  render() {
    const {
      height,
      peaks,
      isSoundEffect,
      theme,
      progressColor,
      pos,
      duration,
      waveformColor,
    } = this.props
    const progressWidth = (pos / duration) * 100
    const { waveWidth, waveHeight } = this.state
    const left = `${100 - progressWidth}%`
    const style = { height: `${height}px`, left: `-${left}` }

    return (
      <Box
        position="relative"
        width={1}
        height="100%"
        style={{ cursor: "pointer" }}
        onClick={this.handleClick}
        ref={(instance) => {
          this.wrapper = instance
        }}
      >
        <WaveCanvas
          color={waveformColor || theme.waveform.default}
          peaks={peaks}
          width={waveWidth}
          height={waveHeight}
          isSoundEffect={isSoundEffect}
        />
        <ProgressWaveWrapper style={style}>
          <OffsetWrapper style={{ left }}>
            <WaveCanvas
              ref={(instance) => {
                this.progress = instance
              }}
              className="progress"
              color={progressColor || theme.colors.text.accent}
              peaks={peaks}
              width={waveWidth}
              height={waveHeight}
              isSoundEffect={isSoundEffect}
            />
          </OffsetWrapper>
        </ProgressWaveWrapper>
      </Box>
    )
  }
}

CanvasWaveform.propTypes = {
  duration: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  isSoundEffect: PropTypes.bool,
  onClick: PropTypes.func,
  peaks: PropTypes.arrayOf(PropTypes.number.isRequired),
  pos: PropTypes.number.isRequired, // num of seconds
  waveformColor: PropTypes.string,
  progressColor: PropTypes.string,
  theme: PropTypes.shape({
    waveform: PropTypes.shape({ default: PropTypes.string.isRequired }),
  }).isRequired,
}

CanvasWaveform.defaultProps = {
  height: 30,
  isSoundEffect: false,
  onClick: () => {},
}

export default withTheme(CanvasWaveform)
