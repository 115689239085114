import { call, takeEvery, put, select, take, delay } from "redux-saga/effects"
import { post } from "utils/request"
import Honeybadger from "@honeybadger-io/js"
import {
  actions as trackingActions,
  types,
  selectIdentified,
} from "ducks/tracking"
import { selectCurrentUserRecord } from "ducks/currentUser"
import { actions as cookieActions } from "middlewares/cookies"
import {
  UTM_PARAMS,
  getMixpanelDeviceId,
  updateMixpanelPeopleProperties,
} from "utils/tracking"
import { getUserConsent } from "utils/userConsent"
import {
  convertTriggerConversion,
  convertTriggerRevenueConversion,
} from "utils/convertTesting"
import {
  identifyBing,
  identifyGoogleAnalytics,
  identifyLinkedIn,
  identifyTikTok,
  identifyMixpanel,
} from "utils/conversionTracking"
import pluralize from "pluralize"
import { capitalize } from "lodash"
import { Userpilot } from "userpilot"
import { selectAppBannerVisible } from "ducks/ui"
import { QUALIFIED_USER_VALUES } from "constants.js"

import { setLiveChatUserInfo } from "ducks/utils/currentUser"
import { initAskNicely, initUserPilot } from "utils/thirdPartyIntegrations"
import { sharedStableId } from "utils/statsig/stable-id"

export function* identifyCurrentUser() {
  if (yield select(selectIdentified())) return

  const currentUser = yield select(selectCurrentUserRecord())

  for (const identifier of [
    setLiveChatUserInfo,
    initUserPilot,
    initAskNicely,
    identifyBing,
    identifyGoogleAnalytics,
    identifyLinkedIn,
    identifyTikTok,
    identifyMixpanel,
  ]) {
    try {
      yield call(identifier, currentUser)
    } catch (err) {
      Honeybadger.notify(err)
      console.error(err)
    }
  }

  yield put(trackingActions.identifyCurrentUserSuccess())
}

export function* ensureCurrentUserIdentified() {
  yield put(trackingActions.identifyCurrentUser())
  yield take(types.IDENTIFY_CURRENT_USER_SUCCESS)
}

export function* watchIdentifyCurrentUser() {
  yield takeEvery(types.IDENTIFY_CURRENT_USER, identifyCurrentUser)
}

export function* setMixpanelPeopleProperties() {
  const currentUser = yield select(selectCurrentUserRecord())

  try {
    yield call(updateMixpanelPeopleProperties, currentUser)
  } catch (err) {
    Honeybadger.notify(err)
  }
}

export function* waitForTrackingLibraries(maxAttempts = 10, delayMs = 100) {
  for (let attempts = 0; attempts < maxAttempts; attempts++) {
    if (window.trackingLoaded && window.statsigLoaded) {
      return true
    }
    yield delay(delayMs)
  }

  if (window.trackingLoaded) {
    if (!window.statsigLoaded) {
      const error = new Error(
        "Statsig failed to load while tracking (Mixpanel) was loaded"
      )
      Honeybadger.notify(error, {
        context: {
          trackingLoaded: window.trackingLoaded,
          statsigLoaded: window.statsigLoaded,
          attempts: maxAttempts,
          totalWaitTimeInMilliseconds: maxAttempts * delayMs,
        },
      })
    }
    return true
  }

  throw new Error(
    `Tracking libraries failed to load after ${maxAttempts *
      delayMs} milliseconds`
  )
}

export function* trackMixpanel(action) {
  const consent = getUserConsent()
  if (consent?.analytics === "no") {
    return
  }

  try {
    yield call(waitForTrackingLibraries)

    const utmCookies = yield put(cookieActions.getCookies(UTM_PARAMS))
    const deviceId = getMixpanelDeviceId()
    const stableId = sharedStableId()
    const appBannerVisible = yield select(selectAppBannerVisible())

    const data = {
      data: {
        type: "tracking",
        attributes: {
          event_name: action.eventName,
          event_properties: {
            ...action.eventProperties,
            ...utmCookies,
            ...(appBannerVisible && { "App Banner Visible": true }),
            ...(deviceId && { $device_id: deviceId }),
            ...(stableId && { "Statsig Stable ID": stableId }),
          },
        },
      },
    }

    const requestURL = `${process.env.API_URL}/tracking`
    const options = {
      data: JSON.stringify(data),
    }

    yield call(post, requestURL, options)
  } catch (err) {
    Honeybadger.notify(err)
    console.error(err)
  }
}

export function* watchTrackMixpanel() {
  yield takeEvery(types.TRACK_MIXPANEL, trackMixpanel)
}

export function* trackLinkedIn({ event, data }) {
  try {
    if (window && window.lintrk) window.lintrk(event, data)
  } catch (err) {
    Honeybadger.notify(err)
    console.error(err)
  }
}

export function* watchTrackLinkedIn() {
  yield takeEvery(types.TRACK_LINKEDIN, trackLinkedIn)
}

export function* trackBing({ action, params = {} }) {
  try {
    const data = {
      ecomm_prodid: params.ecomm_prodid,
      ecomm_pagetype: params.ecomm_pagetype,
      event_category: params.event_category,
      event_label: params.event_label,
      event_value: params.event_value,
      revenue_value: params.revenue_value,
      currency: params.currency || "USD",
    }

    window.uetq = window.uetq || []
    window.uetq.push("event", action, data)
  } catch (err) {
    Honeybadger.notify(err)
    console.error(err)
  }
}

export function* watchTrackBing() {
  yield takeEvery(types.TRACK_BING, trackBing)
}

export function* trackGoogleAds({ event, data }) {
  try {
    if (gtag) gtag("event", event, data)
  } catch (err) {
    Honeybadger.notify(err)
    console.error(err)
  }
}

export function* watchTrackGoogleAds() {
  yield takeEvery(types.TRACK_GOOGLE_ADS, trackGoogleAds)
}

export function* trackTikTok({ event, data }) {
  try {
    if (window.ttq) window.ttq.track(event, data)
  } catch (err) {
    Honeybadger.notify(err)
    console.error(err)
  }
}

export function* watchTrackTikTok() {
  yield takeEvery(types.TRACK_TIKTOK, trackTikTok)
}

export function* trackUserpilot({ event, data }) {
  try {
    Userpilot.track(event, data)
  } catch (err) {
    Honeybadger.notify(err)
    console.error(err)
  }
}

export function* watchTrackUserpilot() {
  yield takeEvery(types.TRACK_USERPILOT, trackUserpilot)
}

export function* trackConvertGoal({ goalId }) {
  try {
    convertTriggerConversion(goalId)
  } catch (err) {
    Honeybadger.notify(err)
    console.error(err)
  }
}

export function* watchTrackConvertGoal() {
  yield takeEvery(types.TRACK_CONVERT_GOAL, trackConvertGoal)
}

export function* trackConvertRevenueGoal({ revenue, goalId }) {
  try {
    convertTriggerRevenueConversion(revenue, goalId)
  } catch (err) {
    Honeybadger.notify(err)
    console.error(err)
  }
}

export function* watchTrackConvertRevenueGoal() {
  yield takeEvery(types.TRACK_CONVERT_REVENUE_GOAL, trackConvertRevenueGoal)
}

////////////////////////////////////////////////////////////////////////
// Collection sagas
////////////////////////////////////////////////////////////////////////

export function* trackGoogleOauthSignIn({ mixpanelParams }) {
  yield call(ensureCurrentUserIdentified)
  yield put(trackingActions.trackUserpilot("Signed In"))

  yield put(
    trackingActions.trackMixpanel("Signed In", {
      "Signed In Method": "Google",
      ...mixpanelParams,
    })
  )
}

export function* watchTrackGoogleOauthSignIn() {
  yield takeEvery(types.TRACK_GOOGLE_OAUTH_SIGN_IN, trackGoogleOauthSignIn)
}

export function* trackGoogleOauthSignUp({ mixpanelParams }) {
  yield call(ensureCurrentUserIdentified)
  yield put(trackingActions.trackUserpilot("Signed Up"))
  yield call(setMixpanelPeopleProperties)
  yield put(trackingActions.trackGoogleAds("free_account_registration"))
  yield put(trackingActions.trackMixpanel("free_account_registration"))
  yield put(trackingActions.trackTikTok("CompleteRegistration"))

  yield put(
    trackingActions.trackGoogleAds("submit", {
      event_label: "google-signup",
      event_category: "Button",
    })
  )

  yield put(
    trackingActions.trackMixpanel("Signed Up", {
      "Signed Up Method": "Google",
      ...mixpanelParams,
    })
  )
}

export function* watchTrackGoogleOauthSignUp() {
  yield takeEvery(types.TRACK_GOOGLE_OAUTH_SIGN_UP, trackGoogleOauthSignUp)
}

export function* trackEnterpriseFormSubmit() {
  yield call(ensureCurrentUserIdentified)

  yield put(
    trackingActions.trackLinkedIn("track", {
      conversion_id: process.env.LINKEDIN_CONVERSION_ENTERPRISE_FORM_SUBMISSION,
    })
  )

  yield put(
    trackingActions.trackBing("enterprise_lead_form_submission", {
      revenue_value: 1375.0,
      currency: "USD",
    })
  )

  yield put(
    trackingActions.trackGoogleAds("conversion", {
      value: 1375.0,
      currency: "USD",
      send_to: process.env.GOOGLE_ADS_ENTERPRISE_FORM_SUBMISSION_SEND_TO,
    })
  )

  yield put(
    trackingActions.trackConvertGoal(
      process.env.CONVERT_GOAL_ID_ENTERPRISE_SUBMIT
    )
  )
}

export function* watchTrackEnterpriseFormSubmit() {
  yield takeEvery(types.TRACK_ENTERPRISE_FORM_SUBMIT, trackEnterpriseFormSubmit)
}

export function* trackMarketplacePurchase({ meta }) {
  try {
    yield call(ensureCurrentUserIdentified)

    yield put(
      trackingActions.trackBing("PRODUCT_PURCHASE", meta.bing_event_data)
    )

    yield put(trackingActions.trackUserpilot("Purchased Marketplace Song"))

    yield put(
      trackingActions.trackGoogleAds("conversion", meta.google_adwords_data)
    )

    yield put(trackingActions.trackGoogleAds("purchase", meta.google_ga4_data))
  } catch (err) {}
}

export function* watchTrackMarketplacePurchase() {
  yield takeEvery(types.TRACK_MARKETPLACE_PURCHASE, trackMarketplacePurchase)
}

export function* trackCartPurchase({ response }) {
  try {
    yield call(ensureCurrentUserIdentified)

    yield put(
      trackingActions.trackConvertRevenueGoal(
        response.meta.revenue_total,
        process.env.CONVERT_GOAL_ID_PURCHASE_AN_ORDER
      )
    )

    yield put(trackingActions.trackUserpilot("Purchased Cart"))

    yield put(
      trackingActions.trackBing("purchase_an_order", {
        revenue_value: response.meta.revenue_total,
        currency: "USD",
      })
    )

    yield put(
      trackingActions.trackTikTok("PlaceAnOrder", {
        content_ids: response.data.map(
          ({
            id,
            relationships: {
              sellable: {
                data: { type },
              },
            },
          }) => `${type}_${id}`
        ),
        description: "Single-use License",
        quantity: response.data.length,
        value: response.meta.revenue_total,
        currency: "USD",
        content_type: "product",
      })
    )

    // Google AdWords conversion
    yield put(
      trackingActions.trackGoogleAds("conversion", {
        send_to: process.env.GOOGLE_ADWORDS_CART_PURCHASE_SEND_TO,
        value: response.meta.revenue_total,
        transaction_id: response.meta.transaction_id,
        currency: "USD",
      })
    )

    // Google Analytics eCommerce event
    yield put(
      trackingActions.trackGoogleAds("purchase", {
        value: response.meta.pretax_total / 100.0,
        tax: response.meta.tax / 100.0,
        transaction_id: response.meta.transaction_id,
        coupon: response.meta.coupon,
        currency: "USD",
        items: response.data.map(
          ({ attributes: { price }, relationships: { sellable } }) => {
            const {
              attributes: { title },
            } = response.included.find(({ id, type }) => {
              return sellable.data.id === id && sellable.data.type === type
            })

            return {
              item_id: sellable.data.id,
              item_name: title,
              item_category: pluralize(sellable.data.type, 1)
                .split("_")
                .map((s) => capitalize(s))
                .join(" "),
              price: price / 100.0,
              quantity: 1,
            }
          }
        ),
      })
    )
  } catch (err) {}
}

export function* watchTrackCartPurchase() {
  yield takeEvery(types.TRACK_CART_PURCHASE, trackCartPurchase)
}

export function* trackQualifiedUser() {
  try {
    const currentUser = yield select(selectCurrentUserRecord())
    const currentUserPersona = currentUser.user_type
    const qualifiedUserValue = QUALIFIED_USER_VALUES[currentUserPersona]
    const sendTo = process.env.GOOGLE_ADS_QUALIFIED_USER_SEND_TO

    if (!qualifiedUserValue) {
      throw new Error(
        `Qualified user value not found for persona: ${currentUserPersona}`
      )
    }

    if (!sendTo) {
      throw new Error(
        `GOOGLE_ADS_QUALIFIED_USER_SEND_TO env variable is not set: ${sendTo}`
      )
    }

    yield call(ensureCurrentUserIdentified)

    yield put(
      trackingActions.trackTikTok("CompletePayment", {
        content_type: "product",
        quantity: 1,
        description: currentUserPersona,
        content_id: currentUserPersona,
        currency: "USD",
        value: qualifiedUserValue,
      })
    )

    yield put(
      trackingActions.trackGoogleAds("conversion", {
        send_to: sendTo,
        value: qualifiedUserValue,
        currency: "USD",
      })
    )
  } catch (error) {
    Honeybadger.notify(error)
    console.error(error.message)
  }
}

export function* watchTrackQualifiedUser() {
  yield takeEvery(types.TRACK_QUALIFIED_USER, trackQualifiedUser)
}

export default [
  watchIdentifyCurrentUser,
  watchTrackMixpanel,
  watchTrackBing,
  watchTrackGoogleAds,
  watchTrackTikTok,
  watchTrackUserpilot,
  watchTrackConvertGoal,
  watchTrackConvertRevenueGoal,
  watchTrackGoogleOauthSignIn,
  watchTrackGoogleOauthSignUp,
  watchTrackEnterpriseFormSubmit,
  watchTrackMarketplacePurchase,
  watchTrackCartPurchase,
  watchTrackQualifiedUser,
]
