import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { border, layout, position, shadow } from "styled-system"

const Image = styled.img`
  ${border}
  ${layout}
  ${position}
  ${shadow}
  object-fit: ${(props) => props.objectFit};
`

const Img = (props) => {
  return (
    <Image
      {...props}
      className={props.className}
      src={props.src}
      alt={props.alt}
    />
  )
}

Img.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default Img
