import React, { useEffect } from "react"
import styled from "styled-components"
import { useSelector, useDispatch } from "react-redux"
import { selectAppBannerVisible, actions as uiActions } from "ducks/ui"
import useMixpanelTracking from "hooks/useMixpanelTracking"

const Banner = styled.div`
  align-items: center;
  background: ${(props) => props.theme.colors.gradients.secondary};
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  display: flex;
  height: ${(props) => props.theme.sizes.elements.appBanner.height.mobile};
  justify-content: center;
  padding: ${(props) => props.theme.space[1]} ${(props) => props.theme.space[2]};
  position: sticky;
  top: 0;
  width: ${(props) => props.theme.sizes.container.full};
  z-index: ${(props) => props.theme.zIndices.fixed};

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    height: ${(props) => props.theme.sizes.elements.appBanner.height.desktop};
  }
`

const Text = styled.div`
  font-size: ${(props) => props.theme.fontSizes.md};
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const VERSION_CHECK_INTERVAL = 30000 // 30 seconds
const VERSION_CHECK_ENABLED = ["production", "staging"].includes(
  process.env.BUILD_ENV
)

const AppVersionChecker = () => {
  const appBannerVisible = useSelector(selectAppBannerVisible())
  const dispatch = useDispatch()
  const { trackMixpanel } = useMixpanelTracking()

  const handleRefresh = () => {
    trackMixpanel("Clicked Element", { element: "App Version Banner" })
    setTimeout(() => window.location.reload(), 70)
  }

  const checkAppVersion = () => {
    if (VERSION_CHECK_ENABLED && !appBannerVisible) {
      fetch(`/meta.json?${new Date().getTime()}`, { cache: "no-cache" })
        .then((response) => response.json())
        .then((meta) => {
          if (process.env.COMMIT_HASH !== meta.version && !appBannerVisible) {
            trackMixpanel("Display Element", { element: "App Version Banner" })

            dispatch(
              uiActions.setAppBanner(
                <Banner onClick={handleRefresh}>
                  <Text>
                    A new version of Soundstripe is available. Please click here
                    to refresh!
                  </Text>
                </Banner>
              )
            )
          }
        })
    }
  }

  useEffect(() => {
    const interval = setInterval(checkAppVersion, VERSION_CHECK_INTERVAL)
    return () => clearInterval(interval)
  }, [appBannerVisible])

  return null
}

export default AppVersionChecker
