import React from "react"
import { useDispatch } from "react-redux"
import { push } from "react-router-redux"
import Button from "components/Button/New"
import { loggedIn } from "utils/authentication"
import useMixpanelTracking from "hooks/useMixpanelTracking"
import useOffer from "hooks/useOffer"
import useBreakpoints from "hooks/useBreakpoints"

const SignUpBtn = () => {
  const offer = useOffer()
  const dispatch = useDispatch()
  const isVisitor = !loggedIn()
  const { trackMixpanel } = useMixpanelTracking()
  const { isSm } = useBreakpoints()
  const offerButtonText = offer && (isSm ? "Sale" : offer.upgradeCallout.header)
  const signUpText = offerButtonText || "Sign Up"
  const subscribeText = offerButtonText || "Subscribe"

  const handleClick = () => {
    if (isVisitor && !offer) {
      trackMixpanel("Clicked Sign Up Link", {
        Context: "Header",
        "Link Text": signUpText,
      })
      dispatch(
        push(
          `/signup?mixpanel_trigger=${encodeURIComponent(
            "Header Nav Sign Up Button"
          )}`
        )
      )
    } else {
      trackMixpanel("Clicked Sign Up Button", {
        Context: "Header",
        "Link Text": signUpText,
      })
      dispatch(push("/pricing"))
    }
  }

  return (
    <Button
      appearance="pinkJoy"
      onClick={handleClick}
      fontSize="sm"
      ml={1}
      data-cy="nav-signup-btn"
    >
      {isVisitor ? signUpText : subscribeText}
    </Button>
  )
}

export default SignUpBtn
