const flexBoxTheme = {
  outerMargin: 0,
  // Defaults
  gutterWidth: 1, // rem
  container: {
    sm: 46, // rem
    md: 61, // rem
    lg: 76, // rem
  },
  breakpoints: {
    xs: 0, // em
    sm: 48, // em
    md: 60, // em
    lg: 75, // em
    xl: 90, // em
    xl2: 120, // em (1920px aka HD/1080p)
  },
}

export default flexBoxTheme
