import algoliasearch from "algoliasearch/lite"

export const searchClient = algoliasearch(
  process.env.ALGOLIA_APP_ID,
  process.env.ALGOLIA_API_KEY
)

export const buildSortedItems = (items, sortOrder) => {
  if (!sortOrder) return items

  return items
    .map((item) => {
      const index = sortOrder.indexOf(parseInt(item.objectID || item.id, 10))
      return [index, item]
    })
    .map((item) => item[1])
}

export const soundEffectsHitsMap = (hitsArray = []) => {
  const hitsMap = hitsArray.map((hit) => {
    const { objectID: id, ...rest } = hit
    return {
      attributes: rest,
      id,
      type: "sound_effects",
    }
  })
  return {
    data: hitsMap,
    included: [],
  }
}

export const videosHitsMap = (hitsArray = []) => {
  const hitsMap = hitsArray.map((hit) => {
    const { objectID: id, ...rest } = hit
    return {
      attributes: rest,
      id,
      type: "video",
    }
  })
  return {
    data: hitsMap,
    included: [],
  }
}

export const songsHitsMap = (hitsArray = []) => {
  let audioFiles = []
  const hitsMap = hitsArray.map((hit) => {
    const hitAudioFiles = hit.audio_files.map((file) => ({
      attributes: file,
      type: "audio_files",
    }))
    audioFiles = audioFiles.concat(hitAudioFiles)
    const { objectID: id, ...rest } = hit
    return {
      attributes: rest,
      id,
      relationships: {
        audio_files: {
          data: hitAudioFiles.map((file) => ({
            ...file.attributes,
            type: file.type,
          })),
        },
      },
      type: "songs",
    }
  })

  return {
    data: hitsMap,
    included: audioFiles,
  }
}

export const marketplaceSongsHitsMap = (hitsArray = []) => {
  let audioFiles = []
  const hitsMap = hitsArray.map((hit) => {
    const hitAudioFiles = hit.audio_files.map((file) => ({
      attributes: file,
      type: "marketplace_audio_files",
    }))
    audioFiles = audioFiles.concat(hitAudioFiles)
    const { objectID: id, ...rest } = hit
    return {
      attributes: rest,
      id,
      relationships: {
        audio_files: {
          data: hitAudioFiles.map((file) => ({
            ...file.attributes,
            type: file.type,
          })),
        },
      },
      type: "marketplace_songs",
    }
  })
  return {
    data: hitsMap,
    included: audioFiles,
  }
}

export const algoliaIndexes = {
  songs: process.env.ALGOLIA_SONG_INDEX_NAME,
  songs_most_recent: `${process.env.ALGOLIA_SONG_INDEX_NAME}_most_recent`,
  marketplace_songs: process.env.ALGOLIA_MARKETPLACE_SONG_INDEX_NAME,
  marketplace_songs_recent: `${process.env.ALGOLIA_MARKETPLACE_SONG_INDEX_NAME}_most_recent`,
  sound_effects: process.env.ALGOLIA_SFX_INDEX_NAME,
  sound_effects_most_recent: `${process.env.ALGOLIA_SFX_INDEX_NAME}_most_recent`,
  video: process.env.ALGOLIA_VIDEO_INDEX_NAME,
  videos: process.env.ALGOLIA_VIDEO_INDEX_NAME,
  videos_most_recent: `${process.env.ALGOLIA_VIDEO_INDEX_NAME}_most_recent`,
  curated_song_playlists: process.env.ALGOLIA_CURATED_SONG_PLAYLISTS_INDEX,
  curated_sound_effect_playlists:
    process.env.ALGOLIA_CURATED_SOUND_EFFECT_PLAYLISTS_INDEX,
  curated_video_playlists: process.env.ALGOLIA_CURATED_VIDEO_PLAYLISTS_INDEX,
}

export const sortIndexName = (sortState, sortMedia) => {
  const sortIndex = algoliaIndexes[sortMedia]
  if (sortState == "popular") return sortIndex
  return `${sortIndex}_most_recent`
}

export const defaultSortIndexForUser = (currentUser, sortMedia) =>
  sortIndexName(
    currentUser?.hasSubscription() ? "recent" : "popular",
    sortMedia
  )

export const currentSortIndexForUser = (
  currentUser,
  sortState,
  sortMedia = "songs"
) => {
  if (sortState) {
    return sortIndexName(sortState, sortMedia)
  }

  const sortParam = new URLSearchParams(window.location.search).get("sort")
  if (sortParam) {
    return sortIndexName(sortParam, sortMedia)
  }

  return defaultSortIndexForUser(currentUser, sortMedia)
}
