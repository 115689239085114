import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { color, space, typography, layout } from "styled-system"
import headingVariants from "./headingVariants"

const StyledH1 = styled.h1`
  ${color}
  ${space}
  ${typography}
  ${headingVariants}
  ${layout}
`

const H1 = ({ size, ...rest }) => {
  return <StyledH1 className={size} {...rest} />
}

H1.propTypes = {
  size: PropTypes.oneOf(["xl3", "xl2", "xl", "lg", "md"]),
}

H1.defaultProps = {
  color: "text.primary",
  fontWeight: "700",
}

export default H1
