import Immutable from "immutable"

const TagUiRecord = Immutable.Record(
  {
    id: null,
    type: "tags",

    // Attributes
    children: [],
    name: "",
  },
  "TagUi"
)

class TagUi extends TagUiRecord {
  constructor(args) {
    super(
      Object.assign({}, args, {
        children: args.child_tags,
      })
    )
  }
}

export default TagUi
