import styled from "styled-components"

const Vocals = styled.div`
  align-items: center;
  display: none;
  flex: 0 0 20px;
  justify-content: center;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: flex;
    margin: 0 ${(props) => props.theme.space[3]};
  }
`

export default Vocals
