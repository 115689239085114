import { call, put, takeLatest } from "redux-saga/effects"
import { post, prependApiUrl } from "utils/request"
import { actions as uiActions } from "ducks/ui"
import { actions, types } from "ducks/referral"
import { actions as modalActions } from "ducks/modal"

export function* referralSaga(action) {
  try {
    const requestURL = prependApiUrl("referral_invitations")

    const dataObj = {
      data: {
        referred_user_emails: action.emails,
        type: "referral_invitations",
      },
    }

    const options = { data: dataObj }
    yield call(post, requestURL, options)
    yield put(actions.sendReferralSuccess())
    yield put(modalActions.close())
    yield put(uiActions.setNotice("Your referrals have been sent!"))
  } catch (err) {
    yield put(actions.sendReferralFail(err))
    yield put(uiActions.setError(err))
  }
}

export function* watchReferralSaga() {
  yield takeLatest(types.SEND_REFERRAL, referralSaga)
  // Suspend execution until location changes
}

export default [watchReferralSaga]
