import PropTypes from "prop-types"
import React from "react"
import { useDispatch } from "react-redux"
import { actions as favoritableActions } from "ducks/favoritable"
import { actions as modalActions } from "ducks/modal"
import { Song as SongRecord } from "records"
import useCurrentUser from "hooks/useCurrentUser"

const FavoriteSong = ({ className, children, song }) => {
  const dispatch = useDispatch()
  const { currentUser } = useCurrentUser()

  const favorite = () => dispatch(favoritableActions.favorite(song))
  const unfavorite = () => dispatch(favoritableActions.unfavorite(song))

  const handleOnClick = () => {
    if (currentUser.loggedIn() && currentUser.canFavorite()) {
      return currentUser.favoritedSong(song.id) ? unfavorite() : favorite()
    }

    return dispatch(
      modalActions.open("SignUpModal", {
        action: "favorite",
        afterSignup: favorite,
      })
    )
  }

  return (
    <div className={className} onClick={handleOnClick}>
      {children}
    </div>
  )
}

FavoriteSong.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  song: PropTypes.instanceOf(SongRecord).isRequired,
}

export default FavoriteSong
