import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import Link from "components/Link/New"

import { sidebarLinkStyles } from "./sidebarLinkStyles"

const SidebarLinkStyles = styled(Link)`
  ${sidebarLinkStyles};
`
const SidebarButtonsStyles = styled.button`
  ${sidebarLinkStyles};
`

const SidebarLink = (props) => {
  if (props.to) {
    return <SidebarLinkStyles {...props} />
  } else {
    return <SidebarButtonsStyles {...props} />
  }
}

SidebarLink.propTypes = {
  external: PropTypes.bool,
}

export default SidebarLink
