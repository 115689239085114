import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import Icon from "components/Icon"
import { ICON_SS_CLOSE } from "components/Icon/constants"

const Button = styled.button`
  cursor: pointer;
  padding: ${(props) => props.theme.space[4]};
`

Button.defaultProps = {
  type: "button",
}

const MobileMenuCloseButton = (props) => (
  <Button {...props}>
    <Icon icon={ICON_SS_CLOSE} size={24} />
  </Button>
)

MobileMenuCloseButton.propTypes = {
  theme: PropTypes.object,
}

export default MobileMenuCloseButton
