import { createAction } from "./utils"

export const types = {
  ADD: "soundstripe/selectable/ADD",
  RESET: "soundstripe/selectable/RESET",
  REMOVE: "soundstripe/selectable/REMOVE",
  SET_PLAYLIST_ID: "soundstripe/selectable/SET_PLAYLIST_ID",
  TOGGLE_SELECT_ALL: "soundstripe/selectable/TOGGLE_SELECT_ALL",
}

export const actions = {
  add: createAction(types.ADD, "id"),
  reset: createAction(types.RESET),
  remove: createAction(types.REMOVE, "id"),
  setPlaylistId: createAction(types.SET_PLAYLIST_ID, "playlistId"),
  toggleSelectAll: createAction(types.TOGGLE_SELECT_ALL, "ids"),
}

const initialState = {
  list: new Set(),
  selectAll: false,
  playlistId: "",
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.ADD:
      return {
        ...state,
        list: state.list.add(action.id),
      }
    case types.REMOVE:
      const newList = state.list
      newList.delete(action.id)
      return {
        ...state,
        list: newList,
      }
    case types.SET_PLAYLIST_ID:
      return {
        ...state,
        playlistId: action.playlistId,
      }
    case types.TOGGLE_SELECT_ALL:
      return {
        ...state,
        list: state.selectAll
          ? new Set()
          : new Set([...action.ids.map((id) => id.toString())]),
        selectAll: !state.selectAll,
      }
    case types.RESET: {
      const newList = state.list?.size > 0 ? state.list.clear() : new Set()
      return {
        selectAll: initialState.selectAll,
        playlistId: initialState.playlistId,
        list: newList,
      }
    }
    default:
      return state
  }
}

export const selectSelectableState = (state) =>
  state.getIn(["ducks", "selectable"])
