import React from "react"
import { useDispatch } from "react-redux"
import { Link } from "react-router"
import PropTypes from "prop-types"
import { Text } from "components/Typography"
import { actions as cookieActions } from "middlewares/cookies"
import useCurrentUser from "hooks/useCurrentUser"
import useMixpanelTracking from "hooks/useMixpanelTracking"
import Icon from "components/Icon"
import { ICON_SS_ARROW_RIGHT } from "components/Icon/constants"

const ONBOARDING_PAGES = {
  ENTITY: "/user_info/ep",
  SIZE: "/user_info/sp",
  YOUTUBE: "/user_info/yt",
  MOBILE: "/user_info/mo",
  INDUSTRY: "/user_info/ip",
}

const { ENTITY, SIZE, YOUTUBE, MOBILE, INDUSTRY } = ONBOARDING_PAGES
const nextPage = (currPage) => `${currPage}${location.search}`

const SkipOnboardingStepLink = () => {
  const dispatch = useDispatch()
  const { currentUser } = useCurrentUser()
  const { trackMixpanel } = useMixpanelTracking()

  const handleSkipClick = () => {
    trackMixpanel("Skipped Onboarding", {
      "Onboarding Page": window.location.pathname.includes(YOUTUBE)
        ? "Content ID"
        : "Personas",
    })
  }

  const skipLinkPaths = {
    [YOUTUBE]: nextPage(MOBILE),
    [MOBILE]: nextPage(ENTITY),
    [ENTITY]: currentUser.entity_size ? nextPage(INDUSTRY) : nextPage(SIZE),
    [SIZE]: nextPage(INDUSTRY),
  }

  const skipLinkPath = () => {
    const path = skipLinkPaths[window.location.pathname]
    if (path) return path

    const signupMediaItem = dispatch(
      cookieActions.getCookie("signup_media_item")
    )
    const { id, mediaType } = JSON.parse(signupMediaItem || "{}")

    switch (mediaType) {
      case "songs":
        return `/songs/${id}?modal=download_modal`
      case "video":
        return `/videos/${id}?modal=download_modal`
      case "sound_effects":
        return `/sfx/${id}?modal=download_modal`
      default:
        return currentUser.canManageTeam() ? "/?modal=invite_team_modal" : "/"
    }
  }

  return (
    <Link
      onClick={handleSkipClick}
      to={skipLinkPath()}
      style={{
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        opacity: 0.8,
      }}
    >
      <Text
        fontSize={{ _: "xs", sm: "md" }}
        color="text.secondary"
        fontWeight="semiBold"
        py={0}
        px={1}
      >
        Skip this step
      </Text>
      <Icon color="text.secondary" icon={ICON_SS_ARROW_RIGHT} size={20} />
    </Link>
  )
}

SkipOnboardingStepLink.propTypes = {
  isMobile: PropTypes.bool,
}

export default SkipOnboardingStepLink
