import styled from "styled-components"

const PageContentWrapper = styled.main`
  min-height: 100%;
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    &.playerVisible {
      padding-bottom: ${(props) => props.theme.space[9]};
    }
  }

  @media print {
    width: 100%;
    margin-left: 0;
  }
`

export default PageContentWrapper
