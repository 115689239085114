import { call, put, takeLatest, select } from "redux-saga/effects"
import { startSubmit, stopSubmit, destroy } from "redux-form/immutable"
import { post } from "utils/request"
import { actions as modalActions } from "ducks/modal"
import { actions as apiActions } from "ducks/api"
import { types } from "ducks/project"
import { createRecord } from "records/util"
import { actions as uiActions } from "ducks/ui"
import pluralize from "pluralize"
import { selectCurrentUserRecord } from "ducks/currentUser"
import { trackUserpilot } from "utils/tracking"

export function* createProject(action) {
  if (action.form) {
    yield put(startSubmit(action.form))
  }

  try {
    const user = yield select(selectCurrentUserRecord())
    let project = action.project
    project = project.toJS()
    const { projectItems } = action
    const requestURL = `${process.env.API_URL}/projects`
    const data = {
      data: {
        type: "projects",
        attributes: {
          ...project.attributes,
        },
      },
    }
    const options = {
      data: JSON.stringify(data),
    }
    const response = yield call(post, requestURL, options)
    if (projectItems?.length > 0) {
      let relationship = "songs"
      if (
        ["sfx", "sound_effects", "sound-effects"].includes(projectItems[0].type)
      )
        relationship = "sound_effects"
      if (pluralize(projectItems[0].type, 2) === "videos")
        relationship = "videos"
      yield put(
        apiActions.appendRelationship(
          createRecord(response.data),
          relationship,
          projectItems.map(({ type, id }) => ({ type, id }))
        )
      )
      yield put(
        uiActions.setNotice(
          `${pluralize(
            relationship.replace("_", " "),
            projectItems.length,
            true
          )} ${pluralize(
            "has",
            projectItems.length
          )} been added to your new project ${project.attributes.name}`
        )
      )
    }
    if (action.form) {
      yield put(modalActions.close())
      yield put(stopSubmit(action.form))
      yield put(destroy(action.form))
    }
    if (action.mobileSongObject) {
      yield put(
        modalActions.open("MobileSongMenuModal", action.mobileSongObject)
      )
    }
    if (action.onSuccess) {
      yield action.onSuccess(createRecord(response.data))
    }
    if (action.callback) {
      yield action.callback(createRecord(response.data))
    }

    if (user.projects_count === 0) {
      trackUserpilot("Created First Project")
    }
  } catch (err) {
    if (action.form) {
      yield put(stopSubmit(action.form, { _error: err }))
    } else {
      yield put(uiActions.setError(err))
    }
  }
}

export function* watchCreateProject() {
  yield takeLatest(types.CREATE, createProject)
}

export default [watchCreateProject]
