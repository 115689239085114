import { eventChannel, END } from "redux-saga"
import { call } from "redux-saga/effects"
import merge from "lodash/merge"

import { post, patch } from "utils/request"
import saga from "utils/saga"
import base64 from "utils/base64"
import { assignDotNotatedKey, deleteDotNotatedKey } from "utils/object"
import { uuidGenerator } from "utils/uuidGenerator"

export const CHUNK_SIZE = 1024 * 1024 // 1MB

// This creates a channel so we can wait for the FileReader's load
// to complete before submitting
function createLoadChannel(reader, file) {
  const channel = saga.createChannel()
  reader.addEventListener("load", channel.put)
  reader.readAsDataURL(file)
  return channel
}

function uploadEmitter({
  fileChunk,
  uploadSessionId,
  byteRange,
  reader,
  url,
  method,
  data,
  fileKeyPath,
}) {
  let emit
  const channel = eventChannel((emitter) => {
    emit = emitter

    return () => {}
  })

  const promise = new Promise((resolve, reject) => {
    const offset = parseInt(byteRange.split("/")[0].split("-")[0])
    const endingByte = parseInt(byteRange.split("/")[0].split("-")[1])
    const total = parseInt(byteRange.split("/")[1])
    const chunkData = {
      data: {
        type: data.type,
        attributes: {
          upload_session_id: uploadSessionId,
        },
      },
    }

    if (data.id) {
      chunkData.data.id = data.id
    }

    assignDotNotatedKey(
      chunkData,
      fileKeyPath,
      base64.setProperMimeType(reader.result, fileChunk.name)
    )

    if (endingByte >= total) {
      // Get rid of the file from the passed-in data's attributes, since we're
      // sending a chunk (not the whole file).
      const fileKeyPathWithoutDataPrefix = fileKeyPath.replace(/^data\./, "")
      deleteDotNotatedKey(data, fileKeyPathWithoutDataPrefix)

      // Merge the passed-in data into this chunk's data, keeping the chunk
      // as the current file to send to the server.
      merge(chunkData.data, data)
    }
    const options = {
      data: JSON.stringify(chunkData),
      headers: {
        "Content-Range": `bytes ${byteRange}`,
        "Content-Disposition": `form-data; filename="${fileChunk.name}"`,
      },
      onUploadProgress: (progressEvent) => {
        // Percentage of current chunk successfully uploaded
        const decimalPctOfChunkCompleted =
          progressEvent.loaded / progressEvent.total

        // The percentage of the original file that the chunk makes up
        const chunkPctOfOriginalFile = (fileChunk.size / total) * 100

        const chunkPctOfOriginalFileCompleted =
          decimalPctOfChunkCompleted * chunkPctOfOriginalFile

        // How much has already been uploaded before this chunk
        const pctOfOriginalFileCompletedPrior =
          offset === 0 ? 0 : ((offset - 1) / total) * 100

        const totalPercentageUploaded =
          pctOfOriginalFileCompletedPrior + chunkPctOfOriginalFileCompleted

        progressEvent.totalPercentageUploaded = parseFloat(
          totalPercentageUploaded.toFixed(2)
        )

        emit(progressEvent)
        if (progressEvent.total === progressEvent.loaded) {
          emit(END)
        }
      },
    }

    switch (method.toUpperCase()) {
      case "POST":
        post(url, options)
          .then((response) => {
            resolve(response)
          })
          .catch((err) => {
            reject(err)
          })
        break
      case "PATCH":
        patch(url, options)
          .then((response) => {
            resolve(response)
          })
          .catch((err) => {
            reject(err)
          })
        break
      default:
        reject(
          new Error(
            `Undefined method "${method}" used when uploading file chunk`
          )
        )
    }
  })

  return [channel, promise]
}

function* monitorLoadEvents({
  channel,
  fileChunk,
  uploadSessionId,
  byteRange,
  reader,
  url,
  method,
  data,
  fileKeyPath,
  progressAction,
}) {
  while (true) {
    yield call(channel.take) // Blocks until the promise resolves
    const [, promise] = uploadEmitter({
      fileChunk,
      uploadSessionId,
      byteRange,
      reader,
      url,
      method,
      data,
      fileKeyPath,
    })
    const response = yield call(() => promise)
    return response
  }
}

export function* uploadFileInChunks({
  file,
  uploadSessionId,
  url,
  method,
  data,
  fileKeyPath,
  progressAction,
}) {
  let response

  // If no uploadSessionId is passed, generate one.
  // We allow one to be passed in case it's used for other purposes.
  // i.e. for audio files, we use the uploadSessionId as its key in the SongForm
  // if it wasn't retrieved from the server on a sequential page load (in which
  // case, its ID would be its key).
  if (!uploadSessionId) {
    uploadSessionId = uuidGenerator.generate()
  }

  // If a file is passed, upload it in chunks and send form data
  // along on the final chunk submission.
  if (file != null) {
    const fileSize = file.size
    const numChunks = Math.ceil(fileSize / CHUNK_SIZE)
    let chunkNum = 0
    const fileReader = new FileReader()

    while (chunkNum < numChunks) {
      const offset = chunkNum * CHUNK_SIZE
      let endingByte = offset + CHUNK_SIZE - 1 // Bytes are zero-indexed and byte range is inclusive on both ends, so remove one.
      if (endingByte > fileSize) {
        endingByte = fileSize
      }

      // LESSON LEARNED -- ENDING BYTE IS NON-INCLUSIVE IN SLICE
      const fileChunk = file.slice(
        offset,
        endingByte + 1,
        base64.mimeTypeLookup(file.name)
      )
      fileChunk.name = file.name
      const byteRange = `${offset}-${endingByte}/${fileSize}`
      response = yield call(monitorLoadEvents, {
        channel: createLoadChannel(fileReader, fileChunk),
        fileChunk,
        uploadSessionId,
        byteRange,
        reader: fileReader,
        url,
        method,
        data,
        fileKeyPath,
        progressAction,
      })
      chunkNum++
    }
  } else {
    // If file is not passed, send form data along now.
    const formData = {
      data: data,
    }
    const options = {
      data: JSON.stringify(formData),
    }
    switch (method.toUpperCase()) {
      case "POST":
        response = yield call(post, url, options)
        break
      case "PATCH":
        response = yield call(patch, url, options)
        break
      default:
        console.warn(`Undefined method "${method}" used when uploading data`)
    }
  }

  return response
}

export default uploadFileInChunks
