import styled from "styled-components"
import { Link } from "react-router"

const ArtistName = styled(Link)`
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  line-height: 1.25rem;
  text-decoration: none;

  &.played {
    color: ${({ theme }) => theme.colors.text.played};
  }

  &:hover,
  &:focus {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.text.primary};
  }

  &:not(:last-child) {
    margin-right: 2px;

    &:after {
      color: ${({ theme }) => theme.colors.text.primary} !important;
      content: ",";
      display: inline-block;
      text-decoration: none !important;
    }
  }
`

export default ArtistName
