import { css } from "styled-components"
import flexBoxTheme from "./FlexboxConstants"

const gradientProps = (
  leftColor,
  rightColor,
  leftPct = "0%",
  rightPct = "80%"
) => ({
  background: [
    leftColor,
    `-moz-linear-gradient(left, ${leftColor} ${leftPct}, ${rightColor} ${rightPct})`, // Firefox 3.6-15
    `-webkit-linear-gradient(left, ${leftColor} ${leftPct}, ${rightColor} ${rightPct})`, // Chrome 10-25, Safari 5.1-6
    `linear-gradient(to right, ${leftColor} ${leftPct}, ${rightColor} ${rightPct})`, // W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+
  ].join("; background: "), // Trust me, I'm a scientist
  filter: `progid:DXImageTransform.Microsoft.gradient( startColorstr='${leftColor}', endColorstr='${rightColor}',GradientType=1 );`, // IE 6-9
})

const backgroundGradientGenerator = (leftColor, rightColor) => css`
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/${leftColor}+0,${rightColor}+80 */
  background: ${leftColor}; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    ${leftColor} 0%,
    ${rightColor} 80%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    ${leftColor} 0%,
    ${rightColor} 80%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    ${leftColor} 0%,
    ${rightColor} 80%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='${leftColor}', endColorstr='${rightColor}',GradientType=1 ); /* IE6-9 */
`

const pricingPanelBGGradientGenerator = (leftColor, rightColor) => css`
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/${leftColor}+2,${rightColor}+87 */
  background: ${leftColor}; /* Old browsers */
  background: -moz-linear-gradient(
    -45deg,
    ${leftColor} 2%,
    ${rightColor} 87%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    -45deg,
    ${leftColor} 2%,
    ${rightColor} 87%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    135deg,
    ${leftColor} 2%,
    ${rightColor} 87%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='${leftColor}', endColorstr='${rightColor}',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
`

// const placeholder = (styles) => css`
//   &:-webkit-input-placeholder {
//     /* Chrome/Opera/Safari */
//     ${styles};
//   }
//   &:-moz-placeholder {
//     /* Firefox 19+ */
//     ${styles};
//   }
//   &:-ms-input-placeholder {
//     /* IE 10+ */
//     ${styles};
//   }
//   &:-moz-placeholder {
//     /* Firefox 18- */
//     ${styles};
//   }
// `

const removeScrollbar = css`
  -ms-overflow-style: none; // IE 10+
  scrollbar-width: none; // Firefox

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track {
    display: none; // Safari and Chrome
    background-color: transparent;
  }
`

const media = {
  xs: (...args) => css`
    @media (max-width: ${flexBoxTheme.breakpoints.sm}em) {
      ${css(...args)};
    }
  `,
  sm: (...args) => css`
    @media (max-width: ${flexBoxTheme.breakpoints.md}em) {
      ${css(...args)};
    }
  `,
  md: (...args) => css`
    @media (max-width: ${flexBoxTheme.breakpoints.lg}em) {
      ${css(...args)};
    }
  `,
  lg: (...args) => css`
    @media (max-width: ${flexBoxTheme.breakpoints.xl}em) {
      ${css(...args)};
    }
  `,
}

const focusStyles = css`
  /* box-shadow: 0 0 0 3px #5186f0; */
  box-shadow: 0px 0px 0px 3px rgba(81, 134, 240, 0.53);
  outline: none;
`

// breakpoints: {
//   xs: 0, // em
//   sm: 48, // em
//   md: 64, // em
//   lg: 75, // em
//   xl: 120, // em (1920px aka HD/1080p)
// },

export {
  backgroundGradientGenerator,
  gradientProps,
  pricingPanelBGGradientGenerator,
  removeScrollbar,
  media,
  focusStyles,
}
