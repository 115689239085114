import { useQuery } from "react-query"
import { prependApiUrl, post } from "utils/request"
import useUiError from "hooks/useUiError"

const useContentful = (contentType) => {
  const { setUiError } = useUiError()

  const { data, loading } = useQuery(`${contentType}FromApi`, () => {
    return post(prependApiUrl("contentful"), {
      data: { query: contentType },
    })
      .then((json) => {
        if (json.error) {
          setUiError({ message: json.error })
          return {}
        } else {
          return json
        }
      })
      .catch((err) => {
        return { error: err }
      })
  })

  return { ...(data || {}), loading: loading }
}

export default useContentful
