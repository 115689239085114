import { compose } from "redux"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { actions as cookieActions } from "middlewares/cookies"
import get from "lodash/get"
import { UTM_PARAMS } from "utils/tracking"

const mapDispatchToProps = (dispatch, ownProps) => {
  const { location } = ownProps
  const query = get(location, "query", {})
  const currentUTMParams = UTM_PARAMS.reduce((obj, current) => {
    const currentQuery = get(query, current, null)
    if (currentQuery) {
      const newObj = obj
      newObj[current] = currentQuery
      return obj
    }
    return obj
  }, {})

  return {
    setUTMCookies: () => {
      const keys = Object.keys(currentUTMParams)
      keys.forEach((key) => {
        dispatch(cookieActions.setCookie(key, currentUTMParams[key]))
      })
    },
  }
}

const withUTM = compose(withRouter, connect(undefined, mapDispatchToProps))

export default withUTM
