import { connect } from "react-redux"
import {
  selectCurrentUserRecord,
  selectCurrentUserIsLoading,
} from "ducks/currentUser"

const mapStateToProps = (state) => ({
  currentUser: selectCurrentUserRecord()(state),
  currentUserLoading: selectCurrentUserIsLoading()(state),
})

const withCurrentUser = connect(mapStateToProps)

export default withCurrentUser
