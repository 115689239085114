import React, { memo } from "react"
import styled, { withTheme } from "styled-components"
import PropTypes from "prop-types"
import Slider from "rc-slider"

import Icon from "components/Icon"
import { ICON_SS_VOLUME_HIGH, ICON_SS_MUTE } from "components/Icon/constants"

import "rc-slider/assets/index.css"

const VolumeWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 0 1;
  height: 100%;
  justify-content: flex-start;

  @media (max-width: ${(props) => props.theme.flexboxgrid.breakpoints.md}em) {
    display: none;
  }
`

const SliderWrapper = styled.div`
  width: 80px;
  margin-left: 12px;
  margin-bottom: 3px;
  transition: all ${(props) => props.theme.transitions.quick};

  @media (max-width: ${(props) => props.theme.flexboxgrid.breakpoints.md}em) {
    width: 60px;
    margin-right: 14px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl2}) {
    width: 120px;
  }

  .rc-slider {
    width: 100%;
    height: 100%;
    padding: 0;
    cursor: pointer !important;

    .rc-slider-track {
      background-color: ${(props) => props.theme.colors.text.secondary};
      border-color: ${(props) => props.theme.colors.text.secondary};
    }

    .rc-slider-handle {
      background: ${(props) => props.theme.colors.background.secondary};
      border-color: ${(props) => props.theme.colors.border.dark};
      box-shadow: ${(props) => props.theme.shadows.default};
      cursor: pointer !important;
    }
  }

  &:hover {
    .rc-slider {
      .rc-slider-track {
        background-color: ${(props) => props.theme.colors.background.accent};
        border-color: ${(props) => props.theme.colors.background.accen};
      }
    }
  }
`

const ControlWrapper = styled.div`
  cursor: pointer;
`

const Volume = (props) => {
  const { handleToggleMute, handleVolumeChange, volume, mute, theme } = props

  const handleVolumeChangeOnMute = (value) => {
    if (props.mute) {
      if (value === 0) {
        props.handleVolumeChange(0)
      }
      props.handleToggleMute()
    }
  }

  return (
    <VolumeWrapper>
      <ControlWrapper onClick={handleToggleMute}>
        <Icon
          color={theme.colors.text.secondary}
          hoverColor={theme.colors.text.primary}
          icon={mute || !volume ? ICON_SS_MUTE : ICON_SS_VOLUME_HIGH}
          size={18}
        />
      </ControlWrapper>
      <SliderWrapper>
        <Slider
          onBeforeChange={handleVolumeChangeOnMute}
          onChange={handleVolumeChange}
          value={mute ? 0 : volume * 100}
        />
      </SliderWrapper>
    </VolumeWrapper>
  )
}

Volume.propTypes = {
  handleToggleMute: PropTypes.func.isRequired,
  handleVolumeChange: PropTypes.func.isRequired,
  mute: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
  volume: PropTypes.number.isRequired,
}

export default memo(withTheme(Volume))
