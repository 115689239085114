import styled from "styled-components"
import { typography } from "styled-system"

const TextGradient = styled.span`
  color: ${(props) => props.theme.colors.text.primary};
  margin: 0;
  background-clip: text;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  opacity: 1;
  background: ${(props) => props.theme.colors.gradients.primary};
  &.premium {
    background: ${(props) => props.theme.colors.gradients.premium};
  }
  display: ${(props) => (props.inline ? "inline-block" : "block")};
  ${typography}
`

TextGradient.defaultProps = {
  fontSize: "md",
  lineHeight: "normal",
  fontWeight: "regular",
}

export default TextGradient
