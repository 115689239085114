import { call, put, takeEvery, take, fork, cancel } from "redux-saga/effects"
import {
  _delete,
  get,
  post,
  patch,
  prependApiUrl,
  updatePageAndFilterQueryString,
} from "utils/request"
import omit from "lodash/omit"
import { uploadFileInChunks } from "utils/fileUpload"
import { push } from "react-router-redux"
import { types, actions as apiActions } from "ducks/api"
import { actions as uiActions } from "ducks/ui"
import { actions as infiniteListActions } from "ducks/infiniteList"
import _get from "lodash/get"
import isFunction from "lodash/isFunction"
import qs from "query-string-parser"

export function* readEndpoint(action) {
  try {
    const endpoint = updatePageAndFilterQueryString(action.endpoint, {
      offset: 0,
      limit: action.limit,
      query: null,
    })
    const requestURL = prependApiUrl(endpoint)
    const response = yield call(get, requestURL)
    yield put(apiActions.readSuccess(response))
  } catch (err) {
    yield put(apiActions.requestFailed(err))

    if (err.name === "not_found") {
      yield put(push("/404"))
    } else {
      yield put(uiActions.setError(err))
    }
  }
}

export function* watchReadEndpoint() {
  yield takeEvery(types.READ_ENDPOINT, readEndpoint)
}

export function* createOrUpdateResource({ data, meta = {} }) {
  try {
    let requestURL = prependApiUrl(data.type)
    let method = post
    let uploadChunksMeta = null

    if (data.id) {
      requestURL += `/${data.id}`
      method = patch
    }
    if (meta.url) {
      requestURL += `/${meta.url}`
    }
    if (meta.completeUrl) {
      requestURL = prependApiUrl(meta.completeUrl)
    }
    if (meta.method) {
      method = meta.method
    }
    if (meta.uploadFileInChunks) {
      uploadChunksMeta = {
        file: _get(data, `${meta.uploadFileInChunks.fileKeyPath}`),
        url: requestURL,
        method: data.id ? "PATCH" : "POST",
        data,
        fileKeyPath: `data.${meta.uploadFileInChunks.fileKeyPath}`,
      }
    }
    let options
    if (!meta.omitData) {
      const dataObj = { data }
      options = { data: JSON.stringify(dataObj) }
    }
    const response = meta.uploadFileInChunks
      ? yield call(uploadFileInChunks, uploadChunksMeta)
      : yield call(method, requestURL, options)

    yield put(apiActions.createOrUpdateSuccess(response))
    if (meta.dispatchSuccessFn) {
      yield put(meta.dispatchSuccessFn)
    }
  } catch (err) {
    yield put(uiActions.setError(err))
    yield put(apiActions.requestFailed(err))
    if (meta.dispatchErrorFn) {
      yield put(meta.dispatchErrorFn())
    }
  }
}

export function* watchCreateOrUpdateResource() {
  yield takeEvery(types.CREATE_OR_UPDATE, createOrUpdateResource)
}

export function* appendRelationship(action) {
  try {
    const { record, related, relationship, onSuccess } = action
    const url = prependApiUrl(
      `${record.type}/${record.id}/relationships/${relationship}`
    )
    const data = related.map((r) => ({ type: r.type, id: parseInt(r.id) }))
    const options = {
      data: JSON.stringify({ data }),
    }
    yield call(post, url, options)
    if (onSuccess) yield call(onSuccess)
  } catch (err) {
    yield put(uiActions.setError(err))
    yield put(apiActions.requestFailed(err))
  }
}

export function* watchAppendRelationship() {
  yield takeEvery(types.APPEND_RELATIONSHIP, appendRelationship)
}

export function* deleteRecord(action) {
  try {
    const record = action.record
    const requestURL = prependApiUrl(`${record.type}/${record.id}`)
    yield call(_delete, requestURL, null)
    yield put(apiActions.deleteSuccess(record))
    if (action.meta.success && isFunction(action.meta.success)) {
      action.meta.success()
    }
  } catch (err) {
    yield put(uiActions.setError(err))
    yield put(apiActions.requestFailed(err))
  }
}

export function* watchDeleteRecord() {
  yield takeEvery(types.DELETE, deleteRecord)
}

export const SET_QUERY = "soundstripe/searchQuery/SET_QUERY"

// utility fn to cause delay waiting for other saga events to occur
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export function* searchQuery(action) {
  // cut the forward slash off the endpoint if necessary
  const formattedEndpoint =
    action.endpoint[0] === "/" ? action.endpoint.substring(1) : action.endpoint
  try {
    yield call(delay, 500)
    const oldQuery = qs.fromQuery(action.currentFilters)
    let newQuery = {}
    const query = action.query.trim()
    if (query) {
      newQuery = {
        ...oldQuery,
        filter: {
          ...oldQuery.filter,
          q: query,
        },
      }
    } else {
      newQuery = omit(oldQuery, "q")
    }
    const newQueryString = qs.toQuery(newQuery)
    const endpointWithCurrentFilters = `${formattedEndpoint}?${newQueryString}`
    const endpoint = updatePageAndFilterQueryString(
      endpointWithCurrentFilters,
      {
        offset: 0,
        limit: action.limit,
      }
    )
    yield put(infiniteListActions.create("songs", endpoint, action.limit))

    // This function handles updating the query params and MixPanel tracking for typed queries.
    action.updateQueryParamsCallbackFn(action.query)
  } catch (error) {
    console.error("Search query saga fail:", error)
  }
}

export function* watchSearchQuery() {
  let previousQuery = null
  let task
  while (true) {
    // got this from a Dan Abv post https://github.com/yelouafi/friend-list/blob/fe2624d6f04806efbe5c8e2970fdae458f005874/redux-saga-solution/sagas/index.js
    const action = yield take(types.QUERY_ENDPOINT)
    if (action.query.trim() !== previousQuery) {
      if (task) yield cancel(task)
      task = yield fork(searchQuery, action)
      previousQuery = action.query.trim()
    }
  }
}

export default [
  watchReadEndpoint,
  watchCreateOrUpdateResource,
  watchAppendRelationship,
  watchDeleteRecord,
  watchSearchQuery,
]
