import Immutable from "immutable"
import { secondsToMMSS } from "utils/formatting"
import { resolutionHierarchyOrder } from "utils/video"

import { createRelationships } from "records/Relationship"

const VideoRecord = Immutable.Record(
  {
    id: null,
    type: "video",
    // Attributes
    aerial: false,
    archived: false,
    artist_name: "",
    camera: "",
    categories: [],
    created_at: "",
    description: "",
    file_size: 0,
    file_type: "",
    fps: 0,
    height: 0,
    keywords: [],
    number_female: 0,
    number_male: 0,
    number_of_people: 0,
    objectID: "",
    preview_still: "",
    published: false,
    seamless_looping: false,
    title: "",
    versions: [],
    video_type: "HD",
    watermarked_video: "",
    width: 0,
    video_encodings: [],

    // Relationships
    relationships: {
      video_encodings: [],
    },
  },
  "Video"
)

class Video extends VideoRecord {
  constructor(args) {
    super(
      Object.assign({}, args, {
        relationships: createRelationships(args.relationships || {}, {
          video_encodings: ["video_encodings", "video_encoding"],
        }),
      })
    )
  }

  highestDefinition = () => {
    if (!this.versions.length) {
      return "HD"
    }
    const rankedVersions = this.versions
      .map((version) => ({
        ...version,
        rank: resolutionHierarchyOrder[version.size],
      }))
      .sort((a, b) => (a.rank > b.rank ? 1 : -1))
    return rankedVersions[0].size
  }

  duration = () =>
    this.versions[0] ? secondsToMMSS(this.versions[0].duration) : ""
}

export default Video
