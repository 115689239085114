import { Record, fromJS, Map } from "immutable"
import { createSelector } from "reselect"
import { selectRecordsByType } from "ducks/api"
import { selectInfiniteListState } from "ducks/infiniteList"
import { selectAlgoliaListState } from "ducks/algoliaList"
import { selectListState } from "ducks/list"
import { createAction } from "./utils"

const storeJS = require("utils/store.min.js")

export const types = {
  CHANGE_VOLUME: "soundstripe/marketplaceAudioPlayer/CHANGE_VOLUME",
  ENDED: "soundstripe/marketplaceAudioPlayer/ENDED",
  NEXT: "soundstripe/marketplaceAudioPlayer/NEXT",
  PLAY: "soundstripe/marketplaceAudioPlayer/PLAY",
  PREV: "soundstripe/marketplaceAudioPlayer/PREV",
  PROGRESS: "soundstripe/marketplaceAudioPlayer/PROGRESS",
  SEEK: "soundstripe/marketplaceAudioPlayer/SEEK",
  PAUSE: "soundstripe/marketplaceAudioPlayer/PAUSE",
  RESUME: "soundstripe/marketplaceAudioPlayer/RESUME",
  TOGGLE_PAUSE: "soundstripe/marketplaceAudioPlayer/TOGGLE_PAUSE",
  STOP: "soundstripe/marketplaceAudioPlayer/STOP",
  SHUFFLE: "soundstripe/marketplaceAudioPlayer/SHUFFLE",
}

export const actions = {
  changeVolume: createAction(types.CHANGE_VOLUME, "volume"),
  ended: createAction(types.ENDED, "data"),
  next: createAction(types.NEXT),
  play: createAction(types.PLAY, "data"),
  prev: createAction(types.PREV),
  progress: createAction(types.PROGRESS, "pos"),
  seek: createAction(types.SEEK, "seekPos"),
  pause: createAction(types.PAUSE),
  resume: createAction(types.RESUME),
  togglePause: createAction(types.TOGGLE_PAUSE),
  stop: createAction(types.STOP),
  shuffle: createAction(types.SHUFFLE, "data"),
}

export const MarketplaceAudioPlayerRecord = Record(
  {
    ended: false,
    playing: false,
    playerVisible: false,
    pos: 0,
    seekPos: null,
    radioIndex: null,
    radioPath: null,
    songId: null,
    audioFileId: null,
    tunedGlobalId: null,
    parentSongId: null,
    childSongRank: 0,
    volume: storeJS.get("marketplaceAudioPlayerVolume") || 1,
    listStoreKey: null,
    playlistId: null,
    projectItemId: null,
    shuffled: false,
  },
  "MarketplaceAudioPlayer"
)

const initialState = new MarketplaceAudioPlayerRecord()

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_VOLUME:
      storeJS.set("marketplaceAudioPlayerVolume", action.volume)
      return state.set("volume", action.volume)

    case types.NEXT:
    case types.PREV:
      return state.set("ended", false)

    case types.PLAY:
      return state.merge(fromJS(action.data)).set("ended", false)

    case types.PROGRESS:
      return state.set("pos", action.pos)

    case types.SEEK:
      return state.set("seekPos", action.seekPos)

    case types.PAUSE:
      return state.set("playing", false)

    case types.RESUME:
      return state.set("playing", true)

    case types.TOGGLE_PAUSE:
      return state
        .set("playerVisible", true)
        .set("playing", !state.get("playing"))

    case types.ENDED:
      return state.merge(fromJS(action.data))

    case types.STOP:
      return state.set("playerVisible", false).set("playing", false)

    case types.SHUFFLE:
      return state.set("shuffled", !state.get("shuffled"))

    default:
      return state
  }
}

const marketplaceAudioPlayerState = (state) =>
  state.getIn(["ducks", "marketplaceAudioPlayer"])
const selectSongId = (state) =>
  state.getIn(["ducks", "marketplaceAudioPlayer", "songId"])
const selectAudioFileId = (state) =>
  state.getIn(["ducks", "marketplaceAudioPlayer", "audioFileId"])
const selectPlaylistId = (state) =>
  state.getIn(["ducks", "marketplaceAudioPlayer", "playlistId"])
const selectListStoreKey = (state) =>
  state.getIn(["ducks", "marketplaceAudioPlayer", "listStoreKey"])
const selectRadioIndex = (state) =>
  state.getIn(["ducks", "marketplaceAudioPlayer", "radioIndex"])

export const selectMarketplaceAudioPlayer = () =>
  createSelector(marketplaceAudioPlayerState, (apState) => apState)

export const selectSongMarketplaceAudioPlayerIsPlaying = () =>
  createSelector(
    marketplaceAudioPlayerState,
    (marketplaceAudioPlayer) => marketplaceAudioPlayer.playing
  )

export const selectPlayerVisible = () =>
  createSelector(
    marketplaceAudioPlayerState,
    (marketplaceAudioPlayer) => marketplaceAudioPlayer.playerVisible
  )

export const selectMarketplaceAudioPlayerShuffled = () =>
  createSelector(
    marketplaceAudioPlayerState,
    (marketplaceAudioPlayer) =>
      marketplaceAudioPlayer.shuffled &&
      marketplaceAudioPlayer.listStoreKey.includes("playlist_songs")
  )

export const selectMarketplaceAudioPlayerSong = () =>
  createSelector(
    [selectSongId, selectRecordsByType("marketplace_songs")],
    (songId, recordsState) => (recordsState ? recordsState.get(songId) : null)
  )

export const selectMarketplaceAudioPlayerAudioFile = () =>
  createSelector(
    [selectAudioFileId, selectRecordsByType("marketplace_audio_files")],
    (audioFileId, recordsState) =>
      recordsState ? recordsState.get(audioFileId) : null
  )

export const selectMarketplaceAudioPlayerPlaylist = () =>
  createSelector(
    [selectPlaylistId, selectRecordsByType("marketplace_playlists")],
    (playlistId, recordsState) =>
      recordsState ? recordsState.get(playlistId) : null
  )

export const selectMarketplaceAudioPlayerSimilarParent = () =>
  createSelector(selectAlgoliaListState, (algoliaState) =>
    algoliaState.get("similar_song_parent")?.data.get(0)
  )

// If there is a storeKey name clash the list will be set to the InfiniteList.
export const selectMarketplaceAudioPlayerList = () =>
  createSelector(
    [
      selectListStoreKey,
      selectInfiniteListState,
      selectAlgoliaListState,
      selectListState,
    ],
    (listStoreKey, infListState, algoliaState, listState) => {
      const storeKey =
        algoliaState.get(listStoreKey) ||
        infListState.get(listStoreKey) ||
        listState.get(listStoreKey)
      return storeKey
    }
  )

export const selectMarketplaceAudioPlayerCursor = () =>
  createSelector(
    [
      selectMarketplaceAudioPlayerSimilarParent(),
      selectMarketplaceAudioPlayerSong(),
      selectMarketplaceAudioPlayerList(),
      selectRadioIndex,
      selectMarketplaceAudioPlayerShuffled(),
    ],
    (
      similarParent,
      marketplaceAudioPlayerSong,
      list,
      playerRadioIndex,
      shuffled
    ) => {
      if (!list || playerRadioIndex == null) {
        return null
      }
      let current = null
      let next = null
      let prev = null

      const listToPlay = shuffled ? list?.shuffledData : list?.data
      const radioIndex = listToPlay.indexOf(
        marketplaceAudioPlayerSong.get("id")
      )
      prev = radioIndex > 0 ? listToPlay.get(radioIndex - 1) : similarParent

      if (similarParent === marketplaceAudioPlayerSong.get("id")) {
        current = marketplaceAudioPlayerSong.get("id")
        next = list.data.get(0)
      } else {
        current = listToPlay.get(radioIndex)
        next = listToPlay.get(radioIndex + 1)
      }

      return new Map({
        prev,
        current,
        next,
      })
    }
  )
