import { fromJS, Map, OrderedMap } from "immutable"
import { createSelector } from "reselect"
import { createRecordsFromResponse } from "records/util"
import { createAction } from "./utils"
import { clearRecords, insertOrUpdateRecords } from "./utils/api"

export const types = {
  READ_ENDPOINT: "soundstripe/api/READ_ENDPOINT",
  READ_SUCCESS: "soundstripe/api/READ_SUCCESS",
  QUERY_ENDPOINT: "soundstripe/api/QUERY_ENDPOINT",
  CREATE_OR_UPDATE: "soundstripe/api/CREATE_OR_UPDATE",
  CREATE_OR_UPDATE_SUCCESS: "soundstripe/api/CREATE_OR_UPDATE_SUCCESS",
  APPEND_RELATIONSHIP: "soundstripe/api/APPEND_RELATIONSHIP",
  DELETE: "soundstripe/api/DELETE",
  DELETE_SUCCESS: "soundstripe/api/DELETE_SUCCESS",
  REQUEST_FAILED: "soundstripe/api/REQUEST_FAILED",
  INSERT_RECORD: "soundstripe/api/INSERT_RECORD",
  CLEAR_RECORDS: "soundstripe/api/CLEAR_RECORDS",
}

export const actions = {
  readEndpoint: createAction(types.READ_ENDPOINT, "endpoint"),
  readSuccess: createAction(types.READ_SUCCESS, "response"),
  queryEndpoint: createAction(
    types.QUERY_ENDPOINT,
    "endpoint",
    "query",
    "currentFilters",
    "updateQueryParamsCallbackFn"
  ),
  /**
   * meta possible shape
   * {
   *  url: string url for the api request,
   *  method: utils method for the api request,
   *  omitData: bool to omit data for the post or put,
   *  dispatchSuccessFn: redux function that will be dispatched on api success
   *  dispatchErrorFn: redux function that will be dispatched on api error
   * }
   */
  createOrUpdateResource: createAction(types.CREATE_OR_UPDATE, "data", "meta"),
  createOrUpdateSuccess: createAction(
    types.CREATE_OR_UPDATE_SUCCESS,
    "response",
    "meta"
  ),
  appendRelationship: createAction(
    types.APPEND_RELATIONSHIP,
    "record",
    "relationship",
    "related",
    "onSuccess"
  ),
  deleteResource: createAction(types.DELETE, "record", "meta"),
  deleteSuccess: createAction(types.DELETE_SUCCESS, "record"),
  requestFailed: createAction(types.REQUEST_FAILED, "error"),
  insertRecord: createAction(types.INSERT_RECORD, "record"),
  clearRecords: createAction(types.CLEAR_RECORDS, "recordsType"),
}

const initialState = fromJS({
  isLoading: false,
  error: null,
  records: {},
  endpoint: "",
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.READ_ENDPOINT:
    case types.QUERY_ENDPOINT:
    case types.CREATE_OR_UPDATE:
    case types.DELETE: {
      return state.set("isLoading", true).set("endpoint", action.endpoint)
    }

    case types.READ_SUCCESS:
    case types.CREATE_OR_UPDATE_SUCCESS: {
      const immRecords = createRecordsFromResponse(action.response)
      return insertOrUpdateRecords(state, immRecords).set("isLoading", false)
    }

    case types.DELETE_SUCCESS: {
      return state
        .deleteIn(["records", action.record.type, action.record.id])
        .set("isLoading", false)
    }

    case types.REQUEST_FAILED: {
      return state.set("isLoading", false).set("error", action.error)
    }

    case types.INSERT_RECORD: {
      return insertOrUpdateRecords(state, action.record)
    }

    case types.CLEAR_RECORDS: {
      return clearRecords(state, action.recordsType)
    }
    default: {
      return state
    }
  }
}

const selectApiState = (state) => state.getIn(["ducks", "api"])
const selectApiRecordsState = (state) =>
  state.getIn(["ducks", "api", "records"])

export const selectApiLoading = (endpoint) =>
  createSelector(
    selectApiState,
    (apiState) =>
      apiState.get("endpoint") === endpoint && apiState.get("isLoading")
  )

export const selectRecordsByType = (type) =>
  createSelector(selectApiRecordsState, (recordState) => recordState.get(type))

export const selectRecordsByTypeAndFn = (type, fn) =>
  createSelector(selectRecordsByType(type), (recordTypeState) =>
    recordTypeState ? recordTypeState.takeWhile((x) => fn(x)) : null
  )

export const selectRecordById = (type, id) =>
  createSelector(selectRecordsByType(type), (recordTypeState) =>
    recordTypeState ? recordTypeState.get(id) : null
  )

export const selectRecordByAttr = (type, attr, param) =>
  createSelector(selectRecordsByType(type), (recordTypeState) =>
    recordTypeState ? recordTypeState.find((x) => x[attr] === param) : null
  )

export const selectRecordsByTypeAndIds = (type, ids) =>
  createSelector(selectRecordsByType(type), (recordTypeState) =>
    recordTypeState
      ? recordTypeState.filter((record) => ids.includes(record.id))
      : null
  )

export const selectRecordByTypeAndFn = (type, fn) =>
  createSelector(selectRecordsByType(type), (recordTypeState) =>
    recordTypeState ? recordTypeState.find((x) => fn(x)) : null
  )

export const selectRelationshipRecordsByType = (type, id, relType, relKey) =>
  createSelector(
    selectRecordById(type, id),
    selectRecordsByType(relType),
    (record, relRecords) => {
      if (!record || !relRecords) {
        return null
      }
      const relObjects =
        record.relationships.get(relKey || relType) || new Map({})
      const ids = []
      relObjects.map((obj) => ids.push(obj.id))
      return relRecords.filter((rec) => ids.includes(rec.id))
    }
  )

export const selectOrderedRelationshipRecordsByType = (type, id, relType) =>
  createSelector(
    selectRecordById(type, id),
    selectRecordsByType(relType),
    (record, relRecords) => {
      if (!record || !relRecords) return null

      const relObjects = record.relationships.get(relType) || new OrderedMap({})
      const ids = []
      relObjects.map((obj) => ids.push(obj.id))
      return new OrderedMap({}).withMutations((orderedMap) => {
        ids.forEach((k) => {
          orderedMap.set(k, relRecords.get(k))
        })
      })
    }
  )
