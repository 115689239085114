import { css } from "styled-components"

const headingVariants = css`
  &.xl3 {
    font-size: ${({ theme }) => theme.fontSizes.xl2};
    line-height: 1.25;

    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: ${({ theme }) => theme.fontSizes.xl3};
      line-height: 1.2;
    }
  }

  &.xl2 {
    font-size: 1.625rem;
    line-height: 1.23077;

    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: ${({ theme }) => theme.fontSizes.xl2};
      line-height: 1.25;
    }
  }

  &.xl {
    font-size: ${({ theme }) => theme.fontSizes.xl};
    line-height: 1.33333;
  }

  &.lg {
    font-size: ${({ theme }) => theme.fontSizes.lg};
    line-height: 1.4;
  }

  &.md {
    font-size: ${({ theme }) => theme.fontSizes.md};
    line-height: 1.5;
  }
`

export default headingVariants
