import React from "react"
import { actions as modalActions } from "ducks/modal"
import { useDispatch } from "react-redux"
import Button from "components/Button/New"
import { ICON_SS_CART } from "components/Icon/constants"
import useMarketplaceMixpanelTracking from "hooks/useMarketplaceMixpanelTracking"
import { loggedIn } from "utils/authentication"
import { TIERS } from "components/MarketplaceSong/constants"

const LicenseButton = ({ song }) => {
  const dispatch = useDispatch()
  const { trackMarketplaceMixpanel } = useMarketplaceMixpanelTracking()

  const openSignUpModal = (data) => {
    dispatch(modalActions.open("SignUpModal", data))
  }

  const openLicenseModal = () => {
    const slideout = song.popular()
      ? "MultiDistributionLicenseSlideout"
      : "MarketTieredPricingSlideout"

    dispatch(modalActions.open(slideout, { song: song }))
  }

  const handleLicenseSong = () => {
    trackMarketplaceMixpanel("Clicked Element", {
      context: "License Song",
      "Song ID": song.id,
      "Song Title": song.title,
    })

    loggedIn()
      ? openLicenseModal()
      : openSignUpModal({
          action: "marketplace_license",
          afterSignup: openLicenseModal,
        })
  }

  return (
    <Button
      onClick={handleLicenseSong}
      appearance="primary"
      icon={ICON_SS_CART}
      minWidth="114px"
    >
      {song.popular() && TIERS[song.song_tier]}
    </Button>
  )
}

export default LicenseButton
