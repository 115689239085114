import styled from "styled-components"

import ArtistName from "./ArtistName"

const ArtistCredits = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

ArtistCredits.ArtistName = ArtistName

export default ArtistCredits
