import { useEffect, useState } from "react"

const storeJS = require("utils/store.min.js")

const useLocalStorage = (key) => {
  const [state, setState] = useState(storeJS.get(key))
  // If we change local storage key, trigger re-render
  useEffect(() => {
    setState(storeJS.get(key))
  }, [key])
  return {
    value: state,
    set: (newValue) => {
      storeJS.set(key, newValue)
      setState(newValue)
    },
    remove: () => {
      storeJS.remove(key)
      setState(null)
    },
  }
}

export default useLocalStorage
