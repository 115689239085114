import Immutable from "immutable"
import pluralize from "pluralize"
import get from "lodash/get"
import includes from "lodash/includes"
import isArray from "lodash/isArray"

// Given an array of relationships and whitelistedTypes, this sanitizes
// relationships so that blacklisted types don't make their way into the
// returned array of Relationship records.
const shouldBeArray = (word) => {
  const plural = pluralize.plural(word)
  if (plural === word) {
    return true
  }
  return false
}

const isWhitelisted = (data, whitelist, isAry) => {
  if (isAry) {
    return !!data
      .map((d) => (includes(whitelist, d.type) ? 1 : 0))
      .reduce((sum, current) => sum + current, 0)
  }

  // This returns false for a null data variable
  return includes(whitelist, get(data, "type", ""))
}

export const createRelationships = (relationships, whitelistedTypes) => {
  const keys = Object.keys(relationships)
  return new Immutable.Map({}).withMutations((relationshipRecords) => {
    keys.forEach((k) => {
      const hasDataProp = relationships[k].hasOwnProperty("data")

      if (relationships[k] && !whitelistedTypes[k] && hasDataProp) {
        if (k !== "session") {
          console.warn(`Relationship ${k} found but not whitelisted.`)
        }
      } else if (relationships[k] && whitelistedTypes[k]) {
        const data = get(relationships, `${k}.data`)
        const isAry = isArray(data)
        const containsWhitelistedType = isWhitelisted(
          data,
          whitelistedTypes[k],
          isAry
        )
        if (!data) {
          relationshipRecords.set(k, data)
        }

        // For plural relationships (data is an array of relationships)
        if (shouldBeArray(k) && isAry && containsWhitelistedType) {
          const relationshipMap = new Immutable.List(
            data.map((d) => new Relationship(d))
          )
          // Should we only be updating not overwriting the object?
          relationshipRecords.set(k, relationshipMap)
        }

        // For singular relationships (data is an object)
        if (!shouldBeArray(k) && !isAry && containsWhitelistedType) {
          const relationship = new Relationship(data)
          // Should we only be updating not overwriting the object?
          relationshipRecords.set(k, relationship)
        }
      }
    })
  })
}

const RelationshipRecord = Immutable.Record(
  {
    type: "",
    id: null,
  },
  "Relationship"
)

class Relationship extends RelationshipRecord {}

export default Relationship
