import Immutable from "immutable"

const ReferralRecord = Immutable.Record(
  {
    id: null,
    type: "referrals",

    // Attributes
    amount_to_credit: 0,
    credited_at: "",
    credits_available_at: "",
    referred_user_name: "",
  },
  "Referral"
)

class Referral extends ReferralRecord {
  constructor(args) {
    super(Object.assign({}, args))
  }
}

export default Referral
