import styled from "styled-components"
import { color, space, typography } from "styled-system"

const H5 = styled.h5`
  ${color}
  ${space}
  ${typography}
  text-transform: capitalize;
`

H5.defaultProps = {
  color: "text.primary",
  fontWeight: "500",
  fontSize: { _: "sm", md: "md" },
  lineHeight: "1.5",
}

export default H5
