import styled from "styled-components"

const Name = styled.div`
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  letter-spacing: ${({ theme }) => theme.letterSpacings[1]};
  line-height: 1.25rem;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.played {
    color: ${({ theme }) => theme.colors.text.played};
  }

  &.disabled {
    cursor: unset;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl2}) {
    &:not(.condensedLayout)&:not(.disabled) {
      font-size: ${({ theme }) => theme.fontSizes.md};
      letter-spacing: ${({ theme }) => theme.letterSpacings[1]};
      line-height: 1.5rem;
    }
  }

  &:hover,
  &:focus {
    &.disabled {
      text-decoration: none;
    }
  }
`

export default Name
