import { css } from "styled-components"

/* Prettier is being dumb */

const inputStyles = css`
  background: ${(props) => props.theme.input.bgColor};
  border: ${(props) => props.theme.input.border};
  border-radius: ${(props) => props.theme.radii.default};
  color: ${(props) => props.theme.colors.text.primary};
  font-family: inherit;
  font-size: ${(props) => props.theme.fontSizes.md};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  line-height: 1.5rem;
  outline: 0;
  padding: ${(props) => props.theme.space[3]};
  transition: all ${(props) => props.theme.transitions.quick};
  width: 100%;

  ::placeholder {
    color: ${(props) => props.theme.input.placeholderColor};
    font-size: ${(props) => props.theme.fontSizes.md};
    line-height: 1.5rem;
    font-weight: ${(props) => props.theme.fontWeights.regular};
    opacity: 1;
    transition: all ${(props) => props.theme.transitions.quick};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
    -webkit-text-fill-color: ${(props) => props.theme.colors.text.primary};
    background: ${(props) => props.theme.input.bgColor};
  }

  &:focus {
    border: ${(props) => props.theme.input.borderFocus};
    outline: currentColor;
  }

  &.error {
    border: ${(props) => props.theme.input.borderError};
    box-shadow: none;
  }

  &:disabled {
    background-color: ${(props) => props.theme.input.disabledBgColor};
    color: ${(props) => props.theme.colors.text.disabled};
    border-color: ${(props) => props.theme.colors.border.default};

    ::placeholder {
      color: ${(props) => props.theme.input.disabledPlaceholder};
    }
  }

  /* This is to disable auto zooming on mobile views https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone */
  @media only screen and (max-width: 48em) {
    font-size: 1em;
  }

  @keyframes onAutoFillStart {
    from {
      /**/
    }
    to {
      /**/
    }
  }

  @keyframes onAutoFillCancel {
    from {
      /**/
    }
    to {
      /**/
    }
  }

  /* Disable autofill styles on Chrome */

  &:-webkit-autofill {
    animation-name: onAutoFillStart;
    transition: background-color 50000s ease-in-out 0s;
  }

  &:not(:-webkit-autofill) {
    animation-name: onAutoFillCancel;
  }

  &.input-with-button {
    border-radius: ${(props) => props.theme.radii.default} 0px 0px
      ${(props) => props.theme.radii.default};
  }

  &.no-border {
    border: 0px;
    padding: 0px;
  }

  &.small {
    padding: ${(props) => props.theme.space[2]}
      ${(props) => props.theme.space[3]};
    font-size: ${(props) => props.theme.fontSizes.sm};
  }

  &.license-flow {
    font-size: ${(props) => props.theme.fontSizes.md};
    font-weight: ${(props) => props.theme.fontWeights.medium};

    ::placeholder {
      font-size: ${(props) => props.theme.fontSizes.md};
      font-weight: ${(props) => props.theme.fontWeights.medium};
    }

    @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
      font-size: ${(props) => props.theme.fontSizes.sm} !important;

      ::placeholder {
        font-size: ${(props) => props.theme.fontSizes.sm} !important;
      }
    }
  }
`

export default inputStyles
