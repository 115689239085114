import styled from "styled-components"
import { withScreenSize } from "hocs/withScreenSize"

const Waveform = styled.div`
  align-items: center;
  display: none;
  flex: 1 0 72px;
  height: 100%;
  justify-content: start;
  margin: 0 ${({ theme }) => theme.space[2]};
  width: 48px;
  min-width: 210px;
  max-width: 300px;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: flex;
    min-width: ${({ inProjectRow, widthInEms }) =>
      inProjectRow && widthInEms >= 80 ? "246px" : "210px"};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    min-width: ${({ inProjectRow }) => (inProjectRow ? "300px" : "auto")};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl2}) {
    margin: 0 ${({ theme }) => theme.space[5]};
  }
`

export default withScreenSize(Waveform)
