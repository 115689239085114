import Immutable from "immutable"
import { createRelationships } from "records/Relationship"

const PlaylistCategoryPlaylistRecord = Immutable.Record(
  {
    id: null,
    type: "playlist_categories",

    // Attributes
    playlist_id: null,
    position: null,

    // Relationships
    relationships: {
      playlist: {},
    },
  },
  "PlaylistCategoryPlaylist"
)

const relationshipTypes = {
  playlist: ["playlists"],
}

class PlaylistCategoryPlaylist extends PlaylistCategoryPlaylistRecord {
  constructor(args) {
    super(
      Object.assign({}, args, {
        relationships: createRelationships(
          args.relationships,
          relationshipTypes
        ),
      })
    )
  }
}

export default PlaylistCategoryPlaylist
