import PropTypes from "prop-types"
import React from "react"
import {
  CheckboxContainer,
  CheckboxLabel,
  HiddenCheckbox,
  StyledCheckbox,
} from "./styles"
import Icon from "components/Icon"
import {
  ICON_SS_CHECKBOX_CHECKED,
  ICON_SS_CHECKBOX_EXCLUDED,
  ICON_SS_CHECKBOX_INDETERMINATE,
} from "components/Icon/constants"

const Checkbox = ({
  ariaLabel,
  checked,
  children,
  className,
  color,
  error,
  isExcluded,
  isIndeterminate,
  labelFontWeight,
  size,
  onClick,
  theme,
  value,
  disabled,
  wrapText,
  ...props
}) => {
  const handleOnClick = (event) => {
    if (!disabled) {
      onClick?.(event)
    }
  }

  let icon = null
  if (checked && !isExcluded && !isIndeterminate) {
    icon = ICON_SS_CHECKBOX_CHECKED
  } else if (isExcluded) {
    icon = ICON_SS_CHECKBOX_EXCLUDED
  } else if (isIndeterminate) {
    icon = ICON_SS_CHECKBOX_INDETERMINATE
  }

  return (
    <CheckboxContainer
      as="label"
      onClick={handleOnClick}
      className={(className, !wrapText && "noWrap")}
      disabled={disabled}
      {...props}
    >
      <HiddenCheckbox
        type="checkbox"
        value={value || ""}
        disabled={disabled}
        aria-disabled={disabled}
        aria-invalid={error && true}
        aria-label={ariaLabel}
      />
      <StyledCheckbox
        onClick={(event) => event.stopPropagation()}
        role="checkbox"
        aria-hidden="true"
        checked={checked}
        disabled={disabled}
        error={error}
        size={size}
        data-active={checked || isExcluded || isIndeterminate}
        data-checked={checked}
        data-disabled={disabled}
        data-error={error && true}
        data-excluded={isExcluded}
        data-indeterminate={isIndeterminate}
      >
        {icon && <Icon icon={icon} color="currentColor" />}
      </StyledCheckbox>
      {children && (
        <CheckboxLabel
          onClick={(event) => event.stopPropagation()}
          as="span"
          className={!wrapText && "noWrap"}
          data-disabled={disabled}
          labelFontWeight={labelFontWeight}
          labelSize={size}
          fontWeight={labelFontWeight}
        >
          {children}
        </CheckboxLabel>
      )}
    </CheckboxContainer>
  )
}

Checkbox.propTypes = {
  ariaLabel: PropTypes.string,
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      name: PropTypes.string,
    }),
    PropTypes.string,
  ]),
  isExcluded: PropTypes.bool,
  isIndeterminate: PropTypes.bool,
  labelFontWeight: PropTypes.string,
  size: PropTypes.string,
  value: PropTypes.any,
  wrapText: PropTypes.bool,
}

Checkbox.defaultProps = {
  labelFontWeight: "normal",
  size: "md",
  wrapText: true,
}

export default Checkbox
