import PropTypes from "prop-types"
import { useEffect } from "react"
import { withRouter } from "react-router"
import useHubspotTracking from "hooks/useHubspotTracking"
import useCurrentUser from "hooks/useCurrentUser"

const AnalyticsListener = ({ router }) => {
  const { sendPage, identifyUser } = useHubspotTracking()
  const { currentUser } = useCurrentUser()

  useEffect(() => {
    hubSpotSendPageView(router?.location)
    router?.listen(hubSpotSendPageView)
  }, [])

  useEffect(() => {
    identifyUser({ email: currentUser.email })
  }, [currentUser.email])

  const hubSpotSendPageView = (location) => {
    if (location && location.pathname) {
      sendPage(location.pathname)
    }
  }

  return null
}

AnalyticsListener.propTypes = {
  router: PropTypes.object,
}

export default withRouter(AnalyticsListener)
