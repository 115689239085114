import React from "react"
import styled from "styled-components"
import useCurrentUser from "hooks/useCurrentUser"
import Box from "components/Box"

const Banner = styled.div`
  align-items: center;
  background: ${(props) => props.theme.colors.yellow200};
  color: ${(props) => props.theme.colors.black};
  display: flex;
  height: ${(props) => props.theme.sizes.elements.appBanner.height.mobile};
  justify-content: center;
  padding: ${(props) => props.theme.space[1]} ${(props) => props.theme.space[2]};
  position: sticky;
  top: 0;
  width: ${(props) => props.theme.sizes.container.full};
  z-index: ${(props) => props.theme.zIndices.fixed};

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    height: ${(props) => props.theme.sizes.elements.appBanner.height.desktop};
  }
`

const Text = styled.div`
  font-size: ${(props) => props.theme.fontSizes.md};
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const TestAccountNotice = () => {
  const { currentUser } = useCurrentUser()

  if (currentUser.test_user) {
    return (
      <Banner>
        <Box
          alignItems="center"
          borderRadius="circle"
          display="flex"
          flexShrink={0}
          height="20px"
          justifyContent="center"
          position="relative"
          width="20px"
          color="black"
          border="1px solid"
          borderColor="black"
          marginRight={2}
        >
          !
        </Box>

        <Text>
          You are logged into a test account. Chargebee (payments, estimates,
          etc) will not function!
        </Text>
      </Banner>
    )
  }

  return null
}

export default TestAccountNotice
