import { fromJS } from "immutable"
import { createSelector } from "reselect"
import { createAction } from "./utils"

export const types = {
  CHECKOUT_FORM_SUBMIT: "soundstripe/marketplace/CHECKOUT_FORM_SUBMIT",
  PAYMENT_REQUEST_BUTTON_SUBMIT:
    "soundstripe/marketplace/PAYMENT_REQUEST_BUTTON_SUBMIT",
  PAYMENT_REQUEST_BUTTON_SUCCESS:
    "soundstripe/marketplace/PAYMENT_REQUEST_BUTTON_SUCCESS",
}

export const actions = {
  checkoutFormSubmit: createAction(
    types.CHECKOUT_FORM_SUBMIT,
    "form",
    "checkoutAttributes",
    "chargebeeCard"
  ),
  paymentRequestButtonSubmit: createAction(
    types.PAYMENT_REQUEST_BUTTON_SUBMIT,
    "checkoutAttributes",
    "authorizedPaymentIntent"
  ),
  paymentRequestButtonSuccess: createAction(
    types.PAYMENT_REQUEST_BUTTON_SUCCESS
  ),
}

const initialState = fromJS({
  isLoading: false,
  stripePaymentSubmitting: false,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.MARKETPLACE_SONG_CHECKOUT_FORM_SUBMIT:
      return state.set("isLoading", true)
    case types.PAYMENT_REQUEST_BUTTON_SUBMIT:
      return state.set("stripePaymentSubmitting", true).set("isLoading", true)
    case types.PAYMENT_REQUEST_BUTTON_SUCCESS:
      return state.set("stripePaymentSubmitting", false)
    default:
      return state
  }
}

const selectMarketplaceState = (state) => state.getIn(["ducks", "marketplace"])

export const selectMarketplaceStripePaymentSubmitting = () =>
  createSelector(selectMarketplaceState, (marketplaceState) =>
    marketplaceState.get("stripePaymentSubmitting")
  )
