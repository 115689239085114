import styled, { css } from "styled-components"
import { removeScrollbar } from "containers/App/style-utils"

export const SidebarWrapper = styled.nav`
  ${removeScrollbar}
  background-color: ${(props) => props.theme.colors.background.layered};
  display: block;
  height: 100vh;
  left: 0;
  overflow: auto;
  padding-bottom: ${(props) => props.theme.space[9]};
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${(props) => props.theme.zIndices.popup};

  @media print {
    display: none;
  }
`

export const SidebarInner = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.divider};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: ${(props) => props.theme.space[4]};
  padding: 0 ${(props) => props.theme.space[6]}
    ${(props) => props.theme.space[4]};
  width: 100%;

  &:last-of-type {
    border-bottom: 0;
  }
`

export const sidebarLinkStyles = css`
  color: ${(props) => props.theme.colors.text.primary};
  font-size: ${(props) => props.theme.fontSizes.md};
  font-weight: ${(props) => props.theme.fontWeights.medium};
  line-height: 1.5rem;
  padding: ${(props) => props.theme.space[2]};
  text-align: left;
  text-decoration: none;
  user-select: none;

  &.primary {
    font-size: ${(props) => props.theme.fontSizes.xl};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    line-height: 1.75rem;
  }

  &.subtle {
    color: ${(props) => props.theme.colors.text.secondary};
  }

  &.offer {
    color: ${(props) => props.theme.colors.brand.pinkJoy};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
  }

  &.pinkJoy {
    color: ${(props) => props.theme.colors.brand.pinkJoy};
  }
`

export const LinkGroupHeader = styled.button`
  align-items: center;
  color: ${(props) => props.theme.colors.text.primary};
  display: inline-flex;
  font-size: ${(props) => props.theme.fontSizes.md};
  font-weight: ${(props) => props.theme.fontWeights.semiBold};
  justify-content: flex-start;
  line-height: 1.5rem;
  padding: ${(props) => props.theme.space[2]};
  text-align: left;
  user-select: none;
  width: 100%;

  svg {
    color: currentcolor;
    margin-left: ${(props) => props.theme.space[1]};
  }
`
