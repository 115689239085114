import PropTypes from "prop-types"
import React, { memo } from "react"
import styled from "styled-components"
import { Link } from "react-router"
import { MarketplaceSong as MarketplaceSongRecord } from "records"

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 0 1;
`

const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 1.25rem;
`

const StyledImg = styled.img`
  width: 3.75rem;
  height: 3.75rem;
  border-radius: ${(props) => props.theme.radii.default};
  box-shadow: ${(props) => props.theme.shadows.default};
  display: block;
  user-select: none;
  object-fit: cover;
`

const LinkWrapper = styled.div`
  width: 147px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.artist {
    color: ${(props) => props.theme.colors.text.secondary};
    margin-top: -7px;
  }
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  padding-left: 3px;
  text-overflow: ellipsis;
  color: ${(props) => props.theme.colors.text.primary};
  white-space: nowrap;
  width: 150px;

  a > &.artist {
    &:after {
      color: ${(props) => props.theme.colors.text.secondary};
    }
  }

  @media (max-width: ${(props) => props.theme.flexboxgrid.breakpoints.md}em) {
    width: 120px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl2}) {
    width: 200px;
  }
`

const StyledLink = styled(Link)`
  font-size: 14px;
  line-height: 18px;
  font-weight: ${(props) => props.theme.fontWeights.medium};
  user-select: none;
  color: ${(props) => props.theme.colors.text.primary};
  text-decoration: none;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.artist {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: ${(props) => props.theme.colors.text.secondary};

    &:not(:last-child) {
      margin-right: 4px;

      &:after {
        content: ",";
        display: inline-block;
        color: ${(props) => props.theme.colors.text.secondary} !important;
        text-decoration: none !important;
      }
    }
  }

  &:not(.title) {
    &:hover {
      color: ${(props) => props.theme.colors.action.hover};
      cursor: pointer;
    }
  }
`

const InfoSection = ({ song }) => (
  <Wrapper>
    <ImgWrapper>
      <StyledImg src={song.pic.versions?.thumb.url} />
    </ImgWrapper>
    <InfoWrapper>
      <LinkWrapper>
        <StyledLink className="title">{song.title}</StyledLink>
      </LinkWrapper>
      <LinkWrapper className="artist">
        {song.artist_credits.map((artist, i) => (
          <StyledLink
            key={i}
            className="artist"
            to={`/market/artists/${artist.slugified_name}-${artist.id}`}
          >
            {artist.name}
          </StyledLink>
        ))}
      </LinkWrapper>
    </InfoWrapper>
  </Wrapper>
)

export default memo(InfoSection)

InfoSection.propTypes = {
  song: PropTypes.instanceOf(MarketplaceSongRecord).isRequired,
}
