import { useDispatch } from "react-redux"
import { actions as uiActions } from "ducks/ui"

const useUiError = () => {
  const dispatch = useDispatch()
  const setUiError = (errorMessage) => {
    dispatch(uiActions.setError(errorMessage))
  }
  return {
    setUiError,
  }
}

export default useUiError
