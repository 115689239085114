import React, { useEffect } from "react"
import { actions as convertActions } from "ducks/convert"
import { useDispatch } from "react-redux"

const currentAndHistoricalData = () => {
  const currentData = window.convert.currentData?.experiences
  const historicalData = window.convert.historicalData?.experiences
  return { currentData, historicalData }
}

const ConvertSetup = ({ children }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (typeof window !== "undefined" && window.convert) {
      const { currentData, historicalData } = currentAndHistoricalData()

      dispatch(convertActions.setCurrentData(currentData))
      dispatch(convertActions.setHistoricalData(historicalData))
    }
    startListeners()
  }, [])

  const startListeners = () => {
    if (typeof window !== "undefined" && window._conv_q) {
      window._conv_q = window._conv_q || []

      /////////
      // snippet.experiences_evaluated
      /////////
      window._conv_q.push({
        what: "addListener",
        params: {
          event: "snippet.experiences_evaluated",
          handler: () => {
            const { currentData, historicalData } = currentAndHistoricalData()

            dispatch(convertActions.setCurrentData(currentData))
            dispatch(convertActions.setHistoricalData(historicalData))
          },
        },
      })
    }
  }

  return <>{children}</>
}

export default ConvertSetup
