import qs from "query-string-parser"

export const mediaTypeToApi = (mediaType) => {
  switch (mediaType) {
    case "music":
    case "songs":
    case "song":
      return "Song"
    case "video":
    case "videos":
      return "Video"
    case "sfx":
    case "sound_effect":
    case "sound_effects":
    case "sound-effects":
      return "SoundEffect"
    default:
      return ""
  }
}

export const mediaTypeFromApi = (mediaType) => {
  switch (mediaType) {
    case "Song":
    case "songs":
    case "song":
      return "music"
    case "Video":
    case "video":
    case "videos":
      return "video"
    case "SoundEffect":
    case "sound-effects":
    case "sound_effect":
    case "sound_effects":
      return "sound-effects"
    default:
      return ""
  }
}

export const trackingMediaType = (mediaType, plural = true) => {
  switch (mediaType.toLowerCase()) {
    case "music":
    case "songs":
    case "song":
      return plural ? "Songs" : "Song"
    case "video":
    case "videos":
      return plural ? "Videos" : "Video"
    case "sfx":
    case "sound-effects":
    case "sound_effect":
    case "sound_effects":
    case "soundeffect":
    case "soundeffects":
    case "sound effect":
    case "sound effects":
      return plural ? "Sound Effects" : "Sound Effect"
  }
}

export const mediaTypeToQuery = (mediaType, additionalParams = {}) =>
  qs.toQuery({
    filter: {
      media_type: mediaTypeToApi(mediaType),
    },
    ...additionalParams,
  })

export const mediaTypeToFeaturedQuery = (mediaType, params = {}) =>
  qs.toQuery({
    filter: {
      media_type: mediaTypeToApi(mediaType),
      ...params,
    },
  })
