export const buildImgixUrl = (imgUrl, imgixParams = {}) => {
  if (
    typeof imgUrl !== "string" ||
    imgUrl.includes("imgix.net") ||
    imgUrl.includes("img.pond5")
  ) {
    return imgUrl
  }

  const url = convertToImgixUrl(imgUrl)
  const params = { ...imgixParams, auto: "format" }
  const qs = Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&")
  return qs.length === 0 ? url : url.concat("?", qs)
}

const convertToImgixUrl = (url) => {
  const contentfulAssetDir = `images.ctfassets.net/${process.env.CONTENTFUL_SPACE_ID}`

  if (url.includes(contentfulAssetDir)) {
    return url.replace(contentfulAssetDir, "soundstripe-ctf.imgix.net")
  }

  if (url.includes("s3.amazonaws.com")) {
    return url.replace("s3.amazonaws.com", "imgix.net")
  }

  if (url.includes("s3.us-west-2.amazonaws.com")) {
    return url.replace("s3.us-west-2.amazonaws.com", "imgix.net")
  }

  return url
}
