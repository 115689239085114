import { post, prependApiUrl } from "utils/request"

const oauthUrl = prependApiUrl("auth/google_oauth2/callback").replace(
  "app/",
  ""
)

export const googleOauth = (params) => {
  return new Promise((resolve, reject) => {
    const client = google.accounts.oauth2.initCodeClient({
      client_id: process.env.GOOGLE_CLIENT_ID,
      scope: "email profile",
      ux_mode: "popup",
      callback: (response) => {
        if (response && !response.error) {
          const data = { data: { ...response, ...params } }
          post(oauthUrl, data)
            .then((res) => resolve(res))
            .catch((error) => reject(error))
        } else {
          reject(response.error)
        }
      },
      error_callback: ({ type }) => {
        reject(type)
      },
    })
    client.requestCode()
  })
}
