import { call, put, select, takeLatest, delay } from "redux-saga/effects"
import has from "lodash/has"
import { get, post, _delete } from "utils/request"
import { types, actions } from "ducks/frameio"
import { actions as modalActions } from "ducks/modal"
import { actions as uiActions } from "ducks/ui"
import { actions as trackingActions } from "ducks/tracking"
import {
  actions as currentUserActions,
  selectCurrentUserRecord,
} from "ducks/currentUser"

const hasAuthorizeUrl = (response) => has(response, "authorize_url")

export function* startFrameioAuthorization() {
  try {
    const requestURL = `${process.env.API_URL}/auth/frameio`
    const { authorize_url } = yield call(get, requestURL)
    window.frame_window = window.open(authorize_url, "MsgWindow")
  } catch (error) {
    yield put(actions.getError(error))
  }
}

export function* watchStartFrameioAuthorization() {
  yield takeLatest(types.START_AUTHORIZATION, startFrameioAuthorization)
}

export function* stopFrameioAuthorization() {
  try {
    yield delay(500)
    const user = yield select(selectCurrentUserRecord())
    if (user && user.hasFrameioAccount()) {
      yield put(uiActions.setNotice("Your Frame.io account is now linked."))
    }
  } catch (error) {
    yield put(
      trackingActions.trackMixpanel("Frame.io Authentication Failed", {
        "Error Message: ": error,
      })
    )
    yield put(uiActions.setError(error))
  }
}

export function* watchStopFrameioAuthorization() {
  yield takeLatest(types.STOP_AUTHORIZATION, stopFrameioAuthorization)
}

export function* unlinkFrameioAccount(action) {
  try {
    const requestURL = `${process.env.API_URL}/auth/frameio`
    yield call(_delete, requestURL)
    yield put(currentUserActions.load())
    if (action.payload.showNotice) {
      yield put(uiActions.setNotice("Your Frame.io account is now unlinked."))
    }
  } catch (error) {
    yield put(uiActions.setError(error))
  }
}

export function* watchUnlinkFrameioAccount() {
  yield takeLatest(types.UNLINK_ACCOUNT, unlinkFrameioAccount)
}

export function* getFrameioToken(action) {
  try {
    const requestURL = `${process.env.API_URL}/auth/frameio/get_token`
    const data = {
      auth_code: action.authCode,
    }
    const options = {
      data: JSON.stringify(data),
    }
    yield call(post, requestURL, options)
    window.close()
  } catch (error) {
    yield put(actions.getError(error))
  }
}

export function* watchGetFrameioToken() {
  yield takeLatest(types.GET_TOKEN, getFrameioToken)
}

export function* frameioGetTeams(action) {
  try {
    const { accountId } = action
    const requestURL = `${process.env.API_URL}/frameio/accounts/${accountId}/teams`
    const response = yield call(get, requestURL)

    if (hasAuthorizeUrl(response)) {
      yield put(actions.startAuthorization())
      yield put(modalActions.close())
    } else {
      yield put(actions.getSuccess(response))
    }
  } catch (error) {
    yield put(actions.getError(error))
  }
}

export function* watchFrameioGetTeams() {
  yield takeLatest(types.GET_TEAMS, frameioGetTeams)
}

export function* frameioGetProjects(action) {
  try {
    const { teamId } = action
    const requestURL = `${process.env.API_URL}/frameio//teams/${teamId}/projects`
    const response = yield call(get, requestURL)

    if (hasAuthorizeUrl(response)) {
      yield put(actions.startAuthorization())
      yield put(modalActions.close())
    } else {
      yield put(actions.getSuccess(response))
    }
  } catch (error) {
    yield put(actions.getError(error))
  }
}

export function* watchFrameioGetProjects() {
  yield takeLatest(types.GET_PROJECTS, frameioGetProjects)
}

export function* frameioGetSharedProjects() {
  try {
    const requestURL = `${process.env.API_URL}/frameio/shared_projects`
    const response = yield call(get, requestURL)

    if (hasAuthorizeUrl(response)) {
      yield put(actions.startAuthorization())
      yield put(modalActions.close())
    } else {
      yield put(actions.getSuccess(response))
    }
  } catch (error) {
    yield put(actions.getError(error))
  }
}

export function* watchFrameioGetSharedProjects() {
  yield takeLatest(types.GET_SHARED_PROJECTS, frameioGetSharedProjects)
}

export function* frameioGetAccounts() {
  try {
    const requestURL = `${process.env.API_URL}/frameio/accounts`
    const response = yield call(get, requestURL)
    if (hasAuthorizeUrl(response)) {
      yield put(actions.startAuthorization())
      yield put(modalActions.close())
    } else {
      yield put(actions.getSuccess(response))
    }
  } catch (error) {
    yield put(actions.getError(error))
  }
}

export function* watchFrameioGetAccounts() {
  yield takeLatest(types.GET_ACCOUNTS, frameioGetAccounts)
}

export function* frameioSendSong(action) {
  try {
    const { song, projectId } = action
    const requestURL = `${process.env.API_URL}/frameio/assets/${projectId}/children`
    const request = {
      data: {
        type: "children",
        audio_file_id: song.primaryAudioFileId(),
      },
    }
    const options = {
      data: JSON.stringify(request),
    }
    const response = yield call(post, requestURL, options)
    if (hasAuthorizeUrl(response)) {
      yield put(actions.startAuthorization())
    } else {
      yield put(actions.sendSongSuccess(response))
      yield put(modalActions.close())
    }
  } catch (error) {
    yield put(actions.sendSongError(error))
  }
}

export function* watchFrameioSendSong() {
  yield takeLatest(types.SEND_SONG, frameioSendSong)
}

export default [
  watchStartFrameioAuthorization,
  watchStopFrameioAuthorization,
  watchUnlinkFrameioAccount,
  watchGetFrameioToken,
  watchFrameioGetAccounts,
  watchFrameioGetTeams,
  watchFrameioGetProjects,
  watchFrameioGetSharedProjects,
  watchFrameioSendSong,
]
