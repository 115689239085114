const storeJS = require("utils/store.min.js")

export const debugFlag = (flag) =>
  process.env[flag] || storeJS.get("debugFlags")?.[flag]

export const DEBUG_FLAGS = {
  DEBUG_TRACKING: "Debug Tracking",
  GA_TRACKING: "Track GA",
  STOCK_CATALOG: "Stock Catalog",
  NON_SUB_MUSIC_PAGE: "Non Sub Music Page",
  NEW_SEARCH_UI: "New Search UI",
  HOLIDAY_SALE: "Holiday Sale",
  SKIP_PROSPERSTACK_FLOW: "Skip ProsperStack Flow",
}
