export const RESOLUTION_HIERARCHY = [
  "4K+",
  "4K",
  "3K",
  "2K",
  "1080",
  "720",
  "SD",
]

export const resolutionHierarchyOrder = RESOLUTION_HIERARCHY.reduce(
  (obj, item, index) => {
    return { ...obj, [item]: index }
  },
  {}
)

export const formatFps = (fps) => {
  const roundedFps = Math.floor(fps)
  return fps - roundedFps === 0 ? `${roundedFps}` : `${fps}`
}
