import { Record } from "immutable"
import { formatDate } from "utils/dateUtils"
import {
  ENTERPRISE_PLAN_IDS,
  UPGRADE_INELIGIBLE_PLAN_IDS,
  TEAM_PLAN_IDS,
  BUSINESS_PLAN_IDS,
  ENTERPRISE_BUSINESS_PLAN_IDS,
} from "utils/authentication"
import { isInternationalTestPlan } from "utils/plan"

const SUB_STATUSES = {
  FUTURE: "future",
  ACTIVE: "active",
  NON_RENEWING: "non_renewing",
  PAUSED: "paused",
  CANCELLED: "cancelled",
}

const SubscriptionRecord = Record(
  {
    addons: [],
    billing_interval: null,
    cancelled_at: null,
    chargebee_id: null,
    current_term_end: null,
    custom_team_seat_limit: null,
    has_api_access: false,
    has_api_addons: false,
    has_onbrand: false,
    has_recurring_coupon: false,
    has_scheduled_changes: false,
    id: null,
    last_upsell_attempt_at: null,
    next_billing_price_cents: null,
    pause_date: null,
    plan_id: null,
    plan_name: null,
    plan_unit_price: null,
    pro: false,
    restrict_cancellation: false,
    resume_date: null,
    status: null,
    upsell_eligible: false,
    prosperstack_signed_payload: null,
    user_id: null,
    migrated_plan: false,
    grandfathered_teams: false,
  },
  "Subscription"
)

class Subscription extends SubscriptionRecord {
  currentTermEnd() {
    if (this.current_term_end) return formatDate(this.current_term_end)
    return null
  }

  currentTermEndLocale() {
    const date = new Date(this.current_term_end)
    return new Intl.DateTimeFormat().format(date)
  }

  pauseDate() {
    if (this.pause_date) {
      return formatDate(this.pause_date)
    }
    return null
  }

  resumeDate() {
    if (this.resume_date) {
      return formatDate(this.resume_date)
    }
    return null
  }

  isActive() {
    return this.status === SUB_STATUSES.ACTIVE
  }

  isCancelled() {
    return this.status === SUB_STATUSES.CANCELLED
  }

  isPaused() {
    return [SUB_STATUSES.PAUSED, SUB_STATUSES.FUTURE].includes(this.status)
  }

  willPause() {
    return this.isActive() && !!this.pause_date
  }

  nonRenewing() {
    return this.status === SUB_STATUSES.NON_RENEWING
  }

  upgradeEligible() {
    return (
      !UPGRADE_INELIGIBLE_PLAN_IDS.includes(this.plan_id) &&
      !isInternationalTestPlan(this.plan_id) &&
      !this.isEnterprise()
    )
  }

  planUnitPrice() {
    let price = parseFloat(this.plan_unit_price) / 100

    if (price % 1) {
      price = price.toFixed(2)
    }

    return price
  }

  nextBillingPrice() {
    if (!this.next_billing_price_cents) return null
    return parseFloat(this.next_billing_price_cents) / 100
  }

  isTeam() {
    if (this.grandfathered_teams) return true
    return [...TEAM_PLAN_IDS, ...BUSINESS_PLAN_IDS].includes(this.plan_id)
  }

  isEnterprise() {
    return ENTERPRISE_PLAN_IDS.includes(this.plan_id)
  }

  isEnterpriseBusiness() {
    return ENTERPRISE_BUSINESS_PLAN_IDS.includes(this.plan_id)
  }

  canReactivate() {
    return !this.isActive() || this.willPause() || this.isPaused()
  }
}

export default Subscription
