import { fromJS } from "immutable"
import { createSelector } from "reselect"
import { createAction } from "./utils"
import { kebabCase } from "lodash"

const storeJS = require("utils/store.min.js")

export const types = {
  SET_CART_STORAGE: "soundstripe/cart/SET_CART_STORAGE",
  SET_CART_TOTALS: "soundstripe/cart/SET_CART_TOTALS",
  GENERATE_INVOICE_ESTIMATE: "soundstripe/cart/GENERATE_INVOICE_ESTIMATE",
  GENERATE_INVOICE_ESTIMATE_SUCCESS:
    "soundstripe/cart/GENERATE_INVOICE_ESTIMATE_SUCCESS",
  GENERATE_INVOICE_ESTIMATE_FAILED:
    "soundstripe/cart/GENERATE_INVOICE_ESTIMATE_FAILED",
  CLEAR_INVOICE_ESTIMATE: "soundstripe/cart/CLEAR_INVOICE_ESTIMATE",
  PAYMENT_REQUEST_BUTTON_SUBMIT:
    "soundstripe/cart/PAYMENT_REQUEST_BUTTON_SUBMIT",
  PAYMENT_REQUEST_BUTTON_SUCCESS:
    "soundstripe/cart/PAYMENT_REQUEST_BUTTON_SUCCESS",
}

export const actions = {
  setCartStorage: createAction(types.SET_CART_STORAGE, "data"),
  setCartTotals: createAction(types.SET_CART_TOTALS, "data"),
  generateInvoiceEstimate: createAction(
    types.GENERATE_INVOICE_ESTIMATE,
    "estimateAttributes",
    "invoice",
    "callback"
  ),
  generateInvoiceEstimateSuccess: createAction(
    types.GENERATE_INVOICE_ESTIMATE_SUCCESS,
    "estimate"
  ),
  generateInvoiceEstimateFailed: createAction(
    types.GENERATE_INVOICE_ESTIMATE_FAILED,
    "error"
  ),
  clearInvoiceEstimate: createAction(types.CLEAR_INVOICE_ESTIMATE),
  paymentRequestButtonSubmit: createAction(
    types.PAYMENT_REQUEST_BUTTON_SUBMIT,
    "checkoutAttributes",
    "authorizedPaymentIntent"
  ),
  paymentRequestButtonSuccess: createAction(
    types.PAYMENT_REQUEST_BUTTON_SUCCESS
  ),
}

const initialState = fromJS({
  cartItems: storeJS.get("cart_data") || [],
  cartTotals: {},
  estimate: null,
  estimateError: null,
  isLoading: false,
  stripePaymentSubmitting: false,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CART_STORAGE:
      storeJS.set("cart_data", action.data)
      return state.set("cartItems", fromJS(action.data))
    case types.SET_CART_TOTALS:
      return state.set("cartTotals", fromJS(action.data))
    case types.GENERATE_INVOICE_ESTIMATE_SUCCESS:
      return state
        .set("estimate", action.estimate)
        .set("isLoading", false)
        .set("estimateError", null)
    case types.GENERATE_INVOICE_ESTIMATE_FAILED:
      return state.set("estimateError", action.error).set("isLoading", false)
    case types.GENERATE_INVOICE_ESTIMATE:
      return state.set("isLoading", true)
    case types.PAYMENT_REQUEST_BUTTON_SUBMIT:
      return state.set("stripePaymentSubmitting", true).set("isLoading", true)
    case types.PAYMENT_REQUEST_BUTTON_SUCCESS:
      return state.set("stripePaymentSubmitting", false)
    case types.CLEAR_INVOICE_ESTIMATE:
      return state
        .set("estimate", null)
        .set("isLoading", false)
        .set("estimateError", null)
    default:
      return state
  }
}

const selectCartState = (state) => state.getIn(["ducks", "cart"])

export const selectCartHasDiscountApplied = () =>
  createSelector(selectInvoiceEstimate(), (estimate) => {
    if (!estimate) return false
    const discounts = estimate.invoice_estimate.discounts
    return (
      discounts &&
      !!discounts.find(
        (discount) =>
          discount.entity_id === "10CARTDISCOUNT" ||
          discount.entity_id === "25CARTDISCOUNT"
      )
    )
  })

export const selectCartItems = () =>
  createSelector(selectCartState, (cartState) => cartState.get("cartItems"))

export const selectCartItemsWithPricing = () =>
  createSelector(selectCartState, (cartState) =>
    cartState
      .get("cartItems")
      .toJS()
      .map((cartItem) => {
        const { invoice_estimate: estimate } = cartState.get("estimate") || {}

        if (!estimate) return cartItem

        const addonId = `${cartItem.license_type.toLowerCase()}-${kebabCase(
          cartItem.sellable_type
        )}-license-usd`
        const lineItem = estimate.line_items.find((item) => {
          return item.entity_id === addonId
        })

        if (!lineItem) return cartItem

        return {
          ...cartItem,
          price: lineItem.unit_amount,
          // discount amount is total for line item, we want to show per-unit
          discount: lineItem.discount_amount / lineItem.quantity,
        }
      })
  )

export const selectCartItemTotals = () =>
  createSelector(selectCartState, (cartState) => cartState.get("cartTotals"))

export const selectInvoiceEstimate = () =>
  createSelector(selectCartState, (cartState) => cartState.get("estimate"))

export const selectInvoiceEstimateError = () =>
  createSelector(selectCartState, (cartState) => cartState.get("estimateError"))

export const selectCartStripePaymentSubmitting = () =>
  createSelector(selectCartState, (cartState) =>
    cartState.get("stripePaymentSubmitting")
  )
