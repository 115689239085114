import { call, put, takeEvery } from "redux-saga/effects"
import { post } from "utils/request"
import { actions as uiActions } from "ducks/ui"
import { actions as modalActions } from "ducks/modal"
import { actions as apiActions } from "ducks/api"
import { types } from "ducks/song"

export function* trySong(action) {
  try {
    const song = action.song
    const requestURL = `${process.env.API_URL}/songs/${song.id}/preview`
    const response = yield call(post, requestURL)
    yield put(apiActions.createOrUpdateSuccess(response))
    yield put(
      modalActions.open("DownloadSongModal", {
        songId: song.id,
        watermark: true,
      })
    )
  } catch (err) {
    yield put(uiActions.setError(err))
  }
}

export function* watchTrySong() {
  yield takeEvery(types.TRY_SONG, trySong)
}

export default [watchTrySong]
