import Immutable from "immutable"
import { secondsToMMSS } from "utils/formatting"

const UrlRecord = Immutable.Record({
  url: "",
})

const UrlWithDownload = Immutable.Record({
  download_url: "",
  url: "",
})

const VersionsRecord = Immutable.Record({
  mp3: new UrlWithDownload(),
  peak_data_large: new UrlRecord(),
  watermarked: new UrlWithDownload(),
})

class Versions extends VersionsRecord {
  constructor(args = {}) {
    super(
      Object.assign({}, args, {
        mp3: new UrlWithDownload(args.mp3),
        peak_data_large: new UrlRecord(args.peak_data_large),
        watermarked: new UrlWithDownload(args.watermarked),
      })
    )
  }
}

const FileRecord = Immutable.Record({
  download_url: "",
  filename: "",
  url: "",
  versions: new Versions(),
})

class File extends FileRecord {
  constructor(args = {}) {
    super(
      Object.assign({}, args, {
        versions: new Versions(args.versions),
      })
    )
  }
}

const AudioFileRecord = Immutable.Record(
  {
    id: null,
    type: "audio_files",

    // Attributes
    clean_version: null,
    description: "",
    duration: "",
    exclusions: null,
    file: new File(),
    file_extension: "",
    primary: undefined,
    vocal_degree: "",
    song_id: null,

    // Relationships
    relationships: [],
  },
  "AudioFile"
)

class AudioFile extends AudioFileRecord {
  constructor(args = {}) {
    let file = args.file || {}
    if (args.peak_data_url || args.playback_url) {
      file = {
        versions: {
          mp3: {
            download_url: args.playback_url,
            url: args.playback_url,
          },
          peak_data_large: {
            url: args.peak_data_url,
          },
          watermarked: {
            download_url: "",
            url: "",
          },
        },
      }
    }

    super(
      Object.assign({}, args, {
        file: new File(file),
      })
    )
  }

  peakDataUrl() {
    return this.file.versions.peak_data_large.url
  }

  durationFormatted() {
    return secondsToMMSS(this.duration)
  }

  downloadUrl() {
    return this.file.download_url
  }

  mp3DownloadUrl() {
    return this.file.versions.mp3.download_url
  }

  mp3Url() {
    return this.file.versions.mp3.url
  }

  watermarkedDownloadUrl() {
    return this.file.versions.watermarked.download_url
  }
}

export default AudioFile
