import React, { useEffect } from "react"
import styled, { css, useTheme } from "styled-components"
import Box from "components/Box"
import Button from "components/Button/New"
import { ICON_SS_CLOSE } from "components/Icon/constants"
import useOffer from "hooks/useOffer"
import useCurrentUser from "hooks/useCurrentUser"
import useLocalStorage from "hooks/useLocalStorage"
import { withRouter } from "react-router"
import { PLAN_IDS } from "utils/authentication"
import Img from "components/Img"
import useBreakpoints from "hooks/useBreakpoints"
import useMixpanelTracking from "hooks/useMixpanelTracking"
import { useDispatch } from "react-redux"
import { actions as modalActions } from "ducks/modal"

const BANNER_ACCESS_PLAN_IDS = [
  PLAN_IDS.MUSIC_SFX_YEARLY_INR,
  PLAN_IDS.MUSIC_SFX_MONTHLY_INR,
  PLAN_IDS.MUSIC_SFX_YEARLY_GBP,
  PLAN_IDS.MUSIC_SFX_MONTHLY_GBP,
  PLAN_IDS.MUSIC_SFX_YEARLY_EUR,
  PLAN_IDS.MUSIC_SFX_MONTHLY_EUR,
  PLAN_IDS.MUSIC_VIDEO_SFX_YEARLY,
  PLAN_IDS.MUSIC_VIDEO_SFX_TEAM_YEARLY,
  PLAN_IDS.MUSIC_VIDEO_SFX_MONTHLY,
  PLAN_IDS.MUSIC_VIDEO_SFX_TEAM_MONTHLY,
  PLAN_IDS.ENTERPRISE_MUSIC_VIDEO_SFX_YEARLY,
  PLAN_IDS.PRO_PLUS_YEARLY,
]

const focusStyles = css`
  &:focus-visible {
    box-shadow: 0 0 0 3px transparent,
      0 0 0 2px ${(props) => props.theme.colors.white};
    outline: none;
    text-decoration: none;
  }

  &:focus:not(:focus-visible) {
    outline: initial;
  }
`

const DismissButton = styled(Button)`
  ${focusStyles}
  align-self: center;
  border: 1px solid transparent !important;
  padding: 0.5rem !important;
  svg {
    color: ${(props) => props.theme.colors.white};
  }
  background-color: transparent;

  &:hover {
    background-color: rgba(0, 0, 0, 0.24) !important;

    svg {
      color: ${(props) => props.theme.colors.white};
    }
  }
`

const CtaButton = styled(Button)`
  ${focusStyles}
  background-color: black !important;
  color: ${(props) => props.theme.colors.white} !important;
  white-space: nowrap;

  &:hover {
    background-color: rgba(0, 0, 0, 0.24) !important;
  }
`

const OfferBanner = (props) => {
  const { router } = props
  const { currentUser } = useCurrentUser()
  const theme = useTheme()
  const offer = useOffer()
  const { value: barState, set: setBarState } = useLocalStorage(
    `${offer?.couponCode}_banner`
  )
  const {
    value: bannerLastClearedDate,
    set: setBannerLastClearedDate,
  } = useLocalStorage("bannerLastClearedDate")

  const onDismiss = () => {
    setBarState("dismissed")
    setBannerLastClearedDate(new Date())
  }
  const userPlanId = currentUser?.planId()
  const { gtLg, isXs } = useBreakpoints()
  const { trackMixpanel } = useMixpanelTracking()
  const dispatch = useDispatch()

  useEffect(() => {
    if (bannerLastClearedDate) {
      const lastClearedDate = new Date(bannerLastClearedDate)
      const currentDate = new Date()

      // Reset to midnight for proper day comparison
      lastClearedDate.setHours(0, 0, 0, 0)
      currentDate.setHours(0, 0, 0, 0)

      // If the last cleared date is from a previous day, reset the banner state
      if (lastClearedDate < currentDate) {
        setBarState("")
      }
    }
  }, [bannerLastClearedDate])

  const openUpgradeModal = () => {
    trackMixpanel("Clicked Element", {
      element: "View Details Button",
      context: "Offer Banner - View Details",
    })

    dispatch(
      modalActions.open("UpgradeModal", {
        context: "View Details",
      })
    )
  }

  const inSaleFunnel =
    window.location.pathname.includes("checkout") ||
    window.location.pathname.includes("pricing") ||
    ((window.location.pathname.includes("sign_in") ||
      window.location.pathname.includes("signup")) &&
      new URL(window.location).searchParams.has("plan"))

  const handleCTAOnClick = () => {
    if (!userPlanId) {
      router.push("/pricing")
    } else {
      openUpgradeModal()
    }
  }

  if (
    !inSaleFunnel &&
    barState !== "dismissed" &&
    currentUser &&
    !currentUser.teamMember() &&
    offer &&
    !BANNER_ACCESS_PLAN_IDS.includes(userPlanId)
  ) {
    return (
      <Box
        position="relative"
        top="0"
        display="flex"
        alignItems="center"
        background={theme.colors.offer.banner.background}
        height={{ _: "4rem", sm: "3rem" }}
        flexDirection="row"
        p={3}
        width={1}
        zIndex="alert"
      >
        {gtLg && (
          <>
            <Img
              position="absolute"
              top="0"
              left="0"
              zIndex="-1"
              src={`/assets/bfcm/left.png`}
              alt="stripes"
            />
            <Img
              position="absolute"
              top="0"
              right="0"
              zIndex="-1"
              src={`/assets/bfcm/right.png`}
              alt="stripes"
            />
          </>
        )}
        <Box
          display="flex"
          flex="1 1 auto"
          flexDirection={{ _: "column", xs: "row" }}
          textAlign={{ _: "left", md: "center" }}
          mr={{ _: 0, xs: 4 }}
          alignItems="center"
          justifyContent="center"
          onClick={handleCTAOnClick}
          pt={{ _: 4, xs: 0 }}
          pb={{ _: 4, xs: 0 }}
        >
          <Box cursor={isXs ? "pointer" : "default"}>
            <Box
              verticalAlign="middle"
              color="white"
              fontSize={{ _: "sm", xs: "md" }}
              fontWeight="medium"
              lineHeight={{ _: "1rem", xs: "1.5rem" }}
            >
              {isXs
                ? offer.banner.mobileNotification
                : offer.banner.notification}
            </Box>
          </Box>
          <Box ml={{ _: 0, xs: 3 }}>
            <CtaButton appearance="subtle" size="sm" onClick={handleCTAOnClick}>
              Save Now
            </CtaButton>
          </Box>
        </Box>
        <DismissButton
          appearance="subtle"
          icon={ICON_SS_CLOSE}
          onClick={onDismiss}
        />
      </Box>
    )
  } else {
    return null
  }
}

export default withRouter(OfferBanner)
