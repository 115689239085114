import axios from "axios"
import merge from "lodash/merge"

import { loggedIn, getToken } from "utils/authentication"
import {
  VOCALS,
  INSTRUMENTAL,
  DURATION_MIN,
  DURATION_MAX,
  BPM_MIN,
  BPM_MAX,
} from "components/SongSearchSidebar/constants"
import { getMixpanelDeviceId, getStatsigStableId } from "utils/tracking"
export const prependApiUrl = (path) =>
  path ? `${process.env.API_URL}/${path}` : process.env.API_URL

/**
 * Throws an error using the response from the network
 *
 * @param  {object} response A response from a network request
 *
 * @return {undefined}       Throws an error
 */
const throwError = (error) => {
  let message = ""
  let err

  if (error.response?.data?.errors) {
    error.response.data.errors.forEach((errorObj) => {
      message += `${errorObj.detail} `
    })
    if (!message.length) {
      message = error.response.data.statusText
    }
    err = new Error(message.trim())
    err.response = error.response
    err.name = error.response.data.errors[0].code
  } else if (typeof error.response?.data === "string") {
    // strings being returned in data are usually going to be stacktraces
    // which we want to avoid showing the user so an empty error is returned
    err = new Error()
  } else {
    err = new Error("No response")
    err.name = "no_response"
  }

  console.warn(err)
  throw err
}

// This adds the likeness_rank to each tag, and adds the resulting tags to taggable_tags
export const addTagsToTaggableTags = (tags, taggable_tags) => {
  const tagsWithLikenessRank = []
  let rank = 0
  tags.forEach((tag) => {
    const mutatedTag = Object.assign({}, tag)
    const destroyed = mutatedTag.attributes._destroy
    if (!destroyed) {
      rank += 1
      mutatedTag.attributes.likeness_rank = rank
    }
    tagsWithLikenessRank.push(mutatedTag)
  })
  addArrayToPayloadArray(tagsWithLikenessRank, taggable_tags, "taggable_tags")
}

/*
 * @param {object} ary        The array of objects to iterate through
 * @param {object} payloadAry The payload array to be added to the data object
 * @param {String} type       The type parameter for the relationship
 *
 */
export const addArrayToPayloadArray = (ary, payloadAry, type) => {
  ary.forEach((item) => {
    const payload = {
      type,
      attributes: item.attributes,
    }
    if (item.id) {
      payload.id = item.id
    }
    payloadAry.push(payload)
  })
}

// see: https://gist.github.com/dgs700/4677933
export const toQueryString = (queryObj) => {
  const buildParams = (prefix, obj, add) => {
    let i = 0
    const l = obj.length
    const rbracket = /\[\]$/
    if (obj instanceof Array) {
      for (l; i < l; i += 1) {
        if (rbracket.test(prefix)) {
          add(prefix, obj[i])
        } else {
          buildParams(
            `${prefix}[${typeof obj[i] === "object" ? i : ""}]`,
            obj[i],
            add
          )
        }
      }
    } else if (typeof obj === "object") {
      // Serialize object item.
      Object.keys(obj).forEach((name) => {
        buildParams(`${prefix}[${name}]`, obj[name], add)
      })
    } else {
      // Serialize scalar item.
      add(prefix, obj)
    }
  }

  const r20 = /%20/g
  const s = []
  const add = (key, value) => {
    // If value is a function, invoke it and return its value
    let val
    if (typeof value === "function") {
      val = value()
    }
    if (value == null) {
      val = ""
    } else {
      val = value
    }
    s[s.length] = `${encodeURIComponent(key)}=${encodeURIComponent(val)}`
  }
  if (queryObj instanceof Array) {
    queryObj.forEach((name) => {
      add(name, queryObj[name])
    })
  } else {
    Object.keys(queryObj).forEach((prefix) => {
      buildParams(prefix, queryObj[prefix], add)
    })
  }
  const output = s.join("&").replace(r20, "+")
  return output
}

export const updateQueryStringParameter = (uri, key, value) => {
  const re = new RegExp(`([?&])${key}=.*?(&|#|$)`, "i")
  if (value === undefined) {
    if (uri.match(re)) {
      return uri.replace(re, "$1$2")
    }
    return uri
  }

  if (uri.match(re)) {
    return uri.replace(re, `$1${key}=${value}$2`)
  }
  let hash = ""
  let escapedUri = uri
  if (uri.indexOf("#") !== -1) {
    hash = uri.replace(/.*#/, "#")
    escapedUri = uri.replace(/#.*/, "")
  }
  const separator = escapedUri.indexOf("?") !== -1 ? "&" : "?"
  return `${uri}${separator}${key}=${value}${hash}`
}

/**
 * Query string from page and filter params, added to or replacing params
 * in passed URL.
 *
 * @param {integer} offset The page offset
 * @param {integer} limit The number of records per page
 * @param {integer} query The search query (if any)
 */
export const updatePageAndFilterQueryString = (
  url,
  { offset, limit, query }
) => {
  let constructedUrl = url
  if (offset != null && limit != null) {
    constructedUrl = updateQueryStringParameter(
      constructedUrl,
      encodeURIComponent("page[offset]"),
      offset
    )
    constructedUrl = updateQueryStringParameter(
      constructedUrl,
      encodeURIComponent("page[limit]"),
      limit
    )
  }

  if (query && query.length) {
    constructedUrl = updateQueryStringParameter(
      constructedUrl,
      encodeURIComponent("filter[q]"),
      query
    )
  }
  return constructedUrl
}

/**
 * Query string from search and filter bar
 *
 * @param {string} path The request path
 * @param {object} searchAndFilterState The state object from the SearchAndFilterBar
 */
export const updateSearchAndFilterQueryString = (
  path,
  { activeTags, vocalFilter, bpmRange, durationRange, searchQuery }
) => {
  let constructedPath = path
  const separator = constructedPath.indexOf("?") !== -1 ? "&" : "?"
  let separatorAdded = false
  if (activeTags && activeTags.length) {
    if (!separatorAdded) {
      constructedPath += separator
      separatorAdded = true
    }
    const activeTagIds = activeTags.map((activeTag) => activeTag.id)
    activeTagIds.forEach((tagId, index) => {
      if (index > 0) {
        constructedPath += "&"
      }
      constructedPath += encodeURIComponent("filter[tag_ids][]")
      constructedPath += `=${tagId}`
    })
  }

  if (vocalFilter) {
    if (vocalFilter === VOCALS) {
      constructedPath = updateQueryStringParameter(
        constructedPath,
        encodeURIComponent("filter[vocals]"),
        true
      )
    } else if (vocalFilter === INSTRUMENTAL) {
      constructedPath = updateQueryStringParameter(
        constructedPath,
        encodeURIComponent("filter[vocals]"),
        false
      )
    }
  }

  const bpmMin = bpmRange[0]
  const bpmMax = bpmRange[1]
  if (
    (bpmMin != null && bpmMin !== BPM_MIN) ||
    (bpmMax != null && bpmMax !== BPM_MAX)
  ) {
    constructedPath = updateQueryStringParameter(
      constructedPath,
      encodeURIComponent("filter[bpm][min]"),
      bpmMin || BPM_MIN
    )
    constructedPath = updateQueryStringParameter(
      constructedPath,
      encodeURIComponent("filter[bpm][max]"),
      bpmMax || BPM_MAX
    )
  }

  const durationMin = durationRange[0]
  const durationMax = durationRange[1]
  if (
    (durationMin != null && durationMin !== DURATION_MIN) ||
    (durationMax != null && durationMax !== DURATION_MAX)
  ) {
    constructedPath = updateQueryStringParameter(
      constructedPath,
      encodeURIComponent("filter[duration][min]"),
      durationMin || DURATION_MIN
    )
    constructedPath = updateQueryStringParameter(
      constructedPath,
      encodeURIComponent("filter[duration][max]"),
      durationMax || DURATION_MAX
    )
  }

  if (searchQuery && searchQuery.length) {
    constructedPath = updateQueryStringParameter(
      constructedPath,
      encodeURIComponent("filter[q]"),
      searchQuery
    )
  }

  return constructedPath
}

/**
 * Query string from page and filter params
 *
 * @param {integer} offset The page offset
 * @param {integer} limit The number of records per page
 * @param {integer} query The search query (if any)
 */
export const pageAndFilterQueryString = ({ offset, limit, query }) => {
  const params = {}
  if (limit != null) {
    params.page = {
      limit,
      offset: offset || 0,
    }
  }

  if (query && query.length) {
    params.filter = {
      q: query,
    }
  }

  return toQueryString(params)
}

/**
 * Default headers
 *
 * @return {object} The headers
 */
export const defaultHeaders = () => {
  const headers = {
    headers: {
      "Content-Type": "application/vnd.api+json",
      Accept: "application/vnd.api+json",
      "X-Soundstripe-Referer": window.location.href,
    },
  }

  return merge(headers, authHeader())
}

async function digestMessage(message) {
  // https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
  const encoder = new TextEncoder()
  const encodedText = encoder.encode(message)
  const hash = await window.crypto.subtle.digest("SHA-256", encodedText)

  // Convert hash to hex string
  const hashArray = Array.from(new Uint8Array(hash))
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("")
  return hashHex
}

/**
 * The registration digest header
 *
 * @return {Promise<object>} A promise resolving to a registration digest header object
 */
export const registrationDigestHeader = async (email) => {
  const agent = navigator.userAgent
  const auth_pepper = process.env.AUTH_PEPPER
  const message = `${agent}${email}${auth_pepper}`
  const digest = await digestMessage(message)
  return { "X-Soundstripe-Registration-Digest": digest }
}

/**
 * The auth header
 *
 * @return {object} The authHeader
 */
export const authHeader = () => {
  let authTokenHeader
  if (loggedIn()) {
    authTokenHeader = {
      headers: {
        "X-Soundstripe-Auth-Token": getToken(),
      },
    }
  } else {
    authTokenHeader = {}
  }

  return authTokenHeader
}

export const trackHeaders = (url) => {
  let trackingHeader = {}

  if (url.match(new RegExp(`^${process.env.API_URL}`))) {
    const deviceId = getMixpanelDeviceId()

    if (deviceId) {
      trackingHeader = {
        headers: {
          "X-Soundstripe-Tracking-Device": getMixpanelDeviceId(),
        },
      }
    }
    if (window.trackingLoaded) {
      const stableId = getStatsigStableId()
      if (stableId) {
        trackingHeader = {
          headers: {
            ...trackingHeader.headers,
            "X-Soundstripe-Stable-Id": getStatsigStableId(),
          },
        }
      }
    }

    if (!loggedIn() && window.trackingLoaded) {
      trackingHeader = {
        headers: {
          ...trackingHeader.headers,
          "X-Soundstripe-Tracking": mixpanel.get_distinct_id(),
        },
      }
    }
  }
  return trackingHeader
}

/**
 * GETs to a URL, returning a promise
 * NOTE: Default headers can be unset by passing null for Content-Type
 * and/or Accept.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export const get = (url, options) =>
  request(url, merge({ method: "GET" }, options))

/**
 * PATCHs to a URL, returning a promise
 * NOTE: Default headers can be unset by passing null for Content-Type
 * and/or Accept.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export const patch = (url, options) =>
  request(url, merge({ method: "PATCH" }, options))

/**
 * POSTs to a URL, returning a promise
 * NOTE: Default headers can be unset by passing null for Content-Type
 * and/or Accept.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export const post = (url, options) =>
  request(url, merge({ method: "POST" }, options))

/**
 * PUTs to a URL, returning a promise
 * NOTE: Default headers can be unset by passing null for Content-Type
 * and/or Accept.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export const _put = (url, options) =>
  request(url, merge({ method: "PUT" }, options))

/**
 * DELETEs to a URL, returning a promise
 * NOTE: Default headers can be unset by passing null for Content-Type
 * and/or Accept.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export const _delete = (url, options) =>
  request(url, merge({ method: "DELETE" }, options))

/**
 * Requests a URL, returning a promise
 * NOTE: Default headers can be unset by passing null for Content-Type
 * and/or Accept.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export const request = (url, reqOptions) => {
  const options = Object.create(reqOptions)
  const hasHeaders = reqOptions.hasHeaders
  delete options.hasHeaders
  const optionsWithUrl = merge(options, { url })

  // Setting data to {} prevents Content-Type from being removed if no data is passed.
  // It's fixed but not released yet.
  // See https://github.com/mzabriskie/axios/issues/362 and https://github.com/mzabriskie/axios/pull/195
  const optionsWithData = merge({ data: {} }, optionsWithUrl)
  const opts = merge(defaultHeaders(), trackHeaders(url), optionsWithData)

  // Unset headers if they're set to null.
  if (opts.headers["Content-Type"] == null) {
    delete opts.headers["Content-Type"]
  }
  if (opts.headers.Accept == null) {
    delete opts.headers.Accept
  }

  return axios(opts)
    .then((response) => (hasHeaders ? response : response.data))
    .catch((error) => {
      if (axios.isCancel(error)) {
        console.warn("Request canceled")
      } else {
        throwError(error)
      }
    })
}
