import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import Box from "components/Box"

const StyledBadge = styled(Box)`
  &.offer {
    background: ${(props) =>
      props.solidBadge
        ? props.theme.colors.offer.solidBadge.background
        : props.theme.colors.offer.outlineBadge.background};
    border: 1px solid
      ${(props) =>
        props.solidBadge
          ? props.theme.colors.offer.solidBadge.border
          : props.theme.colors.offer.outlineBadge.border};
    color: ${(props) =>
      props.solidBadge
        ? props.theme.colors.offer.solidBadge.text
        : props.theme.colors.offer.outlineBadge.text};
  }

  &.sales {
    background: ${(props) => props.theme.colors.background.elevationMid};
    color: ${(props) => props.theme.colors.text.primary};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
  }

  &.success {
    background: ${(props) => props.theme.colors.success};
    color: ${(props) => props.theme.colors.black};
  }
`

const Badge = ({ text, type, ...props }) => {
  return (
    <StyledBadge
      as="span"
      borderRadius="default"
      className={type}
      display="inline-block"
      fontSize="xs"
      fontWeight="medium"
      lineHeight="1rem"
      px={2}
      py={1}
      {...props}
    >
      {text}
    </StyledBadge>
  )
}

Badge.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["offer", "sales", "success"]),
}

Badge.defaultProps = {
  type: "offer",
}

export default Badge
