import { fromJS } from "immutable"
import { createSelector } from "reselect"
import { createAction } from "./utils"

export const types = {
  SET_DAACI_ID: "soundstripe/cart/SET_DAACI_ID",
}

export const actions = {
  setDaaciId: createAction(types.SET_DAACI_ID, "daaciId"),
}

const initialState = fromJS({
  daaciId: "",
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_DAACI_ID:
      return state.set("daaciId", fromJS(action.daaciId))
    default:
      return state
  }
}

const selectAiSongEditorState = (state) =>
  state.getIn(["ducks", "aiSongEditor"])

export const selectCurrentDaaciId = () =>
  createSelector(selectAiSongEditorState, (aiSongEditorState) =>
    aiSongEditorState.get("daaciId")
  )
