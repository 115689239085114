import PropTypes from "prop-types"
import styled from "styled-components"

const coverImageSize = "3rem"
const lgCoverImageSize = "4rem"

const CoverImage = styled.div`
  align-items: center;
  background-color: transparent;
  background-image: url("${({ image }) => image}");
  background-size: cover;
  border-radius: ${({ theme }) => theme.radii.default};
  display: flex;
  flex: 0 0 ${coverImageSize};
  height: ${coverImageSize};
  justify-content: center;
  margin-right: ${({ theme }) => theme.space[3]};
  width: ${coverImageSize};

  &.noCover {
    background: none;
    height: 32px;
    width: 32px;
  }

  &.lg {
    height: ${lgCoverImageSize};
    width: ${lgCoverImageSize};
    flex: 0 0 ${lgCoverImageSize};
  }
`

CoverImage.propTypes = {
  image: PropTypes.string,
}

export default CoverImage
