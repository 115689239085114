import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router"
import styled, { withTheme } from "styled-components"

import Box from "components/Box"
import Logo from "components/Logo"

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const LogoImg = ({ theme, className, mr = { _: 4, md: 7 } }) => (
  <Box
    alignItems="center"
    display="flex"
    height="100%"
    justifyContent="center"
    mr={mr}
    width="170px"
    className={className}
  >
    <StyledLink to="/" aria-label="soundstripe.com">
      <Logo color={theme.colors.text.primary} height="100%" />
    </StyledLink>
  </Box>
)

LogoImg.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.object.isRequired,
}

export default withTheme(LogoImg)
