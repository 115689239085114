import useMediaQueryV2 from "hooks/useMediaQueryV2"
import { breakpoints } from "containers/App/DefaultTheme"

const useBreakpoints = () => {
  const gtSm = useMediaQueryV2(`(min-width: ${breakpoints.sm})`)
  const gtMd = useMediaQueryV2(`(min-width: ${breakpoints.md})`)
  const gtLg = useMediaQueryV2(`(min-width: ${breakpoints.lg})`)
  const gtXl = useMediaQueryV2(`(min-width: ${breakpoints.xl})`)
  const gtXl2 = useMediaQueryV2(`(min-width: ${breakpoints.xl2})`)

  return {
    ...breakpoints,
    isXs: !gtSm,
    isSm: gtSm && !gtMd,
    isMd: gtMd && !gtLg,
    isLg: gtLg && !gtXl,
    isXl: gtXl && !gtXl2,
    isXl2: gtXl2,
    ltSm: !gtSm,
    ltMd: !gtMd,
    ltLg: !gtLg,
    ltXl: !gtXl,
    ltXl2: !gtXl2,
    gtXs: true,
    gtSm,
    gtMd,
    gtLg,
    gtXl,
    gtXl2,
  }
}

export default useBreakpoints
