import Immutable from "immutable"
import { createRelationships } from "records/Relationship"
import Pic from "./Pic"

const ArtistRecord = Immutable.Record(
  {
    id: null,
    type: "artists",

    // Attributes
    name: "",
    bio: "",
    pic: Pic,
    apple_id: null,
    spotify_id: null,

    // Relationships
    relationships: {
      associated_artists: {},
    },
  },
  "Artist"
)

const relationshipTypes = {
  associated_artists: ["artists"],
}

class Artist extends ArtistRecord {
  constructor(args = {}) {
    super(
      Object.assign({}, args, {
        pic: new Pic(args.pic),
        relationships: createRelationships(
          args.relationships,
          relationshipTypes
        ),
      })
    )
  }

  picThumbnailUrl() {
    return this.pic.thumbnailUrl()
  }
}

export default Artist
