import { fromJS } from "immutable"
import { createSelector } from "reselect"
import { createAction } from "./utils"

// ACTION TYPES
const types = {
  UPDATE_SEARCH: "soundstripe/search/UPDATE_SEARCH",
  RESET_SEARCH: "soundstripe/search/RESET_SEARCH",
}

// REDUCER

const initialState = fromJS({
  search: "",
  filters: {},
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_SEARCH:
      return state.set("search", action.search)
    case types.RESET_SEARCH:
      return state.set("search", "")
    default:
      return state
  }
}

export const actions = {
  /**
   * Type is the larger category and then name is the more specific value EX. category => "Genre", name => "Alternative Indie Pop Death Metal"
   */
  resetSearch: createAction(types.RESET_SEARCH),
  updateSearch: createAction(types.UPDATE_SEARCH, "search"),
}

export const selectSearchState = (state) => state.getIn(["ducks", "search"])

export const selectSearch = () =>
  createSelector(selectSearchState, (searchState) => searchState.get("search"))
