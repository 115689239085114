import PropTypes from "prop-types"
import React from "react"
import ReactDOM from "react-dom"
import styled from "styled-components"
import isEqual from "lodash/isEqual"

const pixelRatio =
  window.devicePixelRatio || screen.deviceXDPI / screen.logicalXDPI
const pixelRatioOffset = 0.5 / pixelRatio // A half-pixel offset makes lines crisp

const Canvas = styled.canvas`
  width: 100%;

  &:not(.progress) {
    z-index: 1;
    left: 0;
    top: 0;
    bottom: 0;
  }
`

class WaveCanvas extends React.Component {
  shouldComponentUpdate = (nextProps) => {
    return (
      nextProps.width !== this.props.width ||
      nextProps.height !== this.props.height ||
      nextProps.color !== this.props.color ||
      !isEqual(nextProps.peaks, this.props.peaks)
    )
  }

  componentDidUpdate = (prevProps) => {
    const { color, width, height, peaks } = this.props

    if (
      prevProps.width !== width ||
      prevProps.height !== height ||
      prevProps.color !== color ||
      !isEqual(prevProps.peaks, peaks)
    ) {
      this.updateCanvas(width, height, peaks)
    }
  }

  updateCanvas = (width, height, peaks) => {
    if (!peaks) return false
    const { gradientColors, color } = this.props

    const displayHeight = Math.round(height / pixelRatio)
    const waveCanvasCtx = ReactDOM.findDOMNode(this.wave).getContext("2d")
    if (!waveCanvasCtx) return

    waveCanvasCtx.canvas.width = width
    waveCanvasCtx.canvas.height = height
    waveCanvasCtx.canvas.style.height = `${displayHeight}px`
    waveCanvasCtx.clearRect(0, 0, width, height)
    waveCanvasCtx.fillStyle = color

    if (gradientColors) {
      const gradient = waveCanvasCtx.createLinearGradient(0, 0, width, 0)
      gradientColors.forEach(({ stopPosition, hexCode }) => {
        gradient.addColorStop(stopPosition, hexCode)
      })
      waveCanvasCtx.fillStyle = gradient
    }

    this.drawBars(waveCanvasCtx, width, peaks)
  }

  drawBars = (waveCanvasCtx, width, peaks) => {
    // Bar wave draws the bottom only as a reflection of the top,
    // so we don't need negative values
    const hasNegativePeakVals = this.props.peaks.some((p) => p < 0)
    if (hasNegativePeakVals) {
      // If the first value is negative, add 1 to the filtered indices
      const indexOffset = peaks[0] < 0 ? 1 : 0
      peaks = peaks
        .filter((_, index) => (index + indexOffset) % 2 === 0)
        .map((p) => Math.abs(p))
    }

    // Transloadit data for SFX returns too much data for how short
    // the duration is so we need to reduce it down to 74 samples
    // which is what carrierwave-audio-waveform was doing before
    if (this.props.isSoundEffect) {
      const samples = 74
      const peakSize = Math.floor(peaks.length / samples)
      const filteredPeaks = []

      for (let i = 0; i < samples; i++) {
        let sum = 0
        const peakStart = peakSize * i

        for (let j = 0; j < peakSize; j++) {
          sum = sum + Math.abs(peaks[peakStart + j])
        }
        filteredPeaks.push(sum / peakSize)
      }

      peaks = filteredPeaks
    }

    const maxPeak = Math.max(...peaks)
    const halfHeight = this.props.height / 2 // Don't use height because this is after canvas height has been set
    const scale = peaks.length / width
    const gap = Math.max(pixelRatio, 2)
    const step = pixelRatio + gap
    const rectWidth = pixelRatio + pixelRatioOffset

    for (let i = 0; i < width; i += step) {
      const peakVal = peaks[Math.floor(i * scale)]
      let h = Math.round((peakVal / maxPeak) * halfHeight)
      if (h === 0) h = 1 // converts silences (0s) to 1
      const x = i + pixelRatioOffset
      const y = halfHeight - h
      const rectHeight = h * 2

      waveCanvasCtx.fillRect(x, y, rectWidth, rectHeight)
    }
  }

  render() {
    if (!this.props.peaks) return null

    return (
      <Canvas
        ref={(instance) => {
          this.wave = instance
        }}
      />
    )
  }
}

WaveCanvas.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number.isRequired,
  isSoundEffect: PropTypes.bool,
  peaks: PropTypes.arrayOf(PropTypes.number.isRequired),
  width: PropTypes.number.isRequired,
  gradientColors: PropTypes.arrayOf(
    PropTypes.shape({
      stopPosition: PropTypes.number.isRequired,
      hexCode: PropTypes.string.isRequired,
    })
  ),
}

WaveCanvas.defaultProps = {
  color: "ccc",
  width: 0,
  isSoundEffect: false,
}

export default WaveCanvas
