import { fromJS } from "immutable"
import { createSelector } from "reselect"
import { createAction } from "./utils"

export const types = {
  LOAD: "soundstripe/flag/LOAD",
  LOAD_SUCCESS: "soundstripe/flag/LOAD_SUCCESS",
  LOAD_FAIL: "soundstripe/flag/LOAD_FAIL",
}

export const actions = {
  load: createAction(types.LOAD),
  loadSuccess: createAction(types.LOAD_SUCCESS, "data"),
  loadFail: createAction(types.LOAD_FAIL, "error"),
}

const initialState = fromJS({
  flags: [],
  loading: false,
  error: null,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD:
      return state.set("loading", true)
    case types.LOAD_SUCCESS:
      return state.set("flags", action.data).set("loading", false)
    case types.LOAD_FAIL:
      return state.set("loading", false).set("error", action.error)
    default:
      return state
  }
}

const selectFlagState = (state) => state.getIn(["ducks", "flag"])

export const selectLoading = () =>
  createSelector(selectFlagState, (state) => state.get("loading"))

export const selectFlags = () =>
  createSelector(selectFlagState(), (state) => state.get("flags"))

export const selectFlag = (flagName) =>
  createSelector(selectFlagState, (state) =>
    state.get("flags").find((flag) => flag.name === flagName)
  )

export const selectIsFlagEnabled = (flagName) =>
  createSelector(selectFlagState, (state) => {
    const flag = state.get("flags").find((flag) => flag.name === flagName)

    if (flag) {
      return flag.enabled
    } else {
      return false
    }
  })
