import Immutable from "immutable"

import { createRelationships } from "records/Relationship"

const VideoEncodingRecord = Immutable.Record(
  {
    id: null,
    type: "video_encoding",
    // Attributes
    aspect_ratio: "",
    audio_channels: 0,
    audio_codec: "",
    audio_sample_rate: 0,
    audio_bitrate: 0,
    bitrate: 0,
    codec: "",
    container: "",
    duration: 0,
    filesize: 0,
    filetype: "",
    framerate: 0.0,
    height: 0,
    raw_metadata: {},
    version: "",
    video_id: 0,
    width: 0,

    // Relationships
    relationships: {
      video: [],
    },
  },
  "Video"
)

class VideoEncoding extends VideoEncodingRecord {
  constructor(args) {
    super(
      Object.assign({}, args, {
        relationships:
          args.relationships &&
          createRelationships(args.relationships, {
            video: ["video", "videos"],
          }),
      })
    )
  }
}

export default VideoEncoding
