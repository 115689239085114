import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectCurrentUserRecord } from "ducks/currentUser"
import { actions as subscriptionActions } from "ducks/subscription"
import { getUpgradePlanOptions } from "utils/plan"
import useOffer from "hooks/useOffer"

// Preload estimates when a user signs in, or changes their plan. Ideally
// this would be done with sagas, but flags are fetched with react-query,
// and debug flags (which can enable offers) are stored in a React context,
// so this component just ties that all together.
const UpgradeModalEstimateLoader = () => {
  const dispatch = useDispatch()
  const currentUser = useSelector(selectCurrentUserRecord())
  const userPlanId = currentUser.planId()

  const offer = useOffer()

  useEffect(() => {
    if (!userPlanId) return

    const planIds = getUpgradePlanOptions(userPlanId)

    planIds.forEach((planId) => {
      if (!planId) return null

      const couponCode =
        offer && offer.planIds.includes(planId) ? offer.couponCode : null

      dispatch(
        subscriptionActions.generatePlanEstimate(planId, null, couponCode)
      )
    })
  }, [userPlanId, dispatch, offer])

  return null
}

export default UpgradeModalEstimateLoader
