import { call, put, takeLatest, takeEvery, select } from "redux-saga/effects"
import { startSubmit, stopSubmit, destroy } from "redux-form/immutable"
import { post, patch } from "utils/request"
import { actions as uiActions } from "ducks/ui"
import { actions as modalActions } from "ducks/modal"
import { actions as apiActions } from "ducks/api"
import { actions as playlistableActions } from "ducks/playlistable"
import { types } from "ducks/playlist"
import { mediaTypeToApi } from "utils/mediaType"
import { selectCurrentUserRecord } from "ducks/currentUser"
import { trackUserpilot } from "utils/tracking"

export function* createPlaylist(action) {
  yield put(startSubmit(action.form))

  try {
    const user = yield select(selectCurrentUserRecord())
    let playlist = action.playlist
    playlist = playlist.toJS()
    const { playlistItem } = action
    const requestURL = `${process.env.API_URL}/playlists`
    const mediaType = mediaTypeToApi(playlistItem.type)
    const data = {
      data: {
        type: "playlists",
        attributes: {
          media_type: mediaType,
          ...playlist.attributes,
        },
      },
    }
    const options = {
      data: JSON.stringify(data),
    }
    const response = yield call(post, requestURL, options)
    yield put(
      playlistableActions.addToPlaylist(
        response.data,
        playlistItem,
        null,
        action.onSuccess
      )
    )
    yield put(modalActions.close())
    yield put(stopSubmit(action.form))
    yield put(destroy(action.form))
    if (action.mobileSongObject) {
      yield put(
        modalActions.open("MobileSongMenuModal", action.mobileSongObject)
      )
    }
    if (user.playlists_count === 0) {
      trackUserpilot("Created First Playlist")
    }
  } catch (err) {
    yield put(stopSubmit(action.form, { _error: err }))
  }
}

export function* watchCreatePlaylist() {
  yield takeLatest(types.CREATE, createPlaylist)
}

export function* updateSongPosition(action) {
  try {
    const playlist = action.playlist
    const requestURL = `${process.env.API_URL}/playlists/${playlist.id}/playlist_items`

    const data = {
      data: [
        {
          type: "songs",
          id: action.songId,
          attributes: {
            position: action.index,
          },
        },
      ],
    }
    const options = {
      data: JSON.stringify(data),
    }
    yield call(patch, requestURL, options)
  } catch (err) {
    yield put(uiActions.setError(err))
  }
}

export function* watchUpdateSongPosition() {
  yield takeEvery(types.UPDATE_SONG_POSITION, updateSongPosition)
}

export function* updatePublished(action) {
  try {
    const requestURL = `${process.env.API_URL}/playlists/${action.playlistId}`

    const data = {
      data: {
        type: "playlists",
        id: action.playlistId,
        attributes: {
          published: action.published,
        },
      },
    }
    const options = {
      data: JSON.stringify(data),
    }
    const response = yield call(patch, requestURL, options)
    yield put(apiActions.createOrUpdateSuccess(response))
  } catch (err) {
    yield put(uiActions.setError(err))
  }
}

export function* watchUpdatePublished() {
  yield takeEvery(types.UPDATE_PUBLISHED, updatePublished)
}

export default [
  watchCreatePlaylist,
  watchUpdateSongPosition,
  watchUpdatePublished,
]
