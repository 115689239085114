import { call, put, takeEvery } from "redux-saga/effects"

import { get, toQueryString } from "utils/request"
import { actions as uiActions } from "ducks/ui"
import { actions, types } from "ducks/searchFilter"
import { searchClient } from "utils/algolia"

const ENERGY_TAGS = ["very_low", "low", "medium", "high"]

export function* load(action) {
  if (action.filterType === "energyTags") {
    const filters = ENERGY_TAGS.map((e) => ({
      id: e,
      type: "tags",
      attributes: {
        name: e
          .split("_")
          .map((w) => w.charAt(0).toUpperCase() + w.substr(1))
          .join(" "),
      },
    }))
    yield put(actions.loadSuccess(filters, action.filterType))
    return
  }
  try {
    let filterType
    switch (action.filterType) {
      case "playlists":
        filterType = "curated_playlists"
        break
      default:
        filterType = action.filterType.replace(
          /([A-Z])/g,
          ($1) => `_${$1.toLowerCase()}`
        )
    }
    const params = {
      page: {
        limit: 500,
      },
    }
    if (action.mediaType) params.media_type = action.mediaType
    const query = toQueryString(params)
    const basePath = filterType.endsWith("_tags")
      ? `${filterType}`
      : `ui/search/${filterType}`
    let response = {
      links: {
        next: `${process.env.API_URL}/${basePath}?${query}`,
      },
    }
    let filters = []
    while (response.links.next) {
      const requestURL = response.links.next
      response = yield call(get, requestURL)
      const responseFilters = response.data
      filters = filters.concat(responseFilters)
      if (filterType.endsWith("_tags")) {
        response = { links: {} }
      }
    }
    const uniqueFilters = Array.from(new Set(filters.map((a) => a.id))).map(
      (id) => {
        return filters.find((a) => a.id === id)
      }
    )

    yield put(actions.loadSuccess(uniqueFilters, action.filterType))
  } catch (err) {
    yield put(actions.loadFailed())
    yield put(uiActions.setError(err))
  }
}

export function* loadFacets({ mediaType }) {
  try {
    const indexes = [
      process.env.ALGOLIA_SONG_INDEX_NAME,
      process.env.ALGOLIA_MARKETPLACE_SONG_INDEX_NAME,
      process.env.ALGOLIA_SFX_INDEX_NAME,
      process.env.ALGOLIA_VIDEO_INDEX_NAME,
    ]

    const queries = indexes.map((index) => {
      return {
        indexName: index,
        hitsPerPage: 0,
        params: {
          facets: ["*"],
        },
      }
    })

    const algoliaRes = yield call(searchClient.multipleQueries, queries)

    const result = algoliaRes.results
      ? algoliaRes.results.find((r) => r.index.includes(mediaType))
      : {}

    yield put(actions.loadFacetsSuccess(result.facets))
  } catch (err) {
    yield put(actions.loadFailed())
    yield put(uiActions.setError(err))
  }
}

export function* watchLoadFilters() {
  yield takeEvery(types.LOAD, load)
}

export function* watchLoadFacets() {
  yield takeEvery(types.LOAD_FACETS, loadFacets)
}

export default [watchLoadFilters, watchLoadFacets]
