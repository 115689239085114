import { types as trackingTypes } from "ducks/tracking"
import { debugFlag } from "utils/debugFlag"

const css = "background: #222; color: #bada55; padding: 12px;"

// This tracking endpoint serves two purposes:
// 1. log the tracking calls in a dev environment for testing & QA
// 2. Attach the current app state to the tracking event, this
//    can be used for future analytics
const middleware = () => (store) => (next) => (action) => {
  if (action.type === trackingTypes.TRACK_MIXPANEL) {
    if (debugFlag("DEBUG_TRACKING")) {
      // eslint-disable-next-line no-console
      console.log(
        `%cMIXPANEL EVENT: ${action.eventName}`,
        css,
        action.eventProperties
      )
    }
  }

  next(action)
}

export default middleware
