import Immutable from "immutable"
import { createRelationships } from "records/Relationship"

const ProjectRecord = Immutable.Record(
  {
    id: null,
    hashid: null,
    type: "projects",

    // Attributes
    name: "",
    copyright_information_download_url: "",
    license_agreement_download_url: "",
    cue_sheet_templates_download_url: "",
    description: "",
    film_or_broadcast: false,
    is_first_project: false,
    song_file_format: "",
    auto_download_stems: false,
    video_resolution: "",
    resolution_not_available: "",
    account_id: null,
    user_id: null,
    created_at: null,
    updated_at: null,
    song_ids: [],
    sound_effect_ids: [],
    video_ids: [],
    owner: {},

    relationships: {
      songs: {},
      sound_effects: {},
      videos: {},
    },
  },
  "Project"
)

const relationshipTypes = {
  songs: ["songs"],
  sound_effects: ["sound_effects"],
  videos: ["videos"],
}

class Project extends ProjectRecord {
  constructor(args) {
    super(
      Object.assign({}, args, {
        relationships: createRelationships(
          args.relationships,
          relationshipTypes
        ),
        showPath: `/projects/${args.hashid}`,
      })
    )
  }

  hasNoMedia = () =>
    this.song_ids.length === 0 &&
    this.sound_effect_ids.length === 0 &&
    this.video_ids.length === 0
}

export default Project
