import { useSelector } from "react-redux"
import {
  selectCurrentUserRecord,
  selectCurrentUserIsLoading,
} from "ducks/currentUser"

const useCurrentUser = () => {
  const currentUser = useSelector(selectCurrentUserRecord())
  const currentUserLoading = useSelector(selectCurrentUserIsLoading())

  return {
    currentUser,
    currentUserLoading,
  }
}

export default useCurrentUser
