import PropTypes from "prop-types"
import React, { useState, memo } from "react"
import styled, { withTheme } from "styled-components"
import classNames from "classnames"

import Icon from "components/Icon"
import {
  ICON_SS_HEART_EMPTY,
  ICON_SS_HEART_FULL,
  ICON_SS_ELLIPSIS,
  ICON_SS_CLOSE,
} from "components/Icon/constants"
import SongActions from "components/MarketplaceSong/components/SongActions"
import {
  MarketplaceSong as MarketplaceSongRecord,
  User as UserRecord,
} from "records"

import MorePopover from "./MorePopover"
// import CollectionPopover from "./CollectionPopover"

const ActionWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 0 1;
  justify-content: center;
  padding: 0 ${(props) => props.theme.space[7]};
  position: relative;
`

const HeartIconWrapper = styled(SongActions)`
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 33.5px;
  cursor: pointer;
  position: relative;

  &:hover {
    &:after {
      content: "Favorite";
      position: absolute;
      bottom: 2px;
      left: -6px;
      white-space: nowrap;
      font-size: 10px;
      color: ${(props) => props.theme.colors.text.secondary};
    }
  }

  &.favorited {
    &:hover {
      &:after {
        content: "Unfavorite";
        left: -12px;
      }
    }
  }
`

// const CollectionWrapper = styled.div`
//   display: flex;
//   align-items: center;
//   height: 100%;
//   cursor: pointer;
//   position: relative;
//   padding-right: 33.5px;

//   &:hover {
//     &:after {
//       content: "Add To Collection";
//       position: absolute;
//       bottom: 2px;
//       left: -26px;
//       white-space: nowrap;
//       font-size: 10px;
//       color: ${(props) => props.theme.colors.text.secondary};
//     }

//     &.collectionPopoverVisible {
//       &:after {
//         content: "Close";
//         left: -1px;
//       }
//     }
//   }
// `

const MoreWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  position: relative;
  margin-left: ${(props) => props.theme.space[4]};

  &:hover {
    &:after {
      content: "More";
      position: absolute;
      bottom: 2px;
      left: -1px;
      white-space: nowrap;
      font-size: 10px;
      color: ${(props) => props.theme.colors.text.secondary};
    }

    &.morePopoverVisible {
      &:after {
        content: "Close";
      }
    }
  }
`

const ActionSection = ({ song, currentUser, theme }) => {
  const [morePopoverVisible, setMorePopoverVisible] = useState(false)
  // const [collectionPopoverVisible, setCollectionPopoverVisible] = useState(
  //   false
  // )

  const handleToggleMorePopover = (e) => {
    e.preventDefault()
    if (!morePopoverVisible) {
      setMorePopoverVisible(true)
      setTimeout(() => {
        window.addEventListener("click", function onClick() {
          setMorePopoverVisible(false)
          window.removeEventListener("click", onClick)
        })
      })
    }
  }

  // const handleToggleCollectionPopover = (e) => {
  //   e.preventDefault()
  //   if (!collectionPopoverVisible) {
  //     setCollectionPopoverVisible(true)
  //     setTimeout(() => {
  //       window.addEventListener("click", function onClick() {
  //         setCollectionPopoverVisible(false)
  //         window.removeEventListener("click", onClick)
  //       })
  //     })
  //   }
  // }

  return (
    <ActionWrapper>
      <HeartIconWrapper
        className={classNames({
          favorited: currentUser.favoritedSong(song.id),
        })}
        song={song}
      >
        <Icon
          color={
            currentUser.favoritedSong(song.id)
              ? theme.colors.text.accent
              : undefined
          }
          icon={
            currentUser.favoritedSong(song.id)
              ? ICON_SS_HEART_FULL
              : ICON_SS_HEART_EMPTY
          }
          size={22}
        />
      </HeartIconWrapper>
      {/* <CollectionWrapper
        className={classNames({
          collectionPopoverVisible,
        })}
        onClick={handleToggleCollectionPopover}
      >
        <Icon icon={ICON_SS_ADD} size={22} />
      </CollectionWrapper>
      {collectionPopoverVisible && (
        <CollectionPopover
          song={song}
          currentUser={currentUser}
          closeMorePopover={() => setCollectionPopoverVisible(false)}
        />
      )} */}
      <MoreWrapper
        onClick={handleToggleMorePopover}
        className={classNames({
          morePopoverVisible,
        })}
      >
        <Icon
          icon={morePopoverVisible ? ICON_SS_CLOSE : ICON_SS_ELLIPSIS}
          size={22}
        />
      </MoreWrapper>
      {morePopoverVisible && (
        <MorePopover
          song={song}
          currentUser={currentUser}
          closeMorePopover={() => setMorePopoverVisible(false)}
        />
      )}
    </ActionWrapper>
  )
}

ActionSection.propTypes = {
  song: PropTypes.instanceOf(MarketplaceSongRecord).isRequired,
  currentUser: PropTypes.instanceOf(UserRecord).isRequired,
}

export default memo(withTheme(ActionSection))
