import { Record } from "immutable"
import { createSelector } from "reselect"
import { createAction } from "./utils"

export const types = {
  OPEN: "soundstripe/modal/OPEN",
  CLOSE: "soundstripe/modal/CLOSE",
}

export const actions = {
  open: createAction(types.OPEN, "storeKey", "data"),
  close: createAction(types.CLOSE),
}

export const ModalRecord = Record(
  {
    storeKey: null,
    data: {},
  },
  "Modal"
)

export default (state = new ModalRecord(), action) => {
  switch (action.type) {
    case types.OPEN:
      return state.set("storeKey", action.storeKey).set("data", action.data)
    case types.CLOSE:
      return new ModalRecord()
    default:
      return state
  }
}

const selectModalState = (state) => state.getIn(["ducks", "modal"])

export const selectModalStoreKey = () =>
  createSelector(selectModalState, (modalState) => modalState.get("storeKey"))

export const selectModalData = () =>
  createSelector(selectModalState, (modalState) => modalState.get("data"))
