import styled from "styled-components"

const Status = styled.div`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.xs}) {
    display: block;
    flex: 0 0 32px;
  }
`

export default Status
