import { Portal } from "react-portal"
import key from "keymaster"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useDebugFlag } from "context/DebugFlagContext"
import Box from "components/Box"
import Checkbox from "components/Checkbox"
import Input from "components/Input/New"
import { Text } from "components/Typography"

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background.layered};
  border-radius: ${({ theme }) => theme.radii.default};
  bottom: 0;
  box-shadow: ${({ theme }) => theme.shadows.default};
  display: flex;
  flex-direction: column;
  margin: 16px;
  padding: 16px;
  position: fixed;
  right: 0;
  z-index: 9999999;
`

const DebugMenu = () => {
  const [open, setOpen] = useState(false)
  const { debugFlags, setDebugFlag, DEBUG_FLAGS } = useDebugFlag()

  useEffect(() => {
    key("alt+d, ctrl+d, d", (e) => {
      e.preventDefault()
      setOpen((prev) => !prev)
    })
  }, [])

  if (!open) {
    return null
  }

  return (
    <Portal>
      <Wrapper>
        {Object.keys(DEBUG_FLAGS).map((flag) => {
          const flagName = DEBUG_FLAGS[flag]
          const flagValue = debugFlags[flag]
          if (flagName.includes("Timer"))
            return (
              <Box color="text.primary" key={flagName}>
                <Text fontWeight="semiBold" as="label" htmlFor="start">
                  {flagName}
                </Text>
                <Input
                  type="datetime-local"
                  value={flagValue}
                  onChange={(e) => setDebugFlag(flag, e.target.value)}
                />
              </Box>
            )
          return (
            <Checkbox
              checked={flagValue}
              key={flagName}
              onChange={() => setDebugFlag(flag, !flagValue)}
              py={2}
              labelFontWeight="medium"
            >
              {flagName}
            </Checkbox>
          )
        })}
      </Wrapper>
    </Portal>
  )
}

export default DebugMenu
