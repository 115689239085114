const colorGradients = [
  { "#da3cad": "#9b12e4" },
  { "#ec3686": "#f156c3" },
  { "#9a23ea": "#6744e9" },
  { "#f35865": "#fa9749" },
  { "#da3cad": "#9b12e4" },
  { "#ec3686": "#f156c3" },
  { "#9a23ea": "#6744e9" },
  { "#f35865": "#fa9749" },
  { "#da3cad": "#9b12e4" },
  { "#ec3686": "#f156c3" },
  { "#9a23ea": "#6744e9" },
  { "#f35865": "#fa9749" },
  { "#da3cad": "#9b12e4" },
  { "#ec3686": "#f156c3" },
  { "#9a23ea": "#6744e9" },
  { "#f35865": "#fa9749" },
  { "#da3cad": "#9b12e4" },
  { "#ec3686": "#f156c3" },
  { "#9a23ea": "#6744e9" },
  { "#f35865": "#fa9749" },
]

const gradientGenerator = (index) => {
  const interval = index % 4 // guarantees number is between 0 and 3
  const gradientKeys = Object.keys(colorGradients[interval])
  const colorOne = gradientKeys[0]
  const colorTwo = colorGradients[interval][gradientKeys]
  return `linear-gradient(135deg, ${colorOne} 0%, ${colorTwo} 100%)`
}

export default gradientGenerator
