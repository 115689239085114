import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import get from "lodash/get"
import key from "keymaster"
import ReactHowler from "react-howler"
import { selectAlgoliaListByStoreKey } from "ducks/algoliaList"
import { selectCurrentUserRecord } from "ducks/currentUser"
import { loggedIn } from "utils/authentication"
import { actions as modalActions } from "ducks/modal"
import { actions as favoritableActions } from "ducks/favoritable"
import { MOBILE, withScreenSize } from "hocs/withScreenSize"
import withMarketplaceMixpanelTracking from "hocs/withMarketplaceMixpanelTracking"
import { currentSortIndexForUser } from "utils/algolia"
import { trackAlgolia } from "utils/tracking"
import InfoSection from "./InfoSection"
import RadioControls from "./RadioControls"
import Volume from "./Volume"
import WaveformSection from "./WaveformSection"
import ActionSection from "./ActionSection"
import MobileView from "./MobileView"
import { prependApiUrl, post } from "utils/request"

import {
  selectPlayerVisible as selectAudioPlayerVisible,
  actions as audioPlayerActions,
} from "ducks/audioPlayer"

import {
  actions,
  MarketplaceAudioPlayerRecord,
  selectMarketplaceAudioPlayer,
  selectMarketplaceAudioPlayerSong,
  selectMarketplaceAudioPlayerPlaylist,
  selectMarketplaceAudioPlayerCursor,
  selectMarketplaceAudioPlayerAudioFile,
} from "ducks/marketplaceAudioPlayer"

import {
  actions as soundEffectsPlayerActions,
  selectSoundEffectsPlayerPlaying,
} from "ducks/soundEffectsPlayer"

import {
  MarketplaceSong as MarketplaceSongRecord,
  User as UserRecord,
} from "records"

const DesktopWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: ${(props) => props.theme.layout.audioPlayer.desktop.height};
  left: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.background.secondary};
  border-top: 1px solid ${(props) => props.theme.colors.border.default};
  z-index: ${(props) => props.theme.zIndices.fixed};
  display: flex;
  justify-content: space-between;
`

const PageContentSection = styled.div``

class MarketplaceAudioPlayer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loadingAudioPeaks: true,
      mute: false,
      peaks: null,
      src: null,
      playing: false,
      resetPos: false,
    }
  }

  async fetchStreamUrl(seekPos) {
    const songId = this.props.song?.id
    if (songId) {
      const getStream = async () => {
        try {
          const json = await post(
            prependApiUrl("marketplace/songs/get_stream"),
            {
              data: { song_id: songId },
            }
          )
          if (json.error) {
            return { error: json.error }
          } else {
            return json
          }
        } catch (err) {
          return { error: err }
        }
      }
      try {
        const streamUrl = await getStream()
        this.setState({ src: streamUrl }, this.handleSetPosition(seekPos))
      } catch (error) {
        console.error("Error fetching getStream URL:", error)
      }
    }
  }

  componentDidUpdate = (prevProps) => {
    // Prev Props
    const {
      marketplaceAudioPlayer: {
        seekPos: prevSeekPos,
        pos: prevPos,
        playing: prevPlaying,
      },
      song: prevSong,
      playlist: prevPlaylist,
      audioFile: prevAudioFile,
    } = prevProps

    // Current Props
    const {
      algoliaState,
      currentUser,
      song,
      audioFile,
      trackMarketplaceMixpanel,
      marketplaceAudioPlayer: {
        radioIndex,
        seekPos,
        parentSongId,
        childSongRank,
        playing,
      },
    } = this.props

    if (!this.player) return null

    if (seekPos !== prevSeekPos) {
      this.player.seek(seekPos)
      this.props.playProgress(seekPos)
    }

    const songChanged =
      song.id !== prevSong?.id || audioFile?.id !== prevAudioFile?.id

    if (songChanged) {
      const newSeekPos = this.state.resetPos ? 0 : seekPos
      this.setState({
        loadingAudioPeaks: true,
        peaks: null,
        src: null,
      })
      this.getPeaks(song, audioFile)
      if (song.tuned_global_id) {
        this.fetchStreamUrl(newSeekPos)
      } else {
        this.setState({ src: audioFile?.mp3Url() })
      }
    }

    // If it was paused, track that they listened this far
    // If the audio file was changed while playing, track that they
    // listened this far. If it wasn't playing, it was tracked when
    // it was paused.
    if (prevSong && prevPlaying && (!playing || songChanged)) {
      trackMarketplaceMixpanel("Played Song", {
        "Play Duration": Math.round(prevPos),
        "Play Completed": songChanged,
        "Song ID": prevSong.id,
        "Song Title": prevSong.title,
        "Song Parent ID": parentSongId,
        "Recommendation Rank": childSongRank,
        "Artist ID": prevSong.primaryArtistId(),
        "Artist Name": prevSong.primaryArtistName(),
        "Playlist ID": get(prevPlaylist, "id", null),
        "Playlist Name": get(prevPlaylist, "name", null),
      })
    }

    const userToken = currentUser.get("id")

    const queryID = algoliaState.get("queryID")

    if (song.tuned_global_id && userToken && songChanged) {
      trackAlgolia(
        queryID ? "clickedObjectIDsAfterSearch" : "clickedObjectIDs",
        {
          userToken,
          eventName: "Song Played",
          index: currentSortIndexForUser(
            currentUser,
            algoliaState.get("sort"),
            song.type
          ),
          ...(queryID && { queryID }),
          objectIDs: [song.id],
          ...(queryID && { positions: [radioIndex + 1] }),
        }
      )
    }
  }

  componentWillUnmount = () =>
    key.unbind("space, left, right, up, down, f, p, shift+., shift+,, ., ,")

  handleAddToPlaylist = () => {
    if (!this.props.song) return

    this.props.addToPlaylist(this.props.song)
  }

  handleToggleFavorite = () => {
    const {
      song,
      currentUser,
      unfavorite,
      favorite,
      signUpModalOpen,
    } = this.props

    if (!song) return

    if (loggedIn()) {
      if (currentUser.favoritedMarketplaceSong(song.id)) {
        unfavorite(song)
      } else {
        favorite(song)
      }
    } else {
      signUpModalOpen({
        action: "marketplace_favorite",
        afterSignup: favorite(song),
      })
    }
  }

  handleNext = () => {
    const { marketplaceAudioPlayerCursor, next } = this.props
    const hasNext =
      marketplaceAudioPlayerCursor && marketplaceAudioPlayerCursor.get("next")
    if (hasNext) {
      this.setState({ resetPos: true }, next())
    }
  }

  handleOnEnd = () => {
    const { ended, marketplaceAudioPlayerCursor } = this.props

    const hasNext =
      marketplaceAudioPlayerCursor && marketplaceAudioPlayerCursor.get("next")

    const endedData = hasNext
      ? { ended: true }
      : { ended: true, playing: false }

    ended(endedData)

    this.handleNext()
  }

  handlePlay = () => {
    if (this.props.audioPlayerVisible) {
      this.props.hideSongAudioPlayer()
    }

    if (this.props.soundEffectIsPlaying) {
      this.props.pauseSoundEffectsPlayer()
    }

    this.step()
  }

  handlePrev = () => {
    this.setState({ resetPos: true }, this.props.prev())
  }

  handleSeek = (e) => {
    this.props.seek(e)
  }

  handleToggleMute = () => {
    if (!this.state.mute && !this.props.marketplaceAudioPlayer.volume) {
      this.props.changeVolume(1)
    } else {
      this.setState((state) => ({ mute: !state.mute }))
    }
  }

  handleTogglePause = () => {
    this.props.togglePause()
  }

  handleVolumeChange = (value) => this.props.changeVolume(value / 100)

  handleSeekBackward = (sec) => {
    const newPos = this.props.marketplaceAudioPlayer.pos - sec
    if (newPos <= 0) {
      this.handlePrev()
    } else {
      this.props.seek(newPos)
    }
  }

  handleSeekForward = (sec) => {
    const { marketplaceAudioPlayer, song, seek } = this.props
    if (song) {
      const newPos = marketplaceAudioPlayer.pos + sec
      if (newPos >= song?.duration) {
        this.handleNext()
      } else {
        seek(newPos)
      }
    }
  }

  handleSetPosition = (pos) => {
    setTimeout(() => {
      this.player.seek(pos)
      this.props.playProgress(pos)
    }, 0)
    this.setState({ resetPos: false })
  }

  getPeaks = (song, audioFile) => {
    const peaks = !!audioFile ? audioFile.waveform_data : song.waveform_data
    this.setState({ peaks, loadingAudioPeaks: false })
  }

  step = () => {
    const howler = this.player?.howler
    const songReady = this.state.src && this.player.seek() > 0 // prevents UI Flashing due to async fetch
    if (howler && songReady) {
      this.props.playProgress(this.player.seek())
      setTimeout(this.step, 200)
    } else {
      // retry
      setTimeout(this.step, 200)
    }
  }

  render() {
    const {
      marketplaceAudioPlayer: {
        playerVisible,
        playing,
        volume,
        radioIndex,
        pos,
        listStoreKey,
      },
      className,
      currentUser,
      screenSize,
      song,
      audioFile,
    } = this.props

    if (!playerVisible) return null

    const mediaItem = audioFile || song

    return (
      <PageContentSection
        className={className}
        ref={(wrapper) => {
          this.wrapper = wrapper
        }}
      >
        <ReactHowler
          ref={(instance) => (this.player = instance)}
          src={[this.state.src]}
          playing={playing}
          html5
          onEnd={this.handleOnEnd}
          onPlay={this.handlePlay}
          format={["aac"]}
          volume={volume}
          mute={this.state.mute}
          preload={true}
        />
        {screenSize === MOBILE ? (
          <MobileView
            handleToggleFavorite={this.handleToggleFavorite}
            handleTogglePause={this.handleTogglePause}
            index={radioIndex}
            playing={playing}
            pos={pos}
            storeKey={listStoreKey}
            song={song}
          />
        ) : (
          <DesktopWrapper>
            <InfoSection song={song} />
            <RadioControls
              handlePrev={this.handlePrev}
              handleNext={this.handleNext}
              handleTogglePause={this.handleTogglePause}
              playing={playing}
              showRadioControls={!!listStoreKey}
            />
            <WaveformSection
              duration={mediaItem?.duration}
              formattedDuration={mediaItem?.durationFormatted()}
              handleSeek={this.handleSeek}
              peaks={this.state.peaks}
              pos={pos}
            />
            <Volume
              handleToggleMute={this.handleToggleMute}
              handleVolumeChange={this.handleVolumeChange}
              mute={this.state.mute}
              volume={volume}
            />
            <ActionSection song={song} currentUser={currentUser} />
          </DesktopWrapper>
        )}
      </PageContentSection>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  favorite: (song) => dispatch(favoritableActions.favorite(song)),
  addToPlaylist: (song) =>
    dispatch(modalActions.open("AddMediaToPlaylistModal", { record: song })),
  changeVolume: (volume) => dispatch(actions.changeVolume(volume)),
  ended: (data) => dispatch(actions.ended(data)),
  next: (noAutoPlay) => dispatch(actions.next(noAutoPlay)),
  playProgress: (pos) => dispatch(actions.progress(pos)),
  prev: () => dispatch(actions.prev()),
  unfavorite: (song) => dispatch(favoritableActions.unfavorite(song)),
  seek: (seekPos) => dispatch(actions.seek(seekPos)),
  pause: () => dispatch(actions.pause()),
  resume: () => dispatch(actions.resume()),
  togglePause: () => dispatch(actions.togglePause()),
  hideSongAudioPlayer: () => dispatch(audioPlayerActions.stop()),
  pauseSoundEffectsPlayer: () =>
    dispatch(soundEffectsPlayerActions.togglePause()),
  signUpModalOpen: (data) => {
    dispatch(modalActions.open("SignUpModal", data))
  },
})

const mapStateToProps = (state) => ({
  algoliaState: selectAlgoliaListByStoreKey("marketplace_songs")(state),
  marketplaceAudioPlayer: selectMarketplaceAudioPlayer()(state),
  currentUser: selectCurrentUserRecord()(state),
  playlist: selectMarketplaceAudioPlayerPlaylist()(state),
  song: selectMarketplaceAudioPlayerSong()(state),
  audioFile: selectMarketplaceAudioPlayerAudioFile()(state),
  audioPlayerVisible: selectAudioPlayerVisible()(state),
  soundEffectIsPlaying: selectSoundEffectsPlayerPlaying()(state),
  marketplaceAudioPlayerCursor: selectMarketplaceAudioPlayerCursor()(state),
})

MarketplaceAudioPlayer.propTypes = {
  addToPlaylist: PropTypes.func.isRequired,
  algoliaState: PropTypes.object,
  marketplaceAudioPlayer: PropTypes.instanceOf(MarketplaceAudioPlayerRecord),
  changeVolume: PropTypes.func.isRequired,
  className: PropTypes.string,
  currentUser: PropTypes.instanceOf(UserRecord),
  ended: PropTypes.func.isRequired,
  favorite: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  pauseSoundEffectsPlayer: PropTypes.func.isRequired,
  playProgress: PropTypes.func.isRequired,
  prev: PropTypes.func.isRequired,
  screenSize: PropTypes.string,
  seek: PropTypes.func.isRequired,
  signUpModalOpen: PropTypes.func,
  song: PropTypes.instanceOf(MarketplaceSongRecord),
  soundEffectIsPlaying: PropTypes.bool,
  togglePause: PropTypes.func.isRequired,
  unfavorite: PropTypes.func.isRequired,
}

export default withScreenSize(
  withMarketplaceMixpanelTracking(
    connect(mapStateToProps, mapDispatchToProps)(MarketplaceAudioPlayer)
  )
)
