import { put, select, takeEvery } from "redux-saga/effects"
import { types, selectSoundEffectsPlayer } from "ducks/soundEffectsPlayer"
import { addToPlayHistory } from "utils/playHistory"

import {
  selectCurrentDaaciId,
  actions as aiSongEditorActions,
} from "ducks/aiSongEditor"

export function* play() {
  const soundEffectsPlayer = yield select(selectSoundEffectsPlayer())
  const currentDaaciId = yield select(selectCurrentDaaciId())
  if (!!currentDaaciId) yield put(aiSongEditorActions.setDaaciId(""))
  addToPlayHistory("sound_effects", { objectId: soundEffectsPlayer.objectId })
}

export function* watchPlay() {
  yield takeEvery(types.PLAY, play)
}

export default [watchPlay]
