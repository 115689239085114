import { createAction } from "./utils"

export const types = {
  PLAY: "soundstripe/recentlyPlayed/PLAY",
  TOGGLE_PAUSE: "soundstripe/recentlyPlayed/TOGGLE_PAUSE",
}

export const actions = {
  play: createAction(types.PLAY),
  togglePause: createAction(types.TOGGLE_PAUSE),
}
