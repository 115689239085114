import { put, takeLatest, select } from "redux-saga/effects"
import { types } from "ducks/ui"
import { actions as uiActions } from "ducks/ui"
import { actions as trackingActions } from "ducks/tracking"
import { selectThemeMode } from "ducks/ui"

// types.THEME_MODE_TOGGLE
export function* toggleThemeMode() {
  try {
    const themeMode = selectThemeMode()(yield select())
    yield put(
      trackingActions.trackMixpanel(
        themeMode === "dark" ? "Enabled Dark Mode" : "Disabled Dark Mode"
      )
    )
  } catch (err) {
    yield put(uiActions.setError(err))
  }
}

export function* watchToggleThemeMode() {
  yield takeLatest(types.THEME_MODE_TOGGLE, toggleThemeMode)
}

export default [watchToggleThemeMode]
