import styled from "styled-components"
import ArtistCredits from "./ArtistCredits"
import Content from "./Content"
import Name from "./Name"

const Title = styled.div`
  align-items: center;
  display: flex;
  flex: 3 1 144px;
  min-width: 0;
  width: 0;

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    &:not(.sfx) {
      flex-grow: 1;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl2}) {
    margin-right: ${(props) => props.theme.space[5]};
  }
`

Title.ArtistCredits = ArtistCredits
Title.Content = Content
Title.Name = Name

export default Title
