import PropTypes from "prop-types"
import React, { memo } from "react"
import styled, { withTheme } from "styled-components"

import Icon from "components/Icon"
import {
  ICON_SS_PREVIOUS,
  ICON_SS_NEXT,
  ICON_SS_PLAY,
  ICON_SS_PAUSE,
} from "components/Icon/constants"

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 0 1;
  justify-content: center;
  position: relative;
  width: 140px;
`

const ControlWrapper = styled.div`
  cursor: pointer;
  margin-bottom: 1px;
  transition: all ${({ theme }) => theme.transitions.quick};

  &.play {
    background: ${({ theme }) => theme.colors.background.elevationLow};
    border-radius: ${({ theme }) => theme.radii.circle};
    margin: 0 ${({ theme }) => theme.space[4]};
    padding: ${({ theme }) => theme.space[3]};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;

    &:hover {
      background: ${({ theme }) => theme.colors.background.elevationMid};
    }
  }
`

const RadioControls = ({
  showRadioControls,
  playing,
  handlePrev,
  handleNext,
  handleTogglePause,
  theme,
}) => (
  <Wrapper>
    {showRadioControls && (
      <ControlWrapper onClick={handlePrev}>
        <Icon
          color={theme.colors.text.secondary}
          hoverColor={theme.colors.text.primary}
          icon={ICON_SS_PREVIOUS}
          size={12}
        />
      </ControlWrapper>
    )}
    <ControlWrapper className="play" onClick={handleTogglePause}>
      <Icon
        color={theme.colors.text.primary}
        icon={playing ? ICON_SS_PAUSE : ICON_SS_PLAY}
        size={22}
      />
    </ControlWrapper>
    {showRadioControls && (
      <ControlWrapper onClick={handleNext}>
        <Icon
          color={theme.colors.text.secondary}
          hoverColor={theme.colors.text.primary}
          icon={ICON_SS_NEXT}
          size={12}
        />
      </ControlWrapper>
    )}
  </Wrapper>
)

export default memo(withTheme(RadioControls))

RadioControls.propTypes = {
  handlePrev: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handleTogglePause: PropTypes.func.isRequired,
  playing: PropTypes.bool.isRequired,
  showRadioControls: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
}
