import Immutable from "immutable"
import { createRelationships } from "records/Relationship"
const SaleRecord = Immutable.Record(
  {
    id: null,
    type: "sales",
    ai_song_edit: false,
    buyer: {},
    copyright_information_download_url: null,
    created_at: null,
    cue_sheet_download_url: null,
    description: null,
    downloaded_copyright_info: null,
    license_agreement_download_url: null,
    license_lifetime: false,
    network_contact_email: null,
    orfium_license_code: "",
    price: 0,
    project_type: null,
    stems_included: false,
    tv_broadcast: false,
    uploaded_cue_sheet_url: null,
    user: {},
    license_type: null,
    relationships: {
      sellable: {},
    },
    included: [],
    single_use_license: false,
  },
  "Sale"
)

const relationshipTypes = {
  sellable: ["songs", "sound_effects", "videos"],
}

class Sale extends SaleRecord {
  constructor(args = {}) {
    super(
      Object.assign({}, args, {
        relationships: createRelationships(
          args.relationships,
          relationshipTypes
        ),
        included: args.included,
      })
    )
  }

  isSingleSong = () => this.license_lifetime
  isSingleUseLicense = () => this.single_use_license
  isFreeDownload = () => this.license_type === "CustomerFreeSongLicense"
}

export default Sale
