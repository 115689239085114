import { css } from "styled-components"

const linkStyles = css`
  align-items: center;
  border: none;
  border-radius: 0;
  color: ${(props) => props.theme.colors.text.secondary};
  cursor: pointer;
  display: flex;
  height: 100%;
  font-size: ${(props) => props.theme.fontSizes.sm};
  font-weight: ${(props) => props.theme.fontWeights.medium};
  letter-spacing: ${(props) => props.theme.letterSpacings[1]};
  padding: 0 ${(props) => props.theme.space[2]};
  text-decoration: none;
  user-select: none;
  white-space: nowrap;

  &:hover {
    color: ${(props) => props.theme.colors.text.primary};
  }

  &.active {
    color: ${(props) => props.theme.colors.text.primary};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 0 ${(props) => props.theme.space[3]};
  }
`

export default linkStyles
