import { debugFlag } from "utils/debugFlag"

const css = "background: #222; color: #daba99; padding: 12px;"

export const convertTriggerConversion = (goalId) => {
  const convertArr = window._conv_q || []
  convertArr.push(["triggerConversion", goalId])

  if (debugFlag("DEBUG_TRACKING")) {
    // eslint-disable-next-line no-console
    console.log(`%cCONVERT CONVERSION: Goal ${goalId}`, css)
  }
}

export const convertTriggerRevenueConversion = (revenue, goalId) => {
  const convertArr = window._conv_q || []
  const formattedEventData = ["pushRevenue", revenue, 1, goalId]
  convertArr.push(formattedEventData)

  if (debugFlag("DEBUG_TRACKING")) {
    // eslint-disable-next-line no-console
    console.log(
      `%cCONVERT REVENUE CONVERSION: Event Name: ${formattedEventData[0]}, Revenue: ${formattedEventData[1]}, Quantity: ${formattedEventData[2]}, Goal ID: ${formattedEventData[3]}`,
      css
    )
  }

  if (window && window.bugbug) {
    window.bugbugConvert = window.bugbugConvert || {}
    window.bugbugConvert = {
      ...window.bugbugConvert,
      ...{ [goalId]: formattedEventData },
    }
  }
}

export const getConvertExperimentId = (envVar) => ("" + envVar).split(",")[0]

export const getConvertVariantIds = (envVar) => {
  const [, ...variants] = ("" + envVar).split(",")
  return variants
}

export const getConvertCurrentVariantId = (envVar) => {
  if (!window.convert) return getConvertVariantIds(envVar)[0]
  const activeExperiments = window.convert.currentData?.experiences
  const experimentId = getConvertExperimentId(envVar)
  // If experiments are active, return the selected variant. If not, just return
  // the control variant ID from the ENV string
  if (activeExperiments) {
    return (
      activeExperiments[experimentId]?.variation?.id ||
      getConvertVariantIds(envVar)[0]
    )
  }
  return getConvertVariantIds(envVar)[0]
}

export const getConvertExperiment = (envVar) => {
  return {
    controlVariantId: getConvertVariantIds(envVar)[0],
    currentVariantId: getConvertCurrentVariantId(envVar)?.toString(),
    experimentId: getConvertExperimentId(envVar),
    variantIds: getConvertVariantIds(envVar),
  }
}

export const getConvertUrlParamString = () => {
  if (!(window && window.convert)) {
    return null
  }
  try {
    const hasConvertCookies =
      window.convert.getCookie("_conv_v") && window.convert.getCookie("_conv_s")
    return hasConvertCookies
      ? `_conv_v=${encodeURIComponent(
          window.convert.getCookie("_conv_v")
        )}&_conv_s=${encodeURIComponent(window.convert.getCookie("_conv_s"))}`
      : ""
  } catch (error) {
    return null
  }
}

export default getConvertExperiment
