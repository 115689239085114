import isArray from "lodash/isArray"

export const insertOrUpdateRecords = (
  state,
  records,
  keyPath = ["records"]
) => {
  const insertRecord = (record) =>
    state.setIn([...keyPath, record.type, record.id], record)

  if (isArray(records)) {
    records.forEach((record) => {
      state = insertRecord(record)
    })
    return state
  } else {
    const record = records
    return insertRecord(record)
  }
}

export const clearRecords = (state, recordType) =>
  state.deleteIn(["records", recordType])
