import PropTypes from "prop-types"
import React from "react"
import merge from "lodash/merge"
import { ThemeProvider } from "styled-components"
import { connect } from "react-redux"

import { selectThemeMode } from "ducks/ui"

import flexBoxTheme from "./FlexboxConstants"
import { lightPalette, darkPalette } from "./Colors"

export const breakpoints = {
  xs: "38rem", // 608px
  sm: "48rem", // 768px
  md: "60rem", // 960px
  lg: "75rem", // 1200px
  xl: "90rem", // 1440px
  xl2: "120rem", // 1920px
}

// Breakpoint aliases so that styled-system has access
const BREAKPOINTS_SCALE = Object.assign(
  [
    breakpoints.xs,
    breakpoints.sm,
    breakpoints.md,
    breakpoints.lg,
    breakpoints.xl,
    breakpoints.xl2,
  ],
  breakpoints
)

export const theme = {
  // Border variations
  borders: ["1px solid", "2px solid"],

  // Breakpoints
  breakpoints: BREAKPOINTS_SCALE,

  // Color Palette & Usage Aliases
  colors: {
    ...lightPalette,

    action: {
      default: lightPalette.pink400,
      hover: lightPalette.pink400,
    },

    background: {
      accent: lightPalette.pink300,
      accentSubtle: lightPalette.pink000,
      elevationLow: lightPalette.lightAlpha100,
      elevationMid: lightPalette.lightAlpha200,
      info: lightPalette.blue300,
      infoSubtle: lightPalette.blue000,
      inset: lightPalette.gray000,
      deeperInset: lightPalette.gray100,
      inverted: lightPalette.gray900,
      layered: lightPalette.white,
      primary: lightPalette.white,
      overlayBackdrop: "hsla(0, 0%, 0%, 0.80)",
      slideoutBackdrop: "hsla(0, 0%, 0%, 0.25)",
      secondary: lightPalette.white,
      secondaryOpaque: "rgba(246, 246, 246, 0.89)",
      selected: lightPalette.blue000,

      // Old do not use
      tertiary: "hsla(220, 30%, 60%, 0.09)", // Avoid using: in the process of decpracating

      gradients: {
        loading: `linear-gradient(to right, rgba(13, 15, 17, 0.04) 8%, rgba(13, 15, 17, 0.12) 18%, rgba(13, 15, 17, 0.04) 33%)`,
        subtleBackgroundShift:
          "linear-gradient( 180deg, rgba(255, 255, 255, 1) 0%, rgba(252, 252, 253, 1) 40%, rgba(248, 249, 252, 1) 60%, rgba(245, 247, 250, 1) 80% )",
      },
    },

    border: {
      accent: lightPalette.pink300,
      alert: lightPalette.red300,
      default: lightPalette.gray100,
      dark: lightPalette.gray200,
      darker: lightPalette.gray300,
      info: lightPalette.blue300,
      inverted: lightPalette.gray900,
      selected: lightPalette.blue100,
      subtle: lightPalette.gray100,
      gray: lightPalette.gray500,
    },

    button: {
      cta: {
        bg: {
          default: lightPalette.pink300,
          hover: lightPalette.pink400,
        },
        fg: {
          default: lightPalette.white,
          hover: lightPalette.gray200,
        },
      },
      pinkJoy: {
        bg: {
          default: lightPalette.brand.pinkJoy,
          hover: lightPalette.pink400,
        },
        fg: {
          default: lightPalette.white,
        },
      },
      default: {
        bg: {
          default: "transparent",
        },
        border: {
          default: lightPalette.gray300,
          disabled: lightPalette.gray200,
          hover: lightPalette.gray900,
        },
        fg: {
          default: lightPalette.gray900,
          disabled: lightPalette.gray200,
        },
      },
      primary: {
        bg: {
          default: lightPalette.gray900,
          disabled: lightPalette.gray200,
          hover: lightPalette.gray600,
        },
        border: {
          default: lightPalette.gray900,
          disabled: lightPalette.gray200,
          hover: lightPalette.gray600,
        },
        fg: {
          default: lightPalette.white,
        },
      },
      subtle: {
        bg: {
          default: "transparent",
          hover: lightPalette.lightAlpha100,
          disabled: lightPalette.lightAlpha100,
          active: lightPalette.lightAlpha200,
        },
        border: {
          default: "transparent",
          hover: "transparent",
        },
        fg: {
          default: lightPalette.gray900,
          disabled: lightPalette.gray200,
        },
      },
    },

    text: {
      primary: lightPalette.gray900,
      secondary: lightPalette.gray600,
      subtle: lightPalette.gray500,
      inverted: lightPalette.white,
      accent: lightPalette.pink300,
      alert: lightPalette.red300,
      disabled: lightPalette.gray400,
      info: lightPalette.blue300,
      success: lightPalette.green500,
      played: lightPalette.gray500,
      savings: "#16A34A",
    },

    brands: {
      facebook: "#3b5998",
      x: "#242424",
      dropbox: "#0070e0",
    },

    gradients: {
      primary: "linear-gradient(90deg, #9d34a5 0%, #ff277d 80%)",
      secondary: "linear-gradient(90deg, #9a2ea9 0%, #7800ff 80%)",
      premium:
        "linear-gradient(90deg, rgba(253,119,52,1) 0%, rgba(235,48,124,1) 100%);",
    },

    offer: {
      banner: {
        background: lightPalette.brand.pink,
      },
      outlineBadge: {
        background: "transparent",
        border: lightPalette.brand.pinkJoy,
        text: lightPalette.brand.pinkJoy,
      },
      planCardBody: {
        background: lightPalette.white,
      },
      planCardHeader: {
        background: lightPalette.black,
      },
      solidBadge: {
        background: lightPalette.brand.pinkJoy,
        border: lightPalette.brand.pinkJoy,
        text: lightPalette.white,
      },
      upgradeModal: {
        headerBg: lightPalette.gray000,
        text: lightPalette.gray900,
      },
      // last years below
      background: "hsla(197, 100%, 34%, 1)",
      gradient:
        "linear-gradient(112deg, hsla(197, 100%, 34%, 1) 0%, hsla(263, 79%, 70%, 1) 94%)",
      text: lightPalette.white,
    },

    marketplace: {
      background: {
        secondary: "#f2f2f2",
        tertiary: "#DDDDDD",
        gradient:
          "linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))",
        tooltip: "#D1D5DB",
        license_agreement: lightPalette.black,
      },
      notice: {
        background: lightPalette.blue000,
        border: lightPalette.blue300,
      },
      green: "#059669",
      yellow: "#D97706",
      orange: "#F97316",
      text: {
        disabled: "#AFAFAF",
      },
      scrollbar: "rgba(0, 0, 0, 0.5)",
    },

    alert: lightPalette.red200,
    divider: "rgba(13, 15, 17, 0.16)",
    success: lightPalette.green200,
    info: lightPalette.blue300,
    warn: lightPalette.yellow300,
  },

  // Font Families
  fonts: {
    gintoNord:
      "'GintoNord', 'Graphik', 'Avenir Next', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    graphik:
      "'Graphik', 'Avenir Next', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  },

  // Font size scale
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.25rem",
    xl: "1.5rem",
    xl2: "2rem",
    xl3: "2.5rem",
    xl4: "3rem",
  },

  // Font weight scale
  fontWeights: {
    thin: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },

  // Letter spacing variations
  letterSpacings: [0, ".015em", ".03em", "0.3px", "1px"],

  // Line height scale
  lineHeights: {
    typeSet: "normal",
    locked: 1,
    tight: 1.25,
    normal: 1.5,
    text: {
      xs: "1.33333",
      sm: "1.42857",
      md: "1.5",
      lg: "1.6",
      xl: "32px",
    },
  },

  // Line length
  lineLengths: {
    xs: "22.5rem",
    sm: "26.5rem",
    md: "30rem",
    lg: "37.5rem",
    xl: "46.875rem",
  },

  opacity: {
    disabled: 0.3,
  },

  // Border radius variations
  radii: {
    sharp: 0,
    default: "0.25rem",
    rounder: "0.5rem",
    roundest: "0.75rem",
    pill: "20rem",
    circle: "50%",
  },

  // Element sizing
  sizes: {
    card: {
      videos: {
        md: "221px",
      },
      sound_effects: {
        sm: "106px",
        md: "130px",
      },
      songs: {
        sm: "106px",
        md: "130px",
      },
    },
    container: {
      xxs: "22.5rem",
      xs: "36rem",
      sm: "46rem",
      md: "60rem",
      lg: "80rem",
      xl: "94rem",
      xl2: "120rem",
      full: "100%",
    },
    elements: {
      appBanner: {
        height: {
          desktop: "3rem",
          mobile: "4rem",
        },
      },
      header: {
        height: {
          desktop: "4rem",
          mobile: "3.75rem",
        },
      },
      searchSidebar: {
        width: {
          desktop: "16rem",
          mobile: "100vw",
        },
      },
    },
    modal: {
      sm: "375px",
      md: "512px",
      lg: "608px",
      xl: "768px",
    },
    slideout: "497px",
  },

  // Space scale for margin, padding, positioning
  space: [
    0,
    "0.25rem",
    "0.5rem",
    "0.75rem",
    "1rem",
    "1.25rem",
    "1.5rem",
    "2rem",
    "3rem",
    "4rem",
    "6rem",
    "8rem",
  ],

  // Box shadow variations
  shadows: {
    light: "0px 2px 8px 0px rgba(13, 15, 17, 0.06)",
    default: "0px 2px 6px 0px rgba(13, 15, 17, 0.12)",
    hover: "0px 2px 8px 0px rgba(13, 15, 17, 0.18)",
    overlay: "0 14px 30px 0 rgba(13, 15, 17,0.27)",
    dropdown: "0 12px 64px 0 rgba(13, 15, 17, 0.10)",
    signInForm: "0 14px 90px -10px #cfd3d6",
    accent: "0px 14px 30px rgba(13, 15, 17, 0.27)",
    ledge:
      "0px 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1.5px 1px 0px rgba(0, 0, 0, 0.03), 0px 2px 1px 0px rgba(0, 0, 0, 0.03)",
  },

  // Transition timing and curves
  transitions: {
    slow: "300ms ease-out",
    quick: "150ms ease-out",
  },

  // z-index levels based on element type
  zIndices: {
    default: 1,
    sticky: 300,
    fixed: 400,
    navbar: 450,
    alert: 500,
    toast: 500,
    dropdown: 600,
    spinner: 700,
    popup: 800,
    modalOverlay: 950,
    modal: 999,
  },

  waveform: {
    default: lightPalette.gray200,
    active: lightPalette.pink300,
    played: lightPalette.gray300,
  },

  fontFamily:
    "'Graphik', 'Avenir Next', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",

  // Layout
  layout: {
    sfx: {
      sidebar: {
        width: "250px",
      },
      search: {
        height: "65px",
      },
    },
    audioPlayer: {
      desktop: {
        height: "90px",
      },
      mobile: {
        height: "60px",
      },
    },
  },

  // Tag Selectors
  tagselector: {
    color: lightPalette.gray900,
    bg: lightPalette.gray100,
    bghover: lightPalette.gray200,
  },

  // Inputs
  input: {
    color: lightPalette.gray900,
    bgColor: lightPalette.white,
    blackBgColor: lightPalette.gray800,
    placeholderColor: lightPalette.gray500,
    disabledPlaceholder: lightPalette.gray300,
    disabledBgColor: "rgba(122, 143, 184, 0.13)",
    searchBorderColor: lightPalette.gray100,
    searchBorderFocusColor: lightPalette.gray500,
    searchInputBgColor: lightPalette.gray000,
    border: `1px solid ${lightPalette.gray200}`,
    borderDisabled: lightPalette.gray200,
    borderFocus: `1px solid ${lightPalette.gray900}`,
    borderError: `1px solid ${lightPalette.red200}`,
    boxShadow: "0 2px 4px 0 rgba(207,207,207,0.07)",
    boxShadowFocused: "0 2px 4px 0 rgba(224,198,226,0.5)",
    stickySearch: {
      bg: lightPalette.white,
      border: lightPalette.black,
      dropdown: {
        openBg: lightPalette.white,
        closedHover: "transparent",
        openHover: lightPalette.gray000,
        boxShadow: "0px 2px 8px 0px #0d0f112e",
      },
    },
  },

  // AudioPlayer
  audioPlayer: {
    nextButtonHoverColor: lightPalette.gray900,
    playButtonColor: lightPalette.gray900,
    borderColor: lightPalette.gray000,
    mobileDurationBar: lightPalette.gray200,
    mobileDurationBarProgress: lightPalette.gray900,
  },

  // Chips (Entity, License, Keywords etc...)
  chips: {
    textColor: lightPalette.gray500,
    textSelected: lightPalette.white,
    borderColor: lightPalette.gray300,
    bgColor: "rgba(13, 15, 17, 0.04)",
    bgHover: "rgba(13, 15, 17, 0.08)",
    bgSelected: lightPalette.gray800,
  },

  // Tooltips/Popovers
  // TODO: Build this into a trigger component
  tooltip: {
    triggerBGColor: lightPalette.gray200,
    triggerHoverBGColor: lightPalette.gray300,
    hoverBGColor: "hsla(220, 33%, 97%, 0.08)",
  },

  // react-styled-flexboxgrid defaults
  flexboxgrid: {
    ...flexBoxTheme,
  },
}

export const darkTheme = merge({}, theme, {
  colors: {
    action: {
      default: darkPalette.pink100,
      hover: darkPalette.pink200,
    },

    background: {
      accent: darkPalette.pink300,
      accentSubtle: darkPalette.pink600,
      elevationLow: darkPalette.darkAlpha100,
      elevationMid: darkPalette.darkAlpha200,
      info: darkPalette.blue300,
      infoSubtle: darkPalette.blue500,
      inset: "hsl(220, 14%, 14%)",
      deeperInset: "hsl(220, 20%, 20%)",
      inverted: darkPalette.gray000,
      layered: darkPalette.gray700,
      primary: darkPalette.gray900,
      secondary: darkPalette.gray800,
      secondaryOpaque: "rgba(24, 27, 32, 0.8)",
      selected: "#133881",

      // Old do not use
      tertiary: "hsla(220, 33%, 97%, 0.08)", // Avoid using: in the process of decpracating

      gradients: {
        loading: `linear-gradient(to right, rgba(245, 247, 250, 0.06) 8%, rgba(245, 247, 250, 0.14) 18%, rgba(245, 247, 250, 0.06) 33%)`,
        subtleBackgroundShift:
          "linear-gradient( 0deg, rgba(13, 15, 17) 0%, rgba(18, 20, 23, 1) 40%, rgba(18, 19, 23, 1) 60%, rgba(22, 24, 29, 1) 80% )",
      },
    },

    border: {
      accent: darkPalette.pink300,
      default: darkPalette.gray500,
      dark: darkPalette.gray600,
      darker: darkPalette.gray700,
      info: darkPalette.blue300,
      inverted: darkPalette.gray000,
      subtle: darkPalette.gray700,
      selected: "#3366CC",
      gray: darkPalette.gray500,
    },

    button: {
      cta: {
        bg: {
          default: darkPalette.pink300,
          hover: darkPalette.pink400,
        },
        fg: {
          default: darkPalette.white,
          hover: darkPalette.gray200,
        },
      },
      default: {
        bg: {
          default: "transparent",
        },
        border: {
          default: darkPalette.gray500,
          disabled: darkPalette.gray600,
          hover: darkPalette.gray000,
        },
        fg: {
          default: darkPalette.gray000,
          disabled: darkPalette.gray600,
        },
      },
      primary: {
        bg: {
          default: darkPalette.gray500,
          disabled: darkPalette.gray700,
          hover: darkPalette.gray600,
        },
        border: {
          default: darkPalette.gray500,
          disabled: darkPalette.gray700,
          hover: darkPalette.gray600,
        },
        fg: {
          default: darkPalette.gray000,
        },
      },
      subtle: {
        bg: {
          active: darkPalette.darkAlpha100,
          default: "transparent",
          disabled: darkPalette.darkAlpha200,
          hover: darkPalette.darkAlpha200,
        },
        border: {
          default: "transparent",
          hover: "transparent",
        },
        fg: {
          default: darkPalette.gray000,
          disabled: darkPalette.gray600,
        },
      },
    },

    text: {
      primary: darkPalette.gray000,
      secondary: darkPalette.gray300,
      subtle: darkPalette.gray400,
      inverted: darkPalette.gray900,
      accent: darkPalette.pink200,
      alert: darkPalette.red200,
      disabled: darkPalette.gray500,
      info: darkPalette.blue200,
      success: darkPalette.green200,
      played: darkPalette.gray400,
      savings: "#22C55E",
    },

    // Element color indicators
    alert: darkPalette.red200,
    divider: "rgba(245, 247, 250, 0.1)",
    success: darkPalette.green200,
    info: darkPalette.blue300,
    warn: darkPalette.yellow300,

    offer: {
      banner: {
        background: darkPalette.brand.pink,
      },
      outlineBadge: {
        background: "transparent",
        border: darkPalette.brand.pinkJoy,
        text: darkPalette.white,
      },
      planCardBody: {
        background: darkPalette.gray800,
      },
      planCardHeader: {
        background: darkPalette.gray500,
      },
      solidBadge: {
        background: darkPalette.brand.pinkJoy,
        border: darkPalette.brand.pinkJoy,
        text: darkPalette.white,
      },
      upgradeModal: {
        headerBg: darkPalette.gray900,
        text: darkPalette.gray000,
      },
      // last years below
      background: "hsla(197, 100%, 34%, 1)",
      gradient:
        "linear-gradient(112deg, hsla(197, 100%, 34%, 1) 0%, hsla(263, 79%, 70%, 1) 94%)",
      text: darkPalette.white,
    },

    marketplace: {
      background: {
        secondary: darkPalette.darkAlpha200,
        tertiary: "#DDDDDD",
        gradient: "linear-gradient(rgb(24, 27, 32, 0), rgb(24, 27, 32, 1))",
        tooltip: darkPalette.darkAlpha300,
        license_agreement: darkPalette.darkAlpha200,
      },
      notice: {
        background: darkPalette.blue500,
        border: darkPalette.blue100,
      },
      green: "#059669",
      yellow: "#D97706",
      orange: "#F97316",
      text: {
        disabled: "#AFAFAF",
      },
      scrollbar: "rgba(255, 255, 255, 0.5)",
    },
  },

  shadows: {
    default:
      "0px 2px 6px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
    hover:
      "0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)",
    overlay:
      "0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2)",
    dropdown:
      "0px 8px 12px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2)",
    accent:
      "0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2)",
  },

  waveform: {
    default: darkPalette.gray600,
    active: darkPalette.pink300,
    played: darkPalette.gray400,
  },

  // Chips (Entity, License, Keywords etc...)
  chips: {
    textColor: darkPalette.gray400,
    textSelected: darkPalette.gray900,
    borderColor: darkPalette.gray400,
    borderSelected: darkPalette.gray000,
    bgColor: "rgba(245, 247, 250, 0.06)",
    bgHover: "rgba(245, 247, 250, 0.13)",
    bgSelected: darkPalette.gray100,
  },

  // Tooltips/Popovers
  tooltip: {
    triggerBGColor: darkPalette.gray600,
    triggerHoverBGColor: darkPalette.gray700,
  },

  // Audio player
  audioPlayer: {
    nextButtonHoverColor: darkPalette.white,
    playButtonColor: darkPalette.gray000,
    borderColor: darkPalette.gray700,
    mobileDurationBar: darkPalette.gray800,
    mobileDurationBarProgress: darkPalette.white,
  },

  // Tag Selectors
  tagselector: {
    color: darkPalette.gray000,
    bg: darkPalette.gray600,
    bghover: darkPalette.gray500,
  },

  // Inputs
  input: {
    color: darkPalette.gray100,
    bgColor: darkPalette.gray800,
    blackBgColor: darkPalette.gray900,
    placeholderColor: darkPalette.gray400,
    disabledColor: darkPalette.gray500,
    disabledPlaceholder: lightPalette.gray500,
    disabledBgColor: "rgba(122, 143, 184, 0.13)",
    searchBorderColor: darkPalette.gray700,
    searchBorderFocusColor: darkPalette.gray400,
    searchInputBgColor: darkPalette.gray700,
    border: `1px solid ${darkPalette.gray600}`,
    borderDisabled: darkPalette.gray600,
    borderFocus: `1px solid ${darkPalette.gray000}`,
    boxShadow: "0 2px 4px 0 #181820",
    boxShadowFocused: "0 2px 4px 0 #242463",
    stickySearch: {
      bg: darkPalette.gray500,
      border: lightPalette.black,
      dropdown: {
        openBg: "hsl(220, 14%, 14%)",
        closedHover: "hsl(220, 14%, 14%)",
        openHover: darkPalette.darkAlpha100,
        boxShadow: "none",
      },
    },
  },
})

const lightTheme = theme

const DefaultTheme = ({ themeMode, children }) => {
  const currentTheme = themeMode === "dark" ? darkTheme : lightTheme
  const currentThemeMode = themeMode

  return (
    <ThemeProvider theme={{ ...currentTheme, mode: currentThemeMode }}>
      {children}
    </ThemeProvider>
  )
}

DefaultTheme.propTypes = {
  children: PropTypes.element,
  themeMode: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => ({
  themeMode: selectThemeMode()(state),
})

export default connect(mapStateToProps, null)(DefaultTheme)
