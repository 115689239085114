import {
  spawn,
  call,
  put,
  takeLatest,
  takeEvery,
  select,
} from "redux-saga/effects"
import { _delete, post } from "utils/request"
import { types } from "ducks/projectable"
import { actions as uiActions } from "ducks/ui"
import { actions as modalActions } from "ducks/modal"
import { actions as apiActions } from "ducks/api"
import {
  selectInfiniteListByStoreKey,
  actions as infiniteListActions,
} from "ducks/infiniteList"
import { actions as selectableActions } from "ducks/selectable"

export function* addToProject(action) {
  try {
    const project = action.project
    const projectable = action.projectable
    const requestURL = `${process.env.API_URL}/projects/${project.id}/project_items`
    const data = {
      data: {
        attributes: {
          projectable_id: projectable.id || projectable.objectID,
          projectable_type: projectable.type,
        },
      },
    }
    const options = {
      data: JSON.stringify(data),
    }
    yield call(post, requestURL, options)
    yield put(modalActions.close())
    if (action.onSuccess) action.onSuccess()
    if (action.mobileSongObject) {
      yield put(
        modalActions.open("MobileSongMenuModal", action.mobileSongObject)
      )
    }
  } catch (err) {
    yield put(uiActions.setError(err))
    yield put(modalActions.close())
  }
}

export function* watchAddToProject() {
  yield takeLatest(types.ADD_TO_PROJECT, addToProject)
}

export function* removeFromProject(action) {
  try {
    const { project, projectable, storeKey } = action
    const infList = yield select(selectInfiniteListByStoreKey(storeKey))

    if (infList) {
      const projectableIndex = infList.data.indexOf(projectable.id)
      const newData = infList.data.delete(projectableIndex)
      yield put(infiniteListActions.update(storeKey, "data", newData))
    }
    const requestURL = `${process.env.API_URL}/projects/${project.id}/project_items/${projectable.id}`
    const data = {
      data: {
        attributes: {
          projectable_id: projectable.id.toString(),
        },
      },
    }
    const options = {
      data: JSON.stringify(data),
    }
    yield call(_delete, requestURL, options)
    yield put(apiActions.readEndpoint(`/projects/${project.hashid}`))
    if (projectable?.relationships?.get("audio_files")) {
      yield spawn(
        removeMultiSelectable,
        projectable.relationships
          .get("audio_files")
          .map((audioFile) => audioFile.id)
          .toArray()
      )
    } else {
      yield put(selectableActions.remove(projectable.id))
    }
  } catch (err) {
    yield put(uiActions.setError(err))
  }
}

export function* watchRemoveFromProject() {
  yield takeEvery(types.REMOVE_FROM_PROJECT, removeFromProject)
}

function* removeMultiSelectable(ids) {
  for (const id of ids) {
    yield put(selectableActions.remove(id))
  }
}

export default [watchAddToProject, watchRemoveFromProject]
