import styled from "styled-components"
import { color, space, typography } from "styled-system"

const H3 = styled.h3`
  ${color}
  ${space}
  ${typography}
  text-transform: capitalize;
`

H3.defaultProps = {
  color: "text.primary",
  fontWeight: "500",
  fontSize: { _: "lg", md: "xl" },
  lineHeight: "1.5",
}

export default H3
