import PropTypes from "prop-types"
import React from "react"
import { withTheme } from "styled-components"
import { connect } from "react-redux"
import { push } from "react-router-redux"
import Box from "components/Box"
import Icon from "components/Icon"
import { ICON_SS_SEARCH, ICON_SS_ARROW_RIGHT } from "components/Icon/constants"
import {
  actions as uiActions,
  selectSearchSidebarOpen,
  selectSfxSearchSidebarOpen,
} from "ducks/ui"
import withNewSearchUi from "hocs/withNewSearchUi"

class FilterButton extends React.PureComponent {
  static contextTypes = { router: PropTypes.object }

  static propTypes = {
    push: PropTypes.func.isRequired,
    sidebarOpen: PropTypes.bool,
    sfxSidebarOpen: PropTypes.bool,
    toggleSidebar: PropTypes.func.isRequired,
    theme: PropTypes.object,
    toggleSfxSearchSideBar: PropTypes.func.isRequired,
  }

  handleClick = () => {
    if (this.locationSfxPage()) {
      this.props.toggleSfxSearchSideBar()
    } else if (this.locationVideosPage() || this.locationMarketPage()) {
      this.handleToggleSidebar()
    } else if (!this.locationSongsPage()) {
      this.props.push("/royalty-free-music")
    } else {
      this.handleToggleSidebar()
    }
  }

  handleToggleSidebar = () =>
    this.props.sidebarOpen ? this.hideSidebar() : this.showSidebar()

  hideSidebar = () => {
    this.props.toggleSidebar()
    document.body.classList.remove("overlayed")
  }

  showSidebar = () => {
    document.body.classList.add("overlayed")
    this.props.toggleSidebar()
  }

  hideButton = () =>
    (this.locationSfxPage() ||
      this.locationSongsPage() ||
      this.locationMarketPage() ||
      this.locationVideosPage()) &&
    this.props.isSm

  locationSfxPage = () =>
    this.context.router
      .getCurrentLocation()
      .pathname.match(/^\/sound-effects$/) !== null

  locationSongsPage = () =>
    this.context.router
      .getCurrentLocation()
      .pathname.includes("/royalty-free-music")

  locationVideosPage = () =>
    this.context.router.getCurrentLocation().pathname.match(/^\/video$/) !==
    null

  locationMarketPage = () =>
    this.context.router.getCurrentLocation().pathname.includes("market")

  hideButtonOnMarketPage = () => {
    const newSearchUi = this.props.enabled
    return this.locationMarketPage() && !newSearchUi
  }

  render() {
    const { theme, sidebarOpen, sfxSidebarOpen } = this.props

    // TODO: Remove second conditional when new search UI is released
    if (this.hideButton() || this.hideButtonOnMarketPage()) return null

    return (
      <Box
        onClick={this.handleClick}
        data-cy="mobile-filter-btn"
        style={{ cursor: "pointer" }}
      >
        <Icon
          icon={
            sidebarOpen || sfxSidebarOpen ? ICON_SS_ARROW_RIGHT : ICON_SS_SEARCH
          }
          size={26}
          color={theme.colors.text.primary}
        />
      </Box>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  toggleSfxSearchSideBar: () => dispatch(uiActions.toggleSfxSearchSideBar()),
  toggleSidebar: () => dispatch(uiActions.toggleSearchSideBar()),
  push: (path) => dispatch(push(path)),
})

const mapStateToProps = (state) => ({
  sidebarOpen: selectSearchSidebarOpen()(state),
  sfxSidebarOpen: selectSfxSearchSidebarOpen()(state),
})

export default withNewSearchUi(
  withTheme(connect(mapStateToProps, mapDispatchToProps)(FilterButton))
)
