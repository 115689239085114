import { PARAMS } from "components/SoundEffectSidebar/constants"

const trackMixpanelSoundEffectSearch = (query) => {
  const properties = {
    Query: query[PARAMS.SEARCH],
    Category: query[PARAMS.CATEGORY_LVL0],
    "Sub-Category": query[PARAMS.CATEGORY_LVL1],
  }
  return properties
}

export default trackMixpanelSoundEffectSearch
