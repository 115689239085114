import React from "react"

export const AddMediaToPlaylistModal = React.lazy(() =>
  import("./AddMediaToPlaylistModal")
)
export const AddMediaToProjectModal = React.lazy(() =>
  import("./AddMediaToProjectModal")
)
export const BannerUpgradeModal = React.lazy(() =>
  import("./BannerUpgradeModal")
)
export const BillingAddressModal = React.lazy(() =>
  import("./BillingAddressModal")
)
export const CancelSubscriptionModal = React.lazy(() =>
  import("./CancelSubscriptionModal")
)
export const CartSlideout = React.lazy(() => import("./CartSlideout"))
export const ChangePasswordModal = React.lazy(() =>
  import("./ChangePasswordModal")
)
export const ConfirmationModal = React.lazy(() => import("./ConfirmationModal"))
export const ContentIDSingleUseModal = React.lazy(() =>
  import("./ContentIDSingleUseModal")
)
export const LicensingContactModal = React.lazy(() =>
  import("./LicensingContactModal")
)
export const MarketplaceCustomPaymentModal = React.lazy(() =>
  import("./MarketplaceCustomPaymentModal")
)
export const MarketplaceDocumentsModal = React.lazy(() =>
  import("./MarketplaceDocumentsModal")
)
export const MarketplaceLicenseAgreementModal = React.lazy(() =>
  import("./MarketplaceLicenseAgreementModal")
)
export const CreateProjectModal = React.lazy(() =>
  import("./CreateProjectModal")
)
export const CreateApiKeyModal = React.lazy(() => import("./CreateApiKeyModal"))
export const CustomerCareDowngradeModal = React.lazy(() =>
  import("./CustomerCareDowngradeModal")
)
export const DeleteProjectItemsModal = React.lazy(() =>
  import("./DeleteProjectItemsModal")
)
export const DeleteProjectModal = React.lazy(() =>
  import("./DeleteProjectModal")
)
export const DownloadProjectModal = React.lazy(() =>
  import("./DownloadProjectModal")
)
export const DowngradeConfirmationModal = React.lazy(() =>
  import("./DowngradeConfirmationModal")
)
export const DownloadAudioFilesModal = React.lazy(() =>
  import("./DownloadAudioFilesModal")
)
export const DownloadProjectDocumentsModal = React.lazy(() =>
  import("./DownloadProjectDocumentsModal")
)
export const DownloadDocumentsModal = React.lazy(() =>
  import("./DownloadDocumentsModal")
)
export const DownloadBulkItemsModal = React.lazy(() =>
  import("./DownloadBulkItemsModal")
)
export const DownloadSongModal = React.lazy(() => import("./DownloadSongModal"))
export const MarketplaceDownloadSongModal = React.lazy(() =>
  import("./MarketplaceDownloadSongModal")
)
export const DownloadSoundEffectModal = React.lazy(() =>
  import("./DownloadSoundEffectModal")
)
export const DownloadVideoModal = React.lazy(() =>
  import("./DownloadVideoModal")
)
export const DuplicateProjectItemModal = React.lazy(() =>
  import("./DuplicateProjectItemModal")
)
export const EditAccountInvitationModal = React.lazy(() =>
  import("./EditAccountInvitationModal")
)
export const EditAccountUserModal = React.lazy(() =>
  import("./EditAccountUserModal")
)
export const EditProjectModal = React.lazy(() => import("./EditProjectModal"))
export const EditApiKeyModal = React.lazy(() => import("./EditApiKeyModal"))

export const EnterpriseContactModal = React.lazy(() =>
  import("./EnterpriseContactModal")
)

export const EditProjectItemModal = React.lazy(() =>
  import("./EditProjectItemModal")
)
export const FeatureTourModal = React.lazy(() => import("./FeatureTourModal"))
export const FrameioModal = React.lazy(() => import("./FrameioModal"))
export const FreeUserOnboardingModal = React.lazy(() =>
  import("./FreeUserOnboardingModal")
)
export const HubspotCustomContractModal = React.lazy(() =>
  import("./HubspotCustomContractModal")
)
export const InviteAccountUsersModal = React.lazy(() =>
  import("./InviteAccountUsersModal")
)
export const MobileSongMenuModal = React.lazy(() =>
  import("./MobileSongMenuModal")
)
export const MarketplaceMobileSongMenuModal = React.lazy(() =>
  import("./MarketplaceMobileSongMenuModal")
)
export const MarketplaceWelcomeModal = React.lazy(() =>
  import("./MarketplaceWelcomeModal")
)
export const MultiDistributionLicenseSlideout = React.lazy(() =>
  import("./MultiDistributionLicenseSlideout")
)
export const MarketTieredPricingSlideout = React.lazy(() =>
  import("./MarketTieredPricingSlideout")
)
export const MaxAccountUsersModal = React.lazy(() =>
  import("./MaxAccountUsersModal")
)
export const NotificationModal = React.lazy(() => import("./NotificationModal"))
export const PlanPricingModal = React.lazy(() => import("./PlanPricingModal"))
export const QuarterlyOfferModal = React.lazy(() =>
  import("./QuarterlyOfferModal")
)
export const RemoveAccountUserModal = React.lazy(() =>
  import("./RemoveAccountUserModal")
)
export const RevokeApiKeyModal = React.lazy(() => import("./RevokeApiKeyModal"))
export const SettingsProjectModal = React.lazy(() =>
  import("./SettingsProjectModal")
)
export const SaleTvBroadcastModal = React.lazy(() =>
  import("./SaleTvBroadcastModal")
)
export const SignUpModal = React.lazy(() => import("./SignUpModal"))
export const SingleSongLicenseModal = React.lazy(() =>
  import("./SingleSongLicenseModal")
)
export const SoundEffectLicenseModal = React.lazy(() =>
  import("./SoundEffectLicenseModal")
)
export const SoundEffectLicensingContactModal = React.lazy(() =>
  import("./SoundEffectLicensingContactModal")
)
export const SoundEffectPairedUpsellModal = React.lazy(() =>
  import("./SoundEffectPairedUpsellModal")
)
export const SocialShareModal = React.lazy(() => import("./SocialShareModal"))
export const SongCreditsModal = React.lazy(() => import("./SongCreditsModal"))
export const UpgradeModal = React.lazy(() => import("./UpgradeModal"))
export const SubscribeModal = React.lazy(() => import("./SubscribeModal"))
export const UpsellConfirmationModal = React.lazy(() =>
  import("./UpsellConfirmationModal")
)
export const UpsellModal = React.lazy(() => import("./UpsellModal"))
export const UpdateCompanyModal = React.lazy(() =>
  import("./UpdateCompanyModal")
)
export const VideosNotAvailableModal = React.lazy(() =>
  import("./VideosNotAvailableModal")
)
export const VisitorSFXModal = React.lazy(() => import("./VisitorSFXModal"))
export const YoutubeChannelHelpModal = React.lazy(() =>
  import("./YoutubeChannelHelpModal")
)
