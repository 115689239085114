import PropTypes from "prop-types"
import React, { Suspense, memo } from "react"
import { compose } from "redux"
import { connect } from "react-redux"
import { actions, selectModalStoreKey, selectModalData } from "ducks/modal"
import * as modalComponents from "./modals"

const ModalRoot = ({ storeKey, data, close }) => {
  if (!storeKey) return null
  const ModalComponent = modalComponents[storeKey]

  if (!ModalComponent) {
    console.error(`NO MODAL COMPONENT FOUND FOR THE STORE KEY: ${storeKey}`)
    return null
  }

  const modalClose = () => close()

  return (
    <Suspense fallback={<></>}>
      <ModalComponent modalClose={modalClose} storeKey={storeKey} {...data} />
    </Suspense>
  )
}

ModalRoot.propTypes = {
  close: PropTypes.func.isRequired,
  data: PropTypes.any,
  storeKey: PropTypes.string,
}

const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(actions.close()),
})

const mapStateToProps = (state) => ({
  storeKey: selectModalStoreKey()(state),
  data: selectModalData()(state),
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), memo)

export default enhance(ModalRoot)
