import { Iterable } from "immutable"

/**
 * Recursively deletes a nested key from an object
 *
 * @param  {object} object The object to traverse and remove key from.
 * @param  {array} keys    The hierarchically ordered list of keys to traverse
 *
 * @return {object}       An action object with a type of ERROR_CLEAR passing the error
 */
export const deleteKeyIfExists = (object, keys) => {
  if (keys.length == 1) {
    const key = keys[0]
    delete object[key]
  } else {
    const value = object[keys.shift()]
    if (value) {
      deleteKeyIfExists(value, keys)
    }
  }
}

/**
 * Recursively gets a nested key from an object
 *
 * @param  {object} object The object to traverse and get key from.
 * @param  {array} keys    The hierarchically ordered list of keys to traverse
 *
 * @return {any} The value of the key
 */
export const getKeyIfExists = (object, keys) => {
  if (keys.length == 1) {
    const key = keys[0]
    return object[key]
  } else {
    const value = object[keys.shift()]
    if (value) {
      return getKeyIfExists(value, keys)
    } else {
      return value
    }
  }
}

/**
 * Recursively assigned a value to a nested key within an object
 *
 * @param  {object} object The object to traverse and assign key a value.
 * @param  {array} keys    The hierarchically ordered list of keys to traverse
 */
export const assignNestedKey = (object, keys, value) => {
  if (keys.length == 1) {
    const key = keys[0]
    object[key] = value
  } else {
    const key = keys.shift()
    if (object[key] == null) {
      object[key] = {}
    }
    assignNestedKey(object[key], keys, value)
  }
}

export const deleteDotNotatedKey = (object, dotNotatedKeyPath) => {
  deleteKeyIfExists(object, dotNotatedKeyPath.split("."))
}

export const getDotNotatedKey = (object, dotNotatedKeyPath) => {
  return getKeyIfExists(object, dotNotatedKeyPath.split("."))
}

export const assignDotNotatedKey = (object, dotNotatedKeyPath, value) => {
  return assignNestedKey(object, dotNotatedKeyPath.split("."), value)
}

// Returns objects in the array that aren't deleted
export const activeObjects = (ary) => {
  if (ary) {
    if (Iterable.isIterable(ary)) {
      ary = ary.toJS()
    }
    return ary.filter((obj) => !!!obj.attributes || !!!obj.attributes._destroy)
  } else {
    return []
  }
}
