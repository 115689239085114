import PropTypes from "prop-types"
import React from "react"
import Helmet from "react-helmet"
import { buildUrl } from "utils/buildUrl"

class SEO extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  }

  static propTypes = {
    title: PropTypes.string.isRequired,
    titleCategory: PropTypes.string.isRequired,
    description: PropTypes.string,
    ogType: PropTypes.string,
    image: PropTypes.string,
    imageAlt: PropTypes.string,
    showAppBanner: PropTypes.bool,
  }

  static defaultProps = {
    titleCategory: "Music Licensing",
    ogType: "website",
    image:
      "https://s3-us-west-2.amazonaws.com/soundstripe-production/assets/images/SoundstripeSocialShare.png",
    imageAlt: "Soundstripe",
    showAppBanner: false,
  }

  currentLocationPath = () => {
    const location = this.context.router.getCurrentLocation()
    return location.pathname || "/"
  }

  pageTitle = () => {
    const { title, titleCategory } = this.props
    const titleSuffix =
      titleCategory === "Sound Effect License"
        ? "Royalty Free SFX"
        : "Soundstripe"
    const titleParts = [title, titleSuffix].filter(Boolean)
    return titleParts.join(" | ")
  }

  render() {
    const { description, image, imageAlt, ogType, showAppBanner } = this.props
    const isMarketPage = window.location.pathname.includes("/market")
    const pageTitle = this.pageTitle().trim()
    const pageImage = isMarketPage
      ? "https://soundstripe-production.s3.us-west-2.amazonaws.com/assets/images/MarketSocialShare.png"
      : image
    const url = buildUrl(this.currentLocationPath())

    const meta = [
      // DEFAULTS
      { name: "twitter:card", content: "summary" },
      { name: "twitter:site", content: "@SoundstripeApp" },
      { property: "og:site_name", content: "Soundstripe" },
      { property: "fb:app_id", content: process.env.FACEBOOK_APP_ID },
      // DEFINED BY PROPS
      { name: "description", content: description },
      { name: "twitter:title", content: pageTitle },
      { name: "twitter:image", content: pageImage },
      { name: "twitter:image:alt", content: imageAlt },
      { name: "twitter:description", content: description },
      { property: "og:title", content: pageTitle },
      { property: "og:image", content: pageImage },
      { property: "og:description", content: description },
      { property: "og:type", content: ogType },
      { property: "og:url", content: url },
    ]

    // Mobile App Banner for iOS Safari
    if (showAppBanner) {
      meta.push({ name: "apple-itunes-app", content: "app-id=1618259876" })
    }

    return (
      <Helmet title={pageTitle} meta={meta}>
        <link rel="canonical" href={url} />
      </Helmet>
    )
  }
}

export default SEO
