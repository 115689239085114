import React from "react"

class ChargbeeInitializer extends React.Component {
  constructor(props) {
    super(props)
    this.interval = null
  }

  componentDidMount() {
    this.interval = setInterval(this.initializeChargebeeInstance, 200)
  }

  initializeChargebeeInstance = () => {
    if (window.Chargebee) {
      window.Chargebee.init({
        site: process.env.CHARGEBEE_SITE,
        publishableKey: process.env.CHARGEBEE_PUBLISHABLE_KEY,
      })
      clearInterval(this.interval)
    }
  }

  render() {
    return null
  }
}

export default ChargbeeInitializer
