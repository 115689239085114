import { useDispatch } from "react-redux"
import { actions as trackingActions } from "ducks/tracking"
import { sharedStableId } from "utils/statsig/stable-id"

const useMixpanelTracking = () => {
  const dispatch = useDispatch()

  const trackMixpanel = (eventName, eventProperties) => {
    const convertCurrentExperiences = window.convert?.currentData?.experiences
    const convertHistoricalExperiences =
      window.convert?.historicalData?.experiences

    dispatch(
      trackingActions.trackMixpanel(eventName, {
        ...eventProperties,
        "Current Convert Experiment": convertCurrentExperiences, // Remove after current tests are complete
        "Convert Current Experiences": convertCurrentExperiences,
        "Convert Historical Experiences": convertHistoricalExperiences,
        "Statsig Stable ID": sharedStableId(),
      })
    )
  }

  return {
    trackMixpanel,
  }
}

export default useMixpanelTracking
