import useCurrentUser from "hooks/useCurrentUser"
import { isProduction } from "utils/environment"

const useHubspotTracking = () => {
  const { currentUser } = useCurrentUser()
  const hsq = (window._hsq = window._hsq || [])

  const identifyUser = (userData) => {
    if (isProduction()) {
      hsq.push(["identify", userData])
    }
  }

  const makeEvent = (id, userData) => {
    if (isProduction()) {
      identifyUser(userData)
      hsq.push([
        "trackEvent",
        {
          id,
          userData,
        },
      ])
    }
  }

  const sendPage = (pathname) => {
    if (isProduction()) {
      hsq.push(["setPath", pathname])
      hsq.push(["trackPageView"])
    }
  }

  const trackHubspotEvent = (eventId) => {
    const userData = { email: currentUser.email }
    makeEvent(eventId, userData)
  }

  return {
    identifyUser,
    trackHubspotEvent,
    sendPage,
  }
}

export default useHubspotTracking
