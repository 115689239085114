const trackMixpanelMusicSearch = (searchState) => {
  const { artist, playlists } = searchState
  const activeArtistIds = artist.activeFilters || []
  const activePlaylistIds = playlists.activeFilters || []
  const searchQuery = searchState.searchQuery || ""
  const sort = searchState.sort || ""

  const properties = {
    marketplace: true,
    Query: searchQuery,
    Artists: activeArtistIds,
    Playlists: activePlaylistIds,
    Sort:
      sort === "approved_at"
        ? "order_most_recently_approved"
        : "order_most_popular",
  }

  return properties
}

export default trackMixpanelMusicSearch
