import Immutable from "immutable"
import { createRelationships } from "records/Relationship"
import Pic from "./Pic"
import { mediaTypeFromApi } from "utils/mediaType"

const PlaylistRecord = Immutable.Record(
  {
    id: null,
    type: "playlists",

    // Attributes
    description: "",
    featured: false,
    media_type: null,
    name: "",
    pic: Pic,
    account_id: null,
    playlist_owner: {},
    private: false,
    spotify_id: "",
    published: false,
    runtime_in_seconds: 0,
    playlistable_ids: [],
    showPath: "",
    playlist_items_count: 0,
    typeOfPlaylist: "",
    video_preview_urls: [],
    updated_at: null,
    pillar_page: false,
    url_slug: null,
    title: "",
    blog_content: "",
    title_tag: "",
    meta_description: "",
    song_ids: [],

    // Relationships
    relationships: {
      songs: {},
    },
  },
  "Playlist"
)

const relationshipTypes = {
  songs: ["songs"],
}

class Playlist extends PlaylistRecord {
  constructor(args) {
    super(
      Object.assign({}, args, {
        private: args.typeOfPlaylist === "private_playlists",
        pic: new Pic(args.pic),
        relationships: createRelationships(
          args.relationships,
          relationshipTypes
        ),
        showPath:
          args.typeOfPlaylist === "private_playlists"
            ? `/private_playlists/${mediaTypeFromApi(args.media_type)}/${
                args.id
              }`
            : `/playlists/${args.id}`,
      })
    )
  }
}

export default Playlist
