/**
 * A link to a certain page, an anchor tag
 */

import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { space, typography } from "styled-system"
import { Link } from "react-router"
import classNames from "classnames"
import linkStyles from "./linkStyles"
import Icon from "components/Icon"

const StyledLinkWrapper = styled(Link)`
  ${linkStyles}
  ${space}
  ${typography}
`

const StyledExternalLink = styled.a`
  ${linkStyles}
  ${space}
  ${typography}
`

const StyledLink = ({
  appearance,
  leftIcon,
  rightIcon,
  noUnderline,
  className,
  to,
  ...props
}) => {
  const externalLink = to?.match("^(http|https)://")
  const Component = externalLink ? StyledExternalLink : StyledLinkWrapper
  const linkProp = externalLink ? { href: to } : { to }

  const componentProps = {
    ...props,
    ...linkProp,
    className: classNames(
      className,
      (leftIcon || rightIcon) && "withIcon",
      appearance && appearance,
      noUnderline && "noUnderline"
    ),
  }

  return (
    <Component {...componentProps}>
      {leftIcon && <Icon icon={leftIcon} color="currentColor" />}
      {props.children}
      {rightIcon && <Icon icon={rightIcon} color="currentColor" />}
    </Component>
  )
}

Link.propTypes = {
  appearance: PropTypes.string,
  noUnderline: PropTypes.bool,
  to: PropTypes.string,
}

export default StyledLink
