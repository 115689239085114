import { fromJS, List, OrderedMap, Record } from "immutable"
import { createSelector } from "reselect"
import { selectRecordsByType } from "ducks/api"
import get from "lodash/get"
import { createAction } from "./utils"

export const types = {
  CREATE: "soundstripe/algoliaList/CREATE",
  CREATE_FAILED: "soundstripe/algoliaList/CREATE_FAILED",
  LOAD_MORE: "soundstripe/algoliaList/LOAD_MORE",
  CANCEL_LOAD_MORE: "soundstripe/algoliaList/CANCEL_LOAD_MORE",
  RESET: "soundstripe/algoliaList/RESET",
  STORE: "soundstripe/algoliaList/STORE",
  UPDATE: "soundstripe/algoliaList/UPDATE",
}

export const actions = {
  create: createAction(
    types.CREATE,
    "storeKey",
    "params",
    "search",
    "hitsMapFn",
    "placeholderLimit",
    "ruleContexts"
  ),
  createFailed: createAction(types.CREATE_FAILED, "storeKey"),
  loadMore: createAction(
    types.LOAD_MORE,
    "storeKey",
    "params",
    "page",
    "hitsMapFn",
    "placeholderLimit",
    "ruleContexts"
  ),
  cancelLoadMore: createAction(types.CANCEL_LOAD_MORE, "storeKey"),
  reset: createAction(types.RESET, "storeKey"),
  store: createAction(types.STORE, "storeKey", "data", "meta"),
  update: createAction(types.UPDATE, "storeKey", "attribute", "payload"),
}

export const AlgoliaListRecord = Record(
  {
    currentPage: 0,
    data: new List(),
    facets: {},
    hasResults: false,
    loading: true,
    nextLink: null,
    pages: 0,
    placeholderCount: 0,
    queryID: null,
    sort: null,
    storeKey: null,
    totalCount: 0,
    ruleContexts: [],
  },
  "AlgoliaList"
)

const initialState = fromJS({})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE:
      return state.set(
        action.storeKey,
        new AlgoliaListRecord({
          storeKey: action.storeKey,
          placeholderCount: action.placeholderLimit || 20,
          sort: get(state.get(action.storeKey), "sort"),
          hasResults: true,
        })
      )
    case types.CREATE_FAILED:
      return state.setIn([action.storeKey, "loading"], false)
    case types.LOAD_MORE:
      return state.setIn([action.storeKey, "loading"], true)
    case types.RESET:
      return state.set(
        action.storeKey,
        new AlgoliaListRecord({
          storeKey: action.storeKey,
        })
      )
    case types.STORE:
      const currentList = state.get(action.storeKey)
      const newList = currentList.merge({
        currentPage: get(action, "meta.page", 0),
        data: currentList.data.concat(action.data),
        facets: get(action, "meta.facets", {}),
        loading: false,
        queryID: get(action, "meta.queryID", null),
        pages: get(action, "meta.nbPages", null),
        totalCount: get(action, "meta.nbHits", null),
        hasResults: true,
      })
      return state.set(action.storeKey, newList)
    case types.UPDATE:
      return state.setIn([action.storeKey, action.attribute], action.payload)
    default:
      return state
  }
}

export const selectAlgoliaListState = (state) =>
  state.getIn(["ducks", "algoliaList"])

export const selectAlgoliaListByStoreKey = (storeKey) =>
  createSelector(selectAlgoliaListState, (algoliaListState) => {
    const list = algoliaListState.get(storeKey)
    return list && list.data ? list : new AlgoliaListRecord()
  })

export const selectRecordsFromAlgoliaList = (storeKey, typeOfRecord) =>
  createSelector(
    selectAlgoliaListState,
    selectRecordsByType(typeOfRecord),
    (algoliaListState, recordTypeState) => {
      const infList = algoliaListState.get(storeKey)
      const records = recordTypeState
      const data = infList && infList.data
      if (!data || !records) return {}

      return new OrderedMap({}).withMutations((om) => {
        data.forEach((k) => {
          om.set(k, records.get(k))
        })
      })
    }
  )
