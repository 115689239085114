import queryParser from "querystring"

function determineLocale(options = {}) {
  const defaultOptions = {
    urlLocaleKey: "lang",
    languageCodeOnly: true,
    supportedLocales: ["en"],
    defaultLocale: "en",
  }

  const opt = { ...defaultOptions, ...options }
  let locale = getLocaleFromURL(opt.urlLocaleKey) || getLocaleFromBrowser()

  if (!locale) {
    return opt.defaultLocale
  }

  locale = locale.trim()

  if (opt.languageCodeOnly) {
    locale = locale.split(/-|_/)[0]
  }

  if (!opt.supportedLocales.includes(locale)) {
    locale = opt.defaultLocale
  }

  return locale
}

function getLocaleFromURL(urlLocaleKey) {
  const query = location.search.split("?")

  if (query.length >= 2) {
    const params = queryParser.parse(query[1])
    return params && params[urlLocaleKey]
  }
}

function getLocaleFromBrowser() {
  return (
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage
  )
}

export default determineLocale
