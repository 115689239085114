import Immutable from "immutable"
import Pic from "./Pic"

const MarketplacePlaylistRecord = Immutable.Record(
  {
    id: null,
    type: "marketplace_playlists",

    // Attributes
    name: "",
    description: "",
    pic: Pic,
    published: false,
    playlist_owner: {},
    account_id: null,
    runtime_in_seconds: 0,
    title: "",
    title_tag: "",
    url_slug: null,
    meta_description: "",
    updated_at: null,
    featured: false,
    song_count: 0,
  },
  "MarketplacePlaylist"
)

class MarketplacePlaylist extends MarketplacePlaylistRecord {
  constructor(args) {
    super(
      Object.assign({}, args, {
        pic: new Pic(args.pic),
        showPath: `store/playlists/${args.id}`,
      })
    )
  }
}

export default MarketplacePlaylist
