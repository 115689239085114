export const DURATION_MIN = 0
export const DURATION_MAX = 60

export const SORTING_INDEX_MAP = {
  popular: process.env.ALGOLIA_VIDEO_INDEX_NAME,
  recent: `${process.env.ALGOLIA_VIDEO_INDEX_NAME}_most_recent`,
}

export const FILTERS = {
  ARTISTS: "artist_name",
  CATEGORIES: "categories",
  PLAYLISTS: "playlists",
  DURATION: "versions.duration",
  FAVORITES: "favorites",
  FRAME_RATE: "fps",
  GENDER: "gender",
  LOOPS: "seamless_looping",
  NUMBER_FEMALE: "number_female",
  NUMBER_MALE: "number_male",
  NUMBER_OF_PEOPLE: "number_of_people",
  OBJECT_ID: "objectID",
  RESOLUTION: "video_type",
  SEARCH: "q",
  SHOT_DETAIL: "shot_detail",
  THEME: "theme",
  PREVIOUSLY_LICENSED: "previously_downloaded",
}

export const FACET_TYPES = {
  PLAYLISTS: "playlists.name",
}

export const PARAMS = {
  ARTISTS: `filter[${FILTERS.ARTISTS}][]`,
  CATEGORIES: `filter[${FILTERS.CATEGORIES}][]`,
  PLAYLIST_IDS: "filter[playlist_ids][]",
  DURATION_MAX: `filter[${FILTERS.DURATION}][max]`,
  DURATION_MIN: `filter[${FILTERS.DURATION}][min]`,
  FAVORITES: `filter[${FILTERS.FAVORITES}]`,
  FRAME_RATE: `filter[${FILTERS.FRAME_RATE}][]`,
  GENDER: `filter[${FILTERS.GENDER}][]`,
  LOOPS: `filter[${FILTERS.LOOPS}]`,
  NUMBER_OF_PEOPLE: `filter[${FILTERS.NUMBER_OF_PEOPLE}][]`,
  RESOLUTION: `filter[${FILTERS.RESOLUTION}][]`,
  SEARCH: `filter[${FILTERS.SEARCH}]`,
  SHOT_DETAIL: `filter[${FILTERS.SHOT_DETAIL}][]`,
  SORT_BY: "sort",
  THEME: `filter[${FILTERS.THEME}][]`,
  PREVIOUSLY_LICENSED: "filter[previously_downloaded]",
  PLAYED_TODAY: "filter[played_today]",
  NEGATIVE: {
    ARTISTS: `filter[neg.${FILTERS.ARTISTS}][]`,
    FRAME_RATE: `filter[neg.${FILTERS.FRAME_RATE}][]`,
    RESOLUTION: `filter[neg.${FILTERS.RESOLUTION}][]`,
    THEME: `filter[neg.${FILTERS.THEME}][]`,
    SHOT_DETAIL: `filter[neg.${FILTERS.SHOT_DETAIL}][]`,
    PREVIOUSLY_LICENSED: "filter[neg.previously_downloaded]",
    PLAYED_TODAY: "filter[neg.played_today]",
  },
}

export const SECTIONS = {
  ARTISTS: "Artists",
  PLAYLISTS: "Playlists",
  DURATION: "Duration",
  FRAME_RATE: "Frame Rate",
  LOOPS: "Loops",
  PEOPLE: "People",
  RESOLUTION: "Resolution",
  SHOT_DETAIL: "Shot Detail",
  THEME: "Theme",
  ADVANCED: "Advanced",
}

export const VISIBLE_ARTISTS = {
  addit: "addit",
  Airstock: "Airstock",
  AWorldInMotion: "AWorldInMotion",
  cinejinn: "cinejinn",
  felixmizioznikov: "felixmizioznikov",
  filmmaster: "filmmaster",
  hotelfoxtrot: "hotelfoxtrot",
  icsnaps: "icsnaps",
  ingward: "ingward",
  JensKlingebiel: "JensKlingebiel",
  LBLSIBERIA: "LBLSIBERIA",
  Longjourney: "Longjourney",
  lovemushroom: "lovemushroom",
  LoveVision: "LoveVision",
  LynnMediaGlobal: "LynnMediaGlobal",
  maddrat: "maddrat",
  markoconnell: "markoconnell",
  MediaWhalestock: "MediaWhalestock",
  monkeybusinessimages: "monkeybusinessimages",
  motion_poland: "motion_poland",
  motortion: "motortion",
  MountAiryFilms: "MountAiryFilms",
  naotharp: "naotharp",
  NorwayStock: "NorwayStock",
  pressmaster: "pressmaster",
  reeldealhd: "reeldealhd",
  sheyno: "sheyno",
  SkywardKick: "SkywardKick",
  SkyworksRF: "SkyworksRF",
  Sokolan: "Sokolan",
  StockVideoFactory: "StockVideoFactory",
  TheEngineer: "TheEngineer",
  VIAFilms: "VIAFilms",
  WeatherNews: "WeatherNews",
}

export const THEME_TAGS = {
  "Abstract / Animation": "Abstract / Animation",
  Aerial: "Aerial",
  Agriculture: "Agriculture",
  "Animals / Wildlife": "Animals / Wildlife",
  Business: "Business",
  "Celebration / Holiday": "Celebration / Holiday",
  Cities: "Cities",
  Education: "Education",
  Fashion: "Fashion",
  "Food / Cooking": "Food / Cooking",
  "Green Screen": "Green Screen",
  Industry: "Industry",
  Lifestyle: "Lifestyle",
  "Medical / Health": "Medical / Health",
  Nature: "Nature",
  Science: "Science",
  "Sports / Fitness": "Sports / Fitness",
  Technology: "Technology",
  Transportation: "Transportation",
  Vintage: "Vintage",
  Weather: "Weather",
}

export const SHOT_DETAIL_TAGS = {
  "Close Up": "Close Up",
  Drone: "Drone",
  Macro: "Macro",
  "Point of View": "Point of View",
  "Slow Motion": "Slow Motion",
  Timelapse: "Timelapse",
}

export const FRAME_RATE_TAGS = {
  23.98: "23.98",
  24: "24.0",
  25: "25.0",
  29.97: "29.97",
  30: "30.0",
  "60+": ["59.94", "60.0", "80.0", "90.0", "120.0", "50.0"],
}

export const GENDER_TAGS = {
  Male: "male",
  Female: "female",
  Both: "both",
}

export const NUMBER_TAGS = {
  0: "0",
  1: "1",
  2: "2",
  "3+": "3",
}

export const FILTER_CONFIG = {
  THEME: {
    algoliaFilter: FILTERS.THEME,
    facetType: FILTERS.CATEGORIES,
    params: {
      active: PARAMS.THEME,
      negative: PARAMS.NEGATIVE.THEME,
    },
  },
  FRAME_RATE: {
    algoliaFilter: FILTERS.FRAME_RATE,
    facetType: FILTERS.FRAME_RATE,
    params: {
      active: PARAMS.FRAME_RATE,
      negative: PARAMS.NEGATIVE.FRAME_RATE,
    },
  },
  RESOLUTION: {
    algoliaFilter: FILTERS.RESOLUTION,
    facetType: FILTERS.RESOLUTION,
    params: {
      active: PARAMS.RESOLUTION,
      negative: PARAMS.NEGATIVE.RESOLUTION,
    },
  },
  SHOT_DETAIL: {
    algoliaFilter: FILTERS.SHOT_DETAIL,
    facetType: FILTERS.CATEGORIES,
    params: {
      active: PARAMS.SHOT_DETAIL,
      negative: PARAMS.NEGATIVE.SHOT_DETAIL,
    },
  },
  NUMBER_OF_PEOPLE: {
    algoliaFilter: FILTERS.NUMBER_OF_PEOPLE,
    facetType: FILTERS.NUMBER_OF_PEOPLE,
    params: {
      active: PARAMS.NUMBER_OF_PEOPLE,
    },
  },
  GENDER: {
    algoliaFilter: FILTERS.GENDER,
    facetType: FILTERS.GENDER,
    params: {
      active: PARAMS.GENDER,
    },
  },
  ARTISTS: {
    algoliaFilter: FILTERS.ARTISTS,
    facetType: FILTERS.ARTISTS,
    params: {
      active: PARAMS.ARTISTS,
      negative: PARAMS.NEGATIVE.ARTISTS,
    },
  },
  DURATION: {
    algoliaFilter: FILTERS.DURATION,
    facetType: FILTERS.DURATION,
    params: {
      min: PARAMS.DURATION_MIN,
      max: PARAMS.DURATION_MAX,
    },
    peaks: {
      min: DURATION_MIN,
      max: DURATION_MAX,
    },
  },
  PREVIOUSLY_LICENSED: {
    algoliaFilter: FILTERS.PREVIOUSLY_LICENSED,
    facetType: FILTERS.PREVIOUSLY_LICENSED,
    params: {
      active: PARAMS.PREVIOUSLY_LICENSED,
      negative: PARAMS.NEGATIVE.PREVIOUSLY_LICENSED,
    },
  },
  PLAYED_TODAY: {
    facetType: "played_today",
    params: {
      active: PARAMS.PLAYED_TODAY,
      negative: PARAMS.NEGATIVE.PLAYED_TODAY,
    },
  },
  PLAYLISTS: {
    algoliaFilter: FILTERS.PLAYLISTS,
    facetType: FACET_TYPES.PLAYLISTS,
    joinType: "or",
    params: {
      active: PARAMS.PLAYLIST_IDS,
    },
  },
}
