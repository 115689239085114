import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import styled, { withTheme } from "styled-components"
import { Song as SongRecord, User as UserRecord } from "records"
import withCurrentUser from "hocs/withCurrentUser"
import AudioProgressBar from "components/AudioProgressBar"
import Icon from "components/Icon"
import { useSelector } from "react-redux"
import {
  ICON_SS_PLAY,
  ICON_SS_PAUSE,
  ICON_SS_HEART_FULL,
  ICON_SS_HEART_EMPTY,
  ICON_SS_ELLIPSIS,
} from "components/Icon/constants"
import { actions as modalActions } from "ducks/modal"
import { actions as favoritableActions } from "ducks/favoritable"
import { loggedIn } from "utils/authentication"
import { selectSearchSidebarOpen, selectSfxSearchSidebarOpen } from "ducks/ui"

const MobileViewWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.background.secondary};
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 4rem;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: ${(props) => props.theme.zIndices.fixed};
`

const DurationBar = styled.div`
  height: 2px;
  overflow: hidden;
  padding: 0;
`

const InfoSection = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
  width: 100%;
`

const ClickWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1 0 0;
  min-width: 0;
  padding: ${(props) => props.theme.space[2]} ${(props) => props.theme.space[3]};
`

const SongImgWrapper = styled.div`
  border-radius: ${(props) => props.theme.radii.default};
  height: 40px;
  margin-right: ${(props) => props.theme.space[3]};
  overflow: hidden;
  width: 40px;
`

const SongImg = styled.img`
  height: 100%;
  object-fit: cover;
  width: 100%;
`

const TitleSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: ${(props) => props.theme.space[3]};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const TitleItem = styled.div`
  color: ${(props) => props.theme.colors.text.primary};
  font-size: ${(props) => props.theme.fontSizes.sm};
  line-height: 1.25rem;
  font-weight: ${(props) => props.theme.fontWeights.medium};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;

  &.artist {
    color: ${(props) => props.theme.colors.text.secondary};
    font-weight: ${(props) => props.theme.fontWeights.regular};
  }
`

const ControlsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 0;
  height: 100%;
  justify-content: flex-end;
  margin-right: ${(props) => props.theme.space[2]};
`

const IconWrapper = styled.div`
  cursor: pointer;
  padding: ${(props) => props.theme.space[3]};
`

const MobileView = (props) => {
  const {
    currentUser,
    duration,
    handleTogglePause,
    isPlaying,
    openMobileSongMenuModal,
    song,
    theme,
  } = props

  const searchSidebarIsOpen = useSelector(selectSearchSidebarOpen())
  const sfxSidebarIsOpen = useSelector(selectSfxSearchSidebarOpen())

  const handleAddToFavorites = () => {
    if (loggedIn()) {
      props.favorite()
    } else {
      props.signUpModalOpen({
        action: "favorite",
        afterSignup: props.favorite,
      })
    }
  }

  const handleRemoveFromFavorites = () => {
    if (loggedIn()) {
      props.unfavorite()
    }
  }

  if (searchSidebarIsOpen || sfxSidebarIsOpen) {
    return null
  }

  return (
    <MobileViewWrapper>
      {duration !== 0 && (
        <DurationBar>
          <AudioProgressBar
            onClick={openMobileSongMenuModal}
            onChange={() => {}}
            showDot={false}
            showDuration={false}
          />
        </DurationBar>
      )}
      <InfoSection>
        <ClickWrapper onClick={openMobileSongMenuModal}>
          <SongImgWrapper>
            <SongImg src={song.primaryArtistPicUrl()} />
          </SongImgWrapper>
          <TitleSection>
            <TitleItem>{song.title}</TitleItem>
            <TitleItem className="artist">{song.primaryArtistName()}</TitleItem>
          </TitleSection>
          <Icon class="pointer" icon={ICON_SS_ELLIPSIS} size={24} />
        </ClickWrapper>
        <ControlsWrapper>
          {currentUser.favoritedSong(song.id) ? (
            <IconWrapper onClick={handleRemoveFromFavorites}>
              <Icon
                icon={ICON_SS_HEART_FULL}
                color={theme.colors.text.accent}
                size={24}
              />
            </IconWrapper>
          ) : (
            <IconWrapper onClick={handleAddToFavorites}>
              <Icon icon={ICON_SS_HEART_EMPTY} size={24} />
            </IconWrapper>
          )}
          <IconWrapper onClick={handleTogglePause}>
            <Icon icon={isPlaying ? ICON_SS_PAUSE : ICON_SS_PLAY} size={24} />
          </IconWrapper>
        </ControlsWrapper>
      </InfoSection>
    </MobileViewWrapper>
  )
}

const mapDispatchToProps = (dispatch, props) => ({
  favorite: () => dispatch(favoritableActions.favorite(props.song)),
  unfavorite: () =>
    dispatch(
      favoritableActions.unfavorite(
        props.song,
        props.removeOnUnfavorite && "favoriteSongs"
      )
    ),
  openMobileSongMenuModal: () =>
    dispatch(
      modalActions.open("MobileSongMenuModal", {
        song: props.song,
        index: props.index,
        storeKey: props.storeKey,
      })
    ),
  signUpModalOpen: (data) => {
    dispatch(modalActions.open("SignUpModal", data))
  },
})

export default compose(
  connect(null, mapDispatchToProps),
  withCurrentUser,
  withTheme
)(MobileView)

MobileView.propTypes = {
  currentUser: PropTypes.instanceOf(UserRecord),
  duration: PropTypes.number.isRequired,
  favorite: PropTypes.func.isRequired,
  handleTogglePause: PropTypes.func.isRequired,
  isPlaying: PropTypes.bool.isRequired,
  openMobileSongMenuModal: PropTypes.func.isRequired,
  signUpModalOpen: PropTypes.func,
  song: PropTypes.instanceOf(SongRecord).isRequired,
  unfavorite: PropTypes.func.isRequired,
  theme: PropTypes.object,
}
