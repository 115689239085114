import { fromJS } from "immutable"
import { createSelector } from "reselect"
import { createAction } from "./utils"

const storeJS = require("utils/store.min.js")

export const types = {
  CLEAR: "soundstripe/frameio/CLEAR",
  GET_TOKEN: "soundstripe/frameio/GET_TOKEN",
  GET_ACCOUNTS: "soundstripe/frameio/GET_ACCOUNTS",
  GET_TEAMS: "soundstripe/frameio/GET_TEAMS",
  GET_PROJECTS: "soundsstripe/frameio/GET_PROJECTS",
  GET_SHARED_PROJECTS: "soundsstripe/frameio/GET_SHARED_PROJECTS",
  GET_SUCCESS: "soundstripe/frameio/GET_SUCCESS",
  GET_ERROR: "soundstripe/frameio/GET_ERROR",
  SEND_SONG: "soundstripe/frameio/SEND_SONG",
  SEND_SONG_SUCCESS: "soundstripe/frameio/SEND_SONG_SUCCESS",
  SEND_SONG_ERROR: "soundstripe/frameio/SEND_SONG_ERROR",
  START_AUTHORIZATION: "soundstripe/frameio/START_AUTHORIZATION",
  STOP_AUTHORIZATION: "soundstripe/frameio/STOP_AUTHORIZATION",
  UNLINK_ACCOUNT: "soundstripe/frameio/UNLINK_ACCOUNT",
}

export const actions = {
  clear: createAction(types.CLEAR, "key"),
  getToken: createAction(types.GET_TOKEN, "authCode"),
  getAccounts: createAction(types.GET_ACCOUNTS),
  getTeams: createAction(types.GET_TEAMS, "accountId"),
  getProjects: createAction(types.GET_PROJECTS, "teamId"),
  getSharedProjects: createAction(types.GET_SHARED_PROJECTS),
  getSuccess: createAction(types.GET_SUCCESS, "response"),
  getError: createAction(types.GET_ERROR, "error"),
  sendSong: createAction(types.SEND_SONG, "song", "projectId"),
  sendSongSuccess: createAction(types.SEND_SONG_SUCCESS, "response"),
  sendSongError: createAction(types.SEND_SONG_ERROR, "error"),
  startAuthorization: createAction(types.START_AUTHORIZATION),
  stopAuthorization: createAction(types.STOP_AUTHORIZATION),
  unlinkAccount: createAction(types.UNLINK_ACCOUNT, "payload"),
}

const initialState = fromJS({
  authorizing: false,
  loading: true,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.START_AUTHORIZATION:
      return state.set("authorizing", true)
    case types.STOP_AUTHORIZATION:
      return state.set("authorizing", false)
    case types.GET_ACCOUNTS:
    case types.GET_TEAMS:
    case types.GET_PROJECTS:
    case types.GET_SHARED_PROJECTS:
      return state.set("loading", true)
    case types.GET_SUCCESS:
      return state.set(action.response[0].type, fromJS(action.response))
    case types.GET_ERROR:
      return state.set("loading", false).set("error", action.error)
    case types.CLEAR:
      return state.delete(action.key)
    case types.UNLINK_ACCOUNT:
      storeJS.remove("frameioTeam")
      storeJS.remove("frameioProject")
      return state
    default: {
      return state
    }
  }
}

const frameioState = (state) => state.getIn(["ducks", "frameio"])

export const selectFrameioLoading = () =>
  createSelector(frameioState, (frameio) => frameio.get("loading"))

export const selectFrameioAuthorizing = () =>
  createSelector(frameioState, (frameio) => frameio.get("authorizing"))

export const selectFrameioResource = (resourceType) =>
  createSelector(frameioState, (frameio) =>
    frameio.has(resourceType) ? frameio.get(resourceType) : fromJS({})
  )
