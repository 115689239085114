import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { withRouter } from "react-router"
import { push } from "react-router-redux"
import get from "lodash/get"
import { actions as modalActions } from "ducks/modal"
import { actions as cookieActions } from "middlewares/cookies"
import { loggedIn } from "utils/authentication"
import { replaceQuery } from "utils/urls"
import { MODAL_TYPE, UPGRADE_MODAL_TYPES } from "utils/plan"
import { PLAN_IDS } from "utils/authentication"
import {
  selectCurrentUserRecord,
  selectCurrentUserIsLoading,
} from "ducks/currentUser"

const QueryStringModalLauncher = ({ location, router }) => {
  // We want to ensure we have attempted to fetch the current user before performing any modal actions
  const dispatch = useDispatch()
  const currentUser = useSelector(selectCurrentUserRecord())
  const currentUserLoading = useSelector(selectCurrentUserIsLoading())
  const planId = currentUser.planId()
  const modalQueryStringVal = get(location, "query.modal")
  const handleOnClose = () => router.replace(location.pathname)
  const userLoggedIn = loggedIn() && !currentUserLoading && currentUser.id

  const userModalType = () => {
    if (!currentUser.id) return MODAL_TYPE.VISITOR
    if (!planId) return MODAL_TYPE.FREE_ACCOUNT
    return UPGRADE_MODAL_TYPES[planId] || MODAL_TYPE.VISITOR
  }

  const clearQueryString = () => {
    // eslint-disable-next-line no-unused-vars
    const { modal: _, ...query } = location.query
    if (modalQueryStringVal) {
      replaceQuery(query)
    }
  }

  useEffect(() => {
    switch (modalQueryStringVal) {
      case "upsell_modal":
        if (currentUser?.planId()) {
          dispatch(
            modalActions.open("UpgradeModal", {
              context: "Banner Link",
            })
          )
        } else {
          dispatch(push("/pricing"))
        }
        break
      case "upgrade_modal":
        if (!loggedIn() || userLoggedIn) {
          dispatch(
            modalActions.open("BannerUpgradeModal", {
              onClose: handleOnClose,
              modalType: userModalType(),
            })
          )
        }
        break
      case "quarterly": {
        if (!loggedIn()) {
          dispatch(
            push(`/sign_in?redirect=/account/subscription?modal=quarterly`)
          )
          return () => {}
        }
        if (
          userLoggedIn &&
          !currentUser?.subscription?.has_recurring_coupon &&
          [
            PLAN_IDS.CREATOR_MONTHLY,
            PLAN_IDS.PRO_MONTHLY,
            PLAN_IDS.PRO_PLUS_MONTHLY,
          ].includes(currentUser.planId())
        ) {
          dispatch(
            modalActions.open("QuarterlyOfferModal", {
              onClose: handleOnClose,
            })
          )
        }
        if (userLoggedIn) {
          // remove query string param
          replaceQuery({ modal: null })
        }
      }
      case "download_modal":
        if (!dispatch(cookieActions.getCookie("signup_media_item"))) {
          return () => {}
        } else {
          dispatch(cookieActions.removeCookie("signup_media_item"))
        }

        const [, mediaType, id] = location.pathname.split("/")
        const modalsByMediaType = {
          songs: "DownloadSongModal",
          videos: "DownloadVideoModal",
          sfx: "DownloadSoundEffectModal",
        }

        const key = {
          songs: "songId",
          videos: "videoId",
          sfx: "soundEffectId",
        }

        const modalName = modalsByMediaType[mediaType]
        const modalParamKey = key[mediaType]

        setTimeout(() => {
          dispatch(modalActions.open(modalName, { [modalParamKey]: id }))
        }, 2000)
        break
      case "invite_team_modal":
        if (currentUser.canManageTeam()) {
          dispatch(
            modalActions.open("InviteAccountUsersModal", { signUp: true })
          )
        }
      case "upgrade":
        if (!loggedIn()) {
          dispatch(push("/sign_in?redirect=/?modal=upgrade"))
        }
        if (
          userLoggedIn &&
          currentUser.hasSubscription() &&
          currentUser.isUpgradeEligible()
        ) {
          dispatch(
            modalActions.open("UpgradeModal", {
              context: "Upgrade Query Param",
            })
          )
        }
      default:
        break
    }

    // eslint-disable-next-line no-unused-vars
    clearQueryString()
  }, [location, currentUserLoading, userLoggedIn])

  return null
}

export default withRouter(QueryStringModalLauncher)
