import styled from "styled-components"

const Duration = styled.div`
  color: ${(props) => props.theme.colors.text.secondary};
  display: block;
  flex: 0 0 3.25rem;
  font-size: ${(props) => props.theme.fontSizes.sm};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  line-height: 1.25rem;
  margin-right: ${(props) => props.theme.space[3]};
  text-align: right;

  @media (min-width: ${(props) => props.theme.breakpoints.xs}) {
    display: block;
  }

  &.condensedLayout {
    padding-right: 15px;
  }
`

export default Duration
