import styled from "styled-components"
import Box from "components/Box"

const AudioContentWrapper = styled(Box)`
  border: 1px solid transparent;
  border-radius: ${({ theme }) => theme.radii.default};
  display: flex;
  margin-bottom: ${({ theme }) => theme.space[2]};
  max-width: 100%;
  padding: ${({ theme }) => theme.space[2]};
  position: relative;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    &:not(.condensedLayout) {
      padding: ${({ theme }) => theme.space[3]};
    }
  }

  &.active {
    background: ${({ theme }) => theme.colors.background.elevationLow};
  }

  &.selected {
    border-color: ${({ theme }) => theme.colors.border.selected};
    background: ${({ theme }) => theme.colors.background.selected};
  }

  @media (hover) {
    &:hover {
      &:not(.loading)&:not(.selected) {
        background: ${({ theme }) => theme.colors.background.elevationLow};
      }
    }
  }
`

export default AudioContentWrapper
