import Immutable from "immutable"
import { buildImgixUrl } from "utils/imgix"

const PicRecord = Immutable.Record(
  {
    url: null,
    versions: {
      thumb: {
        url: null,
      },
    },
  },
  "Pic"
)

class Pic extends PicRecord {
  standardUrl() {
    return buildImgixUrl(this.url)
  }

  thumbnailUrl() {
    return buildImgixUrl(this.url, { w: 100, h: 100 })
  }
}

export default Pic
