import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { actions as flagActions } from "ducks/flag"

const FlagLoader = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    let url
    const observer = new MutationObserver((_mutations) => {
      if (url !== window.location.href) {
        url = window.location.href
        dispatch(flagActions.load())
      }
    })

    observer.observe(document.body, { childList: true })

    const listener = () => {
      dispatch(flagActions.load())
    }

    window.addEventListener("focus", listener)

    return () => {
      observer.disconnect()
      window.removeEventListener("focus", listener)
    }
  }, [])

  return null
}

export default FlagLoader
