import { call, put, takeEvery, spawn, select } from "redux-saga/effects"
import { post, _delete, prependApiUrl } from "utils/request"
import { actions as uiActions } from "ducks/ui"
import { actions as currentUserActions } from "ducks/currentUser"
import { types } from "ducks/favoritable"
import {
  actions as infiniteListActions,
  selectInfiniteListByStoreKey,
} from "ducks/infiniteList"
import {
  actions as algoliaListActions,
  selectAlgoliaListByStoreKey,
} from "ducks/algoliaList"

export function* favorite(action) {
  try {
    const { type: recordType, id: recordId } = action.record
    const typeUrl = recordType.includes("marketplace")
      ? recordType.replace("_", "/")
      : recordType
    const requestURL = prependApiUrl(`${typeUrl}/${recordId}/favorite`)
    yield call(post, requestURL)
    yield put(currentUserActions.addFavoritedId(recordType, recordId))
  } catch (err) {
    yield put(uiActions.setError(err))
  }
}

export function* unfavorite(action) {
  try {
    const { type: recordType, id: recordId } = action.record
    const typeUrl = recordType.includes("marketplace")
      ? recordType.replace("_", "/")
      : recordType
    const requestURL = prependApiUrl(`${typeUrl}/${recordId}/favorite`)
    yield call(_delete, requestURL)
    yield put(currentUserActions.removeFavoritedId(recordType, recordId))
    if (action.infListStoreKey) {
      yield spawn(removeFromInfiniteList, recordId, action.infListStoreKey)
    }
    if (action.algoliaListStoreKey) {
      yield spawn(removeFromAlgoliaList, recordId, action.algoliaListStoreKey)
    }
  } catch (err) {
    yield put(uiActions.setError(err))
  }
}

export function* removeFromInfiniteList(recordId, infListStoreKey) {
  try {
    const infList = yield select(selectInfiniteListByStoreKey(infListStoreKey))
    if (infList) {
      const recordIndex = infList.data.indexOf(recordId)
      const newData = infList.data.delete(recordIndex)
      yield put(infiniteListActions.update(infListStoreKey, "data", newData))
    }
  } catch (err) {
    console.error(err)
  }
}

export function* removeFromAlgoliaList(recordId, storeKey) {
  try {
    const infList = yield select(selectAlgoliaListByStoreKey(storeKey))
    if (infList) {
      const recordIndex = infList.data.indexOf(recordId)
      const newData = infList.data.delete(recordIndex)
      yield put(algoliaListActions.update(storeKey, "data", newData))
    }
  } catch (err) {
    console.error(err)
  }
}

export function* watchUnfavorite() {
  yield takeEvery(types.UNFAVORITE, unfavorite)
}

export function* watchFavorite() {
  yield takeEvery(types.FAVORITE, favorite)
}

export default [watchFavorite, watchUnfavorite]
