import { fromJS } from "immutable"
import { createSelector } from "reselect"
import { createAction } from "./utils"

export const types = {
  CREATE: "soundstripe/subscription/CREATE",
  CREATE_SUCCESS: "soundstripe/subscription/CREATE_SUCCESS",
  CREATE_FAILED: "soundstripe/subscription/CREATE_FAILED",
  GENERATE_RENEWAL_ESTIMATE:
    "soundstripe/subscription/GENERATE_RENEWAL_ESTIMATE",
  GENERATE_RENEWAL_ESTIMATE_SUCCESS:
    "soundstripe/subscription/GENERATE_RENEWAL_ESTIMATE_SUCCESS",
  GENERATE_RENEWAL_ESTIMATE_FAILED:
    "soundstripe/subscription/GENERATE_RENEWAL_ESTIMATE_FAILED",

  GENERATE_PLAN_ESTIMATE: "soundstripe/subscription/GENERATE_PLAN_ESTIMATE",
  GENERATE_PLAN_ESTIMATE_SUCCESS:
    "soundstripe/subscription/GENERATE_PLAN_ESTIMATE_SUCCESS",
  GENERATE_PLAN_ESTIMATE_FAILED:
    "soundstripe/subscription/GENERATE_PLAN_ESTIMATE_FAILED",
}

export const actions = {
  create: createAction(types.CREATE, "checkoutAttributes"),
  // Also include the full response from the API to use in the `trackConversionAnalytics` saga
  // Tracking refactoring is a TODO
  createSuccess: createAction(types.CREATE_SUCCESS, "subscription", "response"),
  createFailed: createAction(types.CREATE_FAILED, "error"),
  generateRenewalEstimate: createAction(
    types.GENERATE_RENEWAL_ESTIMATE,
    "subscription"
  ),
  generateRenewalEstimateSuccess: createAction(
    types.GENERATE_RENEWAL_ESTIMATE_SUCCESS,
    "estimate"
  ),
  generateRenewalEstimateFailed: createAction(
    types.GENERATE_RENEWAL_ESTIMATE_FAILED,
    "error"
  ),

  generatePlanEstimate: createAction(
    types.GENERATE_PLAN_ESTIMATE,
    "planId",
    "billingAddress",
    "couponCode"
  ),
  generatePlanEstimateSuccess: createAction(
    types.GENERATE_PLAN_ESTIMATE_SUCCESS,
    "planId",
    "estimate"
  ),
  generatePlanEstimateFailed: createAction(
    types.GENERATE_PLAN_ESTIMATE_FAILED,
    "planId",
    "error"
  ),
}

const initialState = fromJS({
  loading: false,
  renewalEstimate: null,
  renewalEstimateError: null,
  estimates: {}, // For free accounts, subscription estimates, otherwise they are upgrade estimates
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GENERATE_RENEWAL_ESTIMATE:
      return state.set("loading", true)
    case types.GENERATE_RENEWAL_ESTIMATE_SUCCESS:
      return state.set("renewalEstimate", action.estimate).set("loading", false)
    case types.GENERATE_RENEWAL_ESTIMATE_FAILED:
      return state
        .set("renewalEstimate", null)
        .set("renewalEstimateError", action.error)
        .set("loading", false)
    case types.GENERATE_PLAN_ESTIMATE_SUCCESS:
      return state
        .setIn(["estimates", action.planId, "estimate"], action.estimate)
        .setIn(["estimates", action.planId, "loading"], false)
        .setIn(["estimates", action.planId, "error"], null)
    case types.GENERATE_PLAN_ESTIMATE_FAILED:
      return state
        .setIn(["estimates", action.planId, "estimate"], null)
        .setIn(["estimates", action.planId, "loading"], false)
        .setIn(["estimates", action.planId, "error"], action.error)
    default:
      return state
  }
}

const selectSubscriptionState = (state) =>
  state.getIn(["ducks", "subscription"])

export const selectRenewalEstimate = () =>
  createSelector(selectSubscriptionState, (subscriptionState) =>
    subscriptionState.get("renewalEstimate")
  )

export const selectRenewalEstimateLoading = () =>
  createSelector(selectSubscriptionState, (subscriptionState) =>
    subscriptionState.get("loading")
  )

export const selectRenewalEstimateError = () =>
  createSelector(selectSubscriptionState, (subscriptionState) =>
    subscriptionState.get("renewalEstimateError")
  )

export const selectPlanEstimate = (planId) =>
  createSelector(selectSubscriptionState, (subscriptionState) =>
    subscriptionState.getIn(["estimates", planId, "estimate"])
  )

export const selectPlanEstimateError = (planId) =>
  createSelector(selectSubscriptionState, (subscriptionState) =>
    subscriptionState.getIn(["estimates", planId, "error"])
  )

export const selectPlanEstimateLoading = (planId) =>
  createSelector(selectSubscriptionState, (subscriptionState) =>
    subscriptionState.getIn(["estimates", planId, "loading"])
  )
