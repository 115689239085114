import { trackMixpanel } from "utils/tracking"
import { loggedIn } from "utils/authentication"

export function showCrispButton() {
  if (loggedIn()) {
    return
  }
  if (window.$crisp) {
    window.$crisp.push(["do", "chat:show"])
    window.$crisp.push([
      "on",
      "chat:closed",
      function() {
        window.$crisp.push(["do", "chat:show"])
      },
    ])
  }
}

export function hideCrispButton() {
  if (window.$crisp) {
    window.$crisp.push(["do", "chat:hide"])
    window.$crisp.push([
      "on",
      "chat:closed",
      function() {
        window.$crisp.push(["do", "chat:hide"])
      },
    ])
  }
}

export function openLiveChat() {
  if (!window.$crisp) return null

  if (window.$crisp.is("chat:opened")) {
    window.$crisp.push(["do", "chat:close"])
  } else {
    trackMixpanel("Viewed Live Chat")
    window.$crisp.push(["do", "chat:open"])
  }
}

export function playlistRequestCrispChatOpen(email) {
  if (!window.$crisp) return null

  if (window.$crisp.is("chat:opened")) {
    window.$crisp.push(["do", "chat:close"])
  } else {
    trackMixpanel("Requested Playlist")
    window.$crisp.push(["set", "user:email", [email]])
    window.$crisp.push(["do", "chat:open"])
    window.$crisp.push([
      "do",
      "message:send",
      ["text", "I'd like to request a playlist!"],
    ])
    window.$crisp.push("on", "chat:closed", showCrispButton())
  }
}

export function contentIdHelpCrispChatOpen(email) {
  if (!window.$crisp) return null

  if (window.$crisp.is("chat:opened")) {
    window.$crisp.push(["do", "chat:close"])
  } else {
    trackMixpanel("Opened Chat From Content ID")
    window.$crisp.push(["set", "user:email", [email]])
    window.$crisp.push(["do", "chat:open"])
    window.$crisp.push([
      "do",
      "message:send",
      ["text", "I have some questions about Content ID."],
    ])
    window.$crisp.push("on", "chat:closed", showCrispButton())
  }
}

export function youtubeChannelHelpCrispChatOpen(email) {
  if (window.$crisp) {
    window.$crisp.push(["set", "user:email", [email]])
    window.$crisp.push(["do", "chat:open"])
    window.$crisp.push(["do", "chat:close"])
    window.$crisp.push(["do", "chat:open"])
    window.$crisp.push(["do", "chat:show"])
    window.$crisp.push([
      "do",
      "message:send",
      ["text", "I have some questions about finding my YouTube channel."],
    ])
  }
}

export function businessPlanUpgradeCrispChatOpen(email) {
  if (window.$crisp) {
    window.$crisp.push(["set", "user:email", [email]])
    window.$crisp.push(["do", "chat:open"])
    window.$crisp.push(["do", "chat:close"])
    window.$crisp.push(["do", "chat:open"])
    window.$crisp.push(["do", "chat:show"])
    window.$crisp.push([
      "do",
      "message:send",
      ["text", "I'm a business plan user interested in upgrading to video!"],
    ])
  }
}

export function delinquentUpgradeCrispChatOpen(email) {
  if (window.$crisp) {
    window.$crisp.push(["set", "user:email", [email]])
    window.$crisp.push(["do", "chat:open"])
    window.$crisp.push(["do", "chat:show"])
    window.$crisp.push([
      "do",
      "message:send",
      [
        "text",
        "I'd lke to resolve an outstanding invoice so I can upgrade my plan!",
      ],
    ])
  }
}

export function negativeInvoiceUpgradeCrispChatOpen(email) {
  if (window.$crisp) {
    window.$crisp.push(["set", "user:email", [email]])
    window.$crisp.push(["do", "chat:open"])
    window.$crisp.push(["do", "chat:close"])
    window.$crisp.push(["do", "chat:open"])
    window.$crisp.push(["do", "chat:show"])
    window.$crisp.push([
      "do",
      "message:send",
      ["text", "I'm interested in upgrading my plan!"],
    ])
  }
}

export function negativeInvoiceDiscountUpgradeCrispChatOpen(
  email,
  newPlanName,
  couponCode
) {
  if (window.$crisp) {
    window.$crisp.push(["set", "user:email", [email]])
    window.$crisp.push(["do", "chat:open"])
    window.$crisp.push(["do", "chat:close"])
    window.$crisp.push(["do", "chat:open"])
    window.$crisp.push(["do", "chat:show"])
    window.$crisp.push([
      "do",
      "message:send",
      [
        "text",
        `I'm interested in changing my plan to ${newPlanName} with the ${couponCode} discount!`,
      ],
    ])
  }
}

export function taxExemptionCrispChatOpen() {
  if (window.$crisp) {
    window.$crisp.push(["do", "chat:open"])
    window.$crisp.push(["do", "chat:close"])
    window.$crisp.push(["do", "chat:open"])
    window.$crisp.push(["do", "chat:show"])
    window.$crisp.push([
      "do",
      "message:send",
      ["text", "I'd like to request tax exemption."],
    ])
  }
}

export function recurringCouponVideoUpgradeCrispChatOpen() {
  if (window.$crisp) {
    window.$crisp.push(["do", "chat:open"])
    window.$crisp.push(["do", "chat:close"])
    window.$crisp.push(["do", "chat:open"])
    window.$crisp.push(["do", "chat:show"])
    window.$crisp.push([
      "do",
      "message:send",
      [
        "text",
        "I am interested in upgrading to a video plan, but I have a recurring coupon.",
      ],
    ])
  }
}
