import PropTypes from "prop-types"
import React from "react"
import { withTheme } from "styled-components"
import Box from "components/Box"
import Icon from "components/Icon"
import { ICON_SS_MENU } from "components/Icon/constants"

const MobileMenuButton = ({ theme, ...otherProps }) => (
  <Box p={5} {...otherProps} style={{ cursor: "pointer" }}>
    <Icon
      color={otherProps.color || theme.colors.text.primary}
      hoverColor={otherProps.hoverColor || theme.colors.text.primary}
      icon={ICON_SS_MENU}
      size={24}
    />
  </Box>
)

MobileMenuButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  theme: PropTypes.object,
}

export default withTheme(MobileMenuButton)
