import PropTypes from "prop-types"
import React, { memo } from "react"
import { push } from "react-router-redux"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { compose } from "redux"
import { actions as cookieActions } from "middlewares/cookies"
import { actions as modalActions } from "ducks/modal"
import { actions as uiActions } from "ducks/ui"
import { actions as songActions } from "ducks/song"
import { loggedIn } from "utils/authentication"
import { selectCurrentUserRecord } from "ducks/currentUser"
import { User as UserRecord, Song as SongRecord } from "records"
import useMixpanelTracking from "hooks/useMixpanelTracking"

const DELINQUENT_MSG =
  "Uh oh! It looks like your last payment failed. Please update your card to pay off your delinquent invoice."
const PAUSED_MSG =
  "Uh oh! It looks like your subscription is currently paused! Please resume your subscription in order to perform this action!"
const STRIPE_SCA_MSG =
  "Uh oh! It looks like your last payment requires additional attention. Please update your card to pay off your outstanding invoice."

const SongRequest = ({ className, children, ...props }) => {
  const { trackMixpanel } = useMixpanelTracking()
  const { user, song } = props
  const isNotSubscribed = !user.hasSubscription() && props.isLicense

  const onClick = () => {
    trackMixpanel("Clicked Download Song", {
      "Song ID": song.id,
      "Song Title": song.title,
      "Artist ID": song.primary_artist_info.id,
      "Artist Name": song.primary_artist_info.name,
    })
    if (loggedIn() && isNotSubscribed) {
      props.downloadPreview()
    } else if (!loggedIn()) {
      const cookieValue = { id: song.id, mediaType: song.type }
      props.setCookie("signup_media_item", cookieValue, true, 600)
      props.signUpModalOpen({
        action: "download",
        afterSignup: props.downloadPreview,
        showMediaInfo: false,
        songId: song.id,
      })
    } else if (user?.subscription?.status === "incomplete") {
      props.redirectToBilling()
      props.setError(STRIPE_SCA_MSG)
    } else if (user.delinquent) {
      props.redirectToBilling()
      props.setError(DELINQUENT_MSG)
    } else if (user.subscriptionPaused()) {
      props.redirectToSubscription()
      props.setError(PAUSED_MSG)
    } else if (!user.canLicenseSongs()) {
      props.downloadPreview()
    } else if (user.canLicenseSongs()) {
      props.onClick()
    } else {
      props.setError(
        "There was an error with your request. Please contact us for support."
      )
    }

    props.resolve && props.resolve()
  }

  return React.createElement(
    props.component,
    { ...props, className, onClick },
    children
  )
}

SongRequest.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  component: PropTypes.any,
  isLicense: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  redirectToBilling: PropTypes.func,
  resolve: PropTypes.func,
  setError: PropTypes.func,
  signUpModalOpen: PropTypes.func,
  singleSongLicenseModalOpen: PropTypes.func,
  song: PropTypes.instanceOf(SongRecord),
  upgradeModalOpen: PropTypes.func,
  user: PropTypes.instanceOf(UserRecord),
}

SongRequest.defaultProps = {
  component: "div",
}

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUserRecord(),
})

const mapDispatchToProps = (dispatch, props) => ({
  redirectToBilling: () => dispatch(push("/account/billing")),
  redirectToSubscription: () => dispatch(push("/account/subscription")),
  setError: (errorMsg) => dispatch(uiActions.setError(errorMsg)),
  singleSongLicenseModalOpen: (song) =>
    dispatch(modalActions.open("SingleSongLicenseModal", { song: [song] })),
  signUpModalOpen: (data) => dispatch(modalActions.open("SignUpModal", data)),
  upgradeModalOpen: () =>
    dispatch(
      modalActions.open("UpgradeModal", {
        gatedMediaType: "music",
        context: "Song Action",
      })
    ),
  downloadPreview: () => dispatch(songActions.trySong(props.song)),
  setCookie: (name, value, isDomainCookie, expires) => {
    dispatch(cookieActions.setCookie(name, value, isDomainCookie, expires))
  },
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(SongRequest)
