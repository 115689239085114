import { fromJS } from "immutable"
import { createAction } from "./utils"

const initialState = fromJS({})

export const types = {
  AUTHORIZE_PAYMENT_INTENT: "soundstripe/checkout/AUTHORIZE_PAYMENT_INTENT",
  AUTHORIZE_PAYMENT_INTENT_SUCCESS:
    "soundstripe/checkout/AUTHORIZE_PAYMENT_INTENT_SUCCESS",
  AUTHORIZE_PAYMENT_INTENT_FAILED:
    "soundstripe/checkout/AUTHORIZE_PAYMENT_INTENT_FAILED",
}

export const actions = {
  authorizePaymentIntent: createAction(
    types.AUTHORIZE_PAYMENT_INTENT,
    "chargebeeCard",
    "checkoutAttributes"
  ),
  authorizePaymentIntentSuccess: createAction(
    types.AUTHORIZE_PAYMENT_INTENT_SUCCESS,
    "checkoutAttributes"
  ),
  authorizePaymentIntentFailed: createAction(
    types.AUTHORIZE_PAYMENT_INTENT_FAILED,
    "error"
  ),
}

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}
