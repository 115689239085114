export const base64 = {
  prefixRegEx: /^(data:)([-\w]+\/[-\w\+\.]+)?(;base64,)/,
  setProperMimeType: (base64File, filename) => {
    const matches = base64File.match(base64.prefixRegEx)
    if (matches && matches.length > 1) {
      return base64File.replace(
        base64.prefixRegEx,
        `$1${base64.mimeTypeLookup(filename)}$3`
      )
    }
    const err = new Error(
      "File is missing necessary file-type information. This is usually caused by it being exported wrong from an audio software, missing a valid file extension, or some other sort of file corruption."
    )
    throw err
  },
  mimeTypeLookup: (filename) => {
    switch (base64.getExtension(filename)) {
      case "aac":
        return "audio/aac"
      case "aif":
      case "aiff":
        return "audio/aiff"
      case "csv":
        return "text/csv"
      case "gif":
        return "image/gif"
      case "jpg":
      case "jpeg":
        return "image/jpeg"
      case "m4a":
        return "audio/mp4"
      case "mp3":
        return "audio/mpeg"
      case "pdf":
        return "application/pdf"
      case "png":
        return "image/png"
      case "wav":
        return "audio/wav"
      case "xlsx":
        return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      case "zip":
        return "application/zip"
      default: {
        const err = new Error("This file extension is not supported.")
        // console.error("Error from base64.js:", err)
        throw err
      }
    }
  },
  getExtension: (filename) => {
    const basename = filename.split(/[\\/]/).pop() // extract file name from full path ...
    // (supports `\\` and `/` separators)
    const pos = basename.lastIndexOf(".") // get last position of `.`

    if (basename === "" || pos < 1)
      // if file name is empty or ...
      return "" //  `.` not found (-1) or comes first (0)

    return basename.toLowerCase().slice(pos + 1) // extract extension ignoring `.`
  },
}

export default base64
