import { call, put, takeLatest } from "redux-saga/effects"
import { get } from "utils/request"

import { actions as uiActions } from "ducks/ui"
import { actions as modalActions } from "ducks/modal"
import { types } from "ducks/soundEffect"

export function* downloadSoundEffect(action) {
  try {
    const soundEffectId = action.sound_effect
    const requestURL = `${process.env.API_URL}/sound_effects/${soundEffectId}`
    const response = yield call(get, requestURL)

    yield put(
      modalActions.open("SoundEffectDownloadModal", {
        soundEffect: response.data.attributes,
      })
    )
  } catch (err) {
    yield put(uiActions.setError(err))
  }
}

export function* watchDownloadSoundEffect() {
  yield takeLatest(types.DOWNLOAD_SOUND_EFFECT, downloadSoundEffect)
}

export default [watchDownloadSoundEffect]
