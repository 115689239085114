import { formatQueryString } from "utils/formatting"

export const getLastPath = (location) => {
  const pathArray = location && location.pathname.split("/")
  const search = location && location.search
  const [lastPath] = pathArray && pathArray.slice(-1)
  return search ? `${lastPath}${search}` : lastPath
}

const getLocationName = (location) => {
  const pathName =
    location &&
    location.pathname
      .split("/")
      .slice(-1)[0]
      .toLowerCase()

  if (pathName === "sound-effects") return "Sound Effects"

  return (
    pathName
      .toLowerCase()
      .charAt(0)
      .toUpperCase() +
    pathName
      .toLowerCase()
      .slice(1)
      .split("?")[0]
      .trim()
  )
}

export const getMediaType = (location) => {
  const pathName = getLocationName(location)
  if (["Music", "Video", "Sound Effects", "Artists"].includes(pathName)) {
    return pathName
  }

  return null
}

export const replaceQuery = (query) => {
  const qs = formatQueryString(query)
  const pathName = window.location.pathname
  window.history.replaceState(
    {},
    document.title,
    qs ? `${pathName}?${qs}` : pathName
  )
}

export const baseApiUrl = () => {
  const url = new URL(process.env.API_URL)
  return `${url.protocol}//${url.hostname}` + (!!url.port ? `:${url.port}` : "")
}

export const slugifyTag = (tag) => {
  if (!tag) return
  return tag
    .toLowerCase()
    .replace("r&b", "rnb")
    .replace("&", "and")
    .replace(/ \/ /g, "-")
    .replace(/ /g, "-")
}

export const normalizeTag = (tag) => {
  if (!tag) return

  const specialCases = {
    "soundtrack-cinematic": "Soundtrack / Cinematic",
    rnb: "R&B",
    "8-bit": "8-Bit",
    edm: "EDM",
    "lo-fi": "Lo-Fi",
    "drum-and-bass": "Drum & Bass",
  }

  return (
    specialCases[tag] ||
    tag
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
  )
}
