import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import classNames from "classnames"
import buttonStyles from "./buttonStyles"
import Icon from "components/Icon"
import { Link } from "react-router"

const StyledButton = styled.button`
  ${buttonStyles}
`

const StyledLink = styled(Link)`
  ${buttonStyles}
`

const Button = ({
  appearance,
  children,
  className,
  icon,
  iconAlign,
  size,
  to,
  noUnderline,
  textStyle,
  iconSize,
  iconColor,
  ...props
}) => {
  const Component = to ? StyledLink : StyledButton
  const rightIcon = iconAlign === "right"

  const getIconSize = () => {
    if (iconSize) return iconSize
    return size === "sm" ? 20 : 24
  }

  return (
    <Component
      {...props}
      to={to && to}
      className={classNames(className, appearance && appearance, size && size, {
        "no-underline": noUnderline,
        "icon-only": !children,
      })}
    >
      {icon && !rightIcon && (
        <Icon icon={icon} color={iconColor} size={getIconSize()} />
      )}
      {children && <span style={textStyle}>{children}</span>}
      {icon && rightIcon && (
        <Icon icon={icon} color={iconColor} size={getIconSize()} />
      )}
    </Component>
  )
}

Button.propTypes = {
  appearance: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  iconAlign: PropTypes.string,
  size: PropTypes.string,
  to: PropTypes.string,
  noUnderline: PropTypes.bool,
  textStyle: PropTypes.object,
  iconSize: PropTypes.number,
  iconColor: PropTypes.string,
}

Button.defaultProps = {
  iconAlign: "left",
  iconColor: "currentColor",
}

export default Button
