import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import classNames from "classnames"
import { withRouter } from "react-router"
import { User as UserRecord } from "records"
import withCurrentUser from "hocs/withCurrentUser"
import DropdownTab, { DropDownSubLink } from "./DropdownTab"
import { locationMatches } from "./utils"
import { playlistRequestCrispChatOpen } from "utils/crisp"
import Box from "components/Box"

const MyMediaMenuWrapper = styled.div`
  align-content: center;
  animation: fadeIn 0.05s linear 0.05s forwards;
  background: ${(props) => props.theme.colors.background.layered};
  border-radius: ${(props) => props.theme.radii.rounder};
  box-shadow: ${(props) => props.theme.shadows.dropdown};
  display: flex;
  flex-direction: column;
  min-width: 100%;
  padding: ${(props) => props.theme.space[2]} 0;
  position: absolute;
  right: 20px;
  top: calc(100% - ${(props) => props.theme.space[4]});
  user-select: none;
  z-index: ${(props) => props.theme.zIndices.dropdown};
`

const Divider = styled.div`
  background: ${(props) => props.theme.colors.divider};
  height: 1px;
  width: 100%;
  margin-top: ${(props) => props.theme.space[2]};
  margin-bottom: ${(props) => props.theme.space[2]};
`

const MyMediaMenu = (props) => {
  const { currentUser, location } = props
  const onTeam = currentUser.onTeam()

  return (
    <>
      <DropdownTab
        className={classNames({
          active: locationMatches(location, /^\/my_media/),
        })}
        title="My Media"
        header
      >
        <MyMediaMenuWrapper>
          {onTeam && (
            <Box pl={3} py={2} textAlign="left">
              Team
            </Box>
          )}

          <DropDownSubLink to="/my_media/playlists/music">
            <Box pl={onTeam ? 4 : 0}>Playlists</Box>
          </DropDownSubLink>

          {!currentUser.enterpriseApi() && (
            <DropDownSubLink to="/my_media/projects">
              <Box pl={onTeam ? 4 : 0}>Projects</Box>
            </DropDownSubLink>
          )}

          <DropDownSubLink to="/licenses/music">
            <Box pl={onTeam ? 4 : 0}>Downloads</Box>
          </DropDownSubLink>

          {onTeam && (
            <Box pl={3} py={2} textAlign="left">
              My
            </Box>
          )}

          <DropDownSubLink to="/my_media/favorites/music">
            <Box pl={onTeam ? 4 : 0}>Favorites</Box>
          </DropDownSubLink>

          <DropDownSubLink to="/my_media/following/playlists">
            <Box pl={onTeam ? 4 : 0}>Following</Box>
          </DropDownSubLink>

          {currentUser.canRequestPlaylist() && (
            <>
              <Divider />
              <DropDownSubLink to="">
                <Box
                  onClick={() => {
                    playlistRequestCrispChatOpen(currentUser.email)
                  }}
                >
                  Request Playlist
                </Box>
              </DropDownSubLink>
            </>
          )}
        </MyMediaMenuWrapper>
      </DropdownTab>
    </>
  )
}

MyMediaMenu.propTypes = {
  currentUser: PropTypes.instanceOf(UserRecord),
  location: PropTypes.object,
}

export default withRouter(withCurrentUser(MyMediaMenu))
