import styled from "styled-components"

const Content = styled.div`
  flex: 1;
  margin-right: ${(props) => props.theme.space[4]};
  overflow: hidden;
  pointer-events: none;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    pointer-events: initial;
  }
`

export default Content
