import { PLAN_IDS, MONTHLY_PLAN_IDS } from "./authentication"
import getSymbolFromCurrency from "currency-symbol-map"
import { formatRoundToHalfDollar, formatQueryString } from "utils/formatting"
import { PACKAGED_PLAN_IDS, loggedIn } from "utils/authentication"
import { TOTAL_SFX, TOTAL_VIDEOS, EXTENSIONS_LOWERCASE } from "../constants"

export const YEARLY = "yearly"
export const QUARTERLY = "quarterly"
export const MONTHLY = "monthly"
export const PREMIUM = "premium"
export const STANDARD = "standard"

export const SINGLE_SONG_PRICE = 79.99

export const getSelectPlanPath = ({
  planId,
  planOffer,
  signedUp,
  mixpanelTrigger,
}) => {
  const qs = formatQueryString({
    plan: planId,
    coupon_code: planOffer?.couponCode,
    mixpanel_trigger: encodeURIComponent(mixpanelTrigger),
  })
  const checkoutPath = `/checkout?${qs}`
  const visitorPath = `/${signedUp ? "sign_in" : "signup"}?${qs}`

  return loggedIn() ? checkoutPath : visitorPath
}

export const getDualPlanId = (currentPlanId, addedPlanType) => {
  switch (currentPlanId + addedPlanType) {
    case `${PLAN_IDS.MUSIC_YEARLY}video`:
    case `${PLAN_IDS.MUSIC_MONTHLY}video`:
    case `${PLAN_IDS.MEMBER_MONTHLY}video`:
    case `${PLAN_IDS.MEMBER_YEARLY}video`:
    case `${PLAN_IDS.VIDEO_YEARLY}music`:
    case `${PLAN_IDS.VIDEO_MONTHLY}music`:
    case `${PLAN_IDS.MUSIC_MONTHLY_INR}video`:
    case `${PLAN_IDS.MUSIC_YEARLY_INR}video`:
    case `${PLAN_IDS.MUSIC_MONTHLY_GBP}video`:
    case `${PLAN_IDS.MUSIC_YEARLY_GBP}video`:
    case `${PLAN_IDS.MUSIC_MONTHLY_EUR}video`:
    case `${PLAN_IDS.MUSIC_YEARLY_EUR}video`:
      return PLAN_IDS.MUSIC_VIDEO_YEARLY
    case `${PLAN_IDS.MUSIC_MONTHLY_INR}sfx`:
    case `${PLAN_IDS.MUSIC_YEARLY_INR}sfx`:
      return PLAN_IDS.MUSIC_SFX_YEARLY_INR
    case `${PLAN_IDS.MUSIC_MONTHLY_GBP}sfx`:
    case `${PLAN_IDS.MUSIC_YEARLY_GBP}sfx`:
      return PLAN_IDS.MUSIC_SFX_YEARLY_GBP
    case `${PLAN_IDS.MUSIC_MONTHLY_EUR}sfx`:
    case `${PLAN_IDS.MUSIC_YEARLY_EUR}sfx`:
      return PLAN_IDS.MUSIC_SFX_YEARLY_EUR
    case `${PLAN_IDS.MUSIC_TEAM_YEARLY}video`:
    case `${PLAN_IDS.MUSIC_TEAM_MONTHLY}video`:
    case `${PLAN_IDS.MEMBER_TEAM_MONTHLY}video`:
    case `${PLAN_IDS.MEMBER_TEAM_YEARLY}video`:
    case `${PLAN_IDS.VIDEO_TEAM_YEARLY}music`:
    case `${PLAN_IDS.VIDEO_TEAM_MONTHLY}music`:
      return PLAN_IDS.MUSIC_VIDEO_TEAM_YEARLY
    case `${PLAN_IDS.MUSIC_YEARLY}sfx`:
    case `${PLAN_IDS.MUSIC_MONTHLY}sfx`:
    case `${PLAN_IDS.MEMBER_MONTHLY}sfx`:
    case `${PLAN_IDS.MEMBER_YEARLY}sfx`:
    case `${PLAN_IDS.SFX_YEARLY}music`:
    case `${PLAN_IDS.SFX_MONTHLY}music`:
      return PLAN_IDS.MUSIC_SFX_YEARLY
    case `${PLAN_IDS.MUSIC_TEAM_YEARLY}sfx`:
    case `${PLAN_IDS.MUSIC_TEAM_MONTHLY}sfx`:
    case `${PLAN_IDS.MEMBER_TEAM_MONTHLY}sfx`:
    case `${PLAN_IDS.MEMBER_TEAM_YEARLY}sfx`:
    case `${PLAN_IDS.SFX_TEAM_YEARLY}music`:
    case `${PLAN_IDS.SFX_TEAM_MONTHLY}music`:
      return PLAN_IDS.MUSIC_SFX_TEAM_YEARLY
    case `${PLAN_IDS.VIDEO_YEARLY}sfx`:
    case `${PLAN_IDS.VIDEO_MONTHLY}sfx`:
    case `${PLAN_IDS.SFX_YEARLY}video`:
    case `${PLAN_IDS.SFX_MONTHLY}video`:
      return PLAN_IDS.VIDEO_SFX_YEARLY
    case `${PLAN_IDS.VIDEO_TEAM_YEARLY}sfx`:
    case `${PLAN_IDS.VIDEO_TEAM_MONTHLY}sfx`:
    case `${PLAN_IDS.SFX_TEAM_YEARLY}video`:
    case `${PLAN_IDS.SFX_TEAM_MONTHLY}video`:
      return PLAN_IDS.VIDEO_SFX_TEAM_YEARLY
    default:
      return null
  }
}

export const getUpgradeMediaType = (dualPlanId) => {
  switch (dualPlanId) {
    case PLAN_IDS.MUSIC_SFX_YEARLY:
    case PLAN_IDS.MUSIC_SFX_MONTHLY:
    case PLAN_IDS.MUSIC_SFX_TEAM_YEARLY:
    case PLAN_IDS.MUSIC_SFX_TEAM_MONTHLY:
    case PLAN_IDS.MEMBER_PREMIUM_YEARLY:
    case PLAN_IDS.MEMBER_BUSINESS_MONTHLY:
    case PLAN_IDS.MEMBER_BUSINESS_YEARLY:
      return "Video"
    case PLAN_IDS.VIDEO_SFX_YEARLY:
    case PLAN_IDS.VIDEO_SFX_MONTHLY:
    case PLAN_IDS.VIDEO_SFX_TEAM_YEARLY:
    case PLAN_IDS.VIDEO_SFX_TEAM_MONTHLY:
      return "Music"
    case PLAN_IDS.MUSIC_VIDEO_YEARLY:
    case PLAN_IDS.MUSIC_VIDEO_MONTHLY:
    case PLAN_IDS.MUSIC_VIDEO_TEAM_YEARLY:
    case PLAN_IDS.MUSIC_VIDEO_TEAM_MONTHLY:
      return "SFX"
    default:
      return null
  }
}

export const MODAL_TYPE = {
  VISITOR: "visitor",
  FREE_ACCOUNT: "freeAccount",
  FREE_ACCOUNT_RECURRING: "freeAccountRecurring",
  MUSIC_VIDEO_SFX_MONTHLY: "musicVideoSfxMonthly",
  MUSIC_VIDEO_MONTHLY: "musicVideoMonthly",
  MUSIC_SFX_MONTHLY: "musicSfxMonthly",
  VIDEO_SFX_MONTHLY: "videoSfxMonthly",
  MUSIC_MONTHLY: "musicMonthly",
  VIDEO_MONTHLY: "videoMonthly",
  SFX_MONTHLY: "sfxMonthly",
}

export const UPGRADE_MODAL_TYPES = {
  // Yearly Plans
  [PLAN_IDS.MEMBER_YEARLY]: MODAL_TYPE.MUSIC_MONTHLY,
  [PLAN_IDS.MUSIC_VIDEO_YEARLY]: MODAL_TYPE.MUSIC_VIDEO_SFX_MONTHLY,
  [PLAN_IDS.MUSIC_SFX_YEARLY]: MODAL_TYPE.MUSIC_VIDEO_SFX_MONTHLY,
  [PLAN_IDS.VIDEO_SFX_YEARLY]: MODAL_TYPE.MUSIC_VIDEO_SFX_MONTHLY,
  [PLAN_IDS.MUSIC_YEARLY]: MODAL_TYPE.MUSIC_SFX_MONTHLY,
  [PLAN_IDS.VIDEO_YEARLY]: MODAL_TYPE.MUSIC_VIDEO_MONTHLY,
  [PLAN_IDS.SFX_YEARLY]: MODAL_TYPE.MUSIC_SFX_MONTHLY,
  // Monthly Plans
  [PLAN_IDS.MEMBER_MONTHLY]: MODAL_TYPE.MUSIC_MONTHLY,
  [PLAN_IDS.MUSIC_VIDEO_SFX_MONTHLY]: MODAL_TYPE.MUSIC_VIDEO_SFX_MONTHLY,
  [PLAN_IDS.MUSIC_VIDEO_MONTHLY]: MODAL_TYPE.MUSIC_VIDEO_MONTHLY,
  [PLAN_IDS.MUSIC_SFX_MONTHLY]: MODAL_TYPE.MUSIC_SFX_MONTHLY,
  [PLAN_IDS.VIDEO_SFX_MONTHLY]: MODAL_TYPE.VIDEO_SFX_MONTHLY,
  [PLAN_IDS.MUSIC_MONTHLY]: MODAL_TYPE.MUSIC_MONTHLY,
  [PLAN_IDS.VIDEO_MONTHLY]: MODAL_TYPE.VIDEO_MONTHLY,
  [PLAN_IDS.SFX_MONTHLY]: MODAL_TYPE.SFX_MONTHLY,
}

export const planPrice = (planId, referralDiscount = false) => {
  const plans = {
    // Yearly Plans
    [PLAN_IDS.MUSIC_VIDEO_SFX_YEARLY]: "399",
    [PLAN_IDS.MUSIC_VIDEO_YEARLY]: "345",
    [PLAN_IDS.MUSIC_SFX_YEARLY]: "245",
    [PLAN_IDS.VIDEO_SFX_YEARLY]: "299",
    [PLAN_IDS.MUSIC_YEARLY]: "149",
    [PLAN_IDS.VIDEO_YEARLY]: "249",
    [PLAN_IDS.SFX_YEARLY]: "99",
    // Yearly Team Plans
    [PLAN_IDS.MUSIC_VIDEO_SFX_TEAM_YEARLY]: "599",
    [PLAN_IDS.MUSIC_VIDEO_TEAM_YEARLY]: "519",
    [PLAN_IDS.MUSIC_SFX_TEAM_YEARLY]: "368",
    [PLAN_IDS.VIDEO_SFX_TEAM_YEARLY]: "449",
    [PLAN_IDS.MUSIC_TEAM_YEARLY]: "224",
    [PLAN_IDS.VIDEO_TEAM_YEARLY]: "373.50",
    [PLAN_IDS.SFX_TEAM_YEARLY]: "149",
    // Monthly Plans
    [PLAN_IDS.MUSIC_VIDEO_SFX_MONTHLY]: "55",
    [PLAN_IDS.MUSIC_VIDEO_MONTHLY]: "48",
    [PLAN_IDS.MUSIC_SFX_MONTHLY]: "27",
    [PLAN_IDS.VIDEO_SFX_MONTHLY]: "42",
    [PLAN_IDS.MUSIC_MONTHLY]: "19",
    [PLAN_IDS.VIDEO_MONTHLY]: "32",
    [PLAN_IDS.SFX_MONTHLY]: "13",
    // Monthly Team Plans
    [PLAN_IDS.MUSIC_VIDEO_SFX_TEAM_MONTHLY]: "82.50",
    [PLAN_IDS.MUSIC_VIDEO_TEAM_MONTHLY]: "72",
    [PLAN_IDS.MUSIC_SFX_TEAM_MONTHLY]: "41",
    [PLAN_IDS.VIDEO_SFX_TEAM_MONTHLY]: "63",
    [PLAN_IDS.MUSIC_TEAM_MONTHLY]: "29",
    [PLAN_IDS.VIDEO_TEAM_MONTHLY]: "48",
    [PLAN_IDS.SFX_TEAM_MONTHLY]: "20",
    // Twitch Pro Plans
    [PLAN_IDS.TWITCH_PRO_MONTHLY]: "3.99",
    [PLAN_IDS.TWITCH_PRO_YEARLY]: "36",
    // INR Plans
    [PLAN_IDS.MUSIC_MONTHLY_INR]: "862.50",
    [PLAN_IDS.MUSIC_SFX_MONTHLY_INR]: "1237.50",
    [PLAN_IDS.MUSIC_YEARLY_INR]: "7500",
    [PLAN_IDS.MUSIC_SFX_YEARLY_INR]: "11250",
    // GBP Plans
    [PLAN_IDS.MUSIC_MONTHLY_GBP]: "13.47",
    [PLAN_IDS.MUSIC_SFX_MONTHLY_GBP]: "19.14",
    [PLAN_IDS.MUSIC_YEARLY_GBP]: "105.64",
    [PLAN_IDS.MUSIC_SFX_YEARLY_GBP]: "173.70",
    // EUR Plans
    [PLAN_IDS.MUSIC_MONTHLY_EUR]: "15.68",
    [PLAN_IDS.MUSIC_SFX_MONTHLY_EUR]: "22.28",
    [PLAN_IDS.MUSIC_YEARLY_EUR]: "122.94",
    [PLAN_IDS.MUSIC_SFX_YEARLY_EUR]: "202.15",
    // ***** Legacy Plans *****
    [PLAN_IDS.MEMBER_MONTHLY]: referralDiscount ? "12" : "15",
    [PLAN_IDS.MEMBER_YEARLY]: referralDiscount ? "108" : "135",
    [PLAN_IDS.MEMBER_PREMIUM_YEARLY]: "245",
    [PLAN_IDS.MEMBER_BUSINESS_MONTHLY]: "75",
    [PLAN_IDS.MEMBER_BUSINESS_YEARLY]: "795",
    // ***** Packaged Plans *****
    [PLAN_IDS.CREATOR_MONTHLY]: "19.99",
    [PLAN_IDS.CREATOR_YEARLY]: "119",
    [PLAN_IDS.PRO_MONTHLY]: "39.99",
    [PLAN_IDS.PRO_YEARLY]: "239",
    [PLAN_IDS.PRO_PLUS_MONTHLY]: "69.99",
    [PLAN_IDS.PRO_PLUS_YEARLY]: "399",
    [PLAN_IDS.ENTERPRISE_BUSINESS_MONTHLY]: "249.99",
    [PLAN_IDS.ENTERPRISE_BUSINESS_QUARTERLY]: "299",
    [PLAN_IDS.ENTERPRISE_BUSINESS_YEARLY]: "999",
    // Quarterly Plans
    [PLAN_IDS.ENTERPRISE_BUSINESS_QUARTERLY]: "299",
    [PLAN_IDS.CREATOR_QUARTERLY]: "29.75",
    [PLAN_IDS.PRO_QUARTERLY]: "59.75",
    [PLAN_IDS.PRO_PLUS_QUARTERLY]: "99.75",
  }
  return plans[planId]
}

export const planName = (planId) => {
  switch (planId) {
    case PLAN_IDS.MUSIC_YEARLY:
    case PLAN_IDS.MUSIC_YEARLY_INR:
    case PLAN_IDS.MUSIC_YEARLY_GBP:
    case PLAN_IDS.MUSIC_YEARLY_EUR:
    case PLAN_IDS.MUSIC_MONTHLY:
    case PLAN_IDS.MUSIC_MONTHLY_INR:
    case PLAN_IDS.MUSIC_MONTHLY_GBP:
    case PLAN_IDS.MUSIC_MONTHLY_EUR:
    case PLAN_IDS.MUSIC_TEAM_YEARLY:
    case PLAN_IDS.MUSIC_TEAM_MONTHLY:
      return "Music"
    case PLAN_IDS.VIDEO_YEARLY:
    case PLAN_IDS.VIDEO_MONTHLY:
    case PLAN_IDS.VIDEO_TEAM_YEARLY:
    case PLAN_IDS.VIDEO_TEAM_MONTHLY:
      return "Video"
    case PLAN_IDS.SFX_YEARLY:
    case PLAN_IDS.SFX_MONTHLY:
    case PLAN_IDS.SFX_TEAM_YEARLY:
    case PLAN_IDS.SFX_TEAM_MONTHLY:
      return "SFX"
    case PLAN_IDS.MUSIC_VIDEO_YEARLY:
    case PLAN_IDS.MUSIC_VIDEO_MONTHLY:
    case PLAN_IDS.MUSIC_VIDEO_TEAM_YEARLY:
    case PLAN_IDS.MUSIC_VIDEO_TEAM_MONTHLY:
      return "Music & Video"
    case PLAN_IDS.MUSIC_SFX_YEARLY:
    case PLAN_IDS.MUSIC_SFX_MONTHLY:
    case PLAN_IDS.MUSIC_SFX_YEARLY_INR:
    case PLAN_IDS.MUSIC_SFX_MONTHLY_INR:
    case PLAN_IDS.MUSIC_SFX_YEARLY_GBP:
    case PLAN_IDS.MUSIC_SFX_MONTHLY_GBP:
    case PLAN_IDS.MUSIC_SFX_YEARLY_EUR:
    case PLAN_IDS.MUSIC_SFX_MONTHLY_EUR:
    case PLAN_IDS.MUSIC_SFX_TEAM_YEARLY:
    case PLAN_IDS.MUSIC_SFX_TEAM_MONTHLY:
      return "Music & SFX"
    case PLAN_IDS.VIDEO_SFX_YEARLY:
    case PLAN_IDS.VIDEO_SFX_MONTHLY:
    case PLAN_IDS.VIDEO_SFX_TEAM_YEARLY:
    case PLAN_IDS.VIDEO_SFX_TEAM_MONTHLY:
      return "Video & SFX"
    case PLAN_IDS.MUSIC_VIDEO_SFX_YEARLY:
    case PLAN_IDS.MUSIC_VIDEO_SFX_MONTHLY:
    case PLAN_IDS.MUSIC_VIDEO_SFX_TEAM_YEARLY:
    case PLAN_IDS.MUSIC_VIDEO_SFX_TEAM_MONTHLY:
      return "Music, Video & SFX"
    // ****** Legacy plan names ********
    case PLAN_IDS.MEMBER_MONTHLY:
    case PLAN_IDS.MEMBER_YEARLY:
      return "Standard"
    case PLAN_IDS.MEMBER_BUSINESS_MONTHLY:
    case PLAN_IDS.MEMBER_BUSINESS_YEARLY:
      return "Business Plan"
    case PLAN_IDS.MEMBER_PREMIUM_YEARLY:
      return "Premium"
    case PLAN_IDS.ENTERPRISE_MUSIC_SFX_YEARLY:
      return "Enterprise Music & Sound Effects"
    case PLAN_IDS.ENTERPRISE_MUSIC_VIDEO_SFX_YEARLY:
      return "Enterprise Music, Video & Sound Effects"
    case PLAN_IDS.ENTERPRISE_API_MUSIC_YEARLY:
      return "Enterprise API - Music"
    case PLAN_IDS.ENTERPRISE_API_MUSIC_SFX_YEARLY:
      return "Enterprise API - Music & Sound Effects"
    case PLAN_IDS.ENTERPRISE_CUSTOM:
      return "Enterprise"
    case PLAN_IDS.TWITCH_PRO_MONTHLY:
    case PLAN_IDS.TWITCH_PRO_YEARLY:
      return "Twitch Pro"
    // ***** Packaged Plans *****
    case PLAN_IDS.CREATOR_MONTHLY:
    case PLAN_IDS.CREATOR_QUARTERLY:
    case PLAN_IDS.CREATOR_YEARLY:
      return "Creator"
    case PLAN_IDS.PRO_MONTHLY:
    case PLAN_IDS.PRO_QUARTERLY:
    case PLAN_IDS.PRO_YEARLY:
      return "Pro"
    case PLAN_IDS.PRO_PLUS_MONTHLY:
    case PLAN_IDS.PRO_PLUS_QUARTERLY:
    case PLAN_IDS.PRO_PLUS_YEARLY:
      return "Pro Plus"
    case PLAN_IDS.ENTERPRISE_BUSINESS_MONTHLY:
    case PLAN_IDS.ENTERPRISE_BUSINESS_QUARTERLY:
    case PLAN_IDS.ENTERPRISE_BUSINESS_YEARLY:
      return "Business"
    case PLAN_IDS.STRAYNOTE_YEARLY:
      return "Straynote"
    default:
      return null
  }
}

export const filterMediaTypesFromPlan = (planId) =>
  ["music", "video", "sfx"].filter((mediaType) => planId.includes(mediaType))

export const planNameWithInterval = (planId) => {
  if (!planId) {
    return ""
  }
  const name = planName(planId)
  const interval = planId.includes("monthly")
    ? "Monthly"
    : planId.includes("quarterly")
    ? "Quarterly"
    : "Yearly"
  const team = planId.includes("team") ? "for Teams" : ""
  return [name, team, interval].filter((i) => i).join(" ")
}

export const yearlySavings = (planId) => {
  const floatPrice = parseFloat(planPrice(planId))
  if (planId.includes("monthly")) {
    const totalPriceForMonthlyPlan = floatPrice * 12
    const planIdYearlyVersion = planId.replace("monthly", "yearly")
    const yearlyPrice = parseFloat(planPrice(planIdYearlyVersion))
    return Math.round(totalPriceForMonthlyPlan - yearlyPrice)
  }
  const planIdMonthlyVersion = planId.replace("yearly", "monthly")
  const floatMonthlyPrice = parseFloat(planPrice(planIdMonthlyVersion))
  const totalPriceForMonthlyPlan = floatMonthlyPrice * 12
  return Math.round(totalPriceForMonthlyPlan - floatPrice)
}

export const togglePlanInterval = (planId) => {
  return planId.includes("monthly")
    ? planId.replace("monthly", "yearly")
    : planId.replace("yearly", "monthly")
}

export const isMonthlyPlan = (planId) => MONTHLY_PLAN_IDS.includes(planId)

export const isStandardPlan = (planId) =>
  planId === PLAN_IDS.MEMBER_MONTHLY || planId === PLAN_IDS.MEMBER_YEARLY

export const isBusinessPlan = (planId) =>
  planId === PLAN_IDS.MEMBER_BUSINESS_MONTHLY ||
  planId === PLAN_IDS.MEMBER_BUSINESS_YEARLY

export const isSfxPlan = (planId) =>
  planId === PLAN_IDS.SFX_MONTHLY || planId === PLAN_IDS.SFX_YEARLY

export const yearPlanMonthlyPrice = (planId) => {
  if (!!PACKAGED_PLAN_IDS.includes(planId)) {
    return PACKAGED_YEARLY_PLANS_MONTHLY_COST[planId]
  }
  const price = parseFloat(planPrice(planId)) / 12
  return formatRoundToHalfDollar(price)
}

export const monthlyPlanQuarterlyPrice = (planId) => {
  if (!!PACKAGED_PLAN_IDS.includes(planId)) {
    return PACKAGED_MONTHLY_PLANS_QUARTERLY_COST[planId]
  }
  const price = parseFloat(planPrice(planId)) / 4
  return Math.ceil(price * 100) / 100
}

export const quarterlyPlanMonthlyPrice = (planId) => {
  if (!!PACKAGED_PLAN_IDS.includes(planId)) {
    return PACKAGED_MONTHLY_PLANS_COST[planId]
  }
  const price = parseFloat(planPrice(planId)) / 12
  return formatRoundToHalfDollar(price)
}

export const UPSELLABLE_PLANS = {
  [PLAN_IDS.MUSIC_MONTHLY]: {
    planId: PLAN_IDS.MUSIC_YEARLY,
    discount: 0.4,
    couponCode: "CANCELMONTHLY40",
    monthsFree: 5,
  },
  [PLAN_IDS.MUSIC_SFX_MONTHLY]: {
    planId: PLAN_IDS.MUSIC_SFX_YEARLY,
    discount: 0.4,
    couponCode: "CANCELMONTHLY40",
    monthsFree: 5,
  },
  [PLAN_IDS.MUSIC_VIDEO_SFX_MONTHLY]: {
    planId: PLAN_IDS.MUSIC_VIDEO_SFX_YEARLY,
    discount: 0.2,
    couponCode: "CANCELMONTHLY20",
    monthsFree: 3,
  },
}

export const currencyFromPlanId = (planId) => planId?.slice(-3)
export const currencySymbolFromPlanId = (planId) =>
  getSymbolFromCurrency(currencyFromPlanId(planId))
export const isInternationalTestPlan = (planId) =>
  ["inr", "gbp", "eur"].includes(currencyFromPlanId(planId))
export const defaultCountryCodeFromPlanId = (planId) => {
  switch (currencyFromPlanId(planId)) {
    case "inr":
      return "IN"
    case "gbp":
      return "GB"
    case "eur":
      return "DE"
    default:
      return "US"
  }
}
export const currencyMatch = (plan1, plan2) =>
  currencyFromPlanId(plan1) === currencyFromPlanId(plan2)

const CREATOR_DESCRIPTION = "For Personal & Organic Social use"
const PRO_DESCRIPTION = "For Individual & Freelance Commercial use"
export const PRO_PLUS_DESCRIPTION =
  "For individuals looking for stock video and pro workflow features"

const ENTERPRISE_CUSTOM_DESCRIPTION = "For Company Commercial use"

const ENTERPRISE_BUSINESS_DESCRIPTION =
  "For organizations distributing digital content to an internal or local audience"

const CREATOR_FULL_DESCRIPTIONS = [
  "Unlimited access to Music & Sound Effects, including Curated Playlists",
  "New Music Released Weekly",
  "Unlimited Personal Use; covered for web, social media and podcast use",
  "Clear & Monetize 1 YouTube channel",
]

const PRO_FULL_DESCRIPTIONS = [
  "Unlimited access to Music & Sound Effects",
  "Stems for all songs",
  "Unlimited Personal, Commercial and Client Use, including paid ads, commercial & broadcast use",
  "Clear & Monetize up to 5 YouTube channels",
  "Unlimited video clearance by YouTube URLs and codes",
  "Adobe Premiere Pro & Frame.io extensions",
]

const PRO_PLUS_FULL_DESCRIPTIONS = [
  "Unlimited access to Music, Sound Effects & Video",
  "Stems for all songs",
  "Clear & Monetize up to 5 YouTube channels",
  "Unlimited video clearance by YouTube URLs & codes",
  "Unlimited Personal, Commercial and Client Use, including paid ads, commercial & broadcast use",
  "Team Accounts & Collaboration Features",
  "Up to 5 team accounts, role-based controls & shared playlists",
  "Custom Curation Services",
  "Adobe Premiere Pro & Frame.io extensions",
]

export const FEATURES_BY_PLAN = {
  [PLAN_IDS.MUSIC_YEARLY]:
    "Full access to our high-quality music library with stems for each song.",
  [PLAN_IDS.MUSIC_SFX_YEARLY]: `Full access to Music plus ${TOTAL_SFX} Sound Effects.`,
  [PLAN_IDS.MUSIC_VIDEO_YEARLY]: `Full access to Music plus ${TOTAL_VIDEOS} videos.`,
  [PLAN_IDS.VIDEO_SFX_YEARLY]: `Full access to Sound Effects plus ${TOTAL_VIDEOS} videos.`,
  [PLAN_IDS.MUSIC_VIDEO_SFX_YEARLY]: `Full access to Music & Sound Effects, plus ${TOTAL_VIDEOS} videos.`,
  [PLAN_IDS.CREATOR_YEARLY]: CREATOR_DESCRIPTION,
  [PLAN_IDS.PRO_YEARLY]: PRO_DESCRIPTION,
  [PLAN_IDS.PRO_PLUS_YEARLY]: PRO_PLUS_DESCRIPTION,
  [PLAN_IDS.ENTERPRISE_CUSTOM]: ENTERPRISE_CUSTOM_DESCRIPTION,
  [PLAN_IDS.ENTERPRISE_BUSINESS_YEARLY]: ENTERPRISE_BUSINESS_DESCRIPTION,
}

export const MOBILE_FEATURES_BY_PLAN = {
  [PLAN_IDS.CREATOR_YEARLY]: "For Individuals",
  [PLAN_IDS.PRO_YEARLY]: "For Individuals",
  [PLAN_IDS.PRO_PLUS_YEARLY]: "For Individuals",
  [PLAN_IDS.ENTERPRISE_CUSTOM]: "For Companies",
  [PLAN_IDS.ENTERPRISE_BUSINESS_YEARLY]: "For Companies",
}

export const MOBILE_FEATURES_BY_PLAN_NEW = {
  [PLAN_IDS.CREATOR_YEARLY]: "For Individual Personal & Organic Social Use",
  [PLAN_IDS.CREATOR_MONTHLY]: "For Individual Personal & Organic Social Use",
  [PLAN_IDS.PRO_YEARLY]: "For Individual & Freelance Commercial Use",
  [PLAN_IDS.PRO_MONTHLY]: "For Individual & Freelance Commercial Use",
  [PLAN_IDS.PRO_PLUS_YEARLY]: "Everything in Pro, plus 100K+ videos up to 4K",
  [PLAN_IDS.PRO_PLUS_MONTHLY]: "Everything in Pro, plus 100K+ videos up to 4K",
  [PLAN_IDS.ENTERPRISE_CUSTOM]: "For Company Commercial Use",
  [PLAN_IDS.ENTERPRISE_BUSINESS_YEARLY]: "For Team Organic and Paid Social Use",
}

const PACKAGED_YEARLY_PLANS_MONTHLY_COST = {
  [PLAN_IDS.CREATOR_YEARLY]: "9.99",
  [PLAN_IDS.PRO_YEARLY]: "19.99",
  [PLAN_IDS.PRO_PLUS_YEARLY]: "33.99",
  [PLAN_IDS.ENTERPRISE_BUSINESS_YEARLY]: "199.99",
}

const PACKAGED_MONTHLY_PLANS_COST = {
  [PLAN_IDS.CREATOR_MONTHLY]: "9.99",
  [PLAN_IDS.PRO_MONTHLY]: "19.99",
  [PLAN_IDS.PRO_PLUS_MONTHLY]: "33.99",
  [PLAN_IDS.ENTERPRISE_BUSINESS_MONTHLY]: "199.99",
}

const PACKAGED_MONTHLY_PLANS_QUARTERLY_COST = {
  [PLAN_IDS.CREATOR_MONTHLY]: "29.75",
  [PLAN_IDS.PRO_MONTHLY]: "59.75",
  [PLAN_IDS.PRO_PLUS_MONTHLY]: "99.75",
}

const STEMS = "stems on all songs and versions"

const LEGACY_DESCRIPTION = ` and ${EXTENSIONS_LOWERCASE}`
const MUSIC_DESCRIPTION = ` ${STEMS}, and ${EXTENSIONS_LOWERCASE}`
const VIDEO_DESCRIPTION = " and access to the Twitch Music extension"
const SFX_DESCRIPTION = " and extensions for Adobe Premiere Pro & Twitch"
const MUSIC_VIDEO_DESCRIPTION = ` ${STEMS}, and ${EXTENSIONS_LOWERCASE}`
const MUSIC_SFX_DESCRIPTION = `${STEMS}, and ${EXTENSIONS_LOWERCASE}`
const VIDEO_SFX_DESCRIPTION = " and extension for Adobe Premiere Pro for SFX"
const MUSIC_VIDEO_SFX_DESCRIPTION = ` ${STEMS}, and ${EXTENSIONS_LOWERCASE}`
const TWITCH_PRO_DESCRIPTION =
  "Create your own playlists and gain exclusive access to Twitch Pro curated music, song shuffling and countless hours of music perfect for streaming."

export const PLAN_DESCRIPTIONS = {
  // Legacy Plans
  [PLAN_IDS.MEMBER_MONTHLY]: LEGACY_DESCRIPTION,
  [PLAN_IDS.MEMBER_YEARLY]: LEGACY_DESCRIPTION,
  [PLAN_IDS.MEMBER_PREMIUM_YEARLY]: LEGACY_DESCRIPTION,
  [PLAN_IDS.MEMBER_BUSINESS_MONTHLY]: LEGACY_DESCRIPTION,
  [PLAN_IDS.MEMBER_BUSINESS_YEARLY]: LEGACY_DESCRIPTION,

  // Twitch plans
  [PLAN_IDS.TWITCH_PRO_MONTHLY]: TWITCH_PRO_DESCRIPTION,
  [PLAN_IDS.TWITCH_PRO_YEARLY]: TWITCH_PRO_DESCRIPTION,

  // Yearly plans
  [PLAN_IDS.MUSIC_YEARLY]: MUSIC_DESCRIPTION,
  [PLAN_IDS.MUSIC_YEARLY_INR]: MUSIC_DESCRIPTION,
  [PLAN_IDS.MUSIC_YEARLY_GBP]: MUSIC_DESCRIPTION,
  [PLAN_IDS.MUSIC_YEARLY_EUR]: MUSIC_DESCRIPTION,
  [PLAN_IDS.VIDEO_YEARLY]: VIDEO_DESCRIPTION,
  [PLAN_IDS.SFX_YEARLY]: SFX_DESCRIPTION,
  [PLAN_IDS.MUSIC_VIDEO_YEARLY]: MUSIC_VIDEO_DESCRIPTION,
  [PLAN_IDS.MUSIC_SFX_YEARLY]: MUSIC_SFX_DESCRIPTION,
  [PLAN_IDS.MUSIC_SFX_YEARLY_INR]: MUSIC_SFX_DESCRIPTION,
  [PLAN_IDS.MUSIC_SFX_YEARLY_GBP]: MUSIC_SFX_DESCRIPTION,
  [PLAN_IDS.MUSIC_SFX_YEARLY_EUR]: MUSIC_SFX_DESCRIPTION,
  [PLAN_IDS.VIDEO_SFX_YEARLY]: VIDEO_SFX_DESCRIPTION,
  [PLAN_IDS.MUSIC_VIDEO_SFX_YEARLY]: MUSIC_VIDEO_SFX_DESCRIPTION,

  // Yearly Team plans
  [PLAN_IDS.MUSIC_TEAM_YEARLY]: MUSIC_DESCRIPTION,
  [PLAN_IDS.VIDEO_TEAM_YEARLY]: VIDEO_DESCRIPTION,
  [PLAN_IDS.SFX_TEAM_YEARLY]: SFX_DESCRIPTION,
  [PLAN_IDS.MUSIC_VIDEO_TEAM_YEARLY]: MUSIC_VIDEO_DESCRIPTION,
  [PLAN_IDS.MUSIC_SFX_TEAM_YEARLY]: MUSIC_SFX_DESCRIPTION,
  [PLAN_IDS.VIDEO_SFX_TEAM_YEARLY]: VIDEO_SFX_DESCRIPTION,
  [PLAN_IDS.MUSIC_VIDEO_SFX_TEAM_YEARLY]: MUSIC_VIDEO_SFX_DESCRIPTION,

  // Monthly Plans
  [PLAN_IDS.MUSIC_MONTHLY]: MUSIC_DESCRIPTION,
  [PLAN_IDS.MUSIC_MONTHLY_INR]: MUSIC_DESCRIPTION,
  [PLAN_IDS.MUSIC_MONTHLY_GBP]: MUSIC_DESCRIPTION,
  [PLAN_IDS.MUSIC_MONTHLY_EUR]: MUSIC_DESCRIPTION,
  [PLAN_IDS.VIDEO_MONTHLY]: VIDEO_DESCRIPTION,
  [PLAN_IDS.SFX_MONTHLY]: SFX_DESCRIPTION,
  [PLAN_IDS.MUSIC_VIDEO_MONTHLY]: MUSIC_VIDEO_DESCRIPTION,
  [PLAN_IDS.MUSIC_SFX_MONTHLY]: MUSIC_SFX_DESCRIPTION,
  [PLAN_IDS.MUSIC_SFX_MONTHLY_INR]: MUSIC_SFX_DESCRIPTION,
  [PLAN_IDS.MUSIC_SFX_MONTHLY_GBP]: MUSIC_SFX_DESCRIPTION,
  [PLAN_IDS.MUSIC_SFX_MONTHLY_EUR]: MUSIC_SFX_DESCRIPTION,
  [PLAN_IDS.VIDEO_SFX_MONTHLY]: VIDEO_SFX_DESCRIPTION,
  [PLAN_IDS.MUSIC_VIDEO_SFX_MONTHLY]: MUSIC_VIDEO_SFX_DESCRIPTION,

  // Monthly Team plans
  [PLAN_IDS.MUSIC_TEAM_MONTHLY]: MUSIC_DESCRIPTION,
  [PLAN_IDS.VIDEO_TEAM_MONTHLY]: VIDEO_DESCRIPTION,
  [PLAN_IDS.SFX_TEAM_MONTHLY]: SFX_DESCRIPTION,
  [PLAN_IDS.MUSIC_VIDEO_TEAM_MONTHLY]: MUSIC_VIDEO_DESCRIPTION,
  [PLAN_IDS.MUSIC_SFX_TEAM_MONTHLY]: MUSIC_SFX_DESCRIPTION,
  [PLAN_IDS.VIDEO_SFX_TEAM_MONTHLY]: VIDEO_SFX_DESCRIPTION,
  [PLAN_IDS.MUSIC_VIDEO_SFX_TEAM_MONTHLY]: MUSIC_VIDEO_SFX_DESCRIPTION,

  // Enterprise plans
  [PLAN_IDS.ENTERPRISE_MUSIC_SFX_YEARLY]: MUSIC_SFX_DESCRIPTION,
  [PLAN_IDS.ENTERPRISE_MUSIC_VIDEO_SFX_YEARLY]: MUSIC_VIDEO_SFX_DESCRIPTION,

  // Plans and Pricing EXP
  [PLAN_IDS.CREATOR_MONTHLY]: CREATOR_FULL_DESCRIPTIONS,
  [PLAN_IDS.CREATOR_QUARTERLY]: CREATOR_FULL_DESCRIPTIONS,
  [PLAN_IDS.CREATOR_YEARLY]: CREATOR_FULL_DESCRIPTIONS,
  [PLAN_IDS.PRO_MONTHLY]: PRO_FULL_DESCRIPTIONS,
  [PLAN_IDS.PRO_QUARTERLY]: PRO_FULL_DESCRIPTIONS,
  [PLAN_IDS.PRO_YEARLY]: PRO_FULL_DESCRIPTIONS,
  [PLAN_IDS.PRO_PLUS_MONTHLY]: PRO_PLUS_FULL_DESCRIPTIONS,
  [PLAN_IDS.PRO_PLUS_QUARTERLY]: PRO_PLUS_FULL_DESCRIPTIONS,
  [PLAN_IDS.PRO_PLUS_YEARLY]: PRO_PLUS_FULL_DESCRIPTIONS,
}

export const BLACK_FRIDAY_FEATURES_BY_PLAN = {
  [PLAN_IDS.MUSIC_YEARLY]:
    "Full access to our high-quality music library with stems for each song.",
  [PLAN_IDS.MUSIC_SFX_YEARLY]: `Full access to Music plus ${TOTAL_SFX} Sound Effects.`,
  [PLAN_IDS.MUSIC_VIDEO_YEARLY]: `Full access to Music plus ${TOTAL_VIDEOS} videos.`,
  [PLAN_IDS.VIDEO_SFX_YEARLY]: `Full access to Sound Effects plus ${TOTAL_VIDEOS} videos.`,
  [PLAN_IDS.MUSIC_VIDEO_SFX_YEARLY]: `Full access to Music & Sound Effects, plus ${TOTAL_VIDEOS} videos.`,
  [PLAN_IDS.CREATOR_YEARLY]: CREATOR_DESCRIPTION,
  [PLAN_IDS.PRO_YEARLY]: PRO_DESCRIPTION,
  [PLAN_IDS.PRO_PLUS_YEARLY]: PRO_PLUS_DESCRIPTION,
  [PLAN_IDS.ENTERPRISE_CUSTOM]: ENTERPRISE_CUSTOM_DESCRIPTION,
}

export const EXTENDED_SALE_FEATURES_BY_PLAN = {
  [PLAN_IDS.MUSIC_YEARLY]:
    "Full access to our high-quality music library with stems for each song.",
  [PLAN_IDS.MUSIC_SFX_YEARLY]: `Full access to Music plus ${TOTAL_SFX} Sound Effects.`,
  [PLAN_IDS.MUSIC_VIDEO_YEARLY]: `Full access to Music plus ${TOTAL_VIDEOS} videos.`,
  [PLAN_IDS.VIDEO_SFX_YEARLY]: `Full access to Sound Effects plus ${TOTAL_VIDEOS} videos.`,
  [PLAN_IDS.MUSIC_VIDEO_SFX_YEARLY]: `Full access to Music & Sound Effects, plus ${TOTAL_VIDEOS} videos.`,
  [PLAN_IDS.CREATOR_YEARLY]:
    "Unlimited music & sfx for influencers & personal use!",
  [PLAN_IDS.PRO_YEARLY]:
    "Unlimited music, SFX & pro features for freelancers & commercial use!",
  [PLAN_IDS.PRO_PLUS_YEARLY]:
    "Unlimited Music, SFX & Video plus curation support for freelancers & creative teams!",
  [PLAN_IDS.ENTERPRISE_CUSTOM]:
    "Unlimited Music, SFX & Video plus curation support for creative team!",
}

export const billingCyclesRetained = (planId) => {
  const plans = {
    [PLAN_IDS.CREATOR_MONTHLY]: 4.1,
    [PLAN_IDS.CREATOR_YEARLY]: 1.0,
    [PLAN_IDS.PRO_MONTHLY]: 4.0,
    [PLAN_IDS.PRO_YEARLY]: 1.0,
    [PLAN_IDS.PRO_PLUS_MONTHLY]: 4.4,
    [PLAN_IDS.PRO_PLUS_YEARLY]: 1.0,
  }
  return plans[planId]
}
export const marginRate = (planId) => {
  const plans = {
    [PLAN_IDS.CREATOR_MONTHLY]: 0.9,
    [PLAN_IDS.CREATOR_YEARLY]: 0.9,
    [PLAN_IDS.PRO_MONTHLY]: 0.9,
    [PLAN_IDS.PRO_YEARLY]: 0.9,
    [PLAN_IDS.PRO_PLUS_MONTHLY]: 0.55,
    [PLAN_IDS.PRO_PLUS_YEARLY]: 0.55,
  }
  return plans[planId]
}

export const planAsParts = (planId) => {
  if (!planId) {
    console.error("There was no planId supplied")
    return null
  }
  if (planId.includes("enterprise-business")) {
    return {
      product: "enterprise-business",
      billingCycle: planId.includes("quarterly") ? "quarterly" : "yearly",
      currency: planId.slice(-3),
    }
  }
  if (planId.includes("twitch")) {
    return {
      product: "twitch-pro",
      billingCycle: planId.includes("monthly") ? "monthly" : "yearly",
      currency: planId.slice(-3),
    }
  }
  const parts = planId.split("-")
  const length = parts.length
  const product = parts.slice(0, length - 2).join("-")

  return {
    product,
    billingCycle: parts[length - 2],
    currency: parts[length - 1],
  }
}

const percentOffRound = (currentPrice, monthlyPriceYearlyTotal) => {
  const percent = 100 - (currentPrice / monthlyPriceYearlyTotal) * 100
  return Math.round(percent)
}

export const getCheckoutPagePrices = ({
  planId,
  isReferral,
  invoiceEstimate,
}) => {
  const lineItems = invoiceEstimate?.line_items || []
  const { billingCycle, product, currency } = planAsParts(planId)
  const isQuarterly = billingCycle === "quarterly"
  const isYearly = billingCycle === "yearly"
  const cycles = ["yearly", "monthly", "quarterly"]
  // if billing cycle is not valid, return null and throw error
  if (!cycles.includes(billingCycle)) {
    console.error("Invalid billing cycle has been supplied")
    return null
  }
  if (!product) {
    console.error("Invalid product has been supplied")
    return null
  }

  const estimateSubtotal = lineItems?.[0]?.amount || planPrice(planId) * 100
  const taxAmount = lineItems?.[0]?.tax_amount || 0
  const discountAmount = lineItems?.[0]?.discount_amount || 0
  const monthlyPlanId = `${product}-monthly-${currency}`
  const quarterlyPlanId = `${product}-quarterly-${currency}`
  const yearlyPlanId = `${product}-yearly-${currency}`

  let currentPrice = invoiceEstimate?.total ?? planPrice(planId) * 100

  if (isReferral && !discountAmount) {
    currentPrice = currentPrice * 0.8
  }
  const totalWithoutTax = currentPrice - taxAmount

  const yearlyPlanPrice = Number(planPrice(yearlyPlanId)) * 100
  const monthlyPriceYearlyTotal = Number(planPrice(monthlyPlanId)) * 12 * 100
  const quarterlyPriceYearlyTotal = Number(planPrice(quarterlyPlanId)) * 4 * 100

  if (isQuarterly) {
    const monthlyPriceQuarterlyTotal =
      Number(planPrice(monthlyPlanId)) * 3 * 100
    return {
      subtotal: estimateSubtotal,
      monthlyPrice: Number(planPrice(monthlyPlanId)),
      totalWithoutTax,
      total: currentPrice,
      savings: monthlyPriceQuarterlyTotal - estimateSubtotal,
      percentOff: percentOffRound(totalWithoutTax, monthlyPriceQuarterlyTotal),
      toYearlySavings: quarterlyPriceYearlyTotal - yearlyPlanPrice,
    }
  }

  if (isYearly) {
    return {
      subtotal: monthlyPriceYearlyTotal,
      quarterlySubtotal: quarterlyPriceYearlyTotal,
      monthlyPrice: Number(planPrice(monthlyPlanId)),
      quarterlyPrice: Number(planPrice(quarterlyPlanId)),
      totalWithoutTax,
      total: currentPrice,
      savings: monthlyPriceYearlyTotal - estimateSubtotal,
      quarterlySavings: quarterlyPriceYearlyTotal - estimateSubtotal,
      percentOff: percentOffRound(totalWithoutTax, monthlyPriceYearlyTotal),
      quarterlyPercentOff: percentOffRound(
        totalWithoutTax,
        quarterlyPriceYearlyTotal
      ),
    }
  }

  return {
    subtotal: estimateSubtotal,
    monthlyPrice: Number(planPrice(monthlyPlanId)),
    totalWithoutTax,
    total: currentPrice,
    savings: monthlyPriceYearlyTotal - yearlyPlanPrice,
    percentOff: percentOffRound(totalWithoutTax, estimateSubtotal),
  }
}

export const toDollars = (cents) => parseInt(cents, 10) / 100

export const getProductMonthlyPlanMonthlyPrice = (planId) => {
  const { product, currency } = planAsParts(planId)
  const monthlyPlanId = `${product}-monthly-${currency}`
  return planPrice(monthlyPlanId)
}

export const getProductMonthlyPlanYearlyPrice = (planId) => {
  const { product, currency } = planAsParts(planId)
  const monthlyPlanId = `${product}-monthly-${currency}`
  return planPrice(monthlyPlanId) * 12
}
export const roundToHundredths = (num) => Math.round(num * 100) / 100

export const getProductPrices = ({
  planId,
  offerPercentOff,
  quarterlyOfferPercentOff,
}) => {
  const { product, currency } = planAsParts(planId)

  // monthly
  const monthlyPlanId = `${product}-monthly-${currency}`
  const monthlyPlanPrice = Number(planPrice(monthlyPlanId))
  const monthlyPriceYearlyTotal = monthlyPlanPrice * 12

  // yearly
  const yearlyPlanId = `${product}-yearly-${currency}`
  const yearlyPlanPrice = Number(planPrice(yearlyPlanId))
  const yearlyPercentOff = percentOffRound(
    yearlyPlanPrice,
    monthlyPriceYearlyTotal
  )

  // quarterly
  const quarterlyPlanId = `${product}-quarterly-${currency}`
  const quarterlyPlanPrice = Number(planPrice(quarterlyPlanId))
  const quarterlyPercentOff = percentOffRound(
    quarterlyPlanPrice * 4,
    monthlyPriceYearlyTotal
  )
  const quarterlyPriceYearlyTotal = quarterlyPlanPrice * 4

  // yearly offer
  let yearlyOffer = {}
  if (offerPercentOff) {
    const offerPercentToDecimal = offerPercentOff / 100
    const discountedYearlyPrice = yearlyPlanPrice * (1 - offerPercentToDecimal)
    yearlyOffer = {
      yearlyPrice: discountedYearlyPrice,
      monthlyPrice: roundToHundredths(discountedYearlyPrice / 12),
      percentOff: percentOffRound(
        discountedYearlyPrice,
        monthlyPriceYearlyTotal
      ),
      savings: monthlyPriceYearlyTotal - discountedYearlyPrice,
    }
  }

  // quarterly offer
  let quarterlyOffer = {}
  if (quarterlyOfferPercentOff && !!quarterlyPlanPrice) {
    const quarterlyPercentToDecimal = quarterlyOfferPercentOff / 100
    const discountedQuarterlyPrice =
      quarterlyPlanPrice * (1 - quarterlyPercentToDecimal)
    const discountQuarterlyPriceYearlyTotal = discountedQuarterlyPrice * 4
    quarterlyOffer = {
      quarterlyPrice: roundToHundredths(discountedQuarterlyPrice),
      yearlyPrice: discountQuarterlyPriceYearlyTotal,
      monthlyPrice: roundToHundredths(discountQuarterlyPriceYearlyTotal / 12),
      percentOff: percentOffRound(
        discountedQuarterlyPrice * 4,
        monthlyPriceYearlyTotal
      ),
      savings: monthlyPriceYearlyTotal - discountedQuarterlyPrice * 4,
    }
  }

  return {
    monthly: {
      planId: monthlyPlanId,
      yearlyPrice: roundToHundredths(monthlyPriceYearlyTotal),
      quarterlyPrice: roundToHundredths(monthlyPlanPrice * 3),
      monthlyPrice: roundToHundredths(monthlyPlanPrice),
    },
    ...(!!quarterlyPlanPrice
      ? {
          quarterly: {
            planId: quarterlyPlanId,
            yearlyPrice: roundToHundredths(quarterlyPriceYearlyTotal),
            monthlyPrice: roundToHundredths(quarterlyPriceYearlyTotal / 12),
            quarterlyPrice: roundToHundredths(quarterlyPlanPrice),
            percentOff: quarterlyPercentOff,
            savings: roundToHundredths(
              monthlyPriceYearlyTotal - quarterlyPriceYearlyTotal
            ),
            ...(quarterlyOfferPercentOff ? { offer: quarterlyOffer } : {}),
          },
        }
      : {}),
    yearly: {
      planId: yearlyPlanId,
      yearlyPrice: roundToHundredths(yearlyPlanPrice),
      monthlyPrice: roundToHundredths(yearlyPlanPrice / 12),
      percentOff: yearlyPercentOff,
      savings: roundToHundredths(monthlyPriceYearlyTotal - yearlyPlanPrice),
      ...(offerPercentOff ? { offer: yearlyOffer } : {}),
    },
  }
}

export const getUpgradePlanOptions = (userPlanId) => {
  if (userPlanId === PLAN_IDS.TWITCH_PRO_MONTHLY) {
    return [PLAN_IDS.TWITCH_PRO_YEARLY, PLAN_IDS.CREATOR_YEARLY]
  }

  if (
    [
      PLAN_IDS.CREATOR_MONTHLY,
      PLAN_IDS.CREATOR_QUARTERLY,
      PLAN_IDS.TWITCH_PRO_YEARLY,
    ].includes(userPlanId)
  ) {
    return [PLAN_IDS.CREATOR_YEARLY, PLAN_IDS.PRO_YEARLY]
  }

  if (
    [
      PLAN_IDS.CREATOR_YEARLY,
      PLAN_IDS.PRO_QUARTERLY,
      PLAN_IDS.PRO_MONTHLY,
    ].includes(userPlanId)
  ) {
    return [PLAN_IDS.PRO_YEARLY, PLAN_IDS.PRO_PLUS_YEARLY]
  }

  return [PLAN_IDS.PRO_PLUS_YEARLY]
}
