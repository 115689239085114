import Immutable from "immutable"

const File = Immutable.Record({
  playback_url: "",
  peak_data_url: "",
  waveform_base_url: "",
  waveform_progress_url: "",
})

const SoundEffectRecord = Immutable.Record(
  {
    id: null,
    type: "sound_effects",

    // Attributes
    archived: false,
    title: "",
    description: "",
    download_url: null,
    file: new File(),
    variations: false,
    duration: "",
    categories: [],
    subcategories: [],
    category_levels: [],
    objectID: "",
    keywords: [],
    use_peak_data_for_waveform: false,
    primary_category: "",
  },
  "SoundEffect"
)

class SoundEffect extends SoundEffectRecord {
  constructor(args) {
    super(
      Object.assign({}, args, {
        objectID: args.id,
        file: new File({
          playback_url: !!args.file
            ? args.file.playback_url
            : args.playback_url,
          peak_data_url: !!args.file
            ? args.file.peak_data_url
            : args.peak_data_url,
          waveform_base_url: !!args.file
            ? args.file.waveform_base_url
            : args.waveform_base_url,
          waveform_progress_url: !!args.file
            ? args.file.waveform_progress_url
            : args.waveform_progress_url,
        }),
      })
    )
  }

  peakDataUrl() {
    return this.file.peak_data_url
  }
}

export default SoundEffect
