import React from "react"
import { Text } from "components/Typography"

const NewCallout = ({ textProps }) => {
  return (
    <Text
      color="brand.pinkJoy"
      fontSize="0.65rem"
      fontWeight="semiBold"
      marginBottom="0"
      marginLeft="0"
      lineHeight="0.5"
      position="absolute"
      textAlign="left"
      {...textProps}
    >
      New
    </Text>
  )
}

export default NewCallout
