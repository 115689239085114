import { compact, isNaN, isNil, omitBy, truncate } from "lodash"
import pluralize from "pluralize"

export const secondsToMMSS = (seconds) => {
  const sec = parseInt(seconds, 10)
  const secRemaining = sec % 60
  if (!isNaN(sec)) {
    return (
      Math.floor(sec / 60) +
      ":" +
      (secRemaining < 10 ? `0${secRemaining}` : secRemaining)
    )
  }
  return ""
}

export const parameterize = (str) => {
  return str
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/(^-|-$)/g, "")
}

export const stringArrayToSentence = (arr) => {
  // .slice(0) duplicates the array, otherwise
  // pop will mutate the original object
  const arrClone = arr.slice(0)
  const last = arrClone.pop()
  if (arrClone.length) {
    return `${arrClone.join(", ")} and ${last}`
  }

  return last
}

export const upcaseString = (str) => {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word[0].toUpperCase() + word.substr(1))
    .join(" ")
}

export const formatQueryString = (query, compact = true) =>
  new URLSearchParams(compact ? omitBy(query, isNil) : query).toString()

export const formatBytes = (bytes, precision = 3) => {
  if (bytes === 0) return "0 Bytes"

  precision = Math.max(3, precision)

  const k = 1024
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / k ** i).toPrecision(precision))} ${sizes[i]}`
}

// Adapted from https://stackoverflow.com/a/46134583
export const formatNumber = (number) => {
  number = parseInt(number)
  const ABBREVS = ["", "K", "M", "B"]
  const i =
    0 === number ? number : Math.floor(Math.log(number) / Math.log(1000))
  const result = parseFloat(number / Math.pow(1000, i)).toFixed(i > 0 ? 1 : 0)
  return i < ABBREVS.length ? `${result}${ABBREVS[i]}` : "> 1T"
}

export const formatRoundToHalfDollar = (price, odd = false) => {
  const remainder = price % 1
  let formattedPrice = Math.ceil(price)
  if (remainder < 0.26) {
    formattedPrice = Math.round(price)
  } else if (remainder < 0.51) {
    formattedPrice = Math.round(price * 2) / 2
  }
  if (odd) {
    formattedPrice -= 0.01
  }
  return formattedPrice.toFixed(2)
}

export const formatShowOrHideCents = (amount) => {
  return amount % 100 === 0
    ? Math.round(amount / 100)
    : (amount / 100).toFixed(2)
}

export const secondsToHMS = (duration) => {
  const hours = Math.floor(duration / 60 / 60)
  const minutes = Math.floor(duration / 60) % 60
  const seconds = Math.floor(duration - minutes * 60)

  let result = ""

  if (hours > 0) {
    result += `${hours} hr`
  }

  if (minutes > 0) {
    result += ` ${minutes} min`
  }

  if (hours < 1 && seconds > 0) {
    result += ` ${seconds} sec`
  }

  return result
}

export const pluralizeMedia = (mediaType, count) => {
  let label

  switch (mediaType.toLowerCase()) {
    case "soundeffect":
    case "sound-effects":
    case "sfx":
      label = "Sound Effects"
      break
    case "song":
    case "music":
      label = pluralize("song", count)
      break
    case "video":
      label = pluralize("video", count)
      break
  }

  return `${count} ${label}`
}

export const nameToInitials = (user) => {
  return [user?.first_name?.charAt(0), user?.last_name?.charAt(0)]
    .filter((c) => !!c)
    .join("")
    .toUpperCase()
}

export const avatarName = ({ first_name, last_name, archived }) => {
  const archiveStatus = `${archived ? " (removed)" : ""}`
  return `${first_name} ${last_name}${archiveStatus}`
}

export const humanizeList = (list, options = {}) => {
  const maxShown = Math.max(2, options.maxShown || 2)
  const maxItemLength = options.maxItemLength || 28

  if (list.length < 2) return list[0]

  const truncated = list.map((item) =>
    truncate(item, {
      length: maxItemLength,
      separator: /,? +/,
    })
  )

  const parts = compact([
    ...truncated.slice(0, maxShown),
    list.length > maxShown
      ? pluralize("other", list.length - maxShown, true)
      : null,
  ])

  if (parts.length < 3) {
    return parts.join(" and ")
  } else {
    return parts.slice(0, -1).join(", ") + ", and " + parts[parts.length - 1]
  }
}
