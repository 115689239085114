import styled from "styled-components"
import inputStyles from "./inputStyles"
import { color, typography, border, space } from "styled-system"

const Input = styled.input`
  ${inputStyles}
  ${color}
  ${typography}
  ${border}
  ${space}
`
export default Input
