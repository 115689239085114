import { fromJS } from "immutable"
import { createAction } from "ducks/utils"
import { createSelector } from "reselect"

const storeJS = require("utils/store.min.js")

export const FORM = "referral_form"

export const types = {
  SEND_REFERRAL: "soundstripe/Referral/SEND_REFERRAL",
  SEND_REFERRAL_SUCCESS: "soundstripe/Referral/SEND_REFERRAL_SUCCESS",
  SEND_REFERRAL_FAIL: "soundstripe/Referral/SEND_REFERRAL_FAIL",
  GET_REFERRING_USER_ID: "soundstripe/Referral/GET_REFERRING_USER_ID",
  REMOVE_REFERRING_USER_ID: "soundstripe/Referral/REMOVE_REFERRING_USER_ID",
  RESET_SENT: "soundstripe/Referral/RESET_SENT",
}

export const actions = {
  sendReferral: createAction(types.SEND_REFERRAL, "emails"),
  sendReferralSuccess: createAction(types.SEND_REFERRAL_SUCCESS),
  sendReferralFail: createAction(types.SEND_REFERRAL_FAIL, "error"),
  getReferringUserId: createAction(types.GET_REFERRING_USER_ID),
  removeReferringUserId: createAction(types.REMOVE_REFERRING_USER_ID),
  resetSent: createAction(types.RESET_SENT),
}

const initialState = fromJS({
  referring_user_id: storeJS.get("referring_user_id"),
})

export const LOCAL_STORAGE_KEY = "referring_user_id"

export default function(state = initialState, action) {
  switch (action.type) {
    case types.RESET_SENT:
      return state.set("sent", false)
    case types.SEND_REFERRAL:
      return state.set("sending", true)
    case types.SEND_REFERRAL_SUCCESS:
      return state.set("sending", false).set("sent", true)
    case types.SEND_REFERRAL_FAIL:
      return state.set("sending", false).set("error", action.error)
    case types.GET_REFERRING_USER_ID: {
      const referringUserId = storeJS.get(LOCAL_STORAGE_KEY)
      return state.set("referring_user_id", referringUserId)
    }
    case types.REMOVE_REFERRING_USER_ID:
      storeJS.remove(LOCAL_STORAGE_KEY)
      return state.set("referring_user_id", undefined)
    default:
      return state
  }
}

const referralState = (state) => state.getIn(["ducks", "referral"])

export const selectReferralSent = () =>
  createSelector(referralState, (refState) => refState.get("sent"))

export const selectReferringUserId = () =>
  createSelector(referralState, (refState) => refState.get("referring_user_id"))
