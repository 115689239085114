import { call, put, takeEvery } from "redux-saga/effects"
import { post } from "utils/request"
import { actions as uiActions } from "ducks/ui"
import { types } from "ducks/video"

export function* getDownloadUrl(action) {
  try {
    const data = {
      data: {
        attributes: {
          downloadable_type: "Video",
          downloadable_id: parseInt(action.downloadableId, 10),
          sale_id: parseInt(action.saleId, 10),
          version: action.version,
        },
        type: "downloads",
      },
    }
    const options = {
      data: JSON.stringify(data),
    }
    const requestURL = `${process.env.API_URL}/me/downloads`
    const response = yield call(post, requestURL, options)
    if (action.useDropbox) {
      const files = [
        {
          url: response.download_url,
        },
      ]

      window.Dropbox.save({
        files,
      })
    } else {
      window.location.assign(response.download_url)
    }
  } catch (err) {
    yield put(uiActions.setError(err))
  }
}

export function* watchGetDownloadUrl() {
  yield takeEvery(types.DOWNLOAD_URL, getDownloadUrl)
}

export default [watchGetDownloadUrl]
