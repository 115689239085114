import { call, put, takeLatest } from "redux-saga/effects"
import { types, actions as flagActions } from "ducks/flag"
import { baseApiUrl } from "utils/urls"

export function* load() {
  try {
    const response = yield call(fetch, `${baseApiUrl()}/app/v2/flags`, {
      headers: { Accept: "application/json" },
    })
    const json = yield response.json()
    yield put(flagActions.loadSuccess(json))
  } catch (err) {
    console.error(err)
    yield put(flagActions.loadFail(err))
  }
}

export function* watchLoad() {
  yield takeLatest(types.LOAD, load)
}

export default [watchLoad, load]
