import Immutable from "immutable"
import Avatar from "./Avatar"

export const TEAM_MEMBER_ROLES = ["owner", "team_member"]

const AccountUserRecord = Immutable.Record(
  {
    id: null,
    type: "account_user",

    // Attributes
    avatar: Avatar,
    name: "",
    email: "",
    role: "",
  },
  "AccountUser"
)

class AccountUser extends AccountUserRecord {
  constructor(args) {
    super(
      Object.assign({}, args, {
        avatar: new Avatar(args.avatar),
      })
    )
  }

  initials() {
    return this.name
      .split(" ")
      .map((word) => word[0].toUpperCase())
      .join(" ")
  }

  roleDisplay() {
    return this.role === "owner" ? "Admin" : "Team Member"
  }
}

export default AccountUser
