import { fromJS, List } from "immutable"
import { createSelector } from "reselect"
import { createRecordsFromResponse } from "records/util"
import { createAction } from "./utils"

export const types = {
  CREATE: "soundstripe/project/CREATE",
  LOAD: "soundstripe/project/LOAD",
  LOAD_SUCCESS: "soundstripe/project/LOAD_SUCCESS",
  LOAD_FAILED: "soundstripe/project/LOAD_FAILED",
}

export const actions = {
  create: createAction(
    types.CREATE,
    "form",
    "project",
    "projectItems",
    "mobileSongObject",
    "onSuccess",
    "callback"
  ),
  loadProjects: createAction(types.LOAD),
  loadSuccess: createAction(types.LOAD_SUCCESS, "response"),
  loadFailed: createAction(types.LOAD_FAILED),
}

const emptyList = new List()

const initialState = fromJS({
  loading: false,
  error: null,
  projects: emptyList,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD: {
      return state.set("loading", true)
    }
    case types.LOAD_SUCCESS: {
      const projectResponse = {
        data: action.response,
      }
      const immRecords = createRecordsFromResponse(projectResponse)
      return state.set("projects", immRecords).set("loading", false)
    }
    case types.LOAD_FAILED: {
      return state.set("loading", false)
    }
    default: {
      return state
    }
  }
}

const projectsState = (state) => state.getIn(["ducks", "project"])

export const selectLoading = () =>
  createSelector(projectsState, (projects) => projects.get("loading"))

export const selectProjects = () =>
  createSelector(projectsState, (projects) => projects.get("projects"))
