import React from "react"

export const MOBILE = "mobile"
export const DESKTOP = "desktop"

export const withScreenSize = (WrappedComponent) =>
  class extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        screen: this.getSize(),
        widthInEms: this.widthInEms(),
      }
    }

    componentDidMount = () => {
      window.addEventListener("resize", this.handleResize)
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.handleResize)
    }

    widthInEms = () => {
      // see: https://stackoverflow.com/a/42061290
      return (
        window.innerWidth /
        parseFloat(
          getComputedStyle(document.querySelector("html"))["font-size"]
        )
      )
    }

    getSize = () => {
      return this.widthInEms() < 48 ? MOBILE : DESKTOP
    }

    handleResize = () => {
      const screen = this.getSize()
      if (screen !== this.state.screen) {
        this.setState({ screen })
      }
      const widthInEms = this.widthInEms()
      if (widthInEms !== this.state.widthInEms) {
        this.setState({ widthInEms })
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          screenSize={this.state.screen}
          isMobile={this.state.screen === MOBILE}
          isDesktop={this.state.screen === DESKTOP}
          widthInEms={this.state.widthInEms}
        />
      )
    }
  }
