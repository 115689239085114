import { createGlobalStyle } from "styled-components"
import flexBoxTheme from "containers/App/FlexboxConstants"

const generateSpacingUtilities = (num) => `
  // Margin Utilities
  .m-${num} {
    margin: ${num}em !important;
  }

  .mx-${num} {
    margin-left: ${num}em !important;
    margin-right: ${num}em !important;
  }

  .my-${num} {
    margin-top: ${num}em !important;
    margin-bottom: ${num}em !important;
  }

  .mt-${num} {
    margin-top: ${num}em !important;
  }

  .mb-${num} {
    margin-bottom: ${num}em !important;
  }

  .ml-${num} {
    margin-left: ${num}em !important;
  }

  .mr-${num} {
    margin-right: ${num}em !important;
  }

  .m-neg-${num} {
    margin: -${num}em !important;
  }

  .mx-neg-${num} {
    margin-left: -${num}em !important;
    margin-right: -${num}em !important;
  }

  .my-neg-${num} {
    margin-top: -${num}em !important;
    margin-bottom: -${num}em !important;
  }

  .mt-neg-${num} {
    margin-top: -${num}em !important;
  }

  .mb-neg-${num} {
    margin-bottom: -${num}em !important;
  }

  .ml-neg-${num} {
    margin-left: -${num}em !important;
  }

  .mr-neg-${num} {
    margin-right: -${num}em !important;
  }

  // Padding Utilities
  .p-${num} {
    padding: ${num}em !important;
  }

  .px-${num} {
    padding-left: ${num}em !important;
    padding-right: ${num}em !important;
  }

  .py-${num} {
    padding-top: ${num}em !important;
    padding-bottom: ${num}em !important;
  }

  .pt-${num} {
    padding-top: ${num}em !important;
  }

  .pb-${num} {
    padding-bottom: ${num}em !important;
  }

  .pl-${num} {
    padding-left: ${num}em !important;
  }

  .pr-${num} {
    padding-right: ${num}em !important;
  }

  .p-neg-${num} {
    padding: -${num}em !important;
  }

  .px-neg-${num} {
    padding-left: -${num}em !important;
    padding-right: -${num}em !important;
  }

  .py-neg-${num} {
    padding-top: -${num}em !important;
    padding-bottom: -${num}em !important;
  }

  .pt-neg-${num} {
    padding-top: -${num}em !important;
  }

  .pb-neg-${num} {
    padding-bottom: -${num}em !important;
  }

  .pl-neg-${num} {
    padding-left: -${num}em !important;
  }

  .pr-neg-${num} {
    padding-right: -${num}em !important;
  }
`

/* eslint no-unused-expressions: 0 */
const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    background-color: #fff;
    &.modal-open{
      overflow: hidden;
    }
  }

  html.darkMode,
  body.darkMode {
    background-color: #0D0F11;
  }

  body {
    font-family: 'Graphik', 'Avenir Next', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    line-height: 1.5;
    font-size: 16px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    margin: 0;
  }

  body.overlayed {
    @media only screen and (max-width: 48em) {
      overflow: hidden;
      position: fixed;
    }
  }

  button {
    font-family: 'Graphik', 'Avenir Next', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }

  hr {
    border-top-color: #242424;
  }

  #app {
    height: 100%;
  }

  #chatra {
    &:not(.chatra--expanded) {
      display: none;
    }
  }

  a,
  button {
    &:focus-visible {
      box-shadow:
        0 0 0 3px transparent,
        0 0 0 2px #5186F0;
      outline: none;
    }

    &:focus:not(:focus-visible) {
      outline: initial;
      box-shadow: none;
    }
  }

  @-moz-document url-prefix() {
    .hideInFirefox {
      overflow: hidden !important;
    }
  }

  .float-right {
    float: right;
  }

  .float-left {
    float: left;
  }

  .font-weight-normal {
    font-weight: normal;
  }

  .font-weight-bold {
    font-weight: bold;
  }

  .font-weight-light {
    font-weight: light;
  }

  .font-italic {
    font-style: italic;
  }

  .text-small {
    font-size: 12px;
  }

  .text-medium {
    font-size: 14px;
  }

  .text-uppercase {
    text-transform: uppercase;
  }

  .text-lowercase {
    text-transform: lowercase;
  }

  .text-capitalize {
    text-transform: capitalize;
  }

  .text-transform-none {
    text-transform: none;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .text-left {
    text-align: left;
  }

  .text-info {
    color: #582ffd;
  }

  /* Refactor .transparent-label after Black Friday 2019 */
  .transparent-label {
    input[type="email"] {
      background: transparent;
    }
  }

  .hidden {
    display: none !important;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .clearfix {
    &:before, &:after {
      content: "";
      display: table;
    }

    &:after {
      clear: both;
    }
  }

  .pointer {
    &:hover {
      cursor: pointer;
    }
  }

  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  ${generateSpacingUtilities(0)}
  ${generateSpacingUtilities(1)}
  ${generateSpacingUtilities(2)}
  ${generateSpacingUtilities(3)}
  ${generateSpacingUtilities(4)}
  ${generateSpacingUtilities(5)}

  .show-after-xs{
    @media (max-width: ${flexBoxTheme.breakpoints.xs}em) {
      display: none !important;
    }
  }

  .show-after-sm {
    @media (max-width: ${flexBoxTheme.breakpoints.sm}em) {
      display: none !important;
    }
  }

  .show-after-md {
    @media (max-width: ${flexBoxTheme.breakpoints.md}em) {
      display: none !important;
    }
  }

  .show-after-lg {
    @media (max-width: ${flexBoxTheme.breakpoints.lg}em) {
      display: none !important;
    }
  }
  // Margin Utilities
  .m-half {
    margin: 0.5em !important;
  }

  .mx-half {
    margin-left: 0.5em !important;
    margin-right: 0.5em !important;
  }

  .my-half {
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
  }

  .mt-half {
    margin-top: 0.5em !important;
  }

  .mb-half {
    margin-bottom: 0.5em !important;
  }

  .ml-half {
    margin-left: 0.5em !important;
  }

  .mr-half {
    margin-right: 0.5em !important;
  }

  .m-neg-half {
    margin: -0.5em !important;
  }

  .mx-neg-half {
    margin-left: -0.5em !important;
    margin-right: -0.5em !important;
  }

  .my-neg-half {
    margin-top: -0.5em !important;
    margin-bottom: -0.5em !important;
  }

  .mt-neg-half {
    margin-top: -0.5em !important;
  }

  .mb-neg-half {
    margin-bottom: -0.5em !important;
  }

  .ml-neg-half {
    margin-left: -0.5em !important;
  }

  .mr-neg-half {
    margin-right: -0.5em !important;
  }

  // Padding Utilities
  .p-half {
    padding: 0.5em !important;
  }

  .px-half {
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;
  }

  .py-half {
    padding-top: 0.5em !important;
    padding-bottom: 0.5em !important;
  }

  .pt-half {
    padding-top: 0.5em !important;
  }

  .pb-half {
    padding-bottom: 0.5em !important;
  }

  .pl-half {
    padding-left: 0.5em !important;
  }

  .pr-half {
    padding-right: 0.5em !important;
  }

  .p-neg-half {
    padding: 0-.5em !important;
  }

  .px-neg-half {
    padding-left: -0.5em !important;
    padding-right: -0.5em !important;
  }

  .py-neg-half {
    padding-top: -0.5em !important;
    padding-bottom: -0.5em !important;
  }

  .pt-neg-half {
    padding-top: -0.5em !important;
  }

  .pb-neg-half {
    padding-bottom: -0.5em !important;
  }

  .pl-neg-half {
    padding-left: -0.5em !important;
  }

  .pr-neg-half {
    padding-right: -0.5em !important;
  }

  .rc-slider-tooltip {
    z-index: 200;
  }

  .inline-block {
    display: inline-block !important;
  }

  .overflow-wrap-anywhere {
    overflow-wrap: anywhere
  }

  /* Add to visually hide content that will be read by a screen reader */
  .sr-only {
    height:1px;
    left:-10000px;
    overflow:hidden;
    position:absolute;
    top:auto;
    width:1px;
  }

  /**
  * Tooltip Styles
  */

  /* Add this attribute to the element that needs a tooltip */
  [data-tooltip-wide],
  [data-tooltip-bottom],
  [data-tooltip-bottom-wide],
  [data-tooltip-left-wide],
  [data-tooltip] {
    position: relative;
    z-index: 3;
    /* cursor: pointer; */
  }

  /* Hide the tooltip content by default */
  [data-tooltip-wide]:before,
  [data-tooltip-bottom]:before,
  [data-tooltip-bottom-wide]:before,
  [data-tooltip-left-wide]:before,
  [data-tooltip]:before,
  [data-tooltip-wide]:after,
  [data-tooltip-bottom]:after,
  [data-tooltip-bottom-wide]:after,
  [data-tooltip-left-wide]:after,
  [data-tooltip]:after {
    visibility: hidden;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    pointer-events: none;
    font-family: 'Graphik', 'Avenir Next', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }

  /* Position tooltip above the element */
  [data-tooltip]:before {
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-bottom: 5px;
    margin-left: -44px;
    padding: 8px 12px;
    width: 88px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 4px;
    background-color: #22252A;
    color: #FFF;
    content: attr(data-tooltip);
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.25;
    transition:
      opacity 0.2s ease-in-out,
      visibility 0.2s ease-in-out,
      transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
    z-index: 800;
  }

  /* Position tooltip bellow the element */
  [data-tooltip-bottom]:before {
    position: absolute;
    top: 120%;
    left: 50%;
    margin-bottom: 5px;
    margin-left: -50px;
    padding: 7px;
    width: 100px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background-color: #22252A;
    color: #fff !important;
    content: attr(data-tooltip-bottom);
    text-align: center;
    font-size: 14px;
    line-height: 1.2;
    transition:
      opacity 0.2s ease-in-out,
      visibility 0.2s ease-in-out,
      transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  }

  /* Position tooltip bellow the element */
  [data-tooltip-bottom-wide]:before {
    position: absolute;
    top: 120%;
    left: 50%;
    margin-bottom: 5px;
    margin-left: -80px;
    padding: 7px;
    width: 160px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background-color: #22252A;
    color: #fff !important;
    content: attr(data-tooltip-bottom-wide);
    text-align: center;
    font-size: 14px;
    line-height: 1.2;
    transition:
      opacity 0.2s ease-in-out,
      visibility 0.2s ease-in-out,
      transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  }

  /* Position tooltip bellow the element */
  [data-tooltip-left-wide]:before {
    position: absolute;
    top: -80%;
    right: 120%;
    margin-bottom: 0;
    padding: 7px;
    width: 160px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background-color: #22252A;
    color: #fff !important;
    content: attr(data-tooltip-left-wide);
    text-align: left;
    font-size: 14px;
    line-height: 1.2;
    transition:
      opacity 0.1s ease-in-out,
      visibility 0.1s ease-in-out,
  }

  /* Position tooltip above the element */
  [data-tooltip-wide]:before {
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-bottom: 5px;
    margin-left: -80px;
    padding: 7px;
    width: 160px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background-color: #22252A;
    color: #fff;
    content: attr(data-tooltip-wide);
    text-align: center;
    font-size: 14px;
    line-height: 1.2;
    transition:
      opacity 0.2s ease-in-out,
      visibility 0.2s ease-in-out,
      transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  }

  /* Triangle hack to make tooltip look like a speech bubble */
  [data-tooltip-wide]:after,
  [data-tooltip]:after {
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    width: 0;
    border-top: 5px solid #22252A;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    content: " ";
    font-size: 0;
    line-height: 0;
    transition:
      opacity 0.2s ease-in-out,
      visibility 0.2s ease-in-out,
      transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  }

  [data-tooltip-bottom]:after,
  [data-tooltip-bottom-wide]:after {
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    width: 0;
    border-bottom: 5px solid #22252A;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    content: " ";
    font-size: 0;
    line-height: 0;
    transition:
      opacity 0.2s ease-in-out,
      visibility 0.2s ease-in-out,
      transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  }

  [data-tooltip-left]:after,
  [data-tooltip-left-wide]:after {
    position: absolute;
    top: 30%;
    right: 100%;
    margin-left: 0;
    width: 0;
    border-left: 5px solid #22252A;
    border-bottom: 5px solid transparent;
    border-top: 5px solid transparent;
    content: " ";
    font-size: 0;
    line-height: 0;
    transition:
      opacity 0.1s ease-in-out,
      visibility 0.1s ease-in-out,
  }

  /* Show tooltip content on hover */
  [data-tooltip-wide]:hover:before,
  [data-tooltip-bottom]:hover:before,
  [data-tooltip-bottom-wide]:hover:before,
  [data-tooltip-left-wide]:hover:before,
  [data-tooltip]:hover:before,
  [data-tooltip-wide]:hover:after,
  [data-tooltip-bottom]:hover:after,
  [data-tooltip-bottom-wide]:hover:after,
  [data-tooltip-left-wide]:hover:after,
  [data-tooltip]:hover:after {
    visibility: visible;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    z-index: 800;
  }

  [data-tooltip-delay]:before,
  [data-tooltip-wide]:after {
    transition: opacity 0.2s linear 1.5s;
  }

  [data-tooltip-front] {
    z-index: 300;
  }

  .react-multi-email>input {
    min-width: 200px !important;
  }

  .ellipsis {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .display-none {
    display: none;
  }

  .masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
  }

  .masonry-grid-col {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }

  /* Style your items */
  .masonry-grid-col > div { /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 30px;
  }
`

export default GlobalStyle
