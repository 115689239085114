import PropTypes from "prop-types"
import React, { useState, memo, useRef, useEffect } from "react"
import styled from "styled-components"
import { Link } from "react-router"
import classNames from "classnames"
import Box from "components/Box"
import Icon from "components/Icon"
import {
  ICON_SS_CHEVRON_DOWN,
  ICON_SS_CHEVRON_UP,
} from "components/Icon/constants"
import NewCallout from "components/NewCallout"

import linkStyles from "./linkStyles"

const Tab = styled.button`
  ${linkStyles} position: relative;
`

export const DropDownSubLink = styled(Link)`
  color: ${(props) => props.theme.colors.text.primary};
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSizes.sm};
  font-weight: ${(props) => props.theme.fontWeights.medium};
  letter-spacing: ${(props) => props.theme.letterSpacings[1]};
  padding: ${(props) => props.theme.space[3]};
  text-decoration: none;
  text-transform: capitalize;
  text-align: left;
  transition: all ${(props) => props.theme.transitions.quick};
  user-select: none;
  white-space: nowrap;

  &:hover {
    background: ${(props) => props.theme.colors.background.tertiary};
  }
`

const MenuWrapper = styled("div")`
  &.hidden {
    display: none;
  }
`

const DropdownTab = ({
  className,
  newItemPresent,
  title,
  children,
  header,
  ...props
}) => {
  const [visible, setVisibility] = useState(false)
  const menuWrapper = useRef(null)

  const clickOutside = (event) => {
    if (!menuWrapper.current || menuWrapper.current.contains(event.target)) {
      return false
    }
    setVisibility(false)
  }

  useEffect(() => {
    document.addEventListener("mousedown", clickOutside)
    return () => {
      document.removeEventListener("mousedown", clickOutside)
    }
  })

  const handleOnClick = () => {
    setVisibility(!visible)
  }

  return (
    <Tab
      className={className}
      onClick={handleOnClick}
      ref={menuWrapper}
      {...props}
    >
      {newItemPresent && (
        <Box mb={header && "3px"}>
          <NewCallout />
          <div>{title}</div>
        </Box>
      )}

      {!newItemPresent && <div>{title}</div>}
      <MenuWrapper className={classNames({ hidden: !visible })}>
        {children}
      </MenuWrapper>
      <Box display="flex" ml="1">
        <Icon icon={visible ? ICON_SS_CHEVRON_UP : ICON_SS_CHEVRON_DOWN} />
      </Box>
    </Tab>
  )
}

DropdownTab.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  newItemPresent: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string,
  header: PropTypes.bool,
}

DropdownTab.defaultProps = {
  newItemPresent: false,
  header: false,
}

export default memo(DropdownTab)
