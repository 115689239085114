import React, { useMemo } from "react"
import Box from "components/Box"
import { PLAN_IDS } from "utils/authentication"
import useCurrentUser from "hooks/useCurrentUser"
import { BLACK_FRIDAY_FEATURES_BY_PLAN } from "../utils/plan"
import useHolidaySale from "hooks/useHolidaySale"
import { Text } from "components/Typography"

const getPercentOffForCopy = (_planId) => 25

export default () => {
  const offer = useOffer()
  const { currentUser } = useCurrentUser()
  return useMemo(() => offer, [currentUser.id, offer?.couponCode])
}

const useOffer = () => {
  const { currentUser } = useCurrentUser()
  const { active: isBfcmActive } = useHolidaySale()
  const couponCode = process.env.HOLIDAY_COUPON
  const percentOffForCopy = 25
  const endDate = "12/3"
  const offerCta = currentUser.isUpgradeEligible()
    ? "Upgrade at 25% off"
    : "Get 25% off"

  if (!currentUser.eligibleForOffer()) {
    return null
  }

  if (isBfcmActive) {
    return {
      banner: {
        mobileNotification: (
          <Text fontFamily="gintoNord" as="span">
            Get {percentOffForCopy}% off Soundstripe
          </Text>
        ),
        notification: (
          <Box display="flex" alignItems="center">
            <Text fontWeight="semiBold" as="span" color="black">
              🔌 Plug into Year End Savings
            </Text>{" "}
            <Text fontWeight="semiBold" pl={2} fontFamily="gintoNord" as="span">
              Get {percentOffForCopy}% off Soundstripe
            </Text>
          </Box>
        ),
        subHeader: "Get started today!",
      },
      pricingPage: {
        subHeader: "Get started today!",
      },
      buttonText: "Upgrade now",
      couponCode: couponCode,
      endDate,
      footer: "",
      featuresList: BLACK_FRIDAY_FEATURES_BY_PLAN,
      notification: (
        <Box as="span">
          <Box as="span" color="brand.pinkJoy">
            {" "}
            Get {percentOffForCopy}%{" "}
          </Box>
          off 1 Year of Soundstripe
        </Box>
      ),
      notificationSubheader: (
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
        >
          <Box fontSize="md" fontWeight="semiBold" color="text.secondary">
            For a limited time only. Use code {couponCode} at checkout.
          </Box>
        </Box>
      ),
      calculatedPercentDiscount: 25,
      getPercentOffForCopy,
      percentDiscount: percentOffForCopy,
      planIds: [
        PLAN_IDS.CREATOR_YEARLY,
        PLAN_IDS.PRO_YEARLY,
        PLAN_IDS.PRO_PLUS_YEARLY,
      ],
      subscription: {
        notification: (
          <Box>
            Upgrade and get{" "}
            <Box as="span" color="black">
              {percentOffForCopy}%
            </Box>{" "}
            off yearly plans. Make the switch to yearly today.
          </Box>
        ),
        planInfoFooter: (
          <Box>
            Go yearly and save! Switch to a yearly plan and{" "}
            <Box as="span" color="brand.pinkJoy">
              {" "}
              get {percentOffForCopy}% off.
            </Box>
          </Box>
        ),
      },
      upgradeModal: {
        endDate,
        notification: (
          <Box
            display="flex"
            flexDirection={{ _: "column", sm: "row" }}
            alignItems={{ _: "start", sm: "center" }}
            fontSize={{ _: "lg", sm: "xl" }}
            gridGap={{ _: 0, sm: 2 }}
          >
            <div style={{ whitespace: "nowrap" }}>Upgrade & Save:</div>
            <Box as="span" color="brand.pinkJoy">
              {percentOffForCopy}% off Yearly Plans
            </Box>
          </Box>
        ),
      },
      planCard: {
        banner: (_planId) => `25% OFF YEARLY - ACT NOW`,
      },
      upgradeCallout: {
        header: offerCta,
        sidebar: "Our Biggest Sale",
      },
    }
  }

  return null
}
