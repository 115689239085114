import { post, patch } from "utils/request"
import { call, put, takeLatest, select, spawn } from "redux-saga/effects"
import { actions as apiActions } from "ducks/api"
import { storeToken } from "utils/authentication"
import { actions as trackingActions } from "ducks/tracking"
import { stopSubmit } from "redux-form/immutable"
import { push } from "react-router-redux"

import {
  actions as currentUserActions,
  selectCurrentUserRecord,
} from "ducks/currentUser"

import {
  types,
  actions,
  selectCartItems,
  selectCartHasDiscountApplied,
} from "ducks/cart"

export function* setCartStorage(_action) {
  try {
    const currentUser = yield select(selectCurrentUserRecord())
    const address = currentUser.billing_address || {}

    yield put(
      actions.generateInvoiceEstimate({
        billing_address: {
          zip: address.zip,
          country: address.country,
        },
        vat_number: currentUser.vat_number,
        vat_number_prefix: currentUser.vat_number ? address.country : null,
      })
    )
  } catch (err) {}
}

export function* watchSetCartStorage() {
  yield takeLatest(types.SET_CART_STORAGE, setCartStorage)
}

export function* generateInvoiceEstimate(action) {
  try {
    const currentUser = yield select(selectCurrentUserRecord())
    const cartItems = (yield select(selectCartItems())).toJS()

    if (!cartItems.length) {
      yield put(actions.clearInvoiceEstimate())
      return
    }

    const sellables = cartItems.map((item) => ({
      sellable_type: item.sellable_type,
      sellable_id: item.sellable_id,
      license_type: item.license_type.toLowerCase(),
    }))

    const requestURL = `${process.env.API_URL}/users/${currentUser.id}/cart_invoice_estimates`

    const data = {
      data: {
        attributes: {
          ...action.estimateAttributes,
          sellables,
        },
      },
    }

    const { response } = yield call(post, requestURL, { data })

    yield put(actions.generateInvoiceEstimateSuccess(response.estimate))

    // we're hiding the coupon field if discount is applied, so clear the field
    yield spawn(clearCouponIfDiscountApplied)

    if (action.callback) yield action.callback(response.estimate)
  } catch (err) {
    yield put(actions.generateInvoiceEstimateFailed(err))
    if (action.callback) yield action.callback(err)
  }
}

export function* watchGenerateInvoiceEstimate() {
  yield takeLatest(types.GENERATE_INVOICE_ESTIMATE, generateInvoiceEstimate)
}

export function* clearCouponIfDiscountApplied() {
  try {
    if (yield select(selectCartHasDiscountApplied())) {
      yield put(currentUserActions.updateCoupon(""))
    }
  } catch (err) {}
}

export function* paymentRequestButtonSubmit(action) {
  try {
    const { authorizedPaymentIntent, checkoutAttributes } = action
    checkoutAttributes.payment_info.stripe_payment_intent = authorizedPaymentIntent
    const paymentType = checkoutAttributes.payment_type
    delete checkoutAttributes.payment_type

    // Update billing address before we create invoice
    // unless user has already saved payment info
    const user = yield select(selectCurrentUserRecord())
    const billingRequestUrl = `${process.env.API_URL}/users/${user.id}/update_billing_info`

    const billingData = {
      data: {
        type: "users",
        id: user.id.toString(),
        attributes: {
          billing_address: checkoutAttributes.billing_address,
        },
      },
    }

    const billingOptions = { data: JSON.stringify(billingData) }
    yield call(patch, billingRequestUrl, billingOptions)
    delete checkoutAttributes.billing_address
    const requestURL = `${process.env.API_URL}/cart_sales`

    const data = {
      data: {
        type: "sales",
        attributes: checkoutAttributes,
        payment_type: paymentType,
      },
    }

    const options = { data: JSON.stringify(data) }
    const response = yield call(post, requestURL, options)
    if (response?.meta?.token) storeToken(response.meta.token)

    yield put(apiActions.readSuccess(response))
    yield put(trackingActions.trackCartPurchase(response))
    yield put(currentUserActions.removePaymentIntent())
    yield put(actions.paymentRequestButtonSuccess())
    yield put(currentUserActions.load())
    yield put(push(checkoutAttributes.redirectPath))
    yield put(actions.setCartStorage([]))
  } catch (err) {
    yield put(currentUserActions.removePaymentIntent())
    yield put(currentUserActions.removePaymentSource())
    yield put(stopSubmit(action.form, { _error: err }))
    yield put(currentUserActions.requestFailed())
  }
}

export function* watchPaymentRequestButtonSubmit() {
  yield takeLatest(
    types.PAYMENT_REQUEST_BUTTON_SUBMIT,
    paymentRequestButtonSubmit
  )
}

export default [
  watchSetCartStorage,
  watchGenerateInvoiceEstimate,
  generateInvoiceEstimate,
  watchPaymentRequestButtonSubmit,
]
