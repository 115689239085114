import { useEffect } from "react"
import { useDispatch } from "react-redux"
import useCurrentUser from "hooks/useCurrentUser"
import useLocalStorage from "hooks/useLocalStorage"
import { actions as currentUserActions } from "ducks/currentUser"
import { replaceQuery } from "utils/urls"

const useSongRowCart = () => {
  const dispatch = useDispatch()
  const { currentUser } = useCurrentUser()
  const { value, set } = useLocalStorage("song_row_cart")

  const query = new URLSearchParams(window.location.search)

  const paramSet = query.get("song_row_cart") === "true" || value === "true"

  useEffect(() => {
    if (paramSet) {
      set("true")

      if (!!currentUser?.id && !currentUser?.showSongRowCart()) {
        dispatch(
          currentUserActions.update({
            data: {
              attributes: {
                song_row_cart: true,
              },
            },
          })
        )
      }

      query.delete("song_row_cart")
      setTimeout(() => {
        replaceQuery(Object.fromEntries(query))
      }, 0)
    }
  }, [paramSet, currentUser?.id])

  return paramSet || value === "true" || currentUser.showSongRowCart()
}

export default useSongRowCart
