import { useEffect } from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import { withRouter } from "react-router"
import { actions as modalActions } from "ducks/modal"
import {
  actions as currentUserActions,
  selectCurrentUserRecord,
} from "ducks/currentUser"
import { UPGRADE_MODAL_TYPES } from "utils/plan"

// Upsell trigger name for mixpanel tracking
const UPSELL_TRIGGER = "4/30"

const UpsellModalLauncher = (props) => {
  const { location } = props
  const dispatch = useDispatch()
  const currentUser = useSelector(selectCurrentUserRecord())
  const handleOnClose = () => {
    dispatch(currentUserActions.trackUpsellAttempt())
  }

  useEffect(() => {
    let userModalType = UPGRADE_MODAL_TYPES.VISITOR
    const planId = currentUser?.planId()
    if (currentUser && !planId) {
      userModalType = UPGRADE_MODAL_TYPES.FREE_ACCOUNT
    } else if (planId) {
      userModalType = UPGRADE_MODAL_TYPES[planId]
    }
    if (currentUser?.isUpsellEligible() && userModalType) {
      dispatch(
        modalActions.open("BannerUpgradeModal", {
          modalType: userModalType,
          onClose: handleOnClose,
          upsellTrigger: UPSELL_TRIGGER,
        })
      )
    }
  }, [currentUser, location])

  return null
}

UpsellModalLauncher.propTypes = {
  location: PropTypes.object,
}

export default withRouter(UpsellModalLauncher)
