import Immutable from "immutable"

const MarketplaceSaleRecord = Immutable.Record(
  {
    id: null,
    type: "marketplace_sales",
    user: {},
    created_at: null,
    state: "",
  },
  "MarketplaceSale"
)

class MarketplaceSale extends MarketplaceSaleRecord {
  closed = () => this.state === "closed"

  // stub regular sale functions as false
  isSingleSong = () => false
  isSingleUseLicense = () => false
  isFreeDownload = () => false
}

export default MarketplaceSale
